import { LazyQueryHookOptions } from '@apollo/client';
import { format } from 'date-fns';
import { IFilter } from 'interfaces/document';

export const normalizeFilter = (filter: IFilter) => {
	return {
		...filter,
		startDate: format(filter.startDate, 'yyyy-MM-dd'),
		endDate: format(filter.endDate, 'yyyy-MM-dd'),
		statuses: preparedStatus(filter.statuses),
	};
};

export const executeFilterParam = (params: LazyQueryHookOptions, filterParam: keyof IFilter) => {
	return { ...params, variables: { ...params.variables, filter: { ...params?.variables?.filter, [filterParam]: undefined } } };
};

export const preparedStatus = (statuses: string[] | undefined) => {
	return statuses?.map((status) => status.split(',')).flat();
};
