import { clientGraphql } from 'api';
import { GET_STORE_ADRESSES } from 'api/storeAddresses';
import { IPagination } from 'interfaces';
import { IStoreAddress, IStoreAddressesFilter } from 'interfaces/storeAddress';
import { Dispatch } from 'redux';

import {
	CHANGE_STORE_ADRESSES_FILTER,
	FETCH_STORE_ADRESSES_FAILURE,
	FETCH_STORE_ADRESSES_START,
	FETCH_STORE_ADRESSES_SUCCESS,
	SET_STORE_ADRESSES_PAGE,
	SET_STORE_ADRESSES_PAGE_SIZE,
	SET_STORE_DATA,
} from './types';

export const fetchStoreAddresses =
	(filter: IStoreAddressesFilter, { page, pageSize }: IPagination, aborterRef: AbortController) =>
	(dispatch: Dispatch): void => {
		dispatch({ type: FETCH_STORE_ADRESSES_START });
		clientGraphql
			.query({
				query: GET_STORE_ADRESSES,
				variables: { page, pageSize, filter },
				fetchPolicy: 'network-only',
				context: {
					fetchOptions: {
						signal: aborterRef.signal,
					},
				},
			})
			.then((res) => {
				dispatch({
					payload: {
						items: res.data.storeAddresses.lastStoreAddressValuesDtos,
						total: res.data.storeAddresses.total,
					},
					type: FETCH_STORE_ADRESSES_SUCCESS,
				});
			})
			.catch(() => {
				dispatch({ type: FETCH_STORE_ADRESSES_FAILURE });
			});
	};

export const setStoreAddressesPage = (payload: number) => ({
	payload,
	type: SET_STORE_ADRESSES_PAGE,
});

export const setStoreAddressesPageSize = (payload: number) => ({
	payload,
	type: SET_STORE_ADRESSES_PAGE_SIZE,
});

export const changeStoreAddressesFilter = (field: keyof IStoreAddressesFilter, value: IStoreAddressesFilter[typeof field]) => ({
	payload: { field, value },
	type: CHANGE_STORE_ADRESSES_FILTER,
});

export const setStoreData = (payload: IStoreAddress) => ({
	payload,
	type: SET_STORE_DATA,
});
