import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PAYOUT_SCHEDULES, UPDATE_PAYOUT_SCEDULE } from 'api/payoutSchedule';
import NotificationAuthOtherUser from 'containers/NotificationAuthOtherUser';
import format from 'date-fns/format';
import { getAccountingTypes } from 'helpers';
import clsx from 'helpers/clsx';
import { useDeepCompareEffect } from 'hooks/useDeepCompareEffect';
import { useUser } from 'hooks/useUser';
import { AccountingTypes } from 'interfaces';
import { PayoutScheduleDto } from 'interfaces/order';
import { TEST_IDS } from 'tests/config';

import { DatePicker, Select } from 'components/FormControl';
import AccountingTypeIcon from 'components/IconInsideCircle';
import Loader from 'components/Loader';
import MainButton from 'components/MainButton';

import classes from './PaymentSchedule.module.scss';

const PaymentSchedulePage: React.FC = () => {
	const { t } = useTranslation(['payoutSchedules', 'accountingType']);
	const [startDate, setStartDate] = useState<Date>(new Date());
	const [accountingType, setAccountingType] = useState<AccountingTypes[]>([]);
	const [items, setItems] = useState<PayoutScheduleDto[]>([]);
	const [firstLoadItems, setFirstLoadItems] = useState<PayoutScheduleDto[]>([]);
	const { isAdmin, isFinance } = useUser();
	const { data, loading } = useQuery<{ payoutSchedules: PayoutScheduleDto[] }>(GET_PAYOUT_SCHEDULES, {
		variables: {
			filter: {
				dateByMonth: format(startDate, 'yyyy-MM-dd'),
				accountingType,
			},
		},
	});

	const [updatePayoutScedule, { loading: loadingSave }] = useMutation(UPDATE_PAYOUT_SCEDULE, {
		refetchQueries: [GET_PAYOUT_SCHEDULES],
	});

	const handleChangeStartDate = (newDate: Date) => setStartDate(newDate);
	const handleChangeAccountingType = (value: AccountingTypes[]) => setAccountingType(value);

	useDeepCompareEffect(() => {
		setItems(data?.payoutSchedules || []);
		if (!firstLoadItems.length) {
			setFirstLoadItems(data?.payoutSchedules || []);
		}
	}, [data?.payoutSchedules]);

	const handleUpdateItems = (key: keyof PayoutScheduleDto, item: PayoutScheduleDto) => (value: Date) => {
		setItems((state) =>
			state.map((it) => {
				if (it.id === item.id) {
					return {
						...it,
						[key]: format(value, 'yyyy-MM-dd'),
					};
				}
				return it;
			}),
		);
	};

	const handleSave = () => {
		updatePayoutScedule({
			variables: {
				input: items.map(({ __typename, id, ...item }) => item),
			},
		});
	};

	const checkChangedItem = (item: PayoutScheduleDto) => {
		const comapedItem = data?.payoutSchedules.find((it) => it.id === item.id);
		if (!comapedItem) return [];
		const entries = Object.entries(item);
		const changeFields: (keyof PayoutScheduleDto)[] = entries
			.filter(([key, value]) => comapedItem[key as keyof PayoutScheduleDto] !== value)
			.map(([key]) => key as keyof PayoutScheduleDto);
		return changeFields;
	};

	const isGlovoRoles = isAdmin || isFinance;
	const accountingTypes = [...new Set(firstLoadItems.map((it) => it.accountingType))];

	return (
		<div className={classes.root} data-testid={TEST_IDS.PAYMENT_SCHEDULE_PAGE}>
			<NotificationAuthOtherUser />
			{loadingSave && <Loader />}
			<div className={classes.title}>{t('title')}</div>
			<div className={classes.filterWrap}>
				<DatePicker
					showMonthYearPicker
					onChange={handleChangeStartDate}
					datepickerProps={{
						startDate,
					}}
					format="LLLL yyyy"
					rootClassName={classes.datePicker}
					calendarClassName={classes.calendarClassName}
				/>
				{!!accountingTypes.length && (
					<Select
						onChange={handleChangeAccountingType}
						emptyLabel={t('accountingTypes:emptyLabel')}
						multipleTitle={t('accountingTypes:multipleLabel')}
						value={accountingType}
						rootClassName={classes.filterItem}
						multiple
						options={[
							{ value: AccountingTypes.ONCE_A_WEEK, label: t('accountingTypes:options.onceWeek') },
							{ value: AccountingTypes.TWICE_A_MONTH, label: t('accountingTypes:options.twiceMonth') },
							{ value: AccountingTypes.ONCE_A_MONTH, label: t('accountingTypes:options.onceMonth') },
						].filter((type) => accountingTypes.includes(type.value))}
						testId={TEST_IDS.PAYMENT_SCHEDULE_FILTER}
					/>
				)}
			</div>
			<div className={classes.tableHead} data-testid={TEST_IDS.PAYMENT_SCHEDULE_TABLE_HEAD}>
				<div className={classes.tableHeadColl}>{t('cells.period')}</div>
				<div className={classes.tableHeadColl}>{t('cells.expectedPaymentDate')}</div>
				<div className={classes.tableHeadColl}>{t('cells.lastPaymentDate')}</div>
				<div className={classes.tableHeadColl}>{t('cells.lastDiffInputDate')}</div>
			</div>
			<div className={classes.items}>
				{loading ? (
					<Loader simple />
				) : (
					items.map((it) => {
						const expectedPaymentDate = it.expectedPaymentDate ? new Date(it.expectedPaymentDate) : undefined;
						const lastPaymentDate = it.lastPaymentDate ? new Date(it.lastPaymentDate) : undefined;
						const lastDiffInputDate = it.lastDiffInputDate ? new Date(it.lastDiffInputDate) : undefined;
						const fakeId = `${it.startDate}${it.endDate}${it.accountingType}`;
						const changedFields = checkChangedItem(it);
						return (
							<div className={classes.tableHeadRow} key={fakeId} data-testid={TEST_IDS.PAYMENT_SCHEDULE_TABLE_ROW}>
								<div className={clsx(classes.tableHeadColl, classes.tableHeadRowColl)}>
									<AccountingTypeIcon type={it.accountingType} />
									<div>
										{format(new Date(it.startDate), 'dd.MM.yyyy')} – {format(new Date(it.endDate), 'dd.MM.yyyy')}
										<div className={classes.accountingType}>{getAccountingTypes(it.accountingType)}</div>
									</div>
								</div>
								<div className={clsx(classes.tableHeadColl, classes.tableHeadRowColl)}>
									<DatePicker
										disabled={!isGlovoRoles}
										onChange={handleUpdateItems('expectedPaymentDate', it)}
										datepickerProps={{
											minDate: new Date(),
											startDate: expectedPaymentDate,
										}}
										format="dd.MM.yyyy"
										rootClassName={clsx(classes.datePickerColl, {
											[classes.isChangedField]: changedFields.includes('expectedPaymentDate'),
										})}
									/>
								</div>
								<div className={clsx(classes.tableHeadColl, classes.tableHeadRowColl)}>
									<DatePicker
										disabled={!isGlovoRoles}
										onChange={handleUpdateItems('lastPaymentDate', it)}
										datepickerProps={{
											startDate: lastPaymentDate,
											minDate: expectedPaymentDate,
										}}
										format="dd.MM.yyyy"
										rootClassName={clsx(classes.datePickerColl, {
											[classes.isChangedField]: changedFields.includes('lastPaymentDate'),
										})}
									/>
								</div>
								<div className={clsx(classes.tableHeadColl, classes.tableHeadRowColl)}>
									<DatePicker
										disabled={!isGlovoRoles}
										onChange={handleUpdateItems('lastDiffInputDate', it)}
										datepickerProps={{
											startDate: lastDiffInputDate,
										}}
										format="dd.MM.yyyy"
										rootClassName={clsx(classes.datePickerColl, {
											[classes.isChangedField]: changedFields.includes('lastDiffInputDate'),
										})}
									/>
								</div>
							</div>
						);
					})
				)}
			</div>
			{!loading && !!items.length && isGlovoRoles && (
				<div className={classes.saveButtonWrap}>
					<MainButton onClick={handleSave}>{t('save')}</MainButton>
				</div>
			)}
		</div>
	);
};

export default PaymentSchedulePage;
