import React from 'react';
import clsx from 'helpers/clsx';
import { AccountingTypes } from 'interfaces';
import { ReactComponent as CommentedIntoDoc } from 'static/images/comment_doc.svg';
import { ReactComponent as DocsUnsignedIcon } from 'static/images/docs_unsigned.svg';
import { ReactComponent as PaymentSheduleOnceAWeek } from 'static/images/paymentShedule_1.svg';
import { ReactComponent as PaymentSheduleTwiceAMonth } from 'static/images/paymentShedule_2.svg';
import { ReactComponent as PaymentSheduleOnceAMonth } from 'static/images/paymentShedule_4.svg';
import { ReactComponent as WarnIcon } from 'static/images/warn.svg';

import { IProps, TypeIcon } from './types';

import classes from './IconInsideCircle.module.scss';

const AccountingTypeIcon: React.FC<IProps> = ({ className, type }) => {
	const getClassNameIcon = () => {
		switch (type) {
			case AccountingTypes.ONCE_A_WEEK:
				return classes.root__onceAWeek;
			case AccountingTypes.TWICE_A_MONTH:
				return classes.root__twiceAMonth;
			case AccountingTypes.ONCE_A_MONTH:
				return classes.root__onceAMonth;
			case TypeIcon.DOCS:
			case TypeIcon.COMMENT:
				return classes.root__docs;
			default:
				return undefined;
		}
	};

	const getIcon = () => {
		switch (type) {
			case AccountingTypes.ONCE_A_WEEK:
				return <PaymentSheduleOnceAWeek />;
			case AccountingTypes.TWICE_A_MONTH:
				return <PaymentSheduleTwiceAMonth />;
			case AccountingTypes.ONCE_A_MONTH:
				return <PaymentSheduleOnceAMonth />;
			case TypeIcon.DOCS:
				return <DocsUnsignedIcon />;
			case TypeIcon.DEBT:
				return <WarnIcon />;
			case TypeIcon.COMMENT:
				return <CommentedIntoDoc />;
			default:
				return undefined;
		}
	};

	return <span className={clsx(classes.root, className, getClassNameIcon())}>{getIcon()}</span>;
};

export default AccountingTypeIcon;
