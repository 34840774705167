import { gql } from '@apollo/client';

export default gql`
	type BulkUploadFileDto {
		fileId: String
		filename: String
		createdAt: Date
		status: Boolean
		createdBy: String
		comment: String
		errorFileId: String
	}
`;
