import React from 'react';
import { Redirect } from 'react-router';
import { Routes } from 'const';
import { useUser } from 'hooks/useUser';

const Index: React.FC = () => {
	const { isCustomer, loading } = useUser();
	if (loading) return null;
	if (isCustomer) return <Redirect to={Routes.LAAS_ORDERS} />;
	return <Redirect to={Routes.ORDERS} />;
};

export default Index;
