import { IInvoicesModify } from 'interfaces/invoices';

export interface IProps {
	item: IInvoicesModify;
	last?: boolean;
}

export enum STATUS {
	PARTIALLY_PAID = 'PARTIALLY_PAID',
	NOT_PAID = 'NOT_PAID',
	PAID = 'PAID',
}
