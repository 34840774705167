import { CountryCodeEnum } from 'interfaces';
import { IJwt } from 'interfaces/jwt';
import jwtDecode from 'jwt-decode';

import { getTokenSync } from './token';

export const getDecodedJwt = (): (Omit<IJwt, 'AvailableCountries'> & { AvailableCountries: string[] }) | undefined => {
	const token = getTokenSync ? getTokenSync() : undefined; // review for tests
	if (!token) return undefined;
	try {
		const jwt: IJwt = jwtDecode(token);
		return {
			...jwt,
			AvailableCountries: (jwt.AvailableCountries as CountryCodeEnum[] as unknown as string)?.split(',').map((c: string) => c.trim()),
		};
	} catch (_) {
		return undefined;
	}
};

export const getStoreAddressId = () => {
	const jwt = getDecodedJwt();
	return jwt?.UserStoreAddressesIds || [];
};

export const getCountry = (): CountryCodeEnum | undefined => {
	const jwt = getDecodedJwt();
	return jwt?.Country;
};

export const getCurrency = (): React.FC<React.SVGProps<SVGSVGElement>> | undefined => {
	switch (getCountry()) {
		default:
			return undefined;
	}
};

export const getLangFromToken = () => {
	const jwt = getDecodedJwt();
	return jwt?.Lang;
};

export const getLang = (defaultLang = 'uk'): string | undefined => {
	const langFromToken = getLangFromToken();
	if (langFromToken) return langFromToken;
	const country = getCountry();
	if (!country) return defaultLang;
	switch (country) {
		case 'UA':
			return 'uk';
		case 'KG':
		case 'KZ':
			return 'ru';
		case 'GE':
		case 'AM':
			return 'en';
		default:
			return country.toLowerCase();
	}
};
