import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatPrice } from 'helpers';
import clsx from 'helpers/clsx';
import { IDebt, IDebtWithChildren } from 'interfaces/invoices';
import { IStoreAddress } from 'interfaces/storeAddress';
import { ReactComponent as InfoIco } from 'static/images/info.svg';
import { ReactComponent as ArrIcon } from 'static/images/keyboard_arrow_down.svg';
import { ReactComponent as WarningIco } from 'static/images/warn.svg';
import { TEST_IDS } from 'tests/config';

import { Select } from 'components/FormControl';
import Tooltip from 'components/Tooltip';

import DebtLineChild from './DebtLineChild';

import classes from './DebtTable.module.scss';

const DebtLine: FC<{ debt: IDebtWithChildren; activeTab: boolean; storeData: IStoreAddress[] | [] }> = ({ debt, storeData, activeTab }) => {
	const { t } = useTranslation('accounts');
	const [isCollapse, setIsCollapse] = useState(false);
	const handleChangeOnlyView = () => undefined;

	const handleCollapse = () => {
		setIsCollapse(!isCollapse);
	};

	const hasMoreThanOneStore = !!storeData.length && !!(storeData.length - 1);

	return (
		<div className={classes.root} data-testid={TEST_IDS.ACCOUNTS_TABLE_BODY_DEBT_ROW}>
			<div className={classes.tableHeadRow}>
				<div className={classes.tableHeadColl}>
					{debt.children.length > 0 && (
						<div onClick={handleCollapse} className={clsx(classes.dropArrow, { [classes.сollapse]: isCollapse })}>
							<ArrIcon data-testid={TEST_IDS.ACCOUNTS_TABLE_BODY_DEBT_ROW_TOGGLE} />
						</div>
					)}
					<div className={classes.companyInfo}>
						<div className={classes.name}>{debt.companyName}</div>
						<span className={classes.companyInfo_id}>{debt.edrpou}</span>
					</div>
				</div>
				<div className={classes.tableHeadColl}>
					<div className={clsx(classes.institutions, { [classes.institutions_one]: !hasMoreThanOneStore })}>
						<div className={classes.name}>
							{storeData[0]?.storeAddressId} {storeData[0]?.storeName}
						</div>
						<span className={classes.institutions_address}>{storeData[0]?.address}</span>
					</div>
					{hasMoreThanOneStore && (
						<div className={classes.selectPlace}>
							<Select
								onlyView
								rootClassName={classes.addressSelect}
								controlClassName={classes.control}
								emptyLabel={`+${t('institution', { count: storeData.length - 1 })}`}
								onChange={handleChangeOnlyView}
								disabled={!storeData.length}
								options={storeData
									?.slice(1)
									?.map(({ storeAddressId, storeName, address }) => ({ value: storeAddressId, label: storeName, additionalLabel: address }))}
								renderOption={({ value, label, additionalLabel }) => (
									<div className={classes.selectOption}>
										<div className={classes.selectOption_headline}>
											<div>{value}</div>
											<div>{label}</div>
										</div>
										<div className={classes.selectOption_additional}>{additionalLabel}</div>
									</div>
								)}
							/>
						</div>
					)}
				</div>
				<div className={classes.tableHeadColl}>
					<div className={classes.contractNumber}>{debt.contractNumber}</div>
				</div>
				<div className={classes.tableHeadColl}>
					<div>
						<span className={clsx(classes.sum, { [classes.emptyState]: !debt.unexpiredDebtAmount })}>
							{formatPrice(debt.unexpiredDebtAmount, 2, ',', '0,00')}
						</span>
					</div>
				</div>
				<div className={classes.tableHeadColl}>
					<div className={classes.expired}>
						<div className={clsx({ [classes.emptyState]: !debt.expiredDebtAmount })}> {formatPrice(debt.expiredDebtAmount, 2, ',', '0,00')}</div>
						{!!debt.expiredDebt30DaysAmount && !!debt.expiredDebtAmount && !activeTab && (
							<Tooltip
								className={classes.warnTooltip}
								placement="bottom"
								text={<Trans t={t} i18nKey="tooltips.warning" components={{ bold: <span className={classes.bold} /> }} />}
								nowrap
							>
								<div>
									<WarningIco />
								</div>
							</Tooltip>
						)}
					</div>
				</div>
				<div className={classes.tableHeadColl}>
					<div className={classes.agree}>
						<div
							className={clsx(classes.sum, {
								[classes.agreeSum]: debt.totalDebtAmount < 0,
								[classes.emptyState]: !debt.totalDebtAmount,
							})}
						>
							{formatPrice(debt.totalDebtAmount, 2, ',', '0,00')}
						</div>
						{debt.totalDebtAmount < 0 && activeTab && (
							<Tooltip placement="bottom-end" text={<Trans t={t} i18nKey="tooltips.overpayment" />} nowrap>
								<div>
									<InfoIco />
								</div>
							</Tooltip>
						)}
					</div>
				</div>
			</div>
			{isCollapse && (
				<div className={classes.collapseSection}>
					{debt.children.map((child: IDebt) => (
						<DebtLineChild debtChild={child} activeTab={activeTab} key={child.id} />
					))}
				</div>
			)}
		</div>
	);
};
export default DebtLine;
