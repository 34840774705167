import React from 'react';
import clsx from 'helpers/clsx';

import { IPropsSelectOption } from './types';

import classes from './FormControl.module.scss';

const OptionComponent: React.FC<IPropsSelectOption> = ({ children, className, onClick }) => (
	<div className={clsx(classes.selectOption, className)} onClick={onClick}>
		{children}
	</div>
);

export default OptionComponent;
