import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_INFO } from 'api/invoices';
import format from 'date-fns/format';
import { useInvoices } from 'hooks/useInvoices';
import { ICompanyInfo, IContractNumbers } from 'interfaces/filter';
import { TEST_IDS } from 'tests/config';

import { Checkbox, DatePicker, Select } from 'components/FormControl';

import classes from './InvoiceFilters.module.scss';

const Filters: FC<{ activeTab: boolean }> = ({ activeTab }) => {
	const { t } = useTranslation('accounts');
	const { filter, changeFilter } = useInvoices();
	const { loading: loadingCompanyNames, data: companyInfo } = useQuery<ICompanyInfo>(GET_COMPANY_INFO, {
		variables: {
			filter: {
				startDate: format(filter.startDate, 'yyyy-MM-dd'),
				endDate: format(filter.endDate, 'yyyy-MM-dd'),
				edrpou: filter.taxId,
			},
		},
	});
	const [selectedContractNumbers, setSelectedContractNumbers] = useState<string[]>();

	const handleChangeCompany = (edrpou: string) => {
		changeFilter('edrpou', edrpou);
	};

	const handleChangeContractNumber = (contractNumber: string) => {
		changeFilter('contractNumber', contractNumber);
	};

	const handleChangeDatePicker = (dates: [Date, Date]) => {
		const [start, end] = dates;
		if (start && end) {
			changeFilter('startDate', start);
			changeFilter('endDate', end);
		}
	};

	const handleChangeStatus = (statuses: string[]) => {
		changeFilter('statuses', statuses);
	};

	const handlePaymentFilter = () => {
		changeFilter('status', filter.status ? undefined : true);
	};

	const statusFilterData = [
		{ value: 'PAID', label: t('paid') },
		{ value: 'PARTIALLY_PAID', label: t('partiallyPaid') },
		{ value: 'NOT_PAID', label: t('unPaid') },
	];

	const getContractNumbers = (contractInfo: IContractNumbers[]) => {
		const allContractNumers = contractInfo.map(({ value }) => value.trim());
		return [...new Set(allContractNumers)];
	};

	useEffect(() => {
		const selectedCompany = companyInfo?.companyInfo.find((company) => company.taxId === filter.edrpou);
		if (selectedCompany) {
			// changeFilter('contractNumber', undefined);
			return setSelectedContractNumbers(getContractNumbers(selectedCompany.contractInfo));
		}
		if (companyInfo) {
			const contractNumbers = companyInfo?.companyInfo.map((num) => getContractNumbers(num.contractInfo)).flat();
			setSelectedContractNumbers(contractNumbers);
		}
	}, [filter.edrpou, companyInfo]);

	return (
		<div className={classes.sectionFilters}>
			<DatePicker
				onChange={handleChangeDatePicker}
				forceOpenDatepicker={false}
				datepickerProps={{
					twoMonth: true,
					endDate: filter.endDate,
					startDate: filter.startDate,
					maxDate: new Date(),
				}}
				rootClassName={classes.filterItem}
			/>
			<Select
				onChange={handleChangeCompany}
				value={filter.edrpou}
				emptyLabel={t('allAgent')}
				empty={!companyInfo?.companyInfo.length}
				rootClassName={classes.filterItem}
				withSearch
				testId={TEST_IDS.ACCOUNTS_FILTERS_COMPANY_NAME}
				disabled={!companyInfo?.companyInfo?.length || loadingCompanyNames}
				options={
					companyInfo?.companyInfo
						.filter((it) => {
							return !filter.contractNumber || it.contractInfo.some(({ value }) => value === filter.contractNumber);
						})
						.map((company) => ({
							value: company.taxId,
							label: company.companyName,
						})) || []
				}
			/>
			<Select
				onChange={handleChangeContractNumber}
				value={filter.contractNumber}
				emptyLabel={t('allDoc')}
				empty={!selectedContractNumbers?.length}
				rootClassName={classes.filterItem}
				withSearch
				testId={TEST_IDS.ACCOUNTS_FILTERS_CONTRACT_NUMBER}
				disabled={!selectedContractNumbers?.length || loadingCompanyNames}
				options={
					selectedContractNumbers?.map((contractNumber) => ({
						value: contractNumber,
						label: contractNumber,
					})) || []
				}
			/>
			{!activeTab ? (
				<Select
					onChange={handleChangeStatus}
					testId={TEST_IDS.ACCOUNTS_FILTERS_STATUS}
					value={filter.statuses || []}
					emptyLabel={t('allStatus')}
					rootClassName={classes.filterItem}
					multiple
					multipleTitle={t('filter.multipleTitle')}
					options={statusFilterData.map((option) => option)}
				/>
			) : (
				<div className={classes.paymentFilter} data-testid={TEST_IDS.ACCOUNTS_FILTERS_PAYMENT_STATUS}>
					<Checkbox onChange={handlePaymentFilter} isChecked={filter.status || false} />
					{t('filter.paymentStatus')}
				</div>
			)}
		</div>
	);
};

export default Filters;
