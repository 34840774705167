import { getMonthRange } from 'helpers';
import { ValueOf } from 'interfaces';
import { IFilter } from 'interfaces/invoices';

import { IAction } from './types';
import {
	CHANGE_INVOICES_FILTER,
	FETCH_DEBT_COMPANIES_FAILURE,
	FETCH_DEBT_COMPANIES_START,
	FETCH_DEBT_COMPANIES_SUCCESS,
	FETCH_DEBT_GLOVO_FAILURE,
	FETCH_DEBT_GLOVO_START,
	FETCH_DEBT_GLOVO_SUCCESS,
	FETCH_INVOICES_FAILURE,
	FETCH_INVOICES_START,
	FETCH_INVOICES_SUCCESS,
	FETCH_PAYMENTS_FAILURE,
	FETCH_PAYMENTS_START,
	FETCH_PAYMENTS_SUCCESS,
	IState,
	SET_INVOICES_PAGE_SIZE,
	SET_PAYMENTS_PAGE_SIZE,
} from './types';

const initialState: IState = {
	invoices: {
		items: [],
		total: 0,
		pagionation: {
			page: 0,
			pageSize: 25,
		},
	},
	payments: {
		items: [],
		total: 0,
		pagionation: {
			page: 0,
			pageSize: 25,
		},
	},
	filter: ((): IState['filter'] => {
		const [startDate, endDate] = getMonthRange(1);
		return {
			startDate,
			endDate,
		};
	})(),
	glovoDebts: [],
	companiesDebts: [],
	onload: true,
	wasLoaded: false,
	debtsLoad: true,
};

const reducer = (state = initialState, action: IAction): IState => {
	switch (action.type) {
		case FETCH_INVOICES_START:
			return {
				...state,
				onload: true,
			};
		case FETCH_INVOICES_SUCCESS:
			return {
				...state,
				invoices: {
					...state.invoices,
					items: action.payload.invoices,
					total: action.payload.total,
				},
				onload: false,
				wasLoaded: true,
			};
		case FETCH_INVOICES_FAILURE:
			return {
				...state,
				invoices: {
					...state.invoices,
					items: [],
					total: 0,
				},
				onload: false,
				wasLoaded: true,
			};
		case FETCH_PAYMENTS_START:
			return {
				...state,
				onload: true,
			};
		case FETCH_PAYMENTS_SUCCESS:
			return {
				...state,
				payments: {
					...state.payments,
					items: action.payload.payments,
					total: action.payload.total,
				},
				onload: false,
				wasLoaded: true,
			};
		case FETCH_PAYMENTS_FAILURE:
			return {
				...state,
				payments: {
					...state.payments,
					items: [],
					total: 0,
				},
				onload: false,
				wasLoaded: true,
			};
		case FETCH_DEBT_GLOVO_START:
			return {
				...state,
				debtsLoad: true,
			};
		case FETCH_DEBT_GLOVO_SUCCESS:
			return {
				...state,
				glovoDebts: action.payload.debts,
				debtsLoad: false,
				wasLoaded: true,
			};
		case FETCH_DEBT_GLOVO_FAILURE:
			return {
				...state,
				debtsLoad: false,
				wasLoaded: true,
				glovoDebts: [],
			};
		case FETCH_DEBT_COMPANIES_START:
			return {
				...state,
				debtsLoad: true,
			};
		case FETCH_DEBT_COMPANIES_SUCCESS:
			return {
				...state,
				companiesDebts: action.payload.debts,
				debtsLoad: false,
				wasLoaded: true,
			};
		case FETCH_DEBT_COMPANIES_FAILURE:
			return {
				...state,
				debtsLoad: false,
				wasLoaded: true,
				companiesDebts: [],
			};
		case SET_INVOICES_PAGE_SIZE:
			return {
				...state,
				invoices: {
					...state.invoices,
					pagionation: {
						...state.invoices.pagionation,
						pageSize: action.payload,
					},
				},
			};
		case SET_PAYMENTS_PAGE_SIZE:
			return {
				...state,
				payments: {
					...state.payments,
					pagionation: {
						...state.payments.pagionation,
						pageSize: action.payload,
					},
				},
			};
		case CHANGE_INVOICES_FILTER: {
			const getFilterValue = (value: ValueOf<IFilter>) => {
				if (typeof value === 'boolean') return value;
				return value || undefined;
			};
			return {
				...state,
				filter: {
					...state.filter,
					[action.payload.field]: getFilterValue(action.payload.value),
				},
				invoices: {
					...state.invoices,
					pagionation: {
						page: 0,
						pageSize: 25,
					},
				},
			};
		}
		default:
			return state;
	}
};

export default reducer;
