import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Routes } from 'const';
import clsx from 'helpers/clsx';
import {
	isValidEmail,
	isValidPassword,
	REGEXP_IS_CYRILLIC,
	REGEXP_PASSWORD_DIGIT,
	REGEXP_PASSWORD_LETTER_OR_SPECIAL,
	REGEXP_PASSWORD_MIN_6,
} from 'helpers/validation';
import { useAuth } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import useToast from 'hooks/useToast';
import { useUser } from 'hooks/useUser';
import { ReactComponent as CheckIcon } from 'static/images/checkIconCircle.svg';
import { ReactComponent as CrossIcon } from 'static/images/crossIconCircle.svg';
import { ReactComponent as ResetPasswordIcon } from 'static/images/reset-password.svg';
import { TEST_IDS } from 'tests/config';

import { Input } from 'components/FormControl';
import Loader from 'components/Loader';
import MainButton from 'components/MainButton';

import classes from './ForgotPassword.module.scss';

const ForgotPassword: React.FC = () => {
	const [success, setSuccess] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [password, setPassword] = useState<string>('');
	const [passwordRepeat, setPasswordRepeat] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const { resetPasswordByEmail, sendEmail } = useUser();
	const { login } = useAuth();
	const { toast } = useToast();
	const { t } = useTranslation(['forgotPassword', 'errors', 'form', 'validation', 'login']);
	const query = useQuery();
	const emailReset = query.get('email');
	const token = query.get('token');
	const [matchError, setMatchError] = useState('');
	const [passwordReqs, setPasswordReqs] = useState<{ [key: string]: boolean | null }>({
		requirement1: null,
		requirement2: null,
		requirement3: null,
		requirement4: null,
	});

	const handleChangeEmail = (value: string) => {
		setEmail(value);
	};

	const reset = () => {
		setEmail('');
		setPassword('');
		setPasswordRepeat('');
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (token && emailReset) {
			if (password.trim() !== passwordRepeat.trim()) {
				setMatchError(t('validation:errors.passwordsNotEqual'));
				return toast(t('validation:errors.passwordsNotEqual'), { type: 'error' });
			} else if (!isValidPassword(password.trim())) {
				return toast(t('validation:errors.password'), { type: 'error' });
			}
			setLoading(true);
			return resetPasswordByEmail(emailReset.trim(), token.trim(), password.trim())
				.then(({ body }) => {
					if (body?.errorType) {
						throw new Error(body.message);
					}
					return login(emailReset.trim(), password.trim(), false)
						.then(reset)
						.catch(() => Promise.reject(null));
				})
				.catch((err) => {
					toast(t(`errors:${err?.message || 'somethingWentWrong'}`), { type: 'error' });
				})
				.finally(() => {
					setLoading(false);
				});
		}
		if (!isValidEmail(email.trim())) {
			return toast(t('validation:errors.email'), { type: 'error' });
		}
		setLoading(true);
		sendEmail(email.trim())
			.then(({ body }) => {
				if (body?.errorType) {
					throw new Error('somethingWentWrong');
				}
				setSuccess(true);
			})
			.catch(() => {
				toast(t('errors:somethingWentWrong'), { type: 'error' });
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleChangePassword = (value: string) => {
		setPassword(value);
		if (value.length) {
			setPasswordReqs({
				requirement1: REGEXP_PASSWORD_LETTER_OR_SPECIAL.test(value),
				requirement2: REGEXP_PASSWORD_DIGIT.test(value),
				requirement3: REGEXP_PASSWORD_MIN_6.test(value),
				requirement4: !REGEXP_IS_CYRILLIC.test(value),
			});
		} else {
			setPasswordReqs({
				requirement1: null,
				requirement2: null,
				requirement3: null,
				requirement4: null,
			});
		}
	};

	const handleChangePasswordRepeat = (value: string) => {
		if (matchError) {
			setMatchError('');
		}
		setPasswordRepeat(value);
	};

	const getIcon = (state: boolean | null) => {
		if (state === false) {
			return <CrossIcon />;
		}
		return <CheckIcon />;
	};

	return (
		<form onSubmit={handleSubmit} className={classes.form} data-testid={TEST_IDS.FORGOT_PASSWORD_PAGE}>
			{loading && <Loader />}
			{emailReset && token ? (
				<>
					<div className={classes.icon}>
						<ResetPasswordIcon />
					</div>
					<div className={classes.title}>{t('login:needChangePassword.title')}</div>
					<div className={classes.text}>{t('login:needChangePassword.text')}</div>
					<div className={classes.row}>
						<Input
							label={t('form:passwordNew')}
							type={'password'}
							value={password}
							onChange={handleChangePassword}
							testId={TEST_IDS.PASSWORD_INPUT}
						/>
						<div className={classes.requirements}>
							<div className={clsx({ [classes.success]: passwordReqs.requirement1, [classes.fail]: passwordReqs.requirement1 === false })}>
								{getIcon(passwordReqs.requirement1)}
								{t('passwordRequirement1')}
							</div>
							<div className={clsx({ [classes.success]: passwordReqs.requirement2, [classes.fail]: passwordReqs.requirement2 === false })}>
								{getIcon(passwordReqs.requirement2)}
								{t('passwordRequirement2')}
							</div>
							<div className={clsx({ [classes.success]: passwordReqs.requirement3, [classes.fail]: passwordReqs.requirement3 === false })}>
								{getIcon(passwordReqs.requirement3)}
								{t('passwordRequirement3')}
							</div>
							<div className={clsx({ [classes.success]: passwordReqs.requirement4, [classes.fail]: passwordReqs.requirement4 === false })}>
								{getIcon(passwordReqs.requirement4)}
								{t('passwordRequirement4')}
							</div>
						</div>
					</div>
					<div className={classes.row}>
						<Input
							label={t('form:passwordNewRepeat')}
							type={'password'}
							value={passwordRepeat}
							onChange={handleChangePasswordRepeat}
							testId={TEST_IDS.PASSWORD_REPEAT_INPUT}
							error={matchError}
						/>
					</div>
					<div className={clsx(classes.row, classes.rowButton)}>
						<MainButton className={classes.button} disabled={!isValidPassword(password.trim())} type="submit">
							{t('form:savePasswordNew')}
						</MainButton>
					</div>
				</>
			) : (
				<>
					<div className={classes.title}>{success ? t('titleSuccess') : t('title')}</div>
					<div className={classes.text}>{success ? t('textSuccess') : t('text')}</div>
					{!success && (
						<>
							<div className={classes.row}>
								<Input label={t('form:email')} placeholder="name@example.com" value={email} onChange={handleChangeEmail} />
							</div>
							<div className={clsx(classes.row, classes.rowButton)}>
								<MainButton className={classes.button} type="submit">
									{t('form:send')}
								</MainButton>
							</div>
							<div className={clsx(classes.row, classes.rowButton)}>
								<Link to={Routes.LOGIN} className={classes.back}>
									{t('back')}
								</Link>
							</div>
						</>
					)}
				</>
			)}
		</form>
	);
};

export default ForgotPassword;
