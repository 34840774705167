import { gql } from '@apollo/client';

export const SUBSCRIPTION_PAYOUT_SCHEDULE_NOTIFICATION = gql`
	subscription onPayoutScheduleNotification {
		payoutScheduleNotification {
			periodStart
			periodEnd
			reconciliationDeadline
			daysLeft
			accountingType
		}
	}
`;

export const GET_PAYOUT_SCHEDULE_NOTIFICATIONS = gql`
	query PayoutScheduleNotifications {
		payoutScheduleNotifications {
			periodStart
			periodEnd
			reconciliationDeadline
			daysLeft
			accountingType
		}
	}
`;

export const PARTNER_COMMENT_NOTIFICATION = gql`
	subscription PartnerCommentNotification($email: String!) {
		partnerCommentNotification(email: $email) {
			contractNumber
			email
			comment
			createdBy
			createdAt
		}
	}
`;
