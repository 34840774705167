import React from 'react';
import clsx from 'helpers/clsx';
import icon from 'static/images/loader.gif';
import { TEST_IDS } from 'tests/config';

import { IProps } from './types';

import classes from './Loader.module.scss';

const Loader: React.FC<IProps> = ({ className, simple }) => {
	return (
		<div
			className={clsx(classes.root, className, {
				[classes.modal]: !simple,
			})}
			data-testid={TEST_IDS.LOADER}
		>
			<img src={icon} alt="" />
		</div>
	);
};

export default Loader;
