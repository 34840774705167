import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import { fetch } from 'api';
import { ACCEPT_ORDER_AMENDMENT, GET_AMENDMENT_HISTORY, GET_ORDER_APPROVAL, GET_TOTALS, REJECT_ORDER_AMENDMENT } from 'api/orders';
import { GLOVO_ROLES, validTypesForOrderFiles } from 'const';
import { adminLink } from 'const';
import Provider from 'containers/GetStarted/Provider';
import { useGetStartedContext } from 'contexts/GetStarted';
import { isAfter } from 'date-fns';
import compareAsc from 'date-fns/compareAsc';
import parseISO from 'date-fns/parseISO';
import { formatDate, formatPrice, formatTime, getDisputesAmount, getUniqueDisputes, hasErrorMessage } from 'helpers';
import clsx from 'helpers/clsx';
import { getCountry, getCurrency } from 'helpers/jwt';
import { normalizeFilter } from 'helpers/order';
import { hasRole, renderAuthorEmail } from 'helpers/users';
import { useOrders } from 'hooks/useOrders';
import useToast from 'hooks/useToast';
import { useUser } from 'hooks/useUser';
import { CountryCodeEnum, DisabledType, ERRORS, ErrorsType, FinalStatus, PaymentMethods, ValueOf } from 'interfaces';
import { DeliveryPromo, IAmendment, IFormEdit, IOrder, OrderStatus } from 'interfaces/order';
import { RoleEnum } from 'interfaces/users';
import { ReactComponent as ApprovedIcon } from 'static/images/approved.svg';
import { ReactComponent as EditIcon } from 'static/images/edit.svg';
import { ReactComponent as InfoIcon } from 'static/images/info.svg';
import { ReactComponent as LinkIcon } from 'static/images/link.svg';
import { ReactComponent as PaymentMethodIcon } from 'static/images/paymentMethod.svg';
import { TEST_IDS } from 'tests/config';
import { isEqual } from 'underscore';

import CopyButton from 'components/CopyButton';
import { AttachFile, File, FileLoaded, Input, Radio, Select } from 'components/FormControl';
import IconButton from 'components/IconButton';
import Loader from 'components/Loader';
import MainButton from 'components/MainButton';
import Modal from 'components/modals/Modal';
import ProductList from 'components/ProductList';
import { DarkTooltip } from 'components/StyledDarkMenuTooltip';

import data from './reasons.json';

import classes from './OrderModal.module.scss';

interface IProps {
	item: IOrder;
	open: boolean;
	debug?: boolean;
	withProviderGetStarted?: boolean;
	isFake?: boolean;
	rootClassName?: string;
	onClose(): void;
}

interface IFields {
	amount: string;
	chargedToPartner: string;
	paymentMethod: string;
	comment: string;
	receivedFunds?: string;
	deliveryFee?: string;
	finalStatus?: string;
	deliveryDiscountAssumedByPartner?: string;
	productDiscountAssumedByPartner?: string;
	deliveryPromotionAssumedByPartner?: string;
	deliveryDiscountSSP?: string;
	deliveryDiscountAssumedByPartner100?: string;
	deliveryDiscountAssumedByPartnerAndGlovo?: string;
	partnerPayment: number;
	promotionAssumedByPartner: number;
}

interface IResponseAmendments {
	getAmendmentsHistory: IAmendment[];
}

interface IResponseOrderApproval {
	orderApproval: {
		approvedAt: string;
		approvedByEmail: string;
	};
}

const OrderModal: React.FC<IProps> = ({ open, onClose, item, rootClassName, withProviderGetStarted, isFake }) => {
	const { t } = useTranslation(['orders', 'errors', 'form', 'common', 'validation']);
	const { editOrder, approveOrderById, updateOrder, filter, fetchTotals } = useOrders();
	const country = getCountry();
	const [form, setForm] = useState<IFormEdit>({
		amount: item.amount || ('0.00' as unknown as number),
		chargedToPartner: item.chargedToPartner || ('0.00' as unknown as number),
		paymentMethod: item.paymentMethod,
		productDiscountAssumedByPartner: item.productDiscountAssumedByPartner || ('0.00' as unknown as number),
		deliveryDiscountAssumedByPartner: item.deliveryDiscountAssumedByPartner || ('0.00' as unknown as number),
		deliveryPromotionAssumedByPartner: item.deliveryPromotionAssumedByPartner || 0,
		deliveryDiscountSSP: item.deliveryDiscountSSP || 0,
		deliveryDiscountAssumedByPartner100: item.deliveryDiscountAssumedByPartner100 || 0,
		deliveryDiscountAssumedByPartnerAndGlovo: item.deliveryDiscountAssumedByPartnerAndGlovo || 0,
		comment: '',
		receivedFunds: item.receivedFunds,
		deliveryFee: item.deliveryFee || ('0.00' as unknown as number),
		finalStatus: item.finalStatus,
		promotionAssumedByPartner: item.promotionAssumedByPartner || 0,
	});
	const fields: IFields = {
		amount: t('fields.amount'),
		chargedToPartner: t('fields.chargedToPartner'),
		paymentMethod: t('fields.paymentMethod'),
		productDiscountAssumedByPartner: t('fields.productDiscountsAssumedByPartnerCurrLocalShort'),
		deliveryDiscountAssumedByPartner: t('fields.deliveryDiscountsAssumedByPartnerCurrLocalShort'),
		deliveryPromotionAssumedByPartner: t('fields.deliveryDiscountsAssumedByPartnerCurrLocalShort'),
		deliveryDiscountSSP: t('fields.deliveryDiscountsAssumedByPartnerCurrLocalShort'),
		deliveryDiscountAssumedByPartner100: t('fields.deliveryDiscountsAssumedByPartnerCurrLocalShort'),
		deliveryDiscountAssumedByPartnerAndGlovo: t('fields.deliveryDiscountsAssumedByPartnerCurrLocalShort'),
		receivedFunds: t('fields.receivedFunds'),
		partnerPayment: t('fields.partnerPayment'),
		deliveryFee: t('fields.deliveryFee'),
		finalStatus: t('fields.finalStatus'),
		comment: t('fields.comment'),
		promotionAssumedByPartner: t('fields.promotionAssumedByPartner'),
	};
	const { loading: loadingUser, isFinance, isAdmin, isAccount, hasPermissionToChangeOrders, roles, isCredentialsAdmin, isPartner } = useUser();
	const isApprovedOrder = item.orderStatus === OrderStatus.APPROVED;
	const [fileList, setFileList] = useState<File[] | null>(null);
	const [forceAmendment, setForceAmendment] = useState<boolean>(false);
	const [openModalChangePaymentMethod, setOpenModalChangePaymentMethod] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [mount, setMount] = useState<boolean>(false);
	const [errors, setErrors] = useState<ErrorsType<IFormEdit>>();
	const [disabled, setDisabled] = useState<DisabledType<IFormEdit>>();
	const [newPaymentMethod, setNewPaymentMethod] = useState<PaymentMethods>();
	const [openRejectModalId, setOpenRejectModalId] = useState<IAmendment['id']>('');
	const [selectedReason, setSelectedReason] = useState<IAmendment['rejectedReason']>('');
	const { toast } = useToast();
	// it remains a mystery to me why in testing mode this hook returns undefined
	const { onCloseModalOrder } = useGetStartedContext() || {};
	const [getAmendmentsHistory, { data: amendmentsHistory, loading: loadingAmendmentsHistory }] = useLazyQuery<IResponseAmendments>(
		GET_AMENDMENT_HISTORY,
		{
			variables: { orderId: item.id },
			fetchPolicy: 'network-only',
		},
	);
	const hasEdit = amendmentsHistory?.getAmendmentsHistory.length;
	const hasNotReactionAmendments = amendmentsHistory?.getAmendmentsHistory.filter((it) => !it.acceptedAt && !it.rejectedAt).length;
	const hasAcceptedAmendments = amendmentsHistory?.getAmendmentsHistory.filter((it) => it.acceptedAt).length;
	const isLastAmendmentsReject = !!hasEdit && !!amendmentsHistory?.getAmendmentsHistory[hasEdit - 1].rejectedAt;
	const [acceptOrderAmendment] = useMutation(ACCEPT_ORDER_AMENDMENT, {
		refetchQueries: [
			{ query: GET_AMENDMENT_HISTORY, variables: { orderId: item.id }, fetchPolicy: 'network-only' },
			{ query: GET_ORDER_APPROVAL, variables: { orderId: item.id }, fetchPolicy: 'network-only' },
			{ query: GET_TOTALS, variables: { filter: normalizeFilter(filter), fetchPolicy: 'network-only' } },
		],
		awaitRefetchQueries: true,
		fetchPolicy: 'network-only',
	});
	const [rejectOrderAmendment] = useMutation(REJECT_ORDER_AMENDMENT, {
		refetchQueries: [{ query: GET_AMENDMENT_HISTORY, variables: { orderId: item.id }, fetchPolicy: 'network-only' }],
		awaitRefetchQueries: true,
		fetchPolicy: 'network-only',
	});
	const [getOrderApproval, { data: orderApproval, loading: loadingOrderApproval }] = useLazyQuery<IResponseOrderApproval>(GET_ORDER_APPROVAL, {
		variables: { orderId: item.id },
		fetchPolicy: 'network-only',
	});

	const clearError = (key: keyof IFormEdit) => {
		setErrors((state) => {
			if (state) {
				return {
					...state,
					[key]: undefined,
				};
			}
			return state;
		});
	};

	const handleOpenModalChangePaymentMethod = () => setOpenModalChangePaymentMethod(true);
	const handleCloseModalChangePaymentMethod = () => setOpenModalChangePaymentMethod(false);

	const handleChangeInputFile = (f: FileList) => {
		const newFiles = Array.from(f);
		if (!fileList) return setFileList(newFiles);
		setFileList([...fileList, ...newFiles]);
	};
	const handleRemoveFile = (fname: string) => {
		if (fileList) setFileList(fileList.filter((el) => el.name !== fname));
	};

	const beforeUpdateForm = (field: keyof IFormEdit): boolean => {
		clearError(field);
		if (field === 'amount') {
			clearError('chargedToPartner');
		}
		if (field === 'chargedToPartner') {
			clearError('amount');
		}
		return true;
	};

	const updateForm = (field: keyof IFormEdit, value: string | number) => {
		beforeUpdateForm(field);
		setForm((state) => ({
			...state,
			[field]: value,
		}));
	};

	const handleChangeForm = (field: keyof IFormEdit) => (value: string) => {
		updateForm(field, value);
	};

	const handleForceAmendmentForm = () => {
		setForceAmendment(true);
	};

	const getChangedProps = (res: IAmendment | undefined) => {
		return !res
			? {}
			: Object.entries(res).reduce((acc: { [key: string]: ValueOf<IAmendment> }, [key, value]) => {
					if (value !== null && value !== undefined && fields[key as keyof IFields]) acc[key] = value;
					return acc;
				}, {});
	};

	const isChangedForm = () => {
		return !isEqual(
			{
				amount: Number(form.amount),
				chargedToPartner: Number(form.chargedToPartner),
				paymentMethod: form.paymentMethod,
				finalStatus: form.finalStatus,
				deliveryFee: Number(form.deliveryFee) || 0,
				receivedFunds: Number(form.receivedFunds),
				productDiscountAssumedByPartner: Number(form.productDiscountAssumedByPartner),
				deliveryDiscountAssumedByPartner: Number(form.deliveryDiscountAssumedByPartner) || 0,
				deliveryPromotionAssumedByPartner: Number(form.deliveryPromotionAssumedByPartner) || 0,
				deliveryDiscountSSP: Number(form.deliveryDiscountSSP) || 0,
				deliveryDiscountAssumedByPartner100: Number(form.deliveryDiscountAssumedByPartner100) || 0,
				deliveryDiscountAssumedByPartnerAndGlovo: Number(form.deliveryDiscountAssumedByPartnerAndGlovo) || 0,
				promotionAssumedByPartner: Number(form.promotionAssumedByPartner) || 0,
			},
			{
				amount: item.amount,
				chargedToPartner: item.chargedToPartner,
				paymentMethod: item.paymentMethod,
				finalStatus: item.finalStatus,
				deliveryFee: item.deliveryFee || 0,
				receivedFunds: item.receivedFunds,
				productDiscountAssumedByPartner: Number(item.productDiscountAssumedByPartner),
				deliveryDiscountAssumedByPartner: Number(item.deliveryDiscountAssumedByPartner) || 0,
				deliveryPromotionAssumedByPartner: Number(item.deliveryPromotionAssumedByPartner) || 0,
				deliveryDiscountSSP: Number(item.deliveryDiscountSSP) || 0,
				deliveryDiscountAssumedByPartner100: Number(item.deliveryDiscountAssumedByPartner100) || 0,
				deliveryDiscountAssumedByPartnerAndGlovo: Number(item.deliveryDiscountAssumedByPartnerAndGlovo) || 0,
				promotionAssumedByPartner: Number(item.promotionAssumedByPartner) || 0,
			},
		);
	};

	const checkChangePaymentMethod = () => {
		const { amount, originAmount, isMarketplaceOrder } = item;
		const isEmptyAmount = !originAmount && !amount;
		if (!isMarketplaceOrder && isEmptyAmount && Number(form.amount) && form.paymentMethod === PaymentMethods.CASH) {
			setNewPaymentMethod(PaymentMethods.CARD);
			handleOpenModalChangePaymentMethod();
			return false;
		}

		const isEmptyReceivedFunds = !item.originReceivedFunds && !item.receivedFunds;
		if (isMarketplaceOrder && isEmptyReceivedFunds && form.receivedFunds && form.paymentMethod === PaymentMethods.CARD) {
			setNewPaymentMethod(PaymentMethods.CASH);
			handleOpenModalChangePaymentMethod();
			return false;
		}

		if (
			isMarketplaceOrder &&
			form.finalStatus === FinalStatus.DELIVERED &&
			!Number(form.receivedFunds) &&
			form.paymentMethod === PaymentMethods.CASH
		) {
			setNewPaymentMethod(PaymentMethods.CARD);
			handleOpenModalChangePaymentMethod();
			return false;
		}

		return true;
	};

	const validation = () => {
		if (!isChangedForm()) {
			toast(t('errors:hasNoEdit'), { type: 'error' });
			return false;
		}
		const notCorrect = Object.values(form).some((value) => value === null);
		if (notCorrect) {
			Object.entries(form).forEach(([key, value]) => {
				if (value === null) {
					const localeFieldByKey = `fields.${key}`;
					toast(`${t('errors:incorrectField')} ${t(localeFieldByKey)}`, {
						type: 'error',
					});
				}
			});
			return false;
		}

		if (form.chargedToPartner && !form.amount) {
			setErrors({
				amount: t('validation:errors.required'),
			});
			return false;
		}

		if (!form.comment) {
			setErrors({ comment: t('validation:errors.required') });
			return false;
		}

		if (!checkChangePaymentMethod()) return false;

		return true;
	};

	const normalizeInput = (input: IFormEdit): IFormEdit => {
		return {
			...input,
			amount: Number(input.amount),
			chargedToPartner: Number(input.chargedToPartner),
			deliveryDiscountAssumedByPartner: Number(input.deliveryDiscountAssumedByPartner),
			productDiscountAssumedByPartner: Number(input.productDiscountAssumedByPartner),
			deliveryPromotionAssumedByPartner: Number(input.deliveryPromotionAssumedByPartner),
			deliveryDiscountSSP: Number(input.deliveryDiscountSSP),
			deliveryDiscountAssumedByPartner100: Number(input.deliveryDiscountAssumedByPartner100),
			deliveryDiscountAssumedByPartnerAndGlovo: Number(input.deliveryDiscountAssumedByPartnerAndGlovo),
			promotionAssumedByPartner: Number(input.promotionAssumedByPartner),
		};
	};

	const approveChangePaymentMethod = () => {
		if (newPaymentMethod) {
			handleChangeForm('paymentMethod')(newPaymentMethod);
			sendAmendment(true, { paymentMethod: newPaymentMethod });
		}
		handleCloseModalChangePaymentMethod();
	};

	const cancelChangePaymentMethod = () => {
		sendAmendment(true);
		handleCloseModalChangePaymentMethod();
	};

	const sendAmendment = async (skipValidation?: boolean, newForm?: Partial<IFormEdit>) => {
		if (!validation() && !skipValidation) return;
		setErrors(undefined);
		setLoading(true);
		let filesIds;
		if (fileList?.length) {
			const formData = new FormData();
			let isNonValid = false;
			fileList.forEach((file) => {
				const { size } = file;
				const errorLimit = size / 1024 / 1024 > 20 && 20 > 0;
				if (errorLimit) return (isNonValid = true);
				formData.append('files', file);
			});

			if (isNonValid) {
				toast(`${t('errors:incorrectFiles')}`, { type: 'error' });
				setErrors(undefined);
				setLoading(false);
				return;
			}

			filesIds = await fetch('/amendment/files', {
				method: 'post',
				body: formData,
			})
				.then((data) => data.body)
				.catch((res) => {
					const typeStatus = res?.response.status === ERRORS.FILE_STATUS;
					const errorKey = typeStatus ? ERRORS.FILE_TYPE : hasErrorMessage(res);
					const errorMessage = errorKey ? `errors:${errorKey}` : 'errors:serverError';
					toast(t(errorMessage), { type: 'error' });
				})
				.finally(() => setFileList(null));
		}

		editOrder(
			normalizeInput({
				...form,
				...newForm,
				...(!!filesIds && {
					files: filesIds as { fileId: string; filename: string; size: number }[],
				}),
			}),
			item.id,
			[{ query: GET_AMENDMENT_HISTORY, variables: { orderId: item.id } }],
		)
			.then((amendment) => {
				if (forceAmendment) {
					fetchTotals();
					const newPropsOrder = amendment?.acceptedAfterOrderPeriod ? {} : getChangedProps(amendment);
					updateOrder({
						...item,
						...newPropsOrder,
					});
				}
				if (amendment && item.orderStatus !== amendment.orderStatus) {
					updateOrder({
						...item,
						orderStatus: amendment.orderStatus,
					});
				}
				updateForm('comment', '');
				setForceAmendment(false);
				setDisabled(undefined);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleAcceptOrderAmendment = (amendmentId: IAmendment['id']) => () => {
		setLoading(true);
		acceptOrderAmendment({
			variables: { orderId: Number(item.id), amendmentId },
			awaitRefetchQueries: true,
		})
			.then((res) => {
				const newPropsOrder = res?.data.acceptOrderAmendment.acceptedAfterOrderPeriod ? {} : getChangedProps(res?.data.acceptOrderAmendment);
				fetchTotals();
				updateOrder({
					...item,
					...newPropsOrder,
					orderStatus: OrderStatus.APPROVED,
				});
			})
			.catch(() => {
				toast(t('errors:serverError'), { type: 'error' });
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleOpenRejectModalId = (state: IAmendment['id']) => () => {
		setOpenRejectModalId(state);
		setSelectedReason('');
	};

	const handleRejectOrderAmendment = (amendmentId: IAmendment['id'], rejectedReason?: IAmendment['rejectedReason']) => () => {
		setLoading(true);
		rejectOrderAmendment({
			variables: { orderId: Number(item.id), amendmentId, rejectedReason },
		})
			.then(() => {
				updateOrder({
					...item,
					orderStatus: OrderStatus.REJECTED,
				});
			})
			.catch(() => {
				toast(t('errors:serverError'), { type: 'error' });
			})
			.finally(() => {
				handleOpenRejectModalId('')();
				setLoading(false);
			});
	};

	const handleApproveOrder = () => {
		setLoading(true);
		approveOrderById(item.id, [{ query: GET_ORDER_APPROVAL, variables: { orderId: item.id } }])
			.then(() => {
				updateOrder({
					...item,
					orderStatus: OrderStatus.APPROVED,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (!isFake) {
			getAmendmentsHistory();
			getOrderApproval();
		}
		setMount(true);
	}, []);

	const showChangeForm = useMemo(() => {
		if (!item.lastDiffInputDate || isAdmin || isFinance) return false;
		return compareAsc(new Date(), parseISO(`${item.lastDiffInputDate}T14:00`)) >= 0;
	}, [isFinance, item.lastDiffInputDate]);

	const canApprove = !isApprovedOrder && (hasPermissionToChangeOrders() || isFinance) && !hasNotReactionAmendments && !loadingAmendmentsHistory;

	const renderButtonApproveOrder = canApprove && (
		<div className={clsx(classes.center)}>
			<Provider id="approveOrder" canPush={withProviderGetStarted} position="top" sort={12} beforeNext={onCloseModalOrder}>
				<MainButton className={classes.approve} onClick={handleApproveOrder}>
					{t('form:applyOrder')}
				</MainButton>
			</Provider>
		</div>
	);

	const showDisputesAndWaitingTime = country === CountryCodeEnum.KZ;

	const formHead = (
		<div className={classes.formHead}>
			<div className={classes.modalHead}>
				<div className={classes.modalTitle}>{t('edit.subtitle')}</div>
				{item.orders && (
					<ProductList items={item.orders} total={item.amount}>
						{t('edit.watchOrderProducts')}
					</ProductList>
				)}
			</div>
			<ul className={classes.orderInfo}>
				<li>
					<span>{t('fields.date')}</span>
					<span>
						{new Date(item.dispatchingTime).toLocaleString('uk', {
							day: 'numeric',
							month: 'numeric',
							year: 'numeric',
						})}
					</span>
				</li>
				<li>
					<span>{t('fields.storeName')}</span>
					<span>{item.storeName}</span>
				</li>
				<li className={classes.copyLine}>
					<span>{t('fields.storeAddressId')}</span>
					<span>{item.storeAddressId}</span>
					<CopyButton className={classes.copyIco} text={String(item.storeAddressId)} />
				</li>
				<li className={classes.copyLine}>
					<span>{t('fields.companyName')}</span>
					<span>{item.companyName}</span>
					<CopyButton className={classes.copyIco} text={item.companyName} />
				</li>
				<li className={classes.copyLine}>
					<span>{t('fields.taxId')}</span>
					<span>{item.taxId}</span>
					<CopyButton className={classes.copyIco} text={String(item.taxId)} />
				</li>
				<li>
					<span>{t('fields.amount')}</span>
					<span>{formatPrice(item.amount, 2, ',', '0,00')}</span>
				</li>
				<li>
					<span>{t('fields.chargedToPartner')}</span>
					<span className={clsx({ [classes.emptySum]: !item.chargedToPartner })}>{formatPrice(item.chargedToPartner, 2, ',', '0,00')}</span>
				</li>
				{item.isMarketplaceOrder ? (
					<li>
						<span>{t('fields.receivedFunds')}</span>
						<span className={clsx({ [classes.emptySum]: !item.receivedFunds })}>{formatPrice(item.receivedFunds, 2, ',', '0,00')}</span>
					</li>
				) : (
					<li>
						<span>{t('fields.paymentMethod')}</span>
						<span>{item.paymentMethod}</span>
					</li>
				)}
				{!!item.productDiscountAssumedByPartner && (
					<li>
						<span>{t('fields.productsPromo')}</span>
						<span>{formatPrice(item.productDiscountAssumedByPartner, 2, ',', '0,00')}</span>
					</li>
				)}
				{!!item.promotionAssumedByPartner && (
					<li>
						<span>{t('fields.promotionAssumedByPartnerModal')}</span>
						<span>{formatPrice(item.promotionAssumedByPartner, 2, ',', '0,00')}</span>
					</li>
				)}
				{item.isMarketplaceOrder && (
					<>
						<li>
							<span>{t('fields.deliveryFee')}</span>
							<span className={clsx({ [classes.emptySum]: !item.deliveryFee })}>{formatPrice(item.deliveryFee, 2, ',', '0,00')}</span>
						</li>
						<li>
							<span>{t('fields.paymentMethod')}</span>
							<span>{item.paymentMethod}</span>
						</li>
					</>
				)}
				{(!!item.deliveryPromotionAssumedByPartner || !!item.deliveryDiscountAssumedByPartner || !!item.deliveryDiscountPaidByPartner) && (
					<>
						{item.isMarketplaceOrder ? (
							<li>
								<span>{t('fields.deliveryDiscountAssumedByPartner')}</span>
								<span>{formatPrice(item.deliveryPromotionAssumedByPartner, 2, ',', '0,00')}</span>
							</li>
						) : item.deliveryDiscountAssumedByPartner ? (
							<li>
								<span>{t('fields.deliveryDiscountAssumedByPartner')}</span>
								<span>{formatPrice(item.deliveryDiscountAssumedByPartner, 2, ',', '0,00')}</span>
							</li>
						) : (
							<li>
								<span>{t('fields.deliveryDiscountAssumedByPartner')}</span>
								<span>{formatPrice(item.deliveryDiscountPaidByPartner, 2, ',', '0,00')}</span>
							</li>
						)}
					</>
				)}
				{showDisputesAndWaitingTime && (
					<>
						<li>
							<DarkTooltip
								arrow
								color="#fff"
								placement="right"
								title={
									item.disputes?.length ? (
										<div>
											{item.disputes &&
												getUniqueDisputes(item.disputes, 'type').map((r, i, arr) => (
													<span key={r}>
														<Trans t={t} i18nKey={`disputeReason:${r}`} />
														{i + 1 !== arr.length && ', '}
													</span>
												))}
										</div>
									) : null
								}
							>
								<span style={{ display: 'flex', alignItems: 'center' }}>
									{t('totals.disputAmount')}{' '}
									{item.disputes?.length && getDisputesAmount(item.disputes) !== 0 ? <InfoIcon style={{ marginLeft: 5 }} /> : null}
								</span>
							</DarkTooltip>
							<span>{formatPrice(item.disputes && item.disputes?.length > 0 ? getDisputesAmount(item.disputes) : 0, 2, ',', '0,00')}</span>
						</li>
						<li>
							<span>{t('fields.waitingTimeWithVAT')}</span>
							<span>{formatPrice(item.waitingTime ? item.waitingTime : 0, 2, ',', '0,00')}</span>
						</li>
					</>
				)}
			</ul>
			{!hasEdit && renderButtonApproveOrder}
		</div>
	);

	const renderChangeForm = useCallback(
		(element: React.ReactNode) => {
			if (showChangeForm)
				return (
					<div className={classes.dedline}>
						<InfoIcon />
						{t('edit.deadline')}
					</div>
				);
			return element;
		},
		[showChangeForm, t],
	);

	const isPartnerRoles = isPartner || isCredentialsAdmin;
	const isUA = getCountry() === CountryCodeEnum.UA;
	const editAfterPeriodNotification =
		item.endPeriodDate &&
		isUA &&
		isAfter(new Date(), new Date(`2024-09-15T23:59:59`)) &&
		isAfter(new Date(), new Date(`${item.endPeriodDate}T23:59:59`));

	const formChange = renderChangeForm(
		<Provider
			id="edit"
			canPush={withProviderGetStarted && (hasPermissionToChangeOrders() || isFinance)}
			position="top"
			sort={11}
			borderRadius="0 0 0 20px"
			beforePrev={onCloseModalOrder}
			afterNext={!hasPermissionToChangeOrders() && !loadingUser ? onCloseModalOrder : undefined}
		>
			{editAfterPeriodNotification && isPartnerRoles && (
				<div className={clsx(classes.editinfo, classes.editinfo__after_period)}>
					<InfoIcon />
					{t('edit.afterPeriodNotification')}
				</div>
			)}
			<div className={classes.formChange} data-testid={TEST_IDS.MODAL_ORDER_FORM_AMENDMENTS}>
				<div className={classes.formChangeInner}>
					<div className={classes.modalTitle}>{t('edit.subtitleProposeEdit')}</div>
					<div className={classes.row}>
						<div className={classes.cell}>
							<Input
								error={errors?.amount}
								value={form.amount}
								onChange={handleChangeForm('amount')}
								label={t('fields.amount')}
								Icon={getCurrency()}
								rootClassName={clsx({ [classes.emptySum]: !Number(form.amount) })}
								type="number"
								testId={TEST_IDS.MODAL_ORDER_AMOUNT}
							/>
						</div>
						<div className={classes.cell}>
							<Input
								error={errors?.chargedToPartner}
								value={form.chargedToPartner}
								label={t('fields.chargedToPartner')}
								onChange={handleChangeForm('chargedToPartner')}
								Icon={getCurrency()}
								rootClassName={clsx({ [classes.emptySum]: !Number(form.chargedToPartner) })}
								type="number"
								testId={TEST_IDS.MODAL_ORDER_CHARGED_TO_PARTNER}
							/>
						</div>
						{item.isMarketplaceOrder ? (
							<div className={classes.cell}>
								<Input
									value={form.receivedFunds}
									label={t('fields.receivedFunds')}
									onChange={handleChangeForm('receivedFunds')}
									Icon={getCurrency()}
									rootClassName={clsx(classes.receivedFunds, { [classes.emptySum]: !Number(form.receivedFunds) })}
									type="number"
									testId={TEST_IDS.MODAL_ORDER_RECEIVED_FUNDS}
								/>
							</div>
						) : (
							<div className={classes.cell}>
								<Select
									value={form.paymentMethod}
									label={t('fields.paymentMethod')}
									onChange={handleChangeForm('paymentMethod')}
									withoutClear
									disabled={disabled?.paymentMethod}
									testId={TEST_IDS.MODAL_ORDER_PAYMENT_METHOD}
									options={[
										{ label: PaymentMethods.CARD, value: PaymentMethods.CARD },
										{ label: PaymentMethods.CASH, value: PaymentMethods.CASH },
									]}
								/>
							</div>
						)}
					</div>
					{item.isMarketplaceOrder && (
						<div className={classes.row}>
							<div className={classes.cell}>
								<Input
									value={form.deliveryFee}
									label={t('fields.deliveryFee')}
									onChange={handleChangeForm('deliveryFee')}
									testId={TEST_IDS.MODAL_ORDER_AMOUNT}
									rootClassName={clsx({ [classes.emptySum]: !Number(form.deliveryFee) })}
									Icon={getCurrency()}
								/>
							</div>
							<div className={classes.cell}>
								<Select
									disabled
									value={form.paymentMethod}
									label={t('fields.paymentMethod')}
									onChange={handleChangeForm('paymentMethod')}
									withoutClear
									testId={TEST_IDS.MODAL_ORDER_PAYMENT_METHOD}
									options={[
										{ label: PaymentMethods.CARD, value: PaymentMethods.CARD },
										{ label: PaymentMethods.CASH, value: PaymentMethods.CASH },
									]}
								/>
							</div>
							<div className={classes.cell}>
								<Select
									value={form.finalStatus}
									label={t('fields.finalStatus')}
									onChange={handleChangeForm('finalStatus')}
									withoutClear
									testId={TEST_IDS.MODAL_ORDER_AMOUNT}
									options={[
										{ label: 'Delivered', value: FinalStatus.DELIVERED },
										{ label: 'Canceled', value: FinalStatus.CANCELED },
										{ label: 'Not paid', value: FinalStatus.NOT_PAID },
									]}
								/>
							</div>
						</div>
					)}

					<div className={clsx(classes.row, classes.commentLine)}>
						{item.isMarketplaceOrder ? (
							<div className={classes.cell}>
								<Input
									value={form.deliveryPromotionAssumedByPartner}
									label={t('fields.deliveryDiscountsAssumedByPartnerCurrLocalShort')}
									onChange={handleChangeForm('deliveryPromotionAssumedByPartner')}
									Icon={getCurrency()}
									rootClassName={clsx({ [classes.emptySum]: !Number(form.deliveryDiscountAssumedByPartner) })}
								/>
							</div>
						) : (
							<>
								{!!item.deliveryDiscountAssumedByPartner && (
									<div className={classes.cell}>
										<Input
											value={form.deliveryDiscountAssumedByPartner}
											label={t('fields.deliveryDiscountsAssumedByPartnerCurrLocalShort')}
											onChange={handleChangeForm('deliveryDiscountAssumedByPartner')}
											Icon={getCurrency()}
										/>
									</div>
								)}
								{!!item.deliveryDiscountSSP && (
									<div className={classes.cell}>
										<Input
											value={form.deliveryDiscountSSP}
											label={t('fields.deliveryDiscountsAssumedByPartnerCurrLocalShort')}
											onChange={handleChangeForm('deliveryDiscountSSP')}
											Icon={getCurrency()}
										/>
									</div>
								)}
								{!!item.deliveryDiscountAssumedByPartner100 && (
									<div className={classes.cell}>
										<Input
											value={form.deliveryDiscountAssumedByPartner100}
											label={t('fields.deliveryDiscountsAssumedByPartnerCurrLocalShort')}
											onChange={handleChangeForm('deliveryDiscountAssumedByPartner100')}
											Icon={getCurrency()}
										/>
									</div>
								)}
								{!!item.deliveryDiscountAssumedByPartnerAndGlovo && (
									<div className={classes.cell}>
										<Input
											value={form.deliveryDiscountAssumedByPartnerAndGlovo}
											label={t('fields.deliveryDiscountsAssumedByPartnerCurrLocalShort')}
											onChange={handleChangeForm('deliveryDiscountAssumedByPartnerAndGlovo')}
											Icon={getCurrency()}
										/>
									</div>
								)}
								{/* TODO: need uncomment */}
								{/* <div className={classes.cell}>
									<Input
										value={form.productDiscountAssumedByPartner}
										label={t('fields.productDiscountsAssumedByPartnerCurrLocalShort')	}
										onChange={handleChangeForm	('productDiscountAssumedByPartner')}
										Icon={getCurrency()}
									/>
									</div> */}
							</>
						)}
						<div className={classes.commentRow}>
							<div className={classes.comment_label}>{t('edit.comment')}</div>
							<Input
								value={form.comment}
								onChange={handleChangeForm('comment')}
								rootClassName={classes.fullWidth}
								error={errors?.comment}
								testId={TEST_IDS.MODAL_ORDER_COMMENT}
							/>
						</div>
					</div>
					<div className={clsx(classes.row, classes.fileList)}>
						{!!fileList?.length && fileList?.map((f: File) => <AttachFile key={f.name} file={f} onRemove={handleRemoveFile} />)}
					</div>
					<div className={classes.row}>
						<File
							rootClassName={classes.file}
							onChange={handleChangeInputFile}
							testId={TEST_IDS.MODAL_ORDER_FILE}
							validTypes={validTypesForOrderFiles}
						>
							{t('form:attachFile')}
						</File>
					</div>
					<div className={classes.controls}>
						<button className={classes.cancel} onClick={onClose}>
							{t('form:cancel')}
						</button>
						<button onClick={() => sendAmendment()}>{t('form:send')}</button>
					</div>
				</div>
			</div>
		</Provider>,
	);

	function capitalizeFirstLetter(str: string) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	const getKeyWithPromoCondition = (key: keyof IFormEdit) => {
		const deliveryKeys: DeliveryPromo[] = Object.values(DeliveryPromo).filter((val) => val !== DeliveryPromo.MARKETPLACE_PROMO) as DeliveryPromo[];
		if (deliveryKeys.includes(key as unknown as DeliveryPromo)) return null;
		if (key === DeliveryPromo.MARKETPLACE_PROMO) {
			if (item.isMarketplaceOrder) return DeliveryPromo.MARKETPLACE_PROMO;
			const [moreThanZeroField] = deliveryKeys.filter((delKey) => item[delKey as keyof IOrder] && item[delKey as keyof IOrder]);
			return moreThanZeroField as keyof IFormEdit;
		}
		return key;
	};

	const getAmendments = (amendments: IFormEdit, prevAmendments?: IFormEdit) => {
		const res = Object.entries(fields)
			.map(([key, value]) => {
				if (key === 'comment') return null;
				let keyAmendment: null | keyof IFormEdit = key as keyof IFormEdit;
				if (item.isMarketplaceOrder && amendments.comment !== 'import') {
					keyAmendment = getKeyWithPromoCondition(key as keyof IFormEdit);
				}
				if (!keyAmendment) return null;
				let text;
				if (!prevAmendments) {
					const keyOrder = `origin${capitalizeFirstLetter(key)}` as keyof IOrder;
					if (amendments[keyAmendment] === item[keyOrder]) return null;
					text = () => (
						<>
							{value} {t('common:with')}
							<span>{typeof item[keyOrder] === 'number' ? formatPrice(item[keyOrder] as number, 2, ',', '0,00') : item[keyOrder]}</span>
							{t('common:on')}
							<span>
								{typeof amendments[keyAmendment] === 'number'
									? formatPrice(amendments[keyAmendment] as number, 2, ',', '0,00')
									: amendments[keyAmendment]}
							</span>
						</>
					);
				} else {
					if (amendments[keyAmendment] === prevAmendments[keyAmendment]) return null;
					text = () => (
						<>
							{value} {t('common:with')}
							<span>
								{typeof prevAmendments[keyAmendment] === 'number'
									? formatPrice(prevAmendments[keyAmendment] as number, 2, ',', '0,00')
									: prevAmendments[keyAmendment]}
							</span>
							{t('common:on')}
							<span>
								{typeof amendments[keyAmendment] === 'number'
									? formatPrice(amendments[keyAmendment] as number, 2, ',', '0,00')
									: amendments[keyAmendment]}
							</span>
						</>
					);
				}
				return (
					<div className={classes.editText} key={key}>
						{text()}
					</div>
				);
			})
			.filter((it) => !!it);
		res.push(
			<div className={classes.editText} key="comment">
				{fields.comment}: {amendments.comment}
			</div>,
		);
		return res.map((it) => it);
	};

	const isGlovoRoles = hasRole(roles, [...GLOVO_ROLES]) || isCredentialsAdmin;

	const proposeEditExtended = amendmentsHistory?.getAmendmentsHistory.map((it, index, origin) => {
		const isRejected = !!it.rejectedAt;
		const dateReactionAmendment = isRejected ? it.rejectedAt : it.acceptedAt;
		const isLast = index + 1 === origin.length;
		const searchPrevArray = origin.slice(0, index).reverse();
		const prev = searchPrevArray.find((h) => !!h.acceptedAt);
		if (!dateReactionAmendment) return null;
		return (
			<div
				className={clsx(classes.edit, {
					[classes.edit_rejected]: isRejected,
				})}
				key={it.id}
			>
				<div>
					<div className={classes.editRow}>
						<div className={classes.editTitle}>
							{t('edit.suggestEdit')}
							<span className={classes.editDate}>
								{formatDate(new Date(it.createdAt))} {isGlovoRoles && t('common:at')} {isGlovoRoles && formatTime(new Date(it.createdAt))}
							</span>
						</div>
						{getAmendments(it, prev)}
						{!!it.files?.length &&
							it.files.map((file) => (
								<FileLoaded key={file.fileId} id={file.fileId} filename={file.filename} size={file.size} rootClassName={classes.fileLoaded} />
							))}
						<div className={classes.editAuthor}>{renderAuthorEmail(roles, it.createdBy)}</div>
					</div>
					<div className={classes.editRow}>
						<div className={classes.editTitle}>
							{isRejected ? t('edit.rejected') : t('edit.accepted')}
							<span className={classes.editDate}>
								{formatDate(new Date(dateReactionAmendment))} {isGlovoRoles && t('common:at')}{' '}
								{isGlovoRoles && formatTime(new Date(dateReactionAmendment))}
							</span>
						</div>
						{isRejected && it.rejectedReason && (
							<div className={classes.editText}>
								{fields.comment}: {it.rejectedReason}
							</div>
						)}
						<div className={classes.editAuthor}>{renderAuthorEmail(roles, isRejected ? it.rejectedBy : it.acceptedBy)}</div>
						{isRejected && false && (
							<div className={classes.editinfo}>
								<InfoIcon />
								<div className={classes.editinfoText}>
									<Trans t={t} i18nKey={'edit.returnType'} values={{ type: 'Self Refund' }} />
								</div>
							</div>
						)}
					</div>
					{isApprovedOrder && isLast && orderApproval?.orderApproval && (
						<div className={clsx(classes.editRow, classes.editRow_approved)}>
							<div className={classes.editTitle}>
								<ApprovedIcon />
								{t('edit.approved')}
								<span className={classes.editDate}>
									{formatDate(new Date(orderApproval.orderApproval.approvedAt))}
									&nbsp;{isGlovoRoles && t('common:at')}&nbsp;
									{isGlovoRoles && formatTime(new Date(orderApproval.orderApproval.approvedAt))}
								</span>
							</div>
							<div className={classes.editAuthor}>{renderAuthorEmail(roles, orderApproval.orderApproval.approvedByEmail)}</div>
						</div>
					)}
				</div>
			</div>
		);
	});

	const editButton = renderChangeForm(
		<div>
			<button className={clsx(classes.editOrderBtn, { [classes.editOrderBtnReject]: isLastAmendmentsReject })} onClick={handleForceAmendmentForm}>
				<EditIcon />
				{t('form:editOrder')}
			</button>
		</div>,
	);

	const proposeEditSimple = orderApproval?.orderApproval && (
		<div className={clsx(classes.edit)}>
			<div className={clsx(classes.editRow, classes.editRow_approved)}>
				<div className={classes.editTitle}>
					<ApprovedIcon />
					{t('edit.approved')}
					<span className={classes.editDate}>
						{formatDate(new Date(orderApproval.orderApproval.approvedAt))}
						&nbsp;{isGlovoRoles && t('common:at')}&nbsp;
						{isGlovoRoles && formatTime(new Date(orderApproval.orderApproval.approvedAt))}
					</span>
				</div>
				<div className={classes.editAuthor}>{renderAuthorEmail(roles, orderApproval.orderApproval.approvedByEmail)}</div>
			</div>
			{(isFinance || isAdmin || isCredentialsAdmin) && editButton}
		</div>
	);

	const proposeEdit = amendmentsHistory?.getAmendmentsHistory.map((it, index, origin) => {
		const render = !it.acceptedAt && !it.rejectedAt;
		const searchPrevArray = origin.slice(0, index).reverse();
		const prev = searchPrevArray.find((h) => !!h.acceptedAt);
		if (!render) return null;
		return (
			<div className={classes.editBefore} key={it.id}>
				<div className={classes.editRow}>
					<div className={classes.editTitle}>
						{t('edit.suggestEdit')}
						<span className={classes.editDate}>
							{formatDate(new Date(it.createdAt))} {isGlovoRoles && t('common:at')} {isGlovoRoles && formatTime(new Date(it.createdAt))}
						</span>
					</div>
					{getAmendments(it, prev)}
					<div className={classes.editAuthor}>{renderAuthorEmail(roles, it.createdBy)}</div>
					{!!it.files?.length &&
						it.files.map((file) => (
							<FileLoaded key={file.fileId} id={file.fileId} filename={file.filename} size={file.size} rootClassName={classes.fileLoaded} />
						))}
					{!isFinance && (
						<div className={classes.editinfo}>
							<InfoIcon />
							<div className={classes.editinfoText}>{t('edit.consideration')}</div>
						</div>
					)}
				</div>
				{(isFinance || isAdmin || isAccount ? !showChangeForm : false) && (
					<div className={classes.editButtons}>
						<button className={classes.editButtonReject} onClick={isUA ? handleOpenRejectModalId(it.id) : handleRejectOrderAmendment(it.id)}>
							{t('form:reject')}
						</button>
						<button onClick={handleAcceptOrderAmendment(it.id)}>{t('form:approve')}</button>
					</div>
				)}
			</div>
		);
	});

	const renderForm = () => {
		if (isFake) return formChange;
		if (loadingAmendmentsHistory || (loadingOrderApproval && !mount)) return <Loader simple />;
		if (amendmentsHistory?.getAmendmentsHistory.length && !forceAmendment) {
			const conditionToShowEditButton = ((!isApprovedOrder && !hasAcceptedAmendments) || isFinance) && !hasNotReactionAmendments;
			return (
				<>
					{proposeEditExtended}
					{proposeEdit}
					{renderButtonApproveOrder}
					{conditionToShowEditButton && editButton}
					{!conditionToShowEditButton && !orderApproval?.orderApproval && item.orderStatus === OrderStatus.PENDING && editButton}
				</>
			);
		}
		if (isApprovedOrder && !forceAmendment) return proposeEditSimple;
		return formChange;
	};

	const title = hasRole(roles, [RoleEnum.ADMIN, RoleEnum.FINANCE, RoleEnum.ACCOUNT]) ? (
		<div className={classes.titleModal}>
			<span>{`${t('edit.title')} ${item.code}`}</span>
			<CopyButton className={classes.copyIcon} text={item.code} />
			<IconButton size="average" className={classes.adminLink} component="a" target="_blank" href={`${adminLink}/order/${item.id}`}>
				<LinkIcon />
			</IconButton>
		</div>
	) : (
		`${t('edit.title')} ${item.code}`
	);

	const handleChangeReason = (val: string) => () => {
		setSelectedReason(val);
	};

	return (
		<>
			<Modal
				open={open}
				onClose={onClose}
				title={title}
				titleClassName={classes.headline}
				bodyClassName={classes.body}
				rootClassName={clsx(rootClassName, classes.root)}
				closeButtonClassName={classes.closeIcon}
			>
				{loading && <Loader />}
				<div className={classes.form}>
					{formHead}
					{renderForm()}
				</div>
			</Modal>
			<Modal
				open={openModalChangePaymentMethod}
				onClose={handleCloseModalChangePaymentMethod}
				modalClassName={classes.changePaymentMethodModal}
				buttons={{
					cancel: {
						text: t('warningChangePaymentMethod.cancel', {
							old: form.paymentMethod,
						}),
						onClick: cancelChangePaymentMethod,
					},
					confirm: {
						text: t('warningChangePaymentMethod.confirm', {
							new: newPaymentMethod,
						}),
						onClick: approveChangePaymentMethod,
					},
				}}
			>
				<div className={classes.icon}>
					<PaymentMethodIcon />
				</div>
				<div className={classes.title}>
					{t('warningChangePaymentMethod.attention')}
					<br />
					{t('warningChangePaymentMethod.title', {
						old: form.paymentMethod,
						new: newPaymentMethod,
					})}
				</div>
			</Modal>
			<Modal
				open={Boolean(openRejectModalId)}
				onClose={handleOpenRejectModalId('')}
				modalClassName={classes.rejectModal}
				title={t('rejectModaltitle')}
				buttons={{
					cancel: {
						text: t('rejectWithoutComment'),
						onClick: handleRejectOrderAmendment(openRejectModalId),
					},
					confirm: {
						text: t('rejectWithComment'),
						onClick: handleRejectOrderAmendment(openRejectModalId, selectedReason),
						disabled: !selectedReason,
					},
				}}
			>
				<div className={classes.reasonList}>
					{data.reasons.map((r, i) => (
						<Radio key={i} isChecked={selectedReason === r} onChange={handleChangeReason(r)}>
							{t(r)}
						</Radio>
					))}
				</div>
			</Modal>
		</>
	);
};

export default OrderModal;
