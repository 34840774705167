import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCountry, getLang } from 'helpers/jwt';
import { countryLang, getLang as getUserLang, setLang } from 'helpers/lang';
import { useUser } from 'hooks/useUser';
import i18n from 'i18n';
import { TEST_IDS } from 'tests/config';

import { Select } from 'components/FormControl';
import Loader from 'components/Loader';

import classes from './LangSwitcher.module.scss';

const LangSwitcher: React.FC = () => {
	const { t } = useTranslation(['common']);
	const [value, setValue] = useState<string>();
	const [loading, setLoading] = useState<boolean>(false);
	const userCountry = getCountry() as keyof typeof countryLang;
	const { changeUserLang } = useUser();

	const handleChangeCountry = async (lang: string) => {
		setValue(lang);
		setLang(lang);
		changeUserLang(lang);
		setLoading(true);
		await i18n.changeLanguage(lang);
		setLoading(false);
	};

	useEffect(() => {
		if (!userCountry) return;
		let hasLang = true;
		const userLang: string = getUserLang() || (getLang() as string);
		const countryLangs = countryLang[userCountry];
		const availableLangs = (currentLang: string) => countryLangs?.find((langCode) => langCode === currentLang);
		if (userLang && !availableLangs(userLang)) hasLang = false;
		const lang: string = hasLang ? userLang : countryLangs[0];
		handleChangeCountry(lang);
	}, [userCountry]);

	if (!userCountry) return null;

	const viewNaming = countryLang[userCountry];

	const options =
		viewNaming?.map((lang) => ({
			value: lang,
			label: t(`lang.${lang}`),
		})) || [];

	if (options.length === 1) return null;

	if (loading) return <Loader />;

	return (
		<Select
			value={value}
			options={options}
			onChange={handleChangeCountry}
			rootClassName={classes.rootClassName}
			controlClassName={classes.control}
			iconColor="success"
			withoutClear
			testId={TEST_IDS.LANG_SWITCHER}
		/>
	);
};

export default LangSwitcher;
