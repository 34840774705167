const REGEXP_PASSWORD = /^(?=.*[A-Za-z@$!%*#?&])(?=.*\d)[A-Za-z\d@$!%*#?&]{6,}$/i;
export const REGEXP_PASSWORD_MIN_6 = /^.{6,}$/;
export const REGEXP_PASSWORD_LETTER_OR_SPECIAL = /[A-Za-z@$!%*#?&]/;
export const REGEXP_PASSWORD_DIGIT = /\d/;
export const REGEXP_IS_CYRILLIC = /[\u0400-\u04FF]/gi;
const REGEXP_EMAIL =
	// eslint-disable-next-line no-useless-escape
	/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

export const isValidPassword = (password: string): boolean => REGEXP_PASSWORD.test(password);
export const isValidEmail = (email: string): boolean => REGEXP_EMAIL.test(email);
export const isSetString = (str: string): boolean => !!str.trim().length;
export const isLimitString = (str: string, count: number): boolean => str.trim().length <= count;
export const isMinString = (str: string, count: number): boolean => str.trim().length >= count;
export const isLengthString = (str: string, count: number): boolean => str.trim().length === count;
export const isUrlValid = (url: string) => {
	const pattern = new RegExp(
		'^([a-zA-Z]+:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$', // fragment locator
		'i',
	);
	return pattern.test(url);
};
