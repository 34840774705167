import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import InvoiceHead from 'containers/Accounts/InvoiceTable/InvoiceHead';
import clsx from 'helpers/clsx';
import { useInvoices } from 'hooks/useInvoices';
import { useUser } from 'hooks/useUser';
import { ReactComponent as Emptyico } from 'static/images/empty-accounts.svg';
import { ReactComponent as EmptyServiceico } from 'static/images/empty-accounts-service.svg';
import { TEST_IDS } from 'tests/config';

import LoadMore from 'components/LoadMore';
import Nowrap from 'components/Nowrap';

import InvoiceRow from './InvoiceRow';

import classes from 'pages/Accounts/Accounts.module.scss';

const InvoiceTable: FC<{ emptyFilter: boolean }> = ({ emptyFilter }) => {
	const { t } = useTranslation('accounts');
	const { invoices, invoicesTotal, invoicesPagionation, setPageSize } = useInvoices();
	const { isServiceUser } = useUser();

	const emptyState = () => {
		return (
			<tr className={classes.emptyStateRow}>
				<td colSpan={12}>
					<div className={classes.emptyState}>
						{isServiceUser ? <EmptyServiceico /> : <Emptyico />}
						{emptyFilter ? (
							<>
								<h2> {isServiceUser ? t('emptyFilterService') : t('paymentEmpty', { type: t('invoiceType') })}</h2>
								{isServiceUser && <p>{t('emptyFilterServiceDesc')}</p>}
							</>
						) : (
							<Nowrap>
								<h2>{t('paymentEmptyFilter', { type: t('invoiceType') })}</h2>
								<p>
									<Trans t={t} i18nKey="paymentClear" />
								</p>
							</Nowrap>
						)}
					</div>
				</td>
			</tr>
		);
	};
	const start = invoicesPagionation.page * invoicesPagionation.pageSize + 1;
	const end = start + invoicesPagionation.pageSize - 1;
	const canLoadMore = end < invoicesTotal;

	const loadMore = () => {
		setPageSize(invoicesPagionation.pageSize + 25, true);
	};

	return (
		<div className={clsx(classes.accounts, classes.invoices)}>
			<table data-testid={TEST_IDS.ACCOUNTS_INVOICE_TABLE}>
				<InvoiceHead />
				<tbody>
					{!invoices.length && emptyState()}
					{invoices.map((invoice) => (
						<InvoiceRow item={invoice} key={invoice.id} />
					))}
				</tbody>
			</table>
			{!!invoices.length && canLoadMore && <LoadMore onClick={loadMore} className={classes.loadMore} />}
		</div>
	);
};

export default InvoiceTable;
