import { gql } from '@apollo/client';

export default gql`
	type Mutation {
		registerUser(input: UserRegisterRequest): UserDto
		resetPassword(email: String!): UserDto
		approveOrder(orderId: Long): OrderApprovalDto
		submitOrderAmendment(input: OrderPartnerAmendmentInputDto): OrderPartnerAmendmentDto
		addComment(comment: CommentInputDTO!): [PartnerCommentResponseDTO!]!
		createStoreAddress(input: StoreAddressInputDto!): StoreAddressFullDto
		editStoreAddress(input: StoreAddressInputDto!): StoreAddressFullDto
		deleteStoreAddress(storeAddressId: Int, date: Date): Boolean
		approveAllOrders(filter: OrderFilter): Int
		acceptOrderAmendment(orderId: Long!, amendmentId: String!): OrderPartnerAmendmentDto
		rejectOrderAmendment(orderId: Long!, amendmentId: String!): OrderPartnerAmendmentDto
		deleteUser(id: String): UserDto
		updateUserCountryRoles(id: ID!, country: CountryCodeEnum!, roles: [RoleEnum!]!): UserDto
		updatePayoutSchedule(input: [PayoutScheduleInputDto]!): [PayoutScheduleDto]
		changePreferredLang(id: String, newLang: String): String
	}
`;
