import { ClientNameEnum, Routes } from 'const';
import { getCountry, getLang } from 'helpers/jwt';
import { getLang as getUserlang, removeLang } from 'helpers/lang';
import { hasRemember, removeRemember } from 'helpers/remember';
import { getTokenSync, refreshToken, removeRefreshToken, removeToken } from 'helpers/token';
import { IFetchOptions } from 'interfaces';
import retryMyFetch from 'retry-my-fetch';
import { decoratorOptions, Fetch, fetchOptions } from 'retry-my-fetch/dist/interfaces';

import 'cross-fetch';

const langHeaderKey = 'Accept-Language';
const countryCodeKey = 'X-country-code';

const getOptionsWithAuthorization = (options: fetchOptions, token?: string, authContext = false) => {
	if (!token) token = getTokenSync();
	const country = getCountry();
	const lang: string | undefined = getUserlang() || getLang();
	return {
		...options,
		headers: {
			...options?.headers,
			...(token && { authorization: `Bearer ${token}` }),
			...(country && authContext && { [countryCodeKey]: country }),
			...(lang && { [langHeaderKey]: lang }),
		},
	};
};

const logout = () => {
	removeToken();
	removeRefreshToken();
	removeRemember();
	removeLang();
	const href = `${process.env.PUBLIC_URL || ''}${Routes.LOGIN}`;
	window.location.href = href;
};

const canRefetchByUrl = (url: string) => {
	const EXCLUDE_URL: string[] = ['refreshToken', 'authenticate'];
	return !EXCLUDE_URL.some((u) => url.includes(u));
};

const beforeRefetch = async (url: string, options: fetchOptions, statusCode: number) => {
	if (statusCode === 401 && canRefetchByUrl(url)) {
		if (!hasRemember()) {
			return logout();
		}
		const newToken = await refreshToken().catch(() => undefined);
		if (!newToken) {
			return logout();
		}
		return getOptionsWithAuthorization(options, newToken);
	}
	return options;
};

const config = {
	maxTryCount: 5,
	beforeRefetch,
	useAbortController: true,
	doNotRetryIfStatuses: [403, 504],
};

const fetchWithRetry = retryMyFetch(fetch as Fetch, config as decoratorOptions);

export default function fetchWithToken(url: string, options: IFetchOptions = {}) {
	const authContext = url.includes(ClientNameEnum.AUTH) || options.fintoolContracts;
	return fetchWithRetry(url, getOptionsWithAuthorization(options as fetchOptions, undefined, authContext) as fetchOptions);
}
