import { useEffect, useState } from 'react';

interface IOptions {
	defaultMatches?: boolean;
}

export const useMediaQuery = (queryInput: string, options: IOptions = {}): boolean => {
	const query = queryInput.replace(/^@media( ?)/m, '');
	const { defaultMatches = false } = options;
	const [match, setMatch] = useState(() => defaultMatches);

	useEffect(() => {
		let active = true;
		const queryList: MediaQueryList = window.matchMedia(query);
		const updateMatch = () => {
			if (active) {
				setMatch(queryList.matches);
			}
		};
		updateMatch();
		queryList.addListener(updateMatch);
		return () => {
			active = false;
			queryList.removeListener(updateMatch);
		};
	}, [query]);

	return match;
};
