import { gql } from '@apollo/client';

export const GET_BULK_HISTORY = gql`
	query GetBulkUploadHistory($page: Int, $pageSize: Int) {
		getBulkUploadHistory(page: $page, pageSize: $pageSize) {
			data {
				fileId
				filename
				createdAt
				status
				createdBy
				comment
				errorFileId
			}
			total
			totalPages
		}
	}
`;

export const GET_BULK_SUBSCRIPTION = gql`
	subscription bulkUploadHistory {
		bulkUploadHistory {
			fileId
			filename
			createdAt
			status
			createdBy
			comment
			errorFileId
			total
			position
		}
	}
`;
