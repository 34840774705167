import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask, { type Props as InputMaskProps } from 'react-input-mask';
import { weekDays } from 'const';
import clsx from 'helpers/clsx';
import { parseWorkingHours } from 'helpers/contractGeneration';
import { WorkHours, WorkHoursDay, WorkHoursDayError, WorkHoursErrors } from 'interfaces/contractGeneration';
import { ReactComponent as DeleteIcon } from 'static/images/close.svg';
import { ReactComponent as PlusIcon } from 'static/images/plus.svg';
import { get } from 'underscore';

import { Checkbox } from 'components/FormControl';
import formControlclasses from 'components/FormControl/FormControl.module.scss';
import Modal from 'components/modals/Modal';

import { IPropsTemplate } from './types';

import classes from './WorkingHours.module.scss';

/**
 * package has issue with types
 * so implemented workaround https://github.com/sanniassin/react-input-mask/issues/139#issuecomment-1307161406
 */
type TypedInputMaskProps = Omit<InputMaskProps, 'children'> & { children: React.ReactNode };
const TypedInputMask = InputMask as React.ComponentType<TypedInputMaskProps>;

const WorkingHours: React.FC<IPropsTemplate> = ({ onClose, onSave, workingHoursString, translation, canBeEmpty = false }) => {
	const { t } = useTranslation('contractGeneration');
	const [workingHours, setWorkingHours] = useState<WorkHours>(parseWorkingHours(workingHoursString, translation));
	const [errors, setErrors] = useState<WorkHoursErrors>({});

	const isStartWithTwo = (time: string) => +time?.split('')[0] === 2;

	const handleTimeType = (day: string, period: 'before' | 'after') => () => {
		if (workingHours[day] && workingHours[day][period]) {
			const updated = { ...workingHours };
			if (period === 'before') {
				delete updated[day];
			} else {
				delete updated[day][period];
			}

			setWorkingHours(updated);
		} else {
			setWorkingHours({
				...workingHours,
				[day]: {
					...workingHours[day],
					[period as string]: {
						open: '',
						close: '',
					},
				},
			});
		}
	};

	const handleChangeWorkingHours = (e: ChangeEvent<HTMLInputElement>, day: string, period: 'before' | 'after', type: 'open' | 'close') => {
		setErrors({
			...errors,
			[day]: {
				...errors[day],
				[period]: {
					...(errors[day] && errors[day][period] ? errors[day][period] : {}),
					[type]: false,
				},
			},
		});
		setWorkingHours({
			...workingHours,
			[day]: {
				...workingHours[day],
				[period]: {
					...workingHours[day][period],
					[type]: e.target.value,
				},
			},
		});
	};

	const save = async () => {
		try {
			let newErrors: WorkHoursErrors = {};
			Object.keys(workingHours).forEach((day) => {
				if (workingHours[day]) {
					Object.keys(workingHours[day]).forEach((period) => {
						const dayPeriod = get(workingHours[day], period);
						if (dayPeriod) {
							Object.keys(dayPeriod).forEach((type) => {
								const value = dayPeriod[type] as string;
								if (!value || value.includes('_')) {
									newErrors = {
										...newErrors,
										[day]: {
											...newErrors[day],
											[period]: {
												...(newErrors[day] && newErrors[day as keyof WorkHoursErrors][period as keyof WorkHoursDayError]
													? newErrors[day as keyof WorkHoursErrors][period as keyof WorkHoursDayError]
													: {}),
												[type]: true,
											},
										},
									};
								}
							});
						}
					});
				}
			});

			if (Object.keys(newErrors).length) {
				setErrors(newErrors);
			} else {
				const res = weekDays.map((day) => {
					if (workingHours[day]) {
						return `${t(day)} ${workingHours[day].before.open} - ${workingHours[day].before.close}${
							workingHours[day].after ? `, ${workingHours[day].after?.open} - ${workingHours[day].after?.close};` : ';'
						}`;
					}
					return canBeEmpty ? '' : `${t(day)} ${t('workingHours.dayOff')};`;
				});
				onSave(res.join(' ')).then(onClose);
			}
		} catch {
			throw new Error();
		}
	};

	return (
		<Modal
			title={t('contractGeneration:workingHours.title')}
			open
			onClose={onClose}
			rootClassName={classes.working_hours}
			buttons={{
				cancel: {},
				confirm: {
					text: t('contractGeneration:workingHours.save'),
					onClick: save,
				},
			}}
		>
			<div className={classes.working_hours_wrap}>
				{workingHours &&
					weekDays?.map((day) => {
						const currentDay: WorkHoursDay = workingHours[day];
						return (
							<div key={day} className={classes.working_hours_wrap_row}>
								<div className={classes.working_hours_wrap_row_day}>{t(day)}</div>
								<Checkbox isChecked={!!currentDay} onChange={handleTimeType(day, 'before')} />
								{currentDay &&
									Object.keys(currentDay)?.map((key: string) => {
										const time = currentDay[key as keyof typeof currentDay];
										const error = errors[day] && errors[day][key as keyof typeof currentDay];
										if (!time) return null;
										return (
											<React.Fragment key={key}>
												<div className={classes.input_wrap}>
													<label htmlFor="open">{t('workingHours.open')}</label>
													<TypedInputMask
														mask={[/[0-2]/, isStartWithTwo(time.open) ? /[0-3]/ : /[0-9]/, ':', /[0-5]/, /[0-9]/]}
														value={time.open}
														onChange={(e) => handleChangeWorkingHours(e, day, key as 'before' | 'after', 'open')}
													>
														<input id="open" className={clsx(formControlclasses.control, { [formControlclasses.error]: error?.open })} />
													</TypedInputMask>
												</div>
												<div className={classes.input_wrap}>
													<label htmlFor="close">{t('workingHours.close')}</label>
													<TypedInputMask
														mask={[/[0-2]/, isStartWithTwo(time.close) ? /[0-3]/ : /[0-9]/, ':', /[0-5]/, /[0-9]/]}
														value={time.close}
														onChange={(e) => handleChangeWorkingHours(e, day, key as 'before' | 'after', 'close')}
													>
														<input id="close" className={clsx(formControlclasses.control, { [formControlclasses.error]: error?.close })} />
													</TypedInputMask>
												</div>
											</React.Fragment>
										);
									})}
								{currentDay?.before && !currentDay?.after && (
									<button className={classes.button} onClick={handleTimeType(day, 'after')}>
										<PlusIcon className={classes.addIcon} />
										{t('contractGeneration:workingHours.addAfternoon')}
									</button>
								)}
								{currentDay?.after && (
									<button>
										<DeleteIcon color="#797A8D" onClick={handleTimeType(day, 'after')} />
									</button>
								)}
							</div>
						);
					})}
			</div>
		</Modal>
	);
};

export default WorkingHours;
