import { format } from 'date-fns';
import { toCamelCase } from 'helpers/partnersFile';
import { ValueOf } from 'interfaces';
import { DictionaryData, IFilter, PartnerCommission, PartnerHistory, PartnerHistoryDayValue, PartnerHistoryRow } from 'interfaces/partnersFile';

import {
	CHANGE_FILTRATION,
	CHANGE_PARTNER_FILE_FILTER,
	CLEAR_PARTNER_FILE_FILTER,
	CLEAR_PARTNER_FILTRATION,
	CREATE_CONTRACT,
	DELETE_CONTRACT_BY_NUMBER,
	FETCH_ACCESS_DATA,
	FETCH_ACCESS_DATA_FAILURE,
	FETCH_ACCESS_DATA_SUCCESS,
	FETCH_DICTIONARY_SUCCESS,
	FETCH_HISTORY_START,
	FETCH_HISTORY_SUCCESS,
	FETCH_PARTNER_FILE_FAILURE,
	FETCH_PARTNER_FILE_START,
	FETCH_PARTNER_FILE_SUCCESS,
	IAction,
	IState,
	RESET_SELECTED_ROW,
	SET_COMMISSION_VALID,
	SET_PARTNER_FILE_PAGE,
	SET_PARTNER_FILE_PAGE_SIZE,
	SET_PARTNER_HISTORY_PAGE,
	UPDATE_CONTRACT_AND_HISTORY,
} from './types';

const initialState: IState = {
	items: [],
	partnerHistory: [],
	partnerHistoryPagination: {
		page: 0,
		pageSize: 3,
	},
	partnerHistoryTotal: 0,
	total: 0,
	onload: true,
	wasLoaded: false,
	loadingHistory: true,
	filter: {},
	filtration: {},
	dictionaries: [],
	pagination: {
		page: 0,
		pageSize: 25,
	},
	selectedRow: null,
	accessData: {
		emails: [],
		fields: [],
	},
	loadingAccessData: false,
};

const COMMISSION_FIELDS_COUNT = 4;
const COMMISSION_PREFIX = 'commission.';

const reducer = (state = initialState, action: IAction): IState => {
	switch (action.type) {
		case FETCH_PARTNER_FILE_START:
			return {
				...state,
				onload: true,
			};
		case FETCH_PARTNER_FILE_SUCCESS:
			return {
				...state,
				items: action.payload.data,
				total: action.payload.total,
				onload: false,
				wasLoaded: true,
			};
		case FETCH_PARTNER_FILE_FAILURE:
			return {
				...state,
				items: [],
				total: 0,
				onload: false,
				wasLoaded: true,
			};
		case SET_PARTNER_FILE_PAGE:
			return {
				...state,
				pagination: {
					...state.pagination,
					page: action.payload,
				},
			};
		case SET_PARTNER_HISTORY_PAGE:
			return {
				...state,
				partnerHistoryPagination: {
					...state.partnerHistoryPagination,
					page: action.payload,
				},
			};
		case SET_PARTNER_FILE_PAGE_SIZE:
			return {
				...state,
				pagination: {
					...state.pagination,
					pageSize: action.payload,
				},
			};
		case CHANGE_PARTNER_FILE_FILTER: {
			const getFilterValue = (value: ValueOf<IFilter>) => {
				if (typeof value === 'boolean') return value;
				return value || undefined;
			};
			return {
				...state,
				filter: {
					...state.filter,
					[action.payload.field]: getFilterValue(action.payload.value),
				},
				pagination: {
					page: 0,
					pageSize: 25,
				},
			};
		}
		case CLEAR_PARTNER_FILE_FILTER:
			return {
				...state,
				filter: {
					...initialState.filter,
				},
			};
		case CLEAR_PARTNER_FILTRATION:
			return {
				...state,
				filtration: {
					...initialState.filtration,
				},
			};
		case DELETE_CONTRACT_BY_NUMBER:
			return {
				...state,
				items: state.items.filter((it) => it.contractNumber !== action.payload),
			};
		case FETCH_HISTORY_START:
			return {
				...state,
				loadingHistory: true,
			};
		case FETCH_HISTORY_SUCCESS:
			return {
				...state,
				partnerHistory: !state.partnerHistoryPagination.page
					? action.payload.partnerHistory.data
					: [...state.partnerHistory, ...action.payload.partnerHistory.data],
				partnerHistoryTotal: action.payload.partnerHistory.total,
				loadingHistory: false,
			};
		case FETCH_DICTIONARY_SUCCESS:
			return {
				...state,
				dictionaries: action.payload.dictionaries.map((d: DictionaryData) => ({
					...d,
					type: toCamelCase(d.type),
				})),
			};
		case CREATE_CONTRACT:
			return {
				...state,
				items: [action.payload, ...state.items],
				selectedRow: action.payload,
			};
		case RESET_SELECTED_ROW:
			return {
				...state,
				selectedRow: null,
			};
		case CHANGE_FILTRATION:
			return {
				...state,
				filtration: { ...action.payload },
				pagination: { ...state.pagination, page: 0 },
			};
		case UPDATE_CONTRACT_AND_HISTORY:
			return {
				...state,
				items: state.items.map((contract) => {
					if (action.payload.length === COMMISSION_FIELDS_COUNT && contract.contractNumber === action.payload[0].contractNumber) {
						const isNewCommission = action.payload.every((history: PartnerHistory) => history.oldValue === null);
						const isDeletedCommission = action.payload.every((history: PartnerHistory) => history.newValue === null);
						if (isNewCommission) {
							let newCommission: PartnerCommission = {
								id: action.payload[0].commissionId,
								contractNumber: action.payload[0].contractNumber,
								startDate: '',
								endDate: '',
								comment: '',
								value: 0,
								__typename: 'PartnerCommission',
							};
							action.payload.forEach((history: PartnerHistory) => {
								newCommission = {
									...newCommission,
									[history.field.replace(COMMISSION_PREFIX, '')]: history.newValue,
								};
							});
							contract = {
								...contract,
								commission: [...contract.commission, newCommission],
							};
						}
						if (isDeletedCommission) {
							contract = {
								...contract,
								commission: contract.commission.filter((c) => c.id !== action.payload[0].commissionId),
							};
						}
						if (!isNewCommission && !isDeletedCommission) {
							contract = {
								...contract,
								commission: contract.commission.map((c) => {
									action.payload.forEach((history: PartnerHistory) => {
										if (c.id === history.commissionId) {
											c = {
												...c,
												[history.field.replace(COMMISSION_PREFIX, '')]: history.newValue,
											};
										}
										return c;
									});
									return c;
								}),
							};
						}
					} else {
						action.payload.forEach((history: PartnerHistory) => {
							const { field, contractNumber, newValue, commissionId } = history;
							if (contract.contractNumber === contractNumber) {
								if (field.includes(COMMISSION_PREFIX) && commissionId) {
									contract = {
										...contract,
										commission: contract.commission.map((c) => {
											if (commissionId && c.id && c.id === commissionId) {
												return {
													...c,
													[field.replace(COMMISSION_PREFIX, '')]: newValue,
												};
											}
											return c;
										}),
									};
								}
								contract = { ...contract, [field]: newValue };
							}
						});
					}
					return contract;
				}),
				partnerHistory: action.payload.reduce((currentState: PartnerHistoryRow[], item: PartnerHistory) => {
					const createdAt = format(new Date(item.createdAt), 'dd.MM.yyyy');
					const hasRow = currentState.find((row) => row.createdAt === createdAt);
					if (hasRow && item.createdAt) {
						return currentState.map((history) => {
							return {
								...history,
								data: history.data.find((d) => d.createdBy === item.createdBy)
									? history.data.map((d) => {
											if (d.createdBy === item.createdBy) {
												return {
													...d,
													data: d.data ? [item, ...d.data] : [item],
												};
											}
											return d;
										})
									: ([item, ...history.data] as PartnerHistoryDayValue[]),
							};
						});
					} else {
						return [
							...currentState,
							{
								createdAt,
								data: [
									{
										createdBy: item.createdBy,
										data: [item],
									},
								],
							},
						];
					}
				}, state.partnerHistory),
			};
		case FETCH_ACCESS_DATA:
			return {
				...state,
				loadingAccessData: true,
			};
		case FETCH_ACCESS_DATA_SUCCESS:
			return {
				...state,
				loadingAccessData: false,
				accessData: action.payload,
			};
		case FETCH_ACCESS_DATA_FAILURE:
			return {
				...state,
				loadingAccessData: false,
			};
		case SET_COMMISSION_VALID:
			return {
				...state,
				items: state.items.map((i) => (i.contractNumber === action.payload.contractNumber ? { ...i, ...action.payload } : i)),
			};
		default:
			return state;
	}
};

export default reducer;
