import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Routes } from 'const';
import clsx from 'helpers/clsx';
import {
	isValidEmail,
	isValidPassword,
	REGEXP_IS_CYRILLIC,
	REGEXP_PASSWORD_DIGIT,
	REGEXP_PASSWORD_LETTER_OR_SPECIAL,
	REGEXP_PASSWORD_MIN_6,
} from 'helpers/validation';
import { useAuth } from 'hooks/useAuth';
import useToast from 'hooks/useToast';
import { useUser } from 'hooks/useUser';
import { ReactComponent as CheckIcon } from 'static/images/checkIconCircle.svg';
import { ReactComponent as CrossIcon } from 'static/images/crossIconCircle.svg';
import { ReactComponent as ResetPasswordIcon } from 'static/images/reset-password.svg';
import { TEST_IDS } from 'tests/config';

import { Checkbox, Input } from 'components/FormControl';
import Loader from 'components/Loader';
import MainButton from 'components/MainButton';

import classes from './Login.module.scss';

const Login: React.FC = () => {
	const [remember, setRemember] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const passwordOld = useRef<string>('');
	const [password, setPassword] = useState<string>('');
	const [passwordRepeat, setPasswordRepeat] = useState<string>('');
	const [needChangePassword, setNeedChangePassword] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const { t } = useTranslation(['login', 'form', 'validation', 'errors', 'forgotPassword']);
	const { login } = useAuth();
	const { toast } = useToast();
	const { changePassword } = useUser();
	const [passwordReqs, setPasswordReqs] = useState<{ [key: string]: boolean | null }>({
		requirement1: null,
		requirement2: null,
		requirement3: null,
		requirement4: null,
	});
	const [matchError, setMatchError] = useState('');

	const handleChangeEmail = (value: string) => {
		setEmail(value);
	};

	const resetPasswordReqs = () => {
		setPasswordReqs({
			requirement1: null,
			requirement2: null,
			requirement3: null,
			requirement4: null,
		});
	};

	const handleChangePassword = (value: string) => {
		setPassword(value);
		if (value.length) {
			setPasswordReqs({
				requirement1: REGEXP_PASSWORD_LETTER_OR_SPECIAL.test(value),
				requirement2: REGEXP_PASSWORD_DIGIT.test(value),
				requirement3: REGEXP_PASSWORD_MIN_6.test(value),
				requirement4: !REGEXP_IS_CYRILLIC.test(value),
			});
		} else {
			resetPasswordReqs();
		}
	};

	const handleChangePasswordRepeat = (value: string) => {
		if (matchError) {
			setMatchError('');
		}
		setPasswordRepeat(value);
	};

	const toggleRememer = () => {
		setRemember((state) => !state);
	};

	const reset = () => {
		setNeedChangePassword(false);
		setEmail('');
		setPassword('');
		setPasswordRepeat('');
		resetPasswordReqs();
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (needChangePassword) {
			if (password.trim() !== passwordRepeat.trim()) {
				setMatchError(t('validation:errors.passwordsNotEqual'));
				return toast(t('validation:errors.passwordsNotEqual'), { type: 'error' });
			} else if (!isValidPassword(password.trim())) {
				return toast(t('validation:errors.password'), { type: 'error' });
			}
			setLoading(true);
			return changePassword(email.trim(), passwordOld.current.trim(), password.trim())
				.then(() => {
					return login(email.trim(), password.trim(), remember)
						.then(reset)
						.catch(() => Promise.reject(null));
				})
				.catch(() => {
					toast(t('errors:somethingWentWrong'), { type: 'error' });
				})
				.finally(() => {
					setLoading(false);
				});
		}
		if (!isValidEmail(email.trim())) {
			return toast(t('validation:errors.email'), { type: 'error' });
		}
		setLoading(true);
		login(email.trim(), password.trim(), remember)
			.then((res) => {
				if (res === 'changePassword') {
					toast(t('needChangePassword.alert'), { type: 'success' });
					passwordOld.current = password;
					setPassword('');
					setNeedChangePassword(true);
					resetPasswordReqs();
				}
			})
			.catch(() => null)
			.finally(() => {
				setLoading(false);
			});
	};

	const getTitle = () => {
		if (needChangePassword) return t('needChangePassword.title');
		return t('title');
	};

	const getText = () => {
		if (needChangePassword) return t('needChangePassword.text');
		return t('text');
	};

	const getIcon = (state: boolean | null) => {
		if (state === false) {
			return <CrossIcon />;
		}
		return <CheckIcon />;
	};

	return (
		<form onSubmit={handleSubmit} data-testid={TEST_IDS.LOGIN_FORM}>
			{loading && <Loader />}
			{needChangePassword && (
				<div className={classes.icon}>
					<ResetPasswordIcon />
				</div>
			)}
			<div className={classes.title}>{getTitle()}</div>
			<div className={classes.text}>{getText()}</div>
			{needChangePassword ? (
				<>
					<div className={classes.row}>
						<Input
							label={t('form:passwordNew')}
							type={'password'}
							value={password}
							onChange={handleChangePassword}
							testId={TEST_IDS.PASSWORD_INPUT}
						/>
						<div className={classes.requirements}>
							<div className={clsx({ [classes.success]: passwordReqs.requirement1, [classes.fail]: passwordReqs.requirement1 === false })}>
								{getIcon(passwordReqs.requirement1)}
								{t('forgotPassword:passwordRequirement1')}
							</div>
							<div className={clsx({ [classes.success]: passwordReqs.requirement2, [classes.fail]: passwordReqs.requirement2 === false })}>
								{getIcon(passwordReqs.requirement2)}
								{t('forgotPassword:passwordRequirement2')}
							</div>
							<div className={clsx({ [classes.success]: passwordReqs.requirement3, [classes.fail]: passwordReqs.requirement3 === false })}>
								{getIcon(passwordReqs.requirement3)}
								{t('forgotPassword:passwordRequirement3')}
							</div>
							<div className={clsx({ [classes.success]: passwordReqs.requirement4, [classes.fail]: passwordReqs.requirement4 === false })}>
								{getIcon(passwordReqs.requirement4)}
								{t('forgotPassword:passwordRequirement4')}
							</div>
						</div>
					</div>
					<div className={classes.row}>
						<Input
							label={t('form:passwordNewRepeat')}
							type={'password'}
							value={passwordRepeat}
							onChange={handleChangePasswordRepeat}
							testId={TEST_IDS.PASSWORD_REPEAT_INPUT}
							error={matchError}
						/>
					</div>
				</>
			) : (
				<>
					<div className={classes.row}>
						<Input label={t('form:email')} placeholder="name@example.com" value={email} onChange={handleChangeEmail} />
					</div>
					<div className={classes.row}>
						<Input label={t('form:password')} type={'password'} value={password} onChange={handleChangePassword} testId={TEST_IDS.PASSWORD_INPUT} />
					</div>
				</>
			)}
			{!needChangePassword && (
				<div className={clsx(classes.row, classes.rowCheckbox)}>
					<Checkbox isChecked={remember} onChange={toggleRememer}>
						{t('form:rememberMe')}
					</Checkbox>
					<Link to={Routes.FORGOT_PASSWORD} className={classes.forgot}>
						{t('form:forgetPassword')}
					</Link>
				</div>
			)}
			<div className={clsx(classes.row, classes.rowButton)}>
				<MainButton disabled={needChangePassword && !isValidPassword(password.trim())} className={classes.button} type="submit">
					{needChangePassword ? t('form:savePasswordNew') : t('form:loginBtn')}
				</MainButton>
			</div>
		</form>
	);
};

export default Login;
