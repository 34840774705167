import { endOfMonth, format, getDate, setDate, setHours, subMonths } from 'date-fns';
import { AccountingTypes, CountryCodeEnum } from 'interfaces';
import { IFilter, IOrder } from 'interfaces/order';

import { getCountry } from './jwt';

export const middlewareOrder = (orders: IOrder[]): IOrder[] => {
	return orders.map((order) => ({
		...order,
		isAction: false,
		toBePaid: '05.05–08.05',
		returnType: 'Self Refund',
		receivedFunds: order.receivedFunds || 0,
		deliveryFee: order.deliveryFee || 0,
		partnerPayment: order.partnerPayment || 0,
		originDeliveryFee: order.originDeliveryFee || 0,
		originReceivedFunds: order.originReceivedFunds || 0,
	}));
};

export const normalizeFilter = (filter: IFilter, accessCountry?: boolean) => {
	const { companyName: _, approvedAfterPeriodTab: __, ...rest } = filter;
	return {
		...rest,
		storeAddress: undefined,
		disputesTab: (accessCountry && filter.disputesTab) || undefined,
		orderStatus: filter.orderStatus?.length ? filter.orderStatus : undefined,
		startDate: filter.startDate ? format(filter.startDate, 'yyyy-MM-dd') : undefined,
		endDate: filter.endDate ? format(filter.endDate, 'yyyy-MM-dd') : undefined,
		contractNumber: filter.contractNumber ? [filter.contractNumber] : undefined,
	};
};

export const normalizeReceivedDate = (dates: { startDate: Date; endDate: Date }) => {
	const { startDate, endDate } = dates;
	const endDateMs = new Date(endDate).getTime();
	const now = Date.now();
	const maxDate = endDateMs > now ? now : endDateMs;
	return { startDate: new Date(startDate), endDate: new Date(maxDate) };
};

export const getDateRange = (now: Date) => {
	let startDate: Date;
	let endDate: Date;
	if (getDate(now) > 15) {
		endDate = setHours(setDate(now, 15), 12);
		const clone = new Date(endDate);
		startDate = setDate(clone, 1);
	} else {
		endDate = setHours(endOfMonth(subMonths(now, 1)), 12);
		const clone = new Date(endDate);
		startDate = setDate(clone, 16);
	}
	return { startDate, endDate };
};

export const getDateRangeSchedule = () => {
	const country = getCountry();
	switch (country) {
		case CountryCodeEnum.KZ:
		case CountryCodeEnum.UA:
			return AccountingTypes.ONCE_A_WEEK;
		default:
			return AccountingTypes.TWICE_A_MONTH;
	}
};

export const compareDate = (date?: Date, dateForCompare?: Date) =>
	date && dateForCompare && format(new Date(date), 'yyyy-MM-dd') === format(new Date(dateForCompare), 'yyyy-MM-dd');
