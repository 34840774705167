import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PaymentHead from 'containers/Accounts/PaymentTable/PaymentHead';
import clsx from 'helpers/clsx';
import { useInvoices } from 'hooks/useInvoices';
import { ReactComponent as Emptyico } from 'static/images/empty-accounts.svg';
import { TEST_IDS } from 'tests/config';

import LoadMore from 'components/LoadMore';

import PaymentRow from './PaymentRow';

import classes from 'pages/Accounts/Accounts.module.scss';

const PaymentTable: FC<{ emptyFilter: boolean }> = ({ emptyFilter }) => {
	const { t } = useTranslation('accounts');
	const { payments, paymentsTotal, paymentsPagionation, setPageSize } = useInvoices();

	const emptyState = () => {
		return (
			<tr className={classes.emptyStateRow}>
				<td colSpan={12}>
					<div className={classes.emptyState}>
						<Emptyico />
						{emptyFilter ? (
							<h2> {t('paymentEmpty', { type: t('paymentType') })}</h2>
						) : (
							<>
								<h2>{t('paymentEmptyFilter', { type: t('paymentType') })}</h2>
								<Trans t={t} i18nKey="paymentClear" />
							</>
						)}
					</div>
				</td>
			</tr>
		);
	};
	const start = paymentsPagionation.page * paymentsPagionation.pageSize + 1;
	const end = start + paymentsPagionation.pageSize - 1;
	const canLoadMore = end < paymentsTotal;

	const loadMore = () => {
		setPageSize(paymentsPagionation.pageSize + 25, false);
	};

	return (
		<div className={clsx(classes.accounts, classes.payments)}>
			<table data-testid={TEST_IDS.ACCOUNTS_PAYMENT_TABLE}>
				<PaymentHead />
				<tbody>
					{!payments.length && emptyState()}
					{payments.map((payment) => (
						<PaymentRow item={payment} key={payment.id} />
					))}
				</tbody>
			</table>
			{!!payments.length && canLoadMore && <LoadMore onClick={loadMore} className={classes.loadMore} />}
		</div>
	);
};

export default PaymentTable;
