import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetch } from 'api';
import { GLOVO_DOMAIN, Routes, UAregions } from 'const';
import WorkingHours from 'containers/modals/WorkingHours';
import NotificationAuthOtherUser from 'containers/NotificationAuthOtherUser';
import { format } from 'date-fns';
import { parseDate } from 'helpers';
import clsx from 'helpers/clsx';
import { getCountry } from 'helpers/jwt';
import { isLimitString, isMinString, isValidEmail } from 'helpers/validation';
import { useContractGeneration } from 'hooks/useContractGeneration';
import { CountryCodeEnum, OpenDataSyncStatuses } from 'interfaces';
import { AgreementStatus, IAgreement, IAgreementInput, IOpenDataBot, IPStores } from 'interfaces/contractGeneration';
import { ReactComponent as ArrowRightIcon } from 'static/images/arrow_right.svg';
import { ReactComponent as CopyIcon } from 'static/images/copy.svg';
import { ReactComponent as TimeIcon } from 'static/images/time.svg';
import { groupBy, sortBy } from 'underscore';

import { BackToContractList } from 'components/Contract/BackToContractList';
import { Checkbox, DatePicker, Select } from 'components/FormControl';
import formControlclasses from 'components/FormControl/FormControl.module.scss';
import Input from 'components/FormControl/Input';
import { IPropsSelect } from 'components/FormControl/types';
import Loader from 'components/Loader';
import MainButton from 'components/MainButton';
import Confirm from 'components/modals/Confirm';
import { LightTooltip } from 'components/StyledLightTooltip';

import classes from './ContractForm.module.scss';

const ignoreTypes = ['autogenerate', 'calculate'];

const ContractForm: React.FC = () => {
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation(['contractGeneration', 'form', 'errors', 'validation', 'storeAddresses']);
	const { id }: { id: string } = useParams();
	const [contractFiels, setContractFields] = useState<IAgreement>();
	const [errors, setErrors] = useState<{ [key: string]: string }>({});
	const { generateDocument, getContractById } = useContractGeneration();
	const history = useHistory();
	const [file, setFile] = useState<File | null>(null);
	const [searchPartnerStore, setSearchPartnerStore] = useState('');
	const [storesToCopySchedule, setStoresToCopySchedule] = useState<string[]>([]);
	const [prevTaxId, setPrevTaxId] = useState('');
	const isUA = getCountry() === CountryCodeEnum.UA;
	const isKz = getCountry() === CountryCodeEnum.KZ;

	useEffect(() => {
		if (id) {
			setLoading(true);
			getContractById(id)
				.then(({ body }) => body && setContractFields(body as IAgreement))
				.finally(() => setLoading(false));
		}
		return () => setContractFields(undefined);
	}, [id]);

	if (!id) return null;

	if (contractFiels?.agreementStatus === AgreementStatus.CANCELLED) {
		return <Redirect to={Routes.DOCUMENT_PREVIEW.replace(':id', id)} />;
	}

	const handleSearchPartnerStore = (value: string) => setSearchPartnerStore(value);

	const partnerStores: IPStores = contractFiels?.placeholderMap
		? groupBy(
				sortBy(
					sortBy(contractFiels?.placeholderMap, (item: IAgreementInput) => item.configuration?.rowNumber),
					'priority',
				).filter((item) => item.configuration?.rowNumber !== undefined),
				(item: IAgreementInput) => item.configuration?.rowNumber || 0,
			)
		: [];
	const sortTopInputs = contractFiels?.placeholderMap ? sortBy(contractFiels?.placeholderMap, 'priority') : [];

	const validationFields = () => {
		const newErrors: { [key: string]: string } = {};
		sortTopInputs
			.filter((input: IAgreementInput) => !input.defaultValue)
			.forEach((input: IAgreementInput) => {
				if (input.configuration?.pattern && !new RegExp(input.configuration?.pattern).test(input.value)) {
					return (newErrors[input.varName] = t('validation:errors.pattern'));
				}
				switch (input.type) {
					case 'email':
						if (!isValidEmail(input.value.trim())) {
							return (newErrors[input.varName] = t('validation:errors.email'));
						}
						return false;
					case 'text':
						if (input.configuration?.min && !isMinString(input.value, +input.configuration?.min)) {
							return (newErrors[input.varName] = t('validation:errors.minString', { count: input.configuration?.min }));
						}
						if (input.configuration?.max && !isLimitString(input.value, +input.configuration?.max)) {
							return (newErrors[input.varName] = t('validation:errors.limitString', { count: input.configuration?.max }));
						}
						return false;
					case 'number':
						if (input.configuration?.min && +input.value < +input.configuration?.min) {
							return (newErrors[input.varName] = t('validation:errors.minNumber', { count: input.configuration?.min }));
						}
						if (input.configuration?.max && +input.value > +input.configuration?.max) {
							return (newErrors[input.varName] = t('validation:errors.maxNumber', { count: input.configuration?.max }));
						}
						return false;
					case 'tel': {
						const min = input.configuration?.min ? +input.configuration?.min : 10;
						const value = input.value.replace(/[()_\s]/g, '');
						if (!isMinString(value, min)) {
							return (newErrors[input.varName] = t('validation:errors.minString', { count: min }));
						}
						return false;
					}
				}
			});
		if (Object.keys(newErrors).length) {
			setErrors({ ...errors, ...newErrors });
			const firstInputWithError = sortTopInputs.find((input: IAgreementInput) => input.varName === Object.keys(newErrors)[0]);
			if (firstInputWithError) {
				document.querySelector(`input[value='${firstInputWithError.value}']`)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
			toast(t('validation:errors.invalidFields'), { type: 'error' });
		}
		return newErrors;
	};

	const handleGenerateDocument = () => {
		if (contractFiels) {
			const validationErrors = validationFields();
			if (validationErrors && !Object.keys(validationErrors).length) {
				setLoading(true);
				const formData = new FormData();
				formData.append('json', JSON.stringify(contractFiels));
				if (file) {
					formData.append('file', file);
				}
				return generateDocument(formData)
					.then(() => history.push(Routes.DOCUMENT_PREVIEW.replace(':id', contractFiels.agreementId)))
					.finally(() => setLoading(false));
			}
		}
	};

	const saveWorkingHours = async (field: string, value: string) => {
		setLoading(true);
		const fields = {
			...contractFiels,
			placeholderMap: {
				...(contractFiels?.placeholderMap as IAgreement['placeholderMap']),
				[field as string]: {
					...(contractFiels?.placeholderMap[field as string] as IAgreementInput),
					value,
					defaultValue: false,
				},
			},
		};
		const formData = new FormData();
		formData.append('json', JSON.stringify(fields));
		return generateDocument(formData)
			.then(() => {
				toast(t('successAction'), { type: 'success' });
				handleChange(field, value);
				return true;
			})
			.finally(() => setLoading(false));
	};

	const handleLoadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target;
		if (files && files[0]) {
			setFile(files[0]);
		}
	};

	const handleChange = (field: string, value: IPropsSelect['value'] | Date) => {
		if (errors[field]) {
			const clearError = { ...errors };
			delete clearError[field];
			setErrors(clearError);
		}
		setContractFields({
			...contractFiels,
			placeholderMap: {
				...(contractFiels?.placeholderMap as IAgreement['placeholderMap']),
				[field as string]: {
					...(contractFiels?.placeholderMap[field as string] as IAgreementInput),
					value: value instanceof Date ? format(value, 'dd.MM.yyyy') : value,
					defaultValue: !value ? true : false,
				},
			},
		} as IAgreement);
	};

	const validationIban = async (iban: string, field: string) => {
		const { body }: { body: { success: boolean; message: string } } = await fetch(
			`/util/validIban?iban=${iban}`,
			{
				fintoolContracts: true,
			},
			false,
		);
		if (!body.success) {
			setErrors({ ...errors, [field]: body.message });
		}
		return toast(body.message, { type: body.success ? 'success' : 'error' });
	};

	const getInputByType = (input: IAgreementInput) => {
		switch (input.type) {
			case 'text':
			case 'number':
			case 'email':
				return (
					<Input
						fullWidth
						onChange={(value) => handleChange(input.varName, value)}
						value={input.defaultValue ? '' : input.value}
						type={input.type}
						label={input.label}
						subLabel={input.subLabel}
						error={errors[input.varName]}
						min={input.configuration?.min ? +input.configuration?.min : undefined}
						max={input.configuration?.max ? +input.configuration?.max : undefined}
						maxLength={input.configuration?.max ? +input.configuration?.max : undefined}
						minLength={input.configuration?.min ? +input.configuration?.min : undefined}
					/>
				);
			case 'tel':
				return (
					<Input
						fullWidth
						onChange={(value) => handleChange(input.varName, value)}
						setError={() => setErrors({ ...errors, [input.varName]: t('invalidPastePhoneNumber') })}
						value={input.defaultValue ? '' : input.value}
						type="text"
						label={input.label}
						subLabel={input.subLabel}
						error={errors[input.varName]}
						pattern={/^[+\d(](?:.*[0-9()-]+\s)*(?:.*[0-9()-])?$/}
					/>
				);
			case 'date':
				return (
					<DatePicker
						fullWidth
						onChange={(value) => handleChange(input.varName, value as Date)}
						label={input.label}
						datepickerProps={{
							minDate: input.configuration?.min ? new Date(input.configuration?.min.replaceAll('.', '/')) : undefined,
							maxDate: input.configuration?.max ? new Date(input.configuration?.max.replaceAll('.', '/')) : undefined,
							startDate: !input.defaultValue && input.value ? parseDate(input.value, 'dd.MM.yyyy') : undefined,
						}}
						rootClassName={classes.datePicker}
						calendarClassName={classes.calendarClassName}
						subLabel={input.subLabel}
					/>
				);
			default:
				return null;
		}
	};

	const selectAllStores = () => {
		setStoresToCopySchedule(
			Object.keys(partnerStores).length === storesToCopySchedule.length
				? []
				: Object.keys(partnerStores).map(
						(key) => partnerStores[key as unknown as keyof IPStores][partnerStores[key as unknown as keyof IPStores].length - 1].varName,
					),
		);
	};
	const selectStore = (storeName: string) => {
		setStoresToCopySchedule(
			storesToCopySchedule.includes(storeName) ? storesToCopySchedule.filter((store) => store !== storeName) : [...storesToCopySchedule, storeName],
		);
	};

	const saveCopySchedule = async (storeName: string) => {
		try {
			setLoading(true);
			let uptatedFields = { ...contractFiels } as IAgreement;
			storesToCopySchedule.forEach((store) => {
				uptatedFields = {
					...uptatedFields,
					placeholderMap: {
						...uptatedFields.placeholderMap,
						[store]: {
							...uptatedFields.placeholderMap[store],
							value: uptatedFields.placeholderMap[storeName].value,
							defaultValue: false,
						},
					},
				} as IAgreement;
			});
			const formData = new FormData();
			formData.append('json', JSON.stringify(uptatedFields));
			return generateDocument(formData)
				.then((res) => {
					if (res) {
						toast(t('successAction'), { type: 'success' });
						setStoresToCopySchedule([]);
						setSearchPartnerStore('');
						setContractFields(uptatedFields);
					}
					return res;
				})
				.finally(() => setLoading(false));
		} catch {
			toast(t('errors:somethingWentWrong'), { type: 'error' });
		}
	};

	const getInputBySubType = (input: IAgreementInput) => {
		switch (input.configuration?.subtype) {
			case 'address':
			case 'name':
			case 'city':
				return (
					<Input
						fullWidth
						onChange={(value) => handleChange(input.varName, value)}
						value={input.defaultValue ? '' : input.value}
						type="text"
						rootClassName={classes.input}
					/>
				);
			case 'schedule':
				return isUA ? (
					<div className={classes.schedule_wrap}>
						<WorkingHours onSave={(value: string) => saveWorkingHours(input.varName, value) as Promise<boolean>} input={input} translation={t} />
						{!input.defaultValue && Object.keys(partnerStores).length > 1 && (
							<Confirm
								title={t('schedule.copy.title')}
								confirm={{
									onClick: () => saveCopySchedule(input.varName),
									text: t('schedule.copy.confirm'),
								}}
								body={
									<div className={classes.partners_list}>
										<Input fullWidth delayOnChange={700} label={t('search')} value={searchPartnerStore} onChange={handleSearchPartnerStore} />
										<ul>
											<li>
												<Checkbox onChange={selectAllStores} isChecked={Object.keys(partnerStores).length === storesToCopySchedule.length}>
													{t('allPartnerStores')}
												</Checkbox>
											</li>
											{Object.keys(partnerStores)
												.filter(
													(k) =>
														partnerStores[k as unknown as keyof IPStores][0].value.toLowerCase().includes(searchPartnerStore.toLowerCase()) &&
														input.varName !==
															partnerStores[k as unknown as keyof IPStores][partnerStores[k as unknown as keyof IPStores].length - 1].varName,
												)
												.map((key) => {
													const row = partnerStores[key as unknown as keyof IPStores] as IAgreementInput[];
													return (
														<li key={key}>
															<Checkbox
																isChecked={storesToCopySchedule.includes(row[row.length - 1].varName)}
																onChange={() => selectStore(row[row.length - 1].varName)}
															>
																{row[0].value}
															</Checkbox>
														</li>
													);
												})}
										</ul>
									</div>
								}
							>
								<LightTooltip placement="left" title={t('copyWorkingHours')} arrow>
									<CopyIcon className={classes.schedule_wrap_icon} />
								</LightTooltip>
							</Confirm>
						)}
					</div>
				) : (
					<WorkingHours
						canBeEmpty
						onSave={(value: string) => saveWorkingHours(input.varName, value) as Promise<boolean>}
						input={input}
						translation={t}
					>
						<div>
							{input.label && <div className={formControlclasses.label}>{input.label}</div>}
							<div className={formControlclasses.inner}>
								<TimeIcon className={clsx(formControlclasses.icon, formControlclasses.pointer)} />
								<input
									type="text"
									readOnly
									className={clsx(formControlclasses.control, classes.schedule_time)}
									value={input.defaultValue ? '' : input.value}
								/>
							</div>
						</div>
						;
					</WorkingHours>
				);
			case 'region':
				return (
					<Select
						withoutClear
						withSearch
						rootClassName={classes.region}
						controlClassName={classes.region_select}
						value={input.value}
						options={UAregions.map((region) => ({ label: region, value: region }))}
						onChange={(value) => handleChange(input.varName, value)}
					/>
				);
			case 'iban':
				return (
					<Input
						fullWidth
						onChange={(value) => handleChange(input.varName, value)}
						value={input.defaultValue ? '' : input.value}
						type="text"
						label={input.label}
						subLabel={input.subLabel}
						maxLength={input.configuration?.max ? +input.configuration?.max : undefined}
						minLength={input.configuration?.min ? +input.configuration?.min : undefined}
						onBlur={() => {
							const isValid =
								!input.defaultValue &&
								input.configuration?.min &&
								isMinString(input.value, +input.configuration?.min) &&
								input.configuration?.max &&
								isLimitString(input.value, +input.configuration?.max) &&
								input.configuration.pattern &&
								new RegExp(input.configuration?.pattern).test(input.value);
							if (isValid) {
								validationIban(input.value, input.varName);
							} else {
								validationFields();
							}
						}}
						error={errors[input.varName]}
					/>
				);
			case 'tin':
				return (
					<Input
						fullWidth
						onChange={(value) => handleChange(input.varName, value)}
						value={input.defaultValue ? '' : input.value}
						type="text"
						label={input.label}
						subLabel={input.subLabel}
						error={errors[input.varName]}
						maxLength={input.configuration?.max ? +input.configuration?.max : undefined}
						minLength={input.configuration?.min ? +input.configuration?.min : undefined}
						onBlur={() => {
							const isValid =
								!input.defaultValue &&
								input.configuration &&
								input.configuration.min &&
								isMinString(input.value, +input.configuration?.min) &&
								input.configuration.max &&
								isLimitString(input.value, +input.configuration?.max) &&
								input.configuration.pattern &&
								new RegExp(input.configuration?.pattern).test(input.value);
							if (isValid && input.configuration?.tinGroup) {
								fetchOpenDataByTaxId(input.value, input.configuration?.tinGroup);
							} else {
								validationFields();
							}
						}}
					/>
				);
			case 'hold':
			case 'hidden':
				return (
					<Input
						fullWidth
						disabled={input.configuration.subtype === 'hold'}
						onChange={(value) => handleChange(input.varName, value)}
						value={input.defaultValue ? '' : input.value}
						type="text"
						maxLength={input.configuration?.max ? +input.configuration?.max : undefined}
						minLength={input.configuration?.min ? +input.configuration?.min : undefined}
						label={input.label}
						subLabel={input.subLabel}
						error={errors[input.varName]}
					/>
				);
			case 'money':
			case 'percentage':
			case 'quantity':
				return (
					<Input
						fullWidth
						onChange={(value) => handleChange(input.varName, value)}
						value={input.defaultValue ? '' : input.value}
						type="number"
						label={input.label}
						subLabel={input.subLabel}
						error={errors[input.varName]}
						min={input.configuration?.min ? +input.configuration?.min : undefined}
						max={input.configuration?.max ? +input.configuration?.max : undefined}
					/>
				);
			default:
				return null;
		}
	};

	const getStatusResult = (data: IOpenDataBot, tinGroup: string) => {
		switch (data?.status) {
			case OpenDataSyncStatuses.SUCCESS: {
				if (contractFiels) {
					const fillFields = Object.values(contractFiels.placeholderMap)
						.filter((field) => field.configuration?.tinGroup === tinGroup)
						.map((field) => {
							const key = Object.keys(data).find((k) => k === field.configuration?.tinType) as string;
							if (field.configuration && field.configuration.tinType === key) {
								return {
									...field,
									value: data[key],
									defaultValue: false,
								};
							}
							return field;
						})
						.reduce((obj: IAgreement['placeholderMap'], item) => {
							obj[item.varName] = item;
							return obj;
						}, {});

					setContractFields({
						...contractFiels,
						placeholderMap: {
							...fillFields,
							...Object.values(contractFiels.placeholderMap)
								.filter((field) => field.configuration?.tinGroup !== tinGroup)
								.reduce((obj: IAgreement['placeholderMap'], item) => {
									obj[item.varName] = item;
									return obj;
								}, {}),
						},
					});
				}
				return toast(t('storeAddresses:successChangeTaxId'), { type: 'success' });
			}
			case OpenDataSyncStatuses.NOT_EXECUTED:
			case OpenDataSyncStatuses.NOT_FOUND: {
				if (contractFiels) {
					const haveFilledFields = Object.values(contractFiels.placeholderMap).filter(
						(field) => field.configuration?.subtype === 'hold' && !field.defaultValue,
					);
					if (haveFilledFields.length) {
						const updatedFields = haveFilledFields
							.map((field) => ({
								...field,
								value: field.label,
								defaultValue: true,
							}))
							.reduce((obj: IAgreement['placeholderMap'], item) => {
								obj[item.varName] = item;
								return obj;
							}, {});
						setContractFields({
							...contractFiels,
							placeholderMap: {
								...updatedFields,
								...Object.values(contractFiels.placeholderMap)
									.filter((field) => field.configuration?.subtype !== 'hold')
									.reduce((obj: IAgreement['placeholderMap'], item) => {
										obj[item.varName] = item;
										return obj;
									}, {}),
							},
						});
					}
					const currentInput = Object.values(contractFiels.placeholderMap).find(
						(input) => input.configuration?.tinGroup === tinGroup && input.configuration.tinType === 'taxId',
					);
					return currentInput && setErrors({ ...errors, [currentInput.varName]: t('storeAddresses:wrongToChangeTaxId') });
				}
				return toast(t('storeAddresses:wrongToChangeTaxId'), { type: 'error' });
			}
			case OpenDataSyncStatuses.SOMETHING_WENT_WRONG: {
				return toast(t('storeAddresses:failedFoundToChangeTaxId'), { type: 'error' });
			}
		}
	};

	const fetchOpenDataByTaxId = async (taxId: string, tinGroup: string) => {
		if ((isUA || isKz) && taxId !== prevTaxId) {
			setPrevTaxId(taxId);
			setLoading(true);
			const res = await fetch(`/util/getPartnerInfo?taxId=${taxId}`, { fintoolContracts: true }, false).finally(() => setLoading(false));
			if (res.body) {
				getStatusResult(res.body as IOpenDataBot, tinGroup);
			}
		}
	};

	return (
		<div className={classes.root}>
			{loading && <Loader />}
			{contractFiels?.agreementId && (
				<div className={classes.body}>
					<NotificationAuthOtherUser />
					<div className={classes.header}>
						<BackToContractList />
						<h4>{contractFiels.agreementName}</h4>
					</div>
					{sortTopInputs.length ? (
						<React.Fragment>
							<form className={classes.form}>
								{sortTopInputs.map((input: IAgreementInput) => {
									if (input.configuration?.tableNumber || ignoreTypes.includes(input.type)) return null;
									return (
										<div
											style={{
												flex: `${+input.width < 100 ? 0 : 1} 1 ${+input.width - 1.06}%`,
											}}
											className={classes.input_wrap}
											key={input.varName}
										>
											{input.configuration?.subtype ? getInputBySubType(input) : getInputByType(input)}
											{!window.location.host.includes(GLOVO_DOMAIN) && <span style={{ color: 'blue' }}>{input.priority}</span>}
										</div>
									);
								})}
							</form>
							{!!Object.keys(partnerStores).length && (
								<form className={classes.form}>
									<h4>{t('table.head')}</h4>
									<div className={classes.table_wrap}>
										<table>
											<thead>
												<tr>
													<th />
													<th className={classes.name}>{t('table.name')}</th>
													<th>{t('table.city')}</th>
													<th>{t('table.region')}</th>
													<th>{t('table.address')}</th>
													<th>{t('table.workingHours')}</th>
												</tr>
											</thead>
											<tbody>
												{Object.keys(partnerStores).map((key) => {
													const row = partnerStores[key as unknown as keyof IPStores];
													return (
														<tr key={key}>
															<td>{+key || 1}</td>
															{row.map((input: IAgreementInput) => {
																return (
																	<td key={input.varName} width="20%">
																		{getInputBySubType(input)}
																	</td>
																);
															})}
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
								</form>
							)}
						</React.Fragment>
					) : null}
					<div className={classes.action}>
						<input onChange={handleLoadFile} type="file" accept=".docx" />
						<MainButton onClick={handleGenerateDocument} disabled={!!Object.keys(errors).length}>
							{t('generateDocument')} <ArrowRightIcon />
						</MainButton>
					</div>
				</div>
			)}
		</div>
	);
};

export default ContractForm;
