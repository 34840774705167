import { IAction } from './types';
import {
	CHANGE_CUSTOMER_DATA,
	CHANGE_CUSTOMERS_FILTER,
	FETCH_CUSTOMERS_FAILURE,
	FETCH_CUSTOMERS_START,
	FETCH_CUSTOMERS_SUCCESS,
	IState,
	SET_CUSTOMERS_PAGE,
	SET_CUSTOMERS_PAGE_SIZE,
} from './types';

const initialState: IState = {
	items: [],
	total: 0,
	onload: true,
	wasLoaded: false,
	filter: {
		search: '',
	},
	pagination: {
		page: 0,
		pageSize: 100,
	},
};

const reducer = (state = initialState, action: IAction): IState => {
	switch (action.type) {
		case FETCH_CUSTOMERS_START:
			return {
				...state,
				onload: true,
			};
		case FETCH_CUSTOMERS_SUCCESS:
			return {
				...state,
				items: action.payload.items,
				total: action.payload.total,
				onload: false,
				wasLoaded: true,
			};
		case FETCH_CUSTOMERS_FAILURE:
			return {
				...state,
				onload: false,
				wasLoaded: true,
				items: [],
				total: 0,
			};
		case SET_CUSTOMERS_PAGE:
			return {
				...state,
				pagination: {
					...state.pagination,
					page: action.payload,
				},
			};
		case SET_CUSTOMERS_PAGE_SIZE:
			return {
				...state,
				pagination: {
					...state.pagination,
					pageSize: action.payload,
				},
			};
		case CHANGE_CUSTOMERS_FILTER:
			return {
				...state,
				filter: {
					...state.filter,
					[action.payload.field]: action.payload.value,
				},
				pagination: {
					page: 0,
					pageSize: 100,
				},
			};
		case CHANGE_CUSTOMER_DATA:
			return {
				...state,
				items: state.items.map((c) => (c.customerId === action.payload.customerId ? { ...c, ...action.payload } : c)),
			};
		default:
			return state;
	}
};

export default reducer;
