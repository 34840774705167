import { getDateRange } from 'helpers/order';
import { ValueOf } from 'interfaces';
import { IFilter } from 'interfaces/order';

import { IAction } from './types';
import {
	CHANGE_ORDERS_FILTER,
	CLEAR_ALL_ORDERS,
	CLEAR_ORDERS_FILTER,
	FETCH_ORDERS_FAILURE,
	FETCH_ORDERS_START,
	FETCH_ORDERS_TOTALS_FAILURE,
	FETCH_ORDERS_TOTALS_START,
	FETCH_ORDERS_TOTALS_SUCCESS,
	IState,
	SET_ORDERS_PAGE,
	SET_ORDERS_PAGE_SIZE,
	UPDATE_ORDER,
} from './types';
import { FETCH_ORDERS_SUCCESS } from './types';

const initialState: IState = {
	items: [],
	total: 0,
	onload: true,
	wasLoaded: false,
	loadingTotals: true,
	filter: {
		startDate: undefined,
		endDate: undefined,
	},
	pagionation: {
		page: 0,
		pageSize: 100,
	},
};

const reducer = (state = initialState, action: IAction): IState => {
	switch (action.type) {
		case FETCH_ORDERS_START:
			return {
				...state,
				onload: true,
			};
		case FETCH_ORDERS_SUCCESS:
			return {
				...state,
				items: action.payload.orders,
				total: action.payload.total,
				onload: false,
				wasLoaded: true,
			};
		case FETCH_ORDERS_FAILURE:
			return {
				...state,
				onload: false,
				wasLoaded: true,
				items: [],
				total: 0,
			};
		case SET_ORDERS_PAGE:
			return {
				...state,
				pagionation: {
					...state.pagionation,
					page: action.payload,
				},
			};
		case SET_ORDERS_PAGE_SIZE:
			return {
				...state,
				pagionation: {
					...state.pagionation,
					pageSize: action.payload,
				},
			};
		case CHANGE_ORDERS_FILTER: {
			const getFilterValue = (value: ValueOf<IFilter>) => {
				if (typeof value === 'boolean') return value;
				return value || undefined;
			};
			return {
				...state,
				filter: {
					...state.filter,
					[action.payload.field]: getFilterValue(action.payload.value),
				},
				pagionation: {
					page: 0,
					pageSize: 100,
				},
			};
		}
		case CLEAR_ORDERS_FILTER: {
			const { startDate: initialStartDate, endDate: initialEndDate } = getDateRange(new Date());
			const [startDate, endDate] = action.payload || [initialStartDate, initialEndDate];
			return {
				...state,
				filter: {
					...initialState.filter,
					startDate,
					endDate,
				},
			};
		}
		case CLEAR_ALL_ORDERS:
			return {
				...state,
				totals: undefined,
				items: initialState.items,
			};
		case UPDATE_ORDER:
			return {
				...state,
				items: state.items.map((it) => {
					if (it.id === action.payload.id)
						return {
							...it,
							...action.payload,
						};
					return it;
				}),
			};
		case FETCH_ORDERS_TOTALS_START:
			return {
				...state,
				loadingTotals: true,
			};
		case FETCH_ORDERS_TOTALS_FAILURE:
			return {
				...state,
				loadingTotals: false,
			};
		case FETCH_ORDERS_TOTALS_SUCCESS:
			return {
				...state,
				totals: action.payload,
				loadingTotals: false,
			};
		default:
			return state;
	}
};

export default reducer;
