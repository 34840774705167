import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_DATE_PICKER_SCHEDULE } from 'api/orders';
import clsx from 'helpers/clsx';
import { getDateRange, getDateRangeSchedule, normalizeReceivedDate } from 'helpers/order';
import { useLaaSOrders } from 'hooks/useLaaSOrders';
import { IDateRangeSchedule } from 'interfaces/filter';
import { TEST_IDS } from 'tests/config';

import EmptyResult from 'components/EmptyResult';
import Loader from 'components/Loader';
import LoadMore from 'components/LoadMore';
import Pagination from 'components/Pagination';

import Filters from './Filters';
import Order from './Order';
import TableHead from './TableHead';

import classes from './OrdersContainer.module.scss';

const OrdersContainer: React.FC = () => {
	const { items, fetchOrders, pagination, filter, setPageSize, total, onload, wasLoaded, changeFilter, setPage } = useLaaSOrders();
	const loadMore = () => {
		setPageSize(pagination.pageSize + 100);
	};
	const { loading: loadingDateRange, data: dateRange } = useQuery<IDateRangeSchedule>(GET_DATE_PICKER_SCHEDULE, {
		variables: { filter: { accountingType: getDateRangeSchedule() } },
	});

	useEffect(() => {
		if (!loadingDateRange) {
			const { startDate, endDate } = dateRange?.datePickerSchedule ? normalizeReceivedDate(dateRange?.datePickerSchedule) : getDateRange(new Date());
			handleChangeDatePicker([startDate, endDate]);
		}
	}, [dateRange, loadingDateRange]);

	useEffect(() => {
		if (filter.startDate && filter.endDate) fetchOrders();
	}, [filter, pagination]);

	const start = pagination.page * pagination.pageSize + 1;
	const end = start + pagination.pageSize - 1;
	const canLoadMore = !!items.length && end < total;

	const handleChangeDatePicker = (dates: [Date, Date]) => {
		const [startDate, endDate] = dates;
		if (startDate && endDate) {
			changeFilter('startDate', startDate);
			changeFilter('endDate', endDate);
		}
	};

	const orderList = (
		<>
			{onload && !wasLoaded ? (
				<Loader simple />
			) : (
				<div className={classes.orders}>
					<table>
						<TableHead />
						<tbody data-testid={TEST_IDS.ORDERS_TABLE_BODY}>
							{items.map((it) => (
								<Order item={it} key={it.orderId} />
							))}
						</tbody>
					</table>
					{!onload && !items.length && <EmptyResult />}
					{canLoadMore && <LoadMore onClick={loadMore} className={classes.loadMore} />}
				</div>
			)}
		</>
	);

	const handleClickPrev = () => {
		setPage(pagination.page - 1);
	};

	const handleClickNext = () => {
		setPage(pagination.page + 1);
	};

	const renderPagination = () => {
		if (!items.length) return null;
		return (
			<Pagination
				start={start}
				end={end}
				total={total}
				onClickNext={handleClickNext}
				onClickPrev={handleClickPrev}
				className={clsx(classes.pagination, classes.pagination_relative)}
			/>
		);
	};

	return (
		<div className={classes.root} data-testid={TEST_IDS.ORDERS_CONTAINER}>
			<div className={classes.bottom}>{renderPagination()}</div>
			<Filters />
			{orderList}
		</div>
	);
};

export default OrdersContainer;
