import styled from '@emotion/styled';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';

export const StyledMenuItem = styled(({ ...props }: MenuItemProps) => <MenuItem {...props} />)(() => ({
	'&': {
		color: 'rgba(0, 0, 0, 0.87)',
		fontSize: 14,
		fontWeight: 400,
		fontFamily: 'Montserrat',
	},
	'&.Mui-selected': {
		backgroundColor: '#F9F9FA !important',
		'&:hover': {
			backgroundColor: '#F9F9FA',
		},
	},
}));
