import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { clientGraphql } from 'api';
import { GET_OPEN_DATA_BOT_COMPANY } from 'api/storeAddresses';
import { taxIdMinLength } from 'const';
import isBefore from 'date-fns/isBefore';
import isEqual from 'date-fns/isEqual';
import clsx from 'helpers/clsx';
import { getCountry } from 'helpers/jwt';
import { STORE_TYPE } from 'helpers/storeAddresses';
import { isLengthString, isSetString, isValidEmail } from 'helpers/validation';
import { useDeepCompareEffect } from 'hooks/useDeepCompareEffect';
import { useStoreAddresses } from 'hooks/useStoreAddresses';
import useToast from 'hooks/useToast';
import { AccountingTypes, AgreementTypes, CountryCodeEnum, ERRORS, ErrorsType, OpenDataSyncStatuses, PromoCalculationType } from 'interfaces';
import { IResponseOpenData, IStoreAddress, IStoreAddressForm } from 'interfaces/storeAddress';
import { ReactComponent as NewIcon } from 'static/images/circle_arrows.svg';
import { ReactComponent as TemporaryIcon } from 'static/images/temporary.svg';
import { TEST_IDS } from 'tests/config';

import CategoryGen from 'components/CategoryGen';
import { DatePicker, Input, Select } from 'components/FormControl';
import Loader from 'components/Loader';
import Modal from 'components/modals/Modal';
import SwitchComponent from 'components/SwitchComponent';

import { IPropsTemplate } from './types';

import classes from './CreateStoreAddress.module.scss';

const CreateStoreAddress: React.FC<IPropsTemplate> = ({ item, refetchQueries, history = [], onClose }) => {
	const isEdit = !!item;
	const [loading, setLoading] = useState<boolean>(false);
	const { toast } = useToast();
	const { t } = useTranslation(['storeAddresses', 'roles', 'validation', 'errors', 'accountingType']);
	const [form, setForm] = useState<IStoreAddressForm>({
		effectiveDate: new Date(),
		effectiveUntil: new Date(),
		accountingType: undefined,
		accountManagerName: undefined,
		address: undefined,
		city: undefined,
		companyName: undefined,
		storeAddressId: undefined,
		email: undefined,
		email2: undefined,
		storesId: undefined,
		partnerCommissionPercentage: undefined,
		pickUpCommissionPercentage: undefined,
		storeName: undefined,
		taxId: undefined,
		temporary: undefined,
		incidentsPaidByGlovo: false,
		partnerContractNumber: undefined,
		reason: undefined,
		promoCalculationType: undefined,
		promoProductCalculationType: undefined,
		agreementType: undefined,
		openDataBotSyncStatus: undefined,
		legalAddress: undefined,
		iban: undefined,
	});
	const [errors, setErrors] = useState<ErrorsType<IStoreAddressForm>>();
	const { createStoreAddress, editStoreAddress } = useStoreAddresses();
	const [fetchOpenDataBotCompany, { loading: openDataLoading }] = useLazyQuery<IResponseOpenData>(GET_OPEN_DATA_BOT_COMPANY, {
		fetchPolicy: 'network-only',
	});

	const normalizeForm = (input: IStoreAddressForm): IStoreAddressForm => {
		const skipFields: (keyof IStoreAddress)[] = ['temporary', '__typename', 'createdAt', 'createdByEmail', 'gen', 'category'];
		return Object.entries(input).reduce((acc, [key, value]) => {
			if (skipFields.includes(key as keyof IStoreAddress)) return acc;
			return {
				...acc,
				[key]: value,
			};
		}, {});
	};

	const getStatusResult = (data: IResponseOpenData['findSubjectMonitoringData']) => {
		const changeName = handleChangeForm('companyName');
		const changeLegalName = handleChangeForm('legalAddress');
		const changeStatus = handleChangeForm('openDataBotSyncStatus');
		try {
			switch (data?.syncStatus) {
				case OpenDataSyncStatuses.SUCCESS: {
					changeName(data.companyName);
					changeLegalName(data.legalAddress);
					return toast(t('successChangeTaxId'), { type: 'success' });
				}
				case OpenDataSyncStatuses.NOT_EXECUTED:
				case OpenDataSyncStatuses.NOT_FOUND: {
					return toast(t('wrongToChangeTaxId'), { type: 'error' });
				}
				case OpenDataSyncStatuses.SOMETHING_WENT_WRONG: {
					return toast(t('failedFoundToChangeTaxId'), { type: 'error' });
				}
			}
		} finally {
			changeStatus(data?.syncStatus);
		}
	};

	const checkOpenDataByTaxId = item?.status === STORE_TYPE.NEW || item?.status === STORE_TYPE.TEMPORARY;
	const isUA = getCountry() === CountryCodeEnum.UA;
	const isKZ = getCountry() === CountryCodeEnum.KZ;
	const isKG = getCountry() === CountryCodeEnum.KG;

	const fetchOpenDataByTaxId = async (taxId?: number) => {
		const sameTaxId = item?.taxId === taxId;
		if (!taxId || String(taxId)?.length < taxIdMinLength || !(isUA || isKZ) || (checkOpenDataByTaxId ? !checkOpenDataByTaxId : sameTaxId)) return;
		const res = await fetchOpenDataBotCompany({ variables: { taxId } });
		const data = res?.data?.findSubjectMonitoringData;
		if (!data) return;
		getStatusResult(data);
	};

	useEffect(() => {
		if (item) {
			setForm(
				normalizeForm({
					...item,
					effectiveDate: item?.temporary ? new Date(item.effectiveDate) : new Date(),
					effectiveUntil: item?.effectiveUntil ? new Date(item?.effectiveUntil) : new Date(),
					reason: item?.effectiveUntil ?? undefined,
				}),
			);
			if (checkOpenDataByTaxId) fetchOpenDataByTaxId(item.taxId);
		}
	}, [item]);

	useDeepCompareEffect(() => {
		if (form?.effectiveDate) {
			const rawHistoryByEffectiveDate = history.find((it) => {
				return isBefore(new Date(it.effectiveDate), form.effectiveDate as Date) || isEqual(new Date(it.effectiveDate), form.effectiveDate as Date);
			});
			if (rawHistoryByEffectiveDate) {
				setForm(
					normalizeForm({
						...rawHistoryByEffectiveDate,
						effectiveDate: form.effectiveDate,
						effectiveUntil: item?.effectiveUntil ? new Date(item?.effectiveUntil) : new Date(),
						reason: undefined,
					}),
				);
			}
		}
	}, [form?.effectiveDate]);

	const clearError = (key: keyof IStoreAddressForm) => {
		setErrors((state) => {
			if (state) {
				return {
					...state,
					[key]: undefined,
				};
			}
			return state;
		});
	};

	const handleChangeForm = (key: keyof IStoreAddressForm) => (value: string | Date | [Date, Date] | boolean | number) => {
		const numberKeys: (keyof IStoreAddressForm)[] = [
			'storesId',
			'accountingType',
			'partnerCommissionPercentage',
			'pickUpCommissionPercentage',
			'storeAddressId',
		];
		clearError(key);
		setForm((state) => ({
			...state,
			[key]: numberKeys.includes(key) && isSetString(String(value)) ? Number(value) : value,
		}));
	};

	const handleChangeDate = (event: Date) => {
		setForm((state) => ({ ...state, effectiveDate: event, effectiveUntil: event }));
	};

	const handleChangeFormSelect = (key: keyof IStoreAddressForm) => (value: number) => {
		clearError(key);
		setForm((state) => ({ ...state, [key]: value }));
	};

	const handleError = (res: { message: string }) => {
		const errorKeyForLocize = res?.message ? Object.values(ERRORS).find((errName) => res.message === errName) : undefined;
		if (errorKeyForLocize) toast(t(`errors.${errorKeyForLocize}`), { type: 'error' });
		else toast(t('errors:serverError'), { type: 'error' });
		setLoading(false);
	};

	const handleSuccsess = async () => {
		if (isEdit) toast(t('create/edit.edit.success'), { type: 'success' });
		else toast(t('create/edit.success'), { type: 'success' });
		await clientGraphql.clearStore();
		onClose();
	};

	const validationForm = (): boolean => {
		const temporaryNotRequired = ['email2', 'reason'];
		const notRequired = item?.temporary ? temporaryNotRequired : ['email2'];
		const skipFields: (keyof IStoreAddressForm)[] = [
			'temporary',
			'effectiveDate',
			'effectiveUntil',
			'incidentsPaidByGlovo',
			'openDataBotSyncStatus',
			'legalAddress',
			'iban',
		];
		const zeroIsPossible = ['partnerCommissionPercentage', 'pickUpCommissionPercentage'];
		const errorsValidation: ErrorsType<IStoreAddressForm> = Object.entries(form).reduce((result, [key, value]) => {
			if (zeroIsPossible.includes(key) && value === 0) return result;
			if (skipFields.includes(key as keyof IStoreAddressForm)) return result;
			if (key === 'email2' && value && !isValidEmail(value)) {
				return {
					...result,
					[key]: t('validation:errors.email'),
				};
			}
			if (!notRequired.includes(key) && (!value || !isSetString(String(value)))) {
				return {
					...result,
					[key]: t('validation:errors.required'),
				};
			}
			if (key === 'email' && !isValidEmail(value)) {
				return {
					...result,
					[key]: t('validation:errors.email'),
				};
			}
			if (key === 'city' && !isLengthString(value, 3)) {
				return {
					...result,
					[key]: t('validation:errors.iso', { count: 3 }),
				};
			}
			return result;
		}, {});
		if (Object.keys(errorsValidation).length) {
			setErrors(errorsValidation);
			return false;
		}
		return true;
	};

	const handleSubmit = () => {
		setErrors(undefined);
		if (!validationForm()) {
			return;
		}
		setLoading(true);
		if (isEdit) {
			return editStoreAddress(
				{
					...form,
					temporary: false,
				},
				refetchQueries,
			)
				.then(handleSuccsess)
				.catch(handleError);
		}
		createStoreAddress(form).then(handleSuccsess).catch(handleError);
	};
	const onBlurFetching = () => fetchOpenDataByTaxId(form?.taxId);

	const getTitleModal = () => {
		if (isEdit) {
			if (item?.temporary) return `${t('create/edit.approve.title')} №${item?.storeAddressId}`;
			return `${t('create/edit.edit.title')} №${item?.storeAddressId}`;
		}
		return t('create/edit.title');
	};

	const getConfirmButtontext = () => {
		if (isEdit) {
			if (item?.temporary) return t('create/edit.approve.add');
			return t('create/edit.edit.add');
		}
		return t('create/edit.add');
	};

	return (
		<>
			{loading && <Loader />}
			<Modal
				modalClassName={classes.modal}
				bodyClassName={clsx(classes.body, {
					[classes.body_temp]: item?.temporary,
				})}
				title={getTitleModal()}
				open
				onClose={onClose}
				buttons={{
					cancel: {},
					confirm: {
						text: getConfirmButtontext(),
						onClick: handleSubmit,
						className: openDataLoading ? classes.loadLock : '',
					},
				}}
			>
				{item?.status === STORE_TYPE.TEMPORARY && (
					<div className={classes.temp}>
						<TemporaryIcon />
						{t('temp')}
					</div>
				)}
				{item?.status === STORE_TYPE.NEW && (
					<div className={clsx(classes.temp, classes.new)}>
						<NewIcon />
						{t('new')}
					</div>
				)}
				<div className={clsx(classes.row, classes.row_datepicker)}>
					<div className={classes.cell}>
						<Input
							value={form?.storeAddressId}
							onChange={handleChangeForm('storeAddressId')}
							placeholder="_ _ _ _ _ _"
							disabled={isEdit}
							label={t('list.storeAddressId')}
							error={errors?.storeAddressId}
							testId={TEST_IDS.CREATE_STORE_ADDRESS_STORE_ADDRESS_ID}
						/>
					</div>
					<div className={clsx(classes.bigCell, classes.flex)}>
						<DatePicker
							label={t(isEdit ? 'list.effectiveDate' : 'list.effectiveUntil')}
							onChange={handleChangeDate}
							datepickerProps={{
								startDate: form?.effectiveDate,
							}}
							error={errors?.effectiveDate}
							testId={TEST_IDS.CREATE_STORE_ADDRESS_DATE}
						/>
					</div>
				</div>
				<div className={classes.divider} />
				<div className={classes.row}>
					<div className={classes.cell}>
						<Input
							value={form?.storesId}
							onChange={handleChangeForm('storesId')}
							placeholder="_ _ _ _ _ _"
							label={t('list.storesId')}
							error={errors?.storesId}
							testId={TEST_IDS.CREATE_STORE_ADDRESS_STORE_ID}
						/>
					</div>
					<div className={classes.bigCell}>
						<Input
							value={form?.storeName}
							onChange={handleChangeForm('storeName')}
							label={t('list.storeName')}
							error={errors?.storeName}
							testId={TEST_IDS.CREATE_STORE_ADDRESS_STORE_NAME}
						/>
						<div className={classes.blockGenCategory}>
							{item?.gen && <CategoryGen gen={item?.gen} />}
							{item?.category && <CategoryGen category={item?.category} />}
						</div>
					</div>
				</div>
				<div className={classes.row}>
					<div className={classes.cell}>
						<Input
							value={form?.city}
							onChange={handleChangeForm('city')}
							label={t('list.city')}
							error={errors?.city}
							testId={TEST_IDS.CREATE_STORE_ADDRESS_CITY}
						/>
					</div>
					<div className={classes.bigCell}>
						<Input
							value={form?.address}
							onChange={handleChangeForm('address')}
							label={isKZ ? t('list.addressKz') : t('list.address')}
							error={errors?.address}
							testId={TEST_IDS.CREATE_STORE_ADDRESS_ADDRESS}
						/>
					</div>
				</div>
				<div className={classes.row}>
					<div className={classes.cell}>
						<Input
							value={form?.taxId}
							onChange={handleChangeForm('taxId')}
							placeholder="_ _ _ _ _ _"
							label={t('list.taxId')}
							error={errors?.taxId}
							testId={TEST_IDS.CREATE_STORE_ADDRESS_TAXT_ID}
							onBlur={onBlurFetching}
						/>
					</div>
					<div className={classes.bigCell}>
						<Input
							value={form?.companyName}
							onChange={handleChangeForm('companyName')}
							label={t('list.companyName')}
							error={errors?.companyName}
							testId={TEST_IDS.CREATE_STORE_ADDRESS_COMPANY_NAME}
						/>
					</div>
				</div>
				{isKZ && (
					<div className={classes.row}>
						<Input
							fullWidth
							value={form?.legalAddress}
							onChange={handleChangeForm('legalAddress')}
							label={t('list.legalAddress')}
							error={errors?.legalAddress}
							testId={TEST_IDS.CREATE_STORE_LEGAL_ADDRESS}
						/>
					</div>
				)}
				{(isKZ || isKG) && (
					<div className={classes.row}>
						<Input
							fullWidth
							value={form?.iban}
							onChange={handleChangeForm('iban')}
							label={t('list.iban')}
							error={errors?.iban}
							testId={TEST_IDS.CREATE_STORE_ADDRESS_IBAN}
						/>
					</div>
				)}
				<div className={clsx(classes.row, classes.verticalCenter)}>
					<div className={classes.cell}>
						<Input
							value={form?.partnerContractNumber}
							onChange={handleChangeForm('partnerContractNumber')}
							label={t('list.partnerContractNumber')}
							error={errors?.partnerContractNumber}
							testId={TEST_IDS.CREATE_STORE_ADDRESS_PARTNER_CONTRACT_NUMBER}
						/>
					</div>
					<div className={classes.bigCell}>
						<Select
							label={t('list.contractType')}
							onChange={handleChangeFormSelect('agreementType')}
							value={form?.agreementType}
							rootClassName={classes.filterItem}
							error={errors?.agreementType}
							testId={TEST_IDS.CREATE_STORE_ADDRESS_AGREEMENT_TYPE}
							withoutClear
							options={[
								{ value: AgreementTypes.AGENT, label: t('contractType.agent') },
								{ value: AgreementTypes.INFO, label: t('contractType.info') },
							]}
						/>
					</div>
				</div>
				<div className={classes.row}>
					<div className={classes.cell}>
						<Input
							value={form?.partnerCommissionPercentage}
							onChange={handleChangeForm('partnerCommissionPercentage')}
							label={`${t('list.partnerCommissionPercentage')}, %`}
							error={errors?.partnerCommissionPercentage}
							testId={TEST_IDS.CREATE_STORE_ADDRESS_PERCENTAGE}
						/>
					</div>

					<div className={clsx(classes.cell, classes.percentage)}>
						<Input
							value={form?.pickUpCommissionPercentage}
							onChange={handleChangeForm('pickUpCommissionPercentage')}
							label={`${t('list.pickUpCommissionPercentage')}, %`}
							error={errors?.pickUpCommissionPercentage}
							testId={TEST_IDS.CREATE_STORE_ADDRESS_PICKUP}
						/>
					</div>

					<div className={classes.cell}>
						<Select
							label={t('accountingTypes:label')}
							onChange={handleChangeFormSelect('accountingType')}
							value={form?.accountingType}
							rootClassName={classes.filterItem}
							error={errors?.accountingType}
							testId={TEST_IDS.CREATE_STORE_ADDRESS_ACCOUNTING_TYPE}
							withoutClear
							options={[
								{ value: AccountingTypes.ONCE_A_WEEK, label: t('accountingTypes:options.onceWeekShort') },
								{ value: AccountingTypes.TWICE_A_MONTH, label: t('accountingTypes:options.twiceMonthShort') },
								{ value: AccountingTypes.ONCE_A_MONTH, label: t('accountingTypes:options.onceMonthShort') },
							]}
						/>
					</div>
				</div>

				<div className={clsx(classes.row, classes.incidents)}>
					<SwitchComponent
						className={classes.switch}
						isActive={form?.incidentsPaidByGlovo}
						onChange={handleChangeForm('incidentsPaidByGlovo')}
						titleAct={t('list.incidentsPaidByGlovo')}
						titleDis={t('list.incidentsPaidByGlovo')}
						testId={TEST_IDS.CREATE_STORE_ADDRESS_INCIDENTS_PAID_BY_GLOVO}
					/>
				</div>
				<div className={classes.row}>
					<Select
						label={t('list.promoCalculationType')}
						value={item?.promoCalculationType}
						onChange={handleChangeFormSelect('promoCalculationType')}
						rootClassName={classes.selectPromoType}
						error={errors?.promoCalculationType}
						testId={TEST_IDS.CREATE_STORE_ADDRESS_PROMO_CALCULATION_TYPE}
						options={[
							{ value: PromoCalculationType.FULL, label: t('promoCalculationType.act') },
							{ value: PromoCalculationType.REDUCED, label: t('promoCalculationType.double') },
						]}
						withoutClear
					/>
				</div>
				<div className={classes.row}>
					<Select
						label={t('list.promoProductCalculationType')}
						value={item?.promoProductCalculationType}
						onChange={handleChangeFormSelect('promoProductCalculationType')}
						rootClassName={classes.selectPromoType}
						error={errors?.promoProductCalculationType}
						testId={TEST_IDS.CREATE_STORE_ADDRESS_PROMO_PRODUCT_CALCULATION_TYPE}
						options={[
							{ value: PromoCalculationType.FULL, label: t('promoCalculationType.full') },
							{ value: PromoCalculationType.REDUCED, label: t('promoCalculationType.reduced') },
						]}
						withoutClear
					/>
				</div>
				<div className={classes.divider} />
				<div className={classes.row}>
					<Input
						fullWidth
						value={form?.accountManagerName}
						onChange={handleChangeForm('accountManagerName')}
						label={t('list.accountManagerName')}
						error={errors?.accountManagerName}
						testId={TEST_IDS.CREATE_STORE_ADDRESS_MENAGER_NAME}
					/>
				</div>
				<div className={classes.row}>
					<Input
						fullWidth
						value={form?.email}
						onChange={handleChangeForm('email')}
						placeholder="name@example.com"
						label={t('list.email')}
						error={errors?.email}
						testId={TEST_IDS.CREATE_STORE_ADDRESS_EMAIL}
					/>
				</div>
				<div className={classes.row}>
					<Input
						fullWidth
						value={form?.email2}
						onChange={handleChangeForm('email2')}
						placeholder="additional_name@example.com"
						label={t('list.email2')}
						error={errors?.email2}
						testId={TEST_IDS.CREATE_STORE_ADDRESS_EMAIL2}
					/>
				</div>
				<div className={classes.row}>
					<Input
						fullWidth
						value={form?.reason}
						onChange={handleChangeForm('reason')}
						label={t('list.reason')}
						error={errors?.reason}
						testId={TEST_IDS.CREATE_STORE_ADDRESS_REASON}
					/>
				</div>
			</Modal>
		</>
	);
};

export default CreateStoreAddress;
