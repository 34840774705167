import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'helpers/clsx';
import { ReactComponent as Icon } from 'static/images/loadMore.svg';
import { TEST_IDS } from 'tests/config';

import { IProps } from './types';

import classes from './LoadMore.module.scss';

const LoadMore: React.FC<IProps> = ({ children, className, onClick }) => {
	const { t } = useTranslation('form');
	return (
		<div className={clsx(classes.root, className)} onClick={onClick} data-testid={TEST_IDS.LOAD_MORE}>
			<Icon className={classes.icon} />
			{children || t('loadMore')}
		</div>
	);
};

export default LoadMore;
