import { IPagination } from 'interfaces';
import { ICustomer, ICustomerFilter } from 'interfaces/customer';

export const FETCH_CUSTOMERS_START = 'FETCH_CUSTOMERS_START';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE';
export const CHANGE_CUSTOMERS_FILTER = 'CHANGE_CUSTOMERS_FILTER';
export const CLEAR_CUSTOMERS_FILTER = 'CLEAR_CUSTOMERS_FILTER';
export const SET_CUSTOMERS_PAGE = 'SET_CUSTOMERS_PAGE';
export const SET_CUSTOMERS_PAGE_SIZE = 'SET_CUSTOMERS_PAGE_SIZE';
export const CHANGE_CUSTOMER_DATA = 'CHANGE_CUSTOMER_DATA';

export interface IState {
	items: ICustomer[];
	filter: ICustomerFilter;
	total: number;
	onload: boolean;
	wasLoaded: boolean;
	pagination: IPagination;
}

export type IAction =
	| {
			type: typeof FETCH_CUSTOMERS_SUCCESS;
			payload: { items: ICustomer[]; total: number };
	  }
	| {
			type: typeof SET_CUSTOMERS_PAGE | typeof SET_CUSTOMERS_PAGE_SIZE;
			payload: number;
	  }
	| {
			type: typeof FETCH_CUSTOMERS_START | typeof FETCH_CUSTOMERS_FAILURE;
	  }
	| {
			type: typeof CHANGE_CUSTOMER_DATA;
			payload: ICustomer;
	  }
	| {
			type: typeof CHANGE_CUSTOMERS_FILTER;
			payload: { field: string; value: string | number };
	  };
