import { weekDays } from 'const';
import { IPropsTemplate } from 'containers/modals/WorkingHours/types';
import { format } from 'date-fns';
import { groupVchasnoStatuses } from 'helpers';
import { IContract, IContractFilter, WorkHours } from 'interfaces/contractGeneration';

export const normalizeFilter = (filter: IContractFilter) => {
	return {
		...filter,
		startDate: filter.startDate ? format(filter.startDate, 'yyyy-MM-dd') : undefined,
		endDate: filter.endDate ? format(filter.endDate, 'yyyy-MM-dd') : undefined,
		status: filter.status ? groupVchasnoStatuses(filter.status) : undefined,
	};
};

export const merge = (array1: IContract[], array2: IContract[]) => {
	return array1.concat(
		array2.filter((newItem) => {
			return array1.every((oldItem) => oldItem.agreementId !== newItem.agreementId);
		}),
	);
};

export const parseWorkingHours = (hours: string, translation: IPropsTemplate['translation']) => {
	const workingHours: WorkHours = {};
	const pattern = /[0-3][0-9]:[0-5][0-9]/;
	hours.split('; ').forEach((day) => {
		weekDays.forEach((name) => {
			if (day.includes(translation(name)) && pattern.test(day)) {
				const workDay = day
					.replace(translation(name), '')
					.split(', ')
					.map((period) => period.replace(/[\s;]/g, ''));

				const range = workDay[0].split('-');

				workingHours[name] = {
					before: {
						open: range[0],
						close: range[1],
					},
				};

				if (workDay.length > 1) {
					const secondRange = workDay[1].split('-');
					workingHours[name] = {
						...workingHours[name],
						after: {
							open: secondRange[0],
							close: secondRange[1],
						},
					};
				}
			}
		});
	});

	return workingHours;
};

export const queryParamsToString = (params: { [k: string]: string | string[] | undefined }) =>
	Object.keys(params)
		.filter((k) => params[k])
		.map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k] as string))
		.join('&');
