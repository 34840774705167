export interface IProps {
	gen?: string;
	category?: string;
}

export enum CategoryGenEnum {
	genOne = 'GEN 1',
	genTwo = 'GEN 2',
	food = 'Food',
	commerce = 'Q-Commerce',
}
