import { bytesToSize } from 'helpers';
import clsx from 'helpers/clsx';
import { ReactComponent as RemoveIcon } from 'static/images/close.svg';
import { ReactComponent as Icon } from 'static/images/file-loaded.svg';

import { IPropsAttachFile } from './types';

import classes from './FormControl.module.scss';

const AttachFile: React.FC<IPropsAttachFile> = ({ rootClassName, file, onRemove, limitSize = 20, Icon: PropsIco }: IPropsAttachFile) => {
	const handleRemove = (name: string) => () => onRemove(name);
	const { size } = file;
	const errorLimit = size / 1024 / 1024 > limitSize && limitSize > 0;

	const getIcon = () => (PropsIco ? <PropsIco className={classes.fileIcon} /> : <Icon className={classes.fileIcon} />);

	return (
		<div className={clsx(classes.root, classes.fileLoaded, rootClassName, { [classes.errorLimit]: errorLimit })}>
			{getIcon()}
			<div className={classes.fileLoadedName}>{file.name}</div>
			<div className={classes.fileSize}>{bytesToSize(file.size)}</div>
			<div className={classes.fileRemove} onClick={handleRemove(file.name)}>
				<RemoveIcon />
			</div>
		</div>
	);
};

export default AttachFile;
