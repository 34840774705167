import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatPrice, formatTimeLaaS } from 'helpers';
import clsx from 'helpers/clsx';
import { useUser } from 'hooks/useUser';
import { FinalStatus } from 'interfaces';
import { ILaaSOrder } from 'interfaces/laasOrder';
import { TEST_IDS } from 'tests/config';

import CopyButton from 'components/CopyButton';
import Nowrap from 'components/Nowrap';
import Tooltip from 'components/Tooltip';

import classes from './OrdersContainer.module.scss';

interface IProps {
	item: ILaaSOrder;
}

const Order: React.FC<IProps> = ({ item }) => {
	const { t } = useTranslation(['orders', 'common']);
	const { isCustomer } = useUser();

	return (
		<>
			<tr data-testid={TEST_IDS.ORDERS_TABLE_BODY_ROW}>
				<td className={classes.dateCell}>
					<Tooltip text={<Trans t={t} i18nKey="tooltips.date" />} nowrap>
						<span className={clsx(classes.flex, classes.alignCenter)}>
							<Nowrap>{formatTimeLaaS(item.orderCreatedLocalAt)}</Nowrap>
						</span>
					</Tooltip>
				</td>
				<td>
					{item.orderCourierArrivalToDeliveryLocalAt && (
						<Tooltip nowrap text={<Trans t={t} i18nKey="tooltips.orderCourierArrivalToDeliveryLocalAt" />}>
							<span className={clsx(classes.flex, classes.alignCenter)}>
								<Nowrap>{formatTimeLaaS(item.orderCourierArrivalToDeliveryLocalAt)}</Nowrap>
							</span>
						</Tooltip>
					)}
				</td>
				<td className={clsx(classes.pointCodeCell, classes.big)}>
					<Tooltip text={t('fields.cityCode')} nowrap>
						<span>
							<Nowrap>{item.cityCode}</Nowrap>
						</span>
					</Tooltip>
				</td>
				<td className={clsx(classes.light, classes.pointCodeCell)}>
					<Tooltip text={<Trans t={t} i18nKey="fields.orderId" />} nowrap>
						<span>
							<Nowrap>{item.orderId}</Nowrap>
						</span>
					</Tooltip>
				</td>
				{isCustomer && (
					<td className={clsx(classes.light, classes.pointCodeCell)}>
						<Tooltip text={<Trans t={t} i18nKey="fields.customerId" />} nowrap>
							<span>
								<Nowrap>{item.customerId}</Nowrap>
							</span>
						</Tooltip>
					</td>
				)}
				{!isCustomer && (
					<>
						<td className={clsx(classes.light, classes.idCell)}>
							<div className={classes.codeInner}>
								<div className={clsx(classes.iconWrap, classes.flex, classes.alignCenter)}>{item.orderCode}</div>
								<CopyButton className={classes.codeIco} text={item.orderCode} />
							</div>
						</td>
						<td className={clsx(classes.light, classes.idCell)}>
							<div className={classes.codeInner}>
								<div className={clsx(classes.iconWrap, classes.flex, classes.alignCenter)}>{item.trackingNumber}</div>
								{item.trackingNumber && <CopyButton className={classes.codeIco} text={item.trackingNumber} />}
							</div>
						</td>
					</>
				)}
				<td className={classes.name}>
					<Tooltip text={item.name} nowrap>
						<span>
							<Nowrap>{item.name}</Nowrap>
						</span>
					</Tooltip>
				</td>
				<td className={classes.companyName}>
					<Tooltip text={item.companyName} nowrap>
						<span>
							<Nowrap>{item.companyName}</Nowrap>
						</span>
					</Tooltip>
				</td>
				<td className={clsx(classes.pointCodeCell, classes.big)}>
					<Tooltip text={t('fields.taxId')} nowrap>
						<span>
							<Nowrap>{item.taxId}</Nowrap>
						</span>
					</Tooltip>
				</td>
				<td>
					<Tooltip placement="bottom" text={item.pickUpPoint} nowrap>
						<span>
							<Nowrap>{item.pickUpPoint}</Nowrap>
						</span>
					</Tooltip>
				</td>
				<td>
					<Tooltip placement="bottom" text={item.deliveryPoint} nowrap>
						<span>
							<Nowrap>{item.deliveryPoint}</Nowrap>
						</span>
					</Tooltip>
				</td>
				{isCustomer && (
					<td className={clsx(classes.big, { [classes.light]: !item.kmTier })}>
						<Nowrap>{formatPrice(item.kmTier || 0, 2)}</Nowrap>
					</td>
				)}
				<td className={clsx(classes.big, { [classes.light]: !item.rpoRealLocal })}>
					<Nowrap>{formatPrice(item.rpoRealLocal || 0, 2)}</Nowrap>
				</td>
				{!isCustomer && (
					<>
						<td className={clsx(classes.big, { [classes.light]: !item.fullFlightDistance })}>
							<Nowrap>{formatPrice(item.fullFlightDistance || 0, 2)}</Nowrap>
						</td>
						<td className={classes.big}>
							<span>
								<Nowrap>{item.distanceDrivenInMeters}</Nowrap>
							</span>
						</td>
					</>
				)}
				<td className={clsx(classes.light, { [classes.error]: item.orderFinalStatus === FinalStatus.CANCELED })}>
					<Tooltip placement="left" text={<Trans t={t} i18nKey="tooltips.finalStatus" />} nowrap>
						<span>
							<Nowrap>{item.orderFinalStatus}</Nowrap>
						</span>
					</Tooltip>
				</td>
				<td title={item.orderCancelReason}>
					<Tooltip placement="left" text={<Trans t={t} i18nKey="tooltips.cancelReason" />} nowrap>
						<span>
							<Nowrap>{item.orderCancelReason}</Nowrap>
						</span>
					</Tooltip>
				</td>
				<td title={item.partnerResolution}>
					<Nowrap>{item.partnerResolution}</Nowrap>
				</td>
				<td
					className={clsx(classes.light, classes.alignRight, classes.big, {
						[classes.error]: item.chargedToPartner > 0,
					})}
				>
					<Tooltip text={item.chargedToPartner > 0 && <Trans t={t} i18nKey="tooltips.chargedToPartner" />} placement="left" nowrap>
						<span>
							<Nowrap>{formatPrice(item.chargedToPartner, 2)}</Nowrap>
						</span>
					</Tooltip>
				</td>
			</tr>
		</>
	);
};

export default Order;
