import { ValueOf } from 'interfaces';
import { ILaaSOrder, ILaaSTotals } from 'interfaces/laasOrder';
import { IFilter } from 'interfaces/laasOrder';

export const FETCH_LAAS_ORDERS_START = 'FETCH_LAAS_ORDERS_START';
export const FETCH_LAAS_ORDERS_SUCCESS = 'FETCH_LAAS_ORDERS_SUCCESS';
export const FETCH_LAAS_ORDERS_FAILURE = 'FETCH_LAAS_ORDERS_FAILURE';
export const CHANGE_LAAS_ORDERS_FILTER = 'CHANGE_LAAS_ORDERS_FILTER';
export const CLEAR_LAAS_ORDERS_FILTER = 'CLEAR_LAAS_ORDERS_FILTER';
export const SET_LAAS_ORDERS_PAGE = 'SET_LAAS_ORDERS_PAGE';
export const SET_LAAS_ORDERS_PAGE_SIZE = 'SET_LAAS_ORDERS_PAGE_SIZE';
export const UPDATE_LAAS_ORDER = 'UPDATE_LAAS_ORDER';
export const CLEAR_ALL_LAAS_ORDERS = 'CLEAR_ALL_LAAS_ORDERS';

export const FETCH_LAAS_ORDERS_TOTALS_START = 'FETCH_LAAS_ORDERS_TOTALS_START';
export const FETCH_LAAS_ORDERS_TOTALS_SUCCESS = 'FETCH_LAAS_ORDERS_TOTALS_SUCCESS';
export const FETCH_LAAS_ORDERS_TOTALS_FAILURE = 'FETCH_LAAS_ORDERS_TOTALS_FAILURE';

export interface IState {
	items: ILaaSOrder[];
	filter: IFilter;
	total: number;
	onload: boolean;
	wasLoaded: boolean;
	loadingTotals: boolean;
	totals?: ILaaSTotals;
	pagination: {
		page: number;
		pageSize: number;
	};
}

export type IAction =
	| {
			type: typeof FETCH_LAAS_ORDERS_SUCCESS;
			payload: { orders: ILaaSOrder[]; total: number };
	  }
	| {
			type: typeof SET_LAAS_ORDERS_PAGE | typeof SET_LAAS_ORDERS_PAGE_SIZE;
			payload: number;
	  }
	| {
			type:
				| typeof FETCH_LAAS_ORDERS_START
				| typeof FETCH_LAAS_ORDERS_FAILURE
				| typeof CLEAR_ALL_LAAS_ORDERS
				| typeof FETCH_LAAS_ORDERS_TOTALS_START
				| typeof FETCH_LAAS_ORDERS_TOTALS_FAILURE;
	  }
	| {
			type: typeof CHANGE_LAAS_ORDERS_FILTER;
			payload: { field: string; value: ValueOf<IFilter> };
	  }
	| { type: typeof UPDATE_LAAS_ORDER; payload: ILaaSOrder }
	| { type: typeof CLEAR_LAAS_ORDERS_FILTER; payload: [Date, Date] }
	| { type: typeof FETCH_LAAS_ORDERS_TOTALS_SUCCESS; payload: ILaaSTotals };
