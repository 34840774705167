import { RoleEnum } from 'interfaces/users';

export const breakPoints = {
	MOBILE: 768,
	TABLET: 1024,
};

export const APP_PREFIX = 'GLOVO.APP.';
export type PLACEMENT =
	| 'top-start'
	| 'top'
	| 'top-end'
	| 'right-start'
	| 'right'
	| 'right-end'
	| 'bottom-end'
	| 'bottom'
	| 'bottom-start'
	| 'left-end'
	| 'left'
	| 'left-start';
export const validTypesForOrderFiles = [
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/pdf',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'image/*',
	'video/*',
];

export enum Routes {
	ORDERS = '/orders/',
	ORDER_BY_CODE = '/orders/:code',
	LAAS_ORDERS = '/laas-orders',
	USERS = '/userList/',
	LOGIN = '/login/',
	STORE_ADDRESSES = '/storeAddresses/',
	SERVICE_NOTIFICATION = '/serviceNotification/',
	FORGOT_PASSWORD = '/forgotPassword/',
	IMPORT_ORDERS = '/import-orders/',
	ORDER_REPORT = '/order-report/',
	ACCOUNTS = '/accounts/',
	STATISTIC = '/statistic/',
	PAYMENTSCHEDULE = '/paymentSchedule/',
	DOCUMENTS = '/documents/',
	POWER_OUTAGE = '/powerOutage/',
	BULKUPDATE = '/bulkUpdate/',
	PARTNERS_FILE = '/partners-file/',
	CONTRACT_GENERATION = '/contract-generation/',
	QUESTIONNAIRE = '/questionnaire/',
	CONTRACT_FORM = '/contract-form/:id',
	DOCUMENT_PREVIEW = '/document/:id/',
	PARTNERS_FILE_PERMISSIONS = '/partners-file-permissions/',
	CUSTOMERS = '/customers',
	FAQ = '/faq',
}

export enum ClientNameEnum {
	AUTH = 'auth',
}

export const contextAuth = {
	clientName: ClientNameEnum.AUTH,
};

export const EXPORT_BUTTON_STEP_ID = 'exportExcel';

export const menutoolUrl = 'https://menu-tool.glovoapp.com';
export const adminLink = 'https://beta-admin.glovoapp.com';

export const viewUrlGoogle = `https://drive.google.com/viewerng/viewer?embedded=true&url=`;
export const viewUrlOffice = `https://view.officeapps.live.com/op/embed.aspx?src=`;

export const GLOVO_ROLES = [RoleEnum.ACCOUNT, RoleEnum.FINANCE, RoleEnum.ADMIN];
export const PARTNER_ROLES = [RoleEnum.STORE_ADMIN, RoleEnum.STORE_MANAGER, RoleEnum.ACCOUNTANT];
export const taxIdMinLength = 7;

export const vchasnoLinkRegExp =
	/https:\/\/(edo.vchasno.ua|vchasno.ua)\/app\/documents\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/gm;
export const glovoDomainRegex = /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(glovoapp)\.com$/;
export const glailgiRegex = /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(globalbilgi)\.com$/;

export const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const UAShortWeekDays = {
	Monday: 'ПН',
	Tuesday: 'BT',
	Wednesday: 'CP',
	Thursday: 'ЧТ',
	Friday: 'ПТ',
	Saturday: 'СБ',
	Sunday: 'НД',
};
export const UAregions = [
	'АР Крим',
	'Вінницька',
	'Волинська',
	'Дніпропетровська',
	'Донецька',
	'Житомирська',
	'Закарпатська',
	'Запорізька',
	'Івано-Франківська',
	'Київська',
	'Кіровоградська',
	'Луганська',
	'Львівська',
	'Миколаївська',
	'Одеська',
	'Полтавська',
	'Рівненська',
	'Сумська',
	'Тернопільська',
	'Харківська',
	'Херсонська',
	'Хмельницька',
	'Черкаська',
	'Чернівецька',
	'Чернігівська',
];
export const GLOVO_DOMAIN = 'glovoapp.com';
