import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TEST_IDS } from 'tests/config';

import Template from './template';
import { IProps } from './types';

import classes from './AllDocuments.module.scss';

const AllDocuments: React.FC<IProps> = ({ children, ...props }) => {
	const [open, setOpen] = useState<boolean>(false);
	const { t } = useTranslation(['partners']);

	const onClose = () => {
		setOpen(false);
	};

	const onOpen = () => {
		setOpen(true);
	};

	const childrenClone = React.Children.map(children, (child) => {
		return (
			React.isValidElement(child) &&
			React.cloneElement(child as React.ReactElement, {
				onClick: onOpen,
			})
		);
	});

	return (
		<>
			{childrenClone || (
				<button data-testid={TEST_IDS.SHOW_DOCUMENTS} className={classes.showAllButton} onClick={onOpen}>
					{t('showAll')}
				</button>
			)}
			{open && <Template {...props} onClose={onClose} />}
		</>
	);
};

export default AllDocuments;
