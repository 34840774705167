import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'helpers/clsx';

import MainButton from 'components/MainButton';

import Template from './template';
import { IProps } from './types';

import classes from './SendVchasno.module.scss';

const SendVchasno: React.FC<IProps> = ({ children, ...props }) => {
	const [open, setOpen] = useState<boolean>(false);
	const { t } = useTranslation('contractGeneration');

	const onClose = () => {
		setOpen(false);
		if (props.onClose) props.onClose();
	};

	const onOpen = () => {
		setOpen(true);
	};

	const childrenClone = React.Children.map(children, (child) => {
		return (
			React.isValidElement(child) &&
			React.cloneElement(child as React.ReactElement, {
				onClick: onOpen,
			})
		);
	});

	return (
		<>
			{!props.fields.vchasnoSync &&
				(childrenClone || (
					<MainButton className={clsx(classes.flex, classes.alignItemsCenter)} onClick={onOpen}>
						{t('sendVchasno')}
					</MainButton>
				))}
			{open && <Template {...props} onClose={onClose} />}
		</>
	);
};

export default SendVchasno;
