import React, { useMemo } from 'react';
import clsx from 'helpers/clsx';

import { IProps } from './types';

import classes from './IconButton.module.scss';

const IconButton: React.FC<IProps> = ({ children, className, onClick, size = 'default', disabled, testId, component = 'button', ...props }) => {
	const comp = useMemo(() => React.createElement(component), [component]);
	return (
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-expect-error
		<comp.type
			className={clsx(classes.root, className, {
				[classes.average]: size === 'average',
				[classes.small]: size === 'small',
			})}
			onClick={onClick}
			disabled={disabled}
			data-testid={testId}
			{...props}
		>
			{children}
		</comp.type>
	);
};

export default IconButton;
