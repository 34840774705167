import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LazyQueryHookOptions, useLazyQuery, useMutation } from '@apollo/client';
import { GET_STORE_ADDRESSES_DATA } from 'api/storeAddresses';
import { CREATE_USER, DELETE_USER, GET_CUSTOMERS_DATA, UPDATE_USER_ROLES } from 'api/users';
import { contextAuth } from 'const';
import { getCountry } from 'helpers/jwt';
import { IPagination } from 'interfaces';
import { IStoreAddress } from 'interfaces/storeAddress';
import { ICustomerData, IUser, IUserFilter, RoleEnum, UserCountryRoles } from 'interfaces/users';
import { useSelector } from 'store';
import {
	changeUsersFilter,
	deleteUserById as deleteUserByIdAction,
	fetchUsers as fetchUsersAction,
	resetUsersFilter,
	setUsersPage,
	setUsersPageSize,
	updateUserById as updateUserByIdAction,
} from 'store/users/actions';

import useToast from './useToast';

export interface IUseUsers {
	items: IUser[];
	filter: IUserFilter;
	total: number;
	onload: boolean;
	loadingCreateUer: boolean;
	wasLoaded: boolean;
	pagionation: IPagination;
	fetchUsers(): void;
	changeFilter(field: keyof IUserFilter, value: IUserFilter[typeof field]): void;
	setPageSize(pageSize: number): void;
	setPage(page: number): void;
	createUser(
		roles: RoleEnum[],
		email: string,
		userCountryStoreIds: number[],
		storeAddresses: Partial<IStoreAddress>[],
		userCountryCustomerIds: number[],
	): Promise<unknown>;
	deleteUser(id: IUser['id']): Promise<boolean>;
	deleteUserById(id: IUser['id']): void;
	updateUserById(id: IUser): void;
	updateUserRoles(
		user: IUser,
		roles: RoleEnum[],
		storeAddressIds: number[],
		storeAddresses: Partial<IStoreAddress>[],
		customerIds: number[],
	): Promise<boolean>;
	fetchStoreDate(storeAddressIds: number[]): Promise<{ storeAddressData: IStoreAddress[] }>;
	fetchCustomersData(customerIds: number[]): Promise<{ customersData: ICustomerData[] }>;
	setAbortRef(AbortController: AbortController): void;
	aborterRef: AbortController;
	resetFilter(): void;
}

export const useUsers = (): IUseUsers => {
	const { items, filter, pagionation, total, onload, wasLoaded } = useSelector((state) => state.users);
	const [registerUser, { loading: loadingCreateUer }] = useMutation(CREATE_USER, { context: contextAuth });
	const [deleteUserMutation] = useMutation(DELETE_USER, { context: contextAuth });
	const [updateUserRolesMutation] = useMutation(UPDATE_USER_ROLES, { context: contextAuth });
	const [fetchAddressQuery] = useLazyQuery(GET_STORE_ADDRESSES_DATA);
	const [fetchCustomerQuery] = useLazyQuery(GET_CUSTOMERS_DATA);
	const dispatch = useDispatch();
	const { toast } = useToast();
	const { t } = useTranslation(['errors']);
	const [aborterRef, setAbortRef] = useState(new AbortController());

	const fetchUsers = useCallback(() => {
		return dispatch(fetchUsersAction(filter, pagionation, aborterRef));
	}, [dispatch, filter, pagionation]);

	const fetchStoreDate = (allUserStoreIds: number[]): Promise<{ storeAddressData: IStoreAddress[] }> => {
		const params: LazyQueryHookOptions = {
			variables: { storeAddressIds: allUserStoreIds },
			fetchPolicy: 'network-only',
		};
		return new Promise((resolve, reject) => {
			fetchAddressQuery(params)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(() => {
					toast(t('errors:somethingWentWrong'), { type: 'error' });
					reject(false);
				});
		});
	};

	const fetchCustomersData = (customerIds: number[]): Promise<{ customersData: ICustomerData[] }> => {
		const params: LazyQueryHookOptions = {
			variables: { customerIds },
			fetchPolicy: 'network-only',
		};
		return new Promise((resolve, reject) => {
			fetchCustomerQuery(params)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(() => {
					toast(t('errors:somethingWentWrong'), { type: 'error' });
					reject(false);
				});
		});
	};

	const changeFilter = (field: keyof IUserFilter, value: IUserFilter[typeof field]) => {
		dispatch(changeUsersFilter(field, value));
	};

	const resetFilter = () => {
		dispatch(resetUsersFilter());
	};

	const setPageSize = (pageSize: number) => {
		dispatch(setUsersPageSize(pageSize));
	};

	const setPage = (page: number) => {
		dispatch(setUsersPage(page));
	};

	const createUser = (
		roles: RoleEnum[],
		email: string,
		userCountryStoreIds: number[],
		storeAddresses: Partial<IStoreAddress>[],
		userCountryCustomerIds: number[],
	) => {
		const countries = {
			selected: true,
			country: getCountry(),
			roles,
			userCountryStoreIds,
			storeAddresses,
			userCountryCustomerIds,
		};
		return registerUser({
			variables: { input: { email, countries: [countries] } },
		});
	};

	const deleteUserById = (id: IUser['id']) => {
		dispatch(deleteUserByIdAction(id));
	};

	const updateUserById = (user: IUser) => {
		dispatch(updateUserByIdAction(user));
	};

	const deleteUser = (id: IUser['id']): Promise<boolean> => {
		return new Promise((resolve, reject) => {
			deleteUserMutation({
				variables: { id },
			})
				.then(() => {
					deleteUserById(id);
					resolve(true);
				})
				.catch(() => {
					toast(t('errors:somethingWentWrong'), { type: 'error' });
					reject(false);
				});
		});
	};

	const updateUserRoles = (
		user: IUser,
		roles: RoleEnum[],
		storeAddressIds: number[],
		storeAddresses: Partial<IStoreAddress>[],
		customerIds: number[],
	): Promise<boolean> => {
		const countryCode = getCountry();
		return new Promise((resolve, reject) => {
			updateUserRolesMutation({
				variables: { id: user.id, country: countryCode, roles, storeAddressIds, storeAddresses, customerIds },
			})
				.then(() => {
					const currentCountryCode = getCountry();
					const countries: UserCountryRoles[] = user.countries.map((country) => {
						if (currentCountryCode && country.country === currentCountryCode) {
							return {
								...country,
								roles,
								storeAddressIds,
								storeAddresses,
								userCountryStoreIds: storeAddressIds,
								userCountryCustomerIds: customerIds,
							};
						}
						return country;
					});
					updateUserById({ ...user, countries });
					resolve(true);
				})
				.catch(() => {
					toast(t('errors:somethingWentWrong'), { type: 'error' });
					reject(false);
				});
		});
	};

	return {
		items,
		filter,
		pagionation,
		total,
		onload,
		wasLoaded,
		loadingCreateUer,
		fetchUsers,
		changeFilter,
		setPageSize,
		setPage,
		createUser,
		deleteUser,
		deleteUserById,
		updateUserRoles,
		updateUserById,
		fetchStoreDate,
		aborterRef,
		setAbortRef,
		resetFilter,
		fetchCustomersData,
	};
};
