import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router';
import Slider from 'react-slick';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNTING_TYPE } from 'api/payoutSchedule';
import { useGetStartedContext } from 'contexts/GetStarted';
import clsx from 'helpers/clsx';
import { getReleaseAnnouncementItems, setViewed } from 'helpers/releaseAnnouncement';
import { delay } from 'helpers/utis';
import { useUser } from 'hooks/useUser';
import { AccountingTypes } from 'interfaces';
import { IReleaseAnnouncement } from 'interfaces/releaseAnnouncement';
import { ReactComponent as Arr } from 'static/images/keyboard_arrow_down.svg';
import { TEST_IDS } from 'tests/config';

import Modal from 'components/modals/Modal';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import classes from './ReleaseAnnouncement.module.scss';

const ReleaseAnnouncement: React.FC = () => {
	const { t, i18n } = useTranslation('releaseAnnouncement');
	const { roles } = useUser();
	const sliderRef = useRef<Slider | null>(null);
	const plyerRef = useRef<ReactPlayer | null>(null);
	const [isLastSlide, setIsLastSlide] = useState<boolean>(false);

	const { open: isGetStartedOpen, isReady: isReadyGetStarted } = useGetStartedContext();
	const [open, setOpen] = useState<boolean>(false);
	const [releaseAnnouncementItems, setReleaseAnnouncementItems] = useState<IReleaseAnnouncement[]>([]);
	const history = useHistory();

	const { data: accountingType, loading: loadingAccountingType } = useQuery<{ getAccountingType: { accountingType: AccountingTypes }[] }>(
		GET_ACCOUNTING_TYPE,
	);

	const hasOnlyWeeklyPeriod =
		accountingType?.getAccountingType.find((p) => p.accountingType === AccountingTypes.ONCE_A_WEEK) && accountingType?.getAccountingType.length === 1;

	useEffect(() => {
		delay(
			'getReleaseAnnouncementItems',
			() => {
				getReleaseAnnouncementItems(roles).then((res) => {
					setReleaseAnnouncementItems(res.filter((a) => (a.label === 'changePaymentSchedule' ? !hasOnlyWeeklyPeriod : true)));
				});
			},
			1000,
		);
	}, [roles, i18n.language, hasOnlyWeeklyPeriod]);

	useEffect(() => {
		const shouldOpen = releaseAnnouncementItems.length > 0 && isReadyGetStarted && !loadingAccountingType;
		const timer = setTimeout(() => {
			setOpen(shouldOpen);
		}, 250);

		return () => {
			clearTimeout(timer);
		};
	}, [isGetStartedOpen, releaseAnnouncementItems, isReadyGetStarted, loadingAccountingType]);

	const playCurrentVideo = (slideIndex = 0) => {
		Array.prototype.forEach.call(document.getElementsByClassName('slick-slide'), (slide) => {
			const videoEl = slide.getElementsByTagName('video')[0];
			if (slideIndex === +slide.dataset.index && videoEl) {
				videoEl.play();
			} else if (videoEl) {
				videoEl.pause();
			}
		});
	};

	const settings = {
		dots: releaseAnnouncementItems.length > 1,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		dotsClass: classes.sliderDots,
		adaptiveHeight: true,
		autoplay: true,
		autoplaySpeed: 15000,

		appendDots: (dots: { key: string; props: { className: string } }[]) => {
			const newDots = dots.map((dot) => {
				if (dot.props.className === 'slick-active')
					return (
						<span key={dot.key} className={classes.activeDot}>
							{dot}
						</span>
					);
				return <span key={dot.key}>{dot}</span>;
			});
			return <ul>{newDots}</ul>;
		},

		onInit: () => {
			if (releaseAnnouncementItems.length) setViewed(releaseAnnouncementItems[0]);
			setTimeout(playCurrentVideo, 100);
		},

		beforeChange(_: number, newIndex: number) {
			setIsLastSlide(releaseAnnouncementItems.length - 1 === newIndex);
			playCurrentVideo(newIndex);
		},

		afterChange(currentSlide: number) {
			setViewed(releaseAnnouncementItems[currentSlide]);
		},
	};

	const goToNextSlide = () => {
		if (sliderRef.current) sliderRef.current.slickNext();
	};

	const handleClose = () => setOpen(false);
	const handleMoveToSection = (href?: string) => () => {
		if (href) {
			handleClose();
			history.push(href);
		}
	};

	return (
		<Modal open={open} onClose={handleClose} bodyClassName={classes.body} modalClassName={classes.modal} closeButtonClassName={classes.closeButton}>
			<Slider ref={sliderRef} {...settings}>
				{releaseAnnouncementItems.map((notification) => {
					return (
						<div key={notification.id}>
							<div className={classes.header}>
								<div className={classes.containerImg}>
									{!!notification.image?.length && <img className={classes.img} src={notification.image} alt="" />}
									{!!notification.video?.length && (
										<div className={classes.videoWrap}>
											<ReactPlayer ref={plyerRef} url={notification.video} width="100%" height="100%" controls={false} muted loop />
										</div>
									)}
								</div>
							</div>
							<div className={clsx(classes.containerContent, { [classes.last]: releaseAnnouncementItems.length <= 1 })}>
								<div className={classes.title} data-testid={TEST_IDS.RELEASE_MODAL_TITLE} dangerouslySetInnerHTML={{ __html: notification.title }} />
								{notification.content && <div className={classes.content} dangerouslySetInnerHTML={{ __html: notification.content }} />}
								{Array.isArray(notification.list) && !!notification.list?.length && (
									<div className={classes.listWrap}>
										{notification.list.map((item, index) => (
											<div key={index} className={classes.listItem}>
												{item}
											</div>
										))}
									</div>
								)}
								{notification.href && (
									<div className={classes.containerButton}>
										{notification.target ? (
											<a className={classes.button} href={notification.href} target="_blank" rel="noreferrer">
												{t(notification.buttonText)}
											</a>
										) : (
											<span onClick={handleMoveToSection(notification.href)} className={classes.button}>
												{t(notification.buttonText ? notification.buttonText : 'button')}
											</span>
										)}
									</div>
								)}
							</div>
						</div>
					);
				})}
			</Slider>
			{releaseAnnouncementItems.length > 1 && (
				<div className={classes.sliderButtonWrap}>
					<span onClick={goToNextSlide}>
						{isLastSlide ? t('goToStart') : t('nextSlide')} <Arr className={classes.arr} />
					</span>
				</div>
			)}
		</Modal>
	);
};

export default ReleaseAnnouncement;
