import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ILaaSOrder, ILaaSTotals } from 'interfaces/laasOrder';
import { IFilter } from 'interfaces/laasOrder';
import { Direction, OrderSorting } from 'interfaces/order';
import { useSelector } from 'store';
import {
	changeOrdersFilter,
	clearAllOrders,
	clearOrdersFilter,
	fetchOrders as fetchOrdersAction,
	fetchTotals as fetchTotalsAction,
	setOrdersPage,
	setOrdersPageSize,
	updateOrder as updateOrderAction,
} from 'store/laasOrders/actions';

export interface IUseOrders {
	items: ILaaSOrder[];
	filter: IFilter;
	total: number;
	onload: boolean;
	wasLoaded: boolean;
	totals?: ILaaSTotals;
	loadingTotals: boolean;
	pagination: {
		page: number;
		pageSize: number;
	};
	fetchOrders(): void;
	fetchTotals(): void;
	changeFilter(field: keyof IFilter, value: IFilter[typeof field]): void;
	clearFilters(dates?: [Date, Date]): void;
	clearOrders(): void;
	setPage(page: number): void;
	setPageSize(pageSize: number): void;
	changeSorting(sortBy: OrderSorting): void;
	updateOrder(newOrder: ILaaSOrder): void;
	setAbortRef(AbortController: AbortController): void;
	aborterRef: AbortController;
}

export const useLaaSOrders = (): IUseOrders => {
	const { items, filter, pagination, total, onload, wasLoaded, loadingTotals, totals } = useSelector((state) => state.laasOrders);
	const dispatch = useDispatch();
	const [aborterRef, setAbortRef] = useState(new AbortController());

	const fetchOrders = () => {
		return dispatch(fetchOrdersAction(filter, pagination));
	};

	const fetchTotals = () => {
		return dispatch(fetchTotalsAction(filter, aborterRef));
	};

	const aboartRequest = () => {
		if (aborterRef && typeof setAbortRef === 'function') {
			aborterRef.abort();
			setAbortRef(new AbortController());
		}
	};

	const changeFilter = (field: keyof IFilter, value: IFilter[typeof field]) => {
		aboartRequest();
		dispatch(changeOrdersFilter(field, value));
	};

	const clearFilters = (dates?: [Date, Date]) => {
		aboartRequest();
		dispatch(clearOrdersFilter(dates));
	};

	const clearOrders = () => {
		dispatch(clearAllOrders());
	};

	const setPage = (page: number) => {
		dispatch(setOrdersPage(page));
	};

	const setPageSize = (pageSize: number) => {
		dispatch(setOrdersPageSize(pageSize));
	};

	const changeSorting = (sortBy: OrderSorting) => {
		if (filter.sorting?.sortBy === sortBy) {
			if (filter.sorting?.direction === Direction.DESC) return changeFilter('sorting', undefined);
			changeFilter('sorting', {
				...filter.sorting,
				direction: filter.sorting?.direction === Direction.ASC ? Direction.DESC : Direction.ASC,
			});
		} else {
			changeFilter('sorting', {
				sortBy,
				direction: Direction.ASC,
			});
		}
	};

	const updateOrder = (newOrder: ILaaSOrder) => {
		dispatch(updateOrderAction(newOrder));
	};

	return {
		items,
		filter,
		pagination,
		total,
		totals,
		onload,
		wasLoaded,
		loadingTotals,
		fetchOrders,
		changeFilter,
		clearFilters,
		clearOrders,
		setPage,
		setPageSize,
		changeSorting,
		updateOrder,
		fetchTotals,
		setAbortRef,
		aborterRef,
	};
};
