import { gql } from '@apollo/client';

export const GET_LAAS_ORDERS = gql`
	query laasOrders($page: Int, $pageSize: Int, $filter: LaasOrderFilter) {
		laasOrders(page: $page, pageSize: $pageSize, filter: $filter) {
			totalPages
			total
			data {
				orderId
				customerId
				orderCode
				cityCode
				fullFlightDistance
				orderFinalStatus
				orderCancelReason
				orderCreatedLocalAt
				distanceDrivenInMeters
				rpoRealLocal
				orderCourierArrivalToDeliveryLocalAt
				companyName
				taxId
				trackingNumber
				name
				pickUpPoint
				deliveryPoint
				kmTier
				chargedToPartner
				partnerResolution
			}
		}
	}
`;

export const GET_LAAS_ORDERS_FILTER = gql`
	query laasOrderSelect($type: String!, $filter: LaasOrderFilter) {
		laasOrderSelect(type: $type, filter: $filter) {
			taxId
			name
			companyName
			pickUpPoint
		}
	}
`;

export const GET_LAAS_TOTALS = gql`
	query LaasOrderTotals($filter: LaasOrderFilter!) {
		laasOrderTotals(filter: $filter) {
			paidByPartner
			glovoFee
			vat
			incident
		}
	}
`;
