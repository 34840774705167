import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { clientGraphql } from 'api';
import { GET_OPEN_DATA_BOT_COMPANY } from 'api/storeAddresses';
import clsx from 'clsx';
import { taxIdMinLength } from 'const';
import isBefore from 'date-fns/isBefore';
import isEqual from 'date-fns/isEqual';
import { getCountry } from 'helpers/jwt';
import { isLengthString, isSetString, isValidEmail } from 'helpers/validation';
import { useCustomers } from 'hooks/useCustomers';
import { useDeepCompareEffect } from 'hooks/useDeepCompareEffect';
import useToast from 'hooks/useToast';
import { CountryCodeEnum, ERRORS, ErrorsType, OpenDataSyncStatuses } from 'interfaces';
import { ICustomerForm } from 'interfaces/customer';
import { IResponseOpenData } from 'interfaces/storeAddress';
import { TEST_IDS } from 'tests/config';

import { DatePicker, Input } from 'components/FormControl';
import Loader from 'components/Loader';
import Modal from 'components/modals/Modal';

import { IPropsTemplate } from './types';

import classes from './CreateEditCustomer.module.scss';

const CreateEditCustomer: React.FC<IPropsTemplate> = ({ customer, refetchQueries, history = [], onClose }) => {
	const isEdit = !!customer;
	const [loading, setLoading] = useState<boolean>(false);
	const { toast } = useToast();
	const { t } = useTranslation(['storeAddresses', 'customers', 'validation', 'errors']);
	const [form, setForm] = useState<ICustomerForm>({
		effectiveDate: new Date(),
		effectiveUntil: new Date(),
		reason: undefined,
		contractNumber: undefined,
		customerId: undefined,
		name: undefined,
		taxId: undefined,
		city: undefined,
		address: undefined,
		email: undefined,
		companyName: undefined,
	});
	const [fetchOpenDataBotCompany, { loading: openDataLoading }] = useLazyQuery<IResponseOpenData>(GET_OPEN_DATA_BOT_COMPANY, {
		fetchPolicy: 'network-only',
	});
	const [errors, setErrors] = useState<ErrorsType<ICustomerForm>>();
	const { editCustomer } = useCustomers();
	const normalizeForm = (input: ICustomerForm): ICustomerForm => {
		const skipFields: (keyof ICustomerForm)[] = ['__typename'];
		return Object.entries(input).reduce((acc, [key, value]) => {
			if (skipFields.includes(key as keyof ICustomerForm)) return acc;
			return {
				...acc,
				[key]: value,
			};
		}, {});
	};

	useEffect(() => {
		if (customer) {
			setForm(
				normalizeForm({
					contractNumber: customer.contractNumber,
					customerId: customer.customerId,
					name: customer.name,
					companyName: customer.companyName,
					taxId: customer.taxId,
					city: customer.city,
					address: customer.address,
					email: customer.email,
					effectiveDate: new Date(),
					effectiveUntil: customer?.effectiveUntil ? new Date(customer?.effectiveUntil) : new Date(),
					reason: customer?.effectiveUntil ?? undefined,
				}),
			);
		}
	}, [customer]);

	useDeepCompareEffect(() => {
		if (form?.effectiveDate) {
			const rawHistoryByEffectiveDate = history.find((it) => {
				return isBefore(new Date(it.effectiveDate), form.effectiveDate as Date) || isEqual(new Date(it.effectiveDate), form.effectiveDate as Date);
			});
			if (rawHistoryByEffectiveDate) {
				setForm(
					normalizeForm({
						...rawHistoryByEffectiveDate,
						effectiveDate: form.effectiveDate,
						effectiveUntil: customer?.effectiveUntil ? new Date(customer?.effectiveUntil) : new Date(),
						reason: undefined,
					}),
				);
			}
		}
	}, [form?.effectiveDate]);

	const clearError = (key: keyof ICustomerForm) => {
		setErrors((state) => {
			if (state) {
				return {
					...state,
					[key]: undefined,
				};
			}
			return state;
		});
	};

	const handleChangeForm = (key: keyof ICustomerForm) => (value: string) => {
		const numberKeys: (keyof ICustomerForm)[] = [];
		clearError(key);
		setForm((state) => ({
			...state,
			[key]: numberKeys.includes(key) && isSetString(String(value)) ? Number(value) : value,
		}));
	};

	const handleError = (res: { message: string }) => {
		const errorKeyForLocize = res?.message ? Object.values(ERRORS).find((errName) => res.message === errName) : undefined;
		if (errorKeyForLocize) toast(t(`errors.${errorKeyForLocize}`), { type: 'error' });
		else toast(t('errors:serverError'), { type: 'error' });
		setLoading(false);
	};

	const handleSuccsess = async () => {
		if (isEdit) toast(t('create/edit.edit.success'), { type: 'success' });
		await clientGraphql.clearStore();
		onClose();
	};

	const validationForm = (): boolean => {
		const skipFields: (keyof ICustomerForm)[] = ['effectiveDate', 'effectiveUntil', 'openDataBotSyncStatus'];
		const errorsValidation: ErrorsType<ICustomerForm> = Object.entries(form).reduce((result, [key, value]) => {
			if (skipFields.includes(key as keyof ICustomerForm)) return result;
			if (key && (!value || !isSetString(String(value)))) {
				return {
					...result,
					[key]: t('validation:errors.required'),
				};
			}
			if (key === 'email' && !isValidEmail(value)) {
				return {
					...result,
					[key]: t('validation:errors.email'),
				};
			}
			if (key === 'city' && !isLengthString(value, 3)) {
				return {
					...result,
					[key]: t('validation:errors.iso', { count: 3 }),
				};
			}
			return result;
		}, {});
		if (Object.keys(errorsValidation).length) {
			setErrors(errorsValidation);
			return false;
		}
		return true;
	};

	const handleSubmit = () => {
		setErrors(undefined);
		if (!validationForm()) {
			return;
		}
		setLoading(true);
		if (isEdit) {
			return editCustomer(
				{
					...form,
					openDataBotSyncStatus: undefined,
				},
				refetchQueries,
			)
				.then(handleSuccsess)
				.catch(handleError);
		}
	};

	const getStatusResult = (data: IResponseOpenData['findSubjectMonitoringData']) => {
		const changeName = handleChangeForm('companyName');
		const changeStatus = handleChangeForm('openDataBotSyncStatus');
		try {
			switch (data?.syncStatus) {
				case OpenDataSyncStatuses.SUCCESS: {
					changeName(data.companyName);
					return toast(t('successChangeTaxId'), { type: 'success' });
				}
				case OpenDataSyncStatuses.NOT_EXECUTED:
				case OpenDataSyncStatuses.NOT_FOUND: {
					return toast(t('wrongToChangeTaxId'), { type: 'error' });
				}
				case OpenDataSyncStatuses.SOMETHING_WENT_WRONG: {
					return toast(t('failedFoundToChangeTaxId'), { type: 'error' });
				}
			}
		} finally {
			changeStatus(data?.syncStatus);
		}
	};

	const fetchOpenDataByTaxId = async (taxId?: string) => {
		const isUA = getCountry() === CountryCodeEnum.UA;
		const isKZ = getCountry() === CountryCodeEnum.KZ;
		const sameTaxId = customer?.taxId === taxId;
		if (!taxId || String(taxId)?.length < taxIdMinLength || !(isUA || isKZ) || sameTaxId) return;
		const res = await fetchOpenDataBotCompany({ variables: { taxId } });
		const data = res?.data?.findSubjectMonitoringData;
		if (!data) return;
		getStatusResult(data);
	};

	const handleChangeDate = (event: Date) => {
		setForm((state) => ({ ...state, effectiveDate: event, effectiveUntil: event }));
	};

	const onBlurFetching = () => fetchOpenDataByTaxId(form.taxId as string);

	return (
		<>
			{loading && <Loader />}
			<Modal
				modalClassName={classes.modal}
				bodyClassName={classes.body}
				title={t('customers:edit.title')}
				open
				onClose={onClose}
				buttons={{
					cancel: {},
					confirm: {
						text: t('create/edit.edit.add'),
						onClick: handleSubmit,
						className: openDataLoading ? classes.loadLock : '',
					},
				}}
			>
				<div className={clsx(classes.row, classes.row_datepicker)}>
					<div className={classes.cell}>
						<Input
							disabled
							value={form?.customerId}
							placeholder="_ _ _ _ _ _"
							label={t('customers:list.customerId')}
							error={errors?.customerId}
							testId={TEST_IDS.CREATE_STORE_ADDRESS_STORE_ADDRESS_ID}
						/>
					</div>
					<div className={clsx(classes.bigCell, classes.flex)}>
						<DatePicker
							label={t('list.effectiveDate')}
							onChange={handleChangeDate}
							datepickerProps={{
								startDate: form?.effectiveDate,
							}}
							error={errors?.effectiveDate}
							testId={TEST_IDS.CREATE_STORE_ADDRESS_DATE}
						/>
					</div>
				</div>
				<div className={classes.divider} />
				<div className={classes.row}>
					<Input
						fullWidth
						value={form?.name}
						onChange={handleChangeForm('name')}
						label={t('customers:list.name')}
						error={errors?.name}
						testId={TEST_IDS.CREATE_STORE_ADDRESS_STORE_NAME}
					/>
				</div>
				<div className={classes.row}>
					<Input
						fullWidth
						value={form?.companyName}
						onChange={handleChangeForm('companyName')}
						label={t('customers:list.companyName')}
						error={errors?.companyName}
						testId={TEST_IDS.CREATE_STORE_ADDRESS_COMPANY_NAME}
					/>
				</div>
				<div className={classes.row}>
					<Input
						fullWidth
						value={form?.taxId}
						onChange={handleChangeForm('taxId')}
						placeholder="_ _ _ _ _ _"
						label={t('list.taxId')}
						error={errors?.taxId}
						testId={TEST_IDS.CREATE_STORE_ADDRESS_TAXT_ID}
						onBlur={onBlurFetching}
					/>
				</div>
				<div className={classes.row}>
					<div className={classes.cell}>
						<Input
							value={form?.city}
							onChange={handleChangeForm('city')}
							label={t('list.city')}
							error={errors?.city}
							testId={TEST_IDS.CREATE_STORE_ADDRESS_CITY}
						/>
					</div>
					<div className={classes.bigCell}>
						<Input
							value={form?.address}
							onChange={handleChangeForm('address')}
							label={t('list.address')}
							error={errors?.address}
							testId={TEST_IDS.CREATE_STORE_ADDRESS_ADDRESS}
						/>
					</div>
				</div>
				<div className={classes.row}>
					<Input
						fullWidth
						value={form?.contractNumber}
						onChange={handleChangeForm('contractNumber')}
						label={t('list.partnerContractNumber')}
						error={errors?.contractNumber}
						testId={TEST_IDS.CREATE_STORE_ADDRESS_PARTNER_CONTRACT_NUMBER}
					/>
				</div>
				<div className={classes.row}>
					<Input
						fullWidth
						value={form?.email}
						onChange={handleChangeForm('email')}
						placeholder="name@example.com"
						label={t('list.email')}
						error={errors?.email}
						testId={TEST_IDS.CREATE_STORE_ADDRESS_EMAIL}
					/>
				</div>
				<div className={classes.row}>
					<Input
						fullWidth
						value={form?.reason}
						onChange={handleChangeForm('reason')}
						label={t('list.reason')}
						error={errors?.reason}
						testId={TEST_IDS.CREATE_STORE_ADDRESS_REASON}
					/>
				</div>
			</Modal>
		</>
	);
};

export default CreateEditCustomer;
