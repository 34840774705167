import { prefixApiUrl } from 'api';
import { APP_PREFIX } from 'const';
import storage from 'helpers/localStorage';
import cookie from 'js-cookie';

const TOKEN_KEY = 'token';
const REFRESH_TOKEN_KEY = 'refreshToken';
const TOKEN_KEY_PARENT = 'tokenParent';
const REFRESH_TOKEN_KEY_PARENT = 'refreshTokenParent';

const TOKEN_KEY_TEMP = `${APP_PREFIX}tokenTemp`;
const REFRESH_TOKEN_KEY_TEMP = `${APP_PREFIX}refreshTokenTemp`;

export const hasToken = (): boolean => {
	return !!storage.getItem(TOKEN_KEY);
};

export const setToken = (token: string) => {
	storage.setItem(TOKEN_KEY, token);
};

export const getToken = async (): Promise<string | undefined> => {
	return new Promise((resolve) => {
		resolve(storage.getItem(TOKEN_KEY));
	});
};

export const getTokenSync = (): string | undefined => storage.getItem(TOKEN_KEY);

export const removeToken = () => {
	storage.removeItem(TOKEN_KEY);
};

export const hasRefreshToken = () => {
	return !!storage.getItem(REFRESH_TOKEN_KEY);
};

export const setRefreshToken = (token: string) => {
	storage.setItem(REFRESH_TOKEN_KEY, token);
};

export const getRefreshToken = (): string | undefined => {
	return storage.getItem(REFRESH_TOKEN_KEY);
};

export const removeRefreshToken = () => {
	storage.removeItem(REFRESH_TOKEN_KEY);
};

interface IResponseRefresh {
	jwt: string;
}

export const refreshToken = (): Promise<string | undefined> => {
	return new Promise((resolve, reject) => {
		const token = getRefreshToken();
		fetch(`${prefixApiUrl}/refreshToken`, {
			method: 'post',
			headers: {
				authorization: token ? `Bearer ${token}` : '',
			},
		})
			.then(async (res) => {
				try {
					const { jwt }: IResponseRefresh = await res.json();
					setToken(jwt);
					resolve(jwt);
				} catch (_) {
					throw new Error("Can't refresh token");
				}
			})
			.catch(() => reject(undefined));
	});
};

export const setTokenParent = () => {
	storage.setItem(TOKEN_KEY_PARENT, getTokenSync());
};

export const setRefreshTokenParent = () => {
	storage.setItem(REFRESH_TOKEN_KEY_PARENT, getRefreshToken());
};

export const getTokenParent = (): string | undefined => {
	return storage.getItem(TOKEN_KEY_PARENT);
};

export const getRefreshTokenParent = (): string | undefined => {
	return storage.getItem(REFRESH_TOKEN_KEY_PARENT);
};

export const removeTokenParent = (): void => {
	storage.removeItem(TOKEN_KEY_PARENT);
};

export const removeRefreshTokenParent = (): void => {
	storage.removeItem(REFRESH_TOKEN_KEY_PARENT);
};

export const backTokensFromParent = () => {
	const token = getTokenParent();
	if (token) setToken(token);
	const refreshTokenValue = getRefreshTokenParent();
	if (refreshTokenValue) setRefreshToken(refreshTokenValue);
	removeTokenParent();
	removeRefreshTokenParent();
};

export const saveTempTokens = () => {
	const token = getTokenSync();
	const domain = process.env.NODE_ENV === 'production' ? '.glovoapp.com' : undefined;
	if (token) {
		cookie.set(TOKEN_KEY_TEMP, token, {
			domain,
		});
	}
	const tokenRefresh = getRefreshToken();
	if (tokenRefresh) {
		cookie.set(REFRESH_TOKEN_KEY_TEMP, tokenRefresh, {
			domain,
		});
	}
};

export const readTempTokens = (): Promise<boolean> => {
	return new Promise((resolve) => {
		const token = cookie.get(TOKEN_KEY_TEMP);
		const domain = process.env.NODE_ENV === 'production' ? '.glovoapp.com' : undefined;
		if (token) {
			setToken(token);
			cookie.remove(TOKEN_KEY_TEMP, {
				domain,
			});
		}
		const tokenRefresh = cookie.get(REFRESH_TOKEN_KEY_TEMP);
		if (tokenRefresh) {
			setRefreshToken(tokenRefresh);
			cookie.remove(REFRESH_TOKEN_KEY_TEMP, {
				domain,
			});
		}
		setTimeout(() => {
			resolve(true);
		}, 0);
	});
};
