import React from 'react';
import { Route } from 'react-router-dom';
import LayoutMain from 'layouts/main';

import { IProps } from './types';

const RouteWithLayout: React.FC<IProps> = ({ layout: Layout = LayoutMain, ...props }) => (
	<Layout>
		<Route {...props} />
	</Layout>
);

export default RouteWithLayout;
