import { gql } from '@apollo/client';

export const GET_STORE_ADRESSES = gql`
	query StoreAddresses($page: Int, $pageSize: Int, $filter: StoreAddressFilter) {
		storeAddresses(page: $page, pageSize: $pageSize, filter: $filter) {
			lastStoreAddressValuesDtos {
				id
				storeAddressId
				storeName
				address
				companyName
				taxId
				partnerCommissionPercentage
				accountManagerName
				email
				email2
				temporary
				accountingType
				storesId
				city
				effectiveDate
				createdAt
				incidentsPaidByGlovo
				partnerContractNumber
				category
				gen
				promoCalculationType
				promoProductCalculationType
				status
				agreementType
				pickUpCommissionPercentage
				openDataBotSyncStatus
				legalAddress
				iban
			}
			total
			totalPages
		}
	}
`;

export const GET_STORE_ADRESS_HISTORY = gql`
	query StoreAddressHistory($storeAddressId: Int) {
		storeAddressHistory(storeAddressId: $storeAddressId) {
			id
			storeAddressId
			storeName
			address
			companyName
			taxId
			partnerCommissionPercentage
			accountManagerName
			email
			email2
			createdByEmail
			createdAt
			temporary
			accountingType
			effectiveDate
			storesId
			city
			incidentsPaidByGlovo
			partnerContractNumber
			reason
			promoCalculationType
			promoProductCalculationType
			agreementType
			pickUpCommissionPercentage
			openDataBotSyncStatus
			legalAddress
			iban
		}
	}
`;

export const CREATE_STORE_ADRESS = gql`
	mutation CreateStoreAddress($input: StoreAddressInputDto!) {
		createStoreAddress(input: $input) {
			id
			storeAddressId
			storeName
			address
			companyName
			taxId
			partnerCommissionPercentage
			accountManagerName
			email
			email2
			createdByEmail
			createdAt
			temporary
			accountingType
			incidentsPaidByGlovo
			partnerContractNumber
			reason
			promoCalculationType
			promoProductCalculationType
			agreementType
			pickUpCommissionPercentage
		}
	}
`;

export const GET_STORE_ADDRESSES_DATA = gql`
	query StoreAddressData($storeAddressIds: [Int]) {
		storeAddressData(storeAddressIds: $storeAddressIds) {
			id
			storeName
			address
			storeAddressId
		}
	}
`;

export const GET_OPEN_DATA_BOT_COMPANY = gql`
	query FindSubjectMonitoringData($taxId: String) {
		findSubjectMonitoringData(taxId: $taxId) {
			companyName
			syncStatus
			legalAddress
		}
	}
`;

export const GET_STORE_DATA_BY_TAX_ID = gql`
	query storeAddressByTaxId($taxIds: [String]) {
		storeAddressByTaxId(taxIds: $taxIds) {
			taxId
			storeAddressIds
		}
	}
`;

export const EDIT_STORE_ADRESS = gql`
	mutation EditStoreAddress($input: StoreAddressInputDto!) {
		editStoreAddress(input: $input) {
			id
			storeAddressId
			storeName
			address
			companyName
			taxId
			partnerCommissionPercentage
			accountManagerName
			email
			email2
			createdByEmail
			createdAt
			temporary
			accountingType
			incidentsPaidByGlovo
			partnerContractNumber
			reason
			promoCalculationType
			promoProductCalculationType
			agreementType
			pickUpCommissionPercentage
			iban
		}
	}
`;

export const DELETE_STORE_ADRESS = gql`
	mutation DeleteStoreAddress($storeAddressId: Int, $date: Date) {
		deleteStoreAddress(storeAddressId: $storeAddressId, date: $date) {
			id
			storeAddressId
			storeName
			address
			companyName
			taxId
			partnerCommissionPercentage
			accountManagerName
			email
			email2
			createdByEmail
			createdAt
			temporary
			accountingType
			incidentsPaidByGlovo
			partnerContractNumber
			reason
			promoCalculationType
			promoProductCalculationType
			agreementType
			pickUpCommissionPercentage
		}
	}
`;
