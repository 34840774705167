import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationAuthOtherUser from 'containers/NotificationAuthOtherUser';
import clsx from 'helpers/clsx';
import { formatDate } from 'helpers/index';
import { useExport } from 'hooks/useExport';
import { ReactComponent as FirstIcon } from 'static/images/first.svg';
import { ReactComponent as InfoIcon } from 'static/images/info.svg';
import { ReactComponent as SecondIcon } from 'static/images/second.svg';
import { ReactComponent as WarnIcon } from 'static/images/warn.svg';

import { DatePicker } from 'components/FormControl';
import Loader from 'components/Loader';
import MainButton from 'components/MainButton';

import classes from './Report.module.scss';

const Report = () => {
	const { t } = useTranslation(['report', 'common']);
	const [selectedStartDate, setStartDate] = useState<Date>(new Date());

	const [isSubmit, setIsSubmit] = useState<boolean>(false);
	const { onload, exportOrderReport, isError } = useExport();

	const handleChangeStartDate = (newDate: Date) => {
		setIsSubmit(false);
		setStartDate(newDate);
	};

	const handleSubmit = () => {
		setIsSubmit(true);
		const [, month, year] = formatDate(selectedStartDate).split('.');
		const formatedStartDate = { month: month.replace(/0/g, ''), year };
		exportOrderReport(formatedStartDate);
	};

	return (
		<div className={classes.root}>
			<NotificationAuthOtherUser />
			{onload && <Loader />}
			<div className={classes.title}>{t('title')}</div>
			<div className={classes.container}>
				<div className={classes.point}>
					<FirstIcon />
					<p className={classes.point_text}>{t('subtitle')}</p>
				</div>
				<div className={classes.container_form}>
					<DatePicker
						showMonthYearPicker
						onChange={handleChangeStartDate}
						datepickerProps={{
							startDate: selectedStartDate,
						}}
						format="LLLL yyyy"
						rootClassName={classes.datePicker}
						calendarClassName={classes.calendarClassName}
					/>
					<MainButton className={classes.btn} onClick={handleSubmit}>
						{t('generate')}
					</MainButton>
				</div>
				<div className={classes.point}>
					<SecondIcon className={clsx({ [classes.point_second]: isSubmit })} />
					{isSubmit && (
						<div>
							{isError ? (
								<div className={clsx(classes.point_info_block, classes.point_info_block_error)}>
									<WarnIcon className={classes.warn} />
									<p>{t('error')}</p>
								</div>
							) : (
								<div className={clsx(classes.point_info_block, classes.point_info_block_success)}>
									<InfoIcon />
									<p>{t('success')}</p>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Report;
