import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from 'const';
import { useAuth } from 'hooks/useAuth';
import { ReactComponent as Background } from 'static/images/login-bg.svg';
import { ReactComponent as Logo } from 'static/images/Logo.svg';

import classes from './LoginLayout.module.scss';

const LoginLayout: React.FC = ({ children }) => {
	const { isAuth } = useAuth();
	const history = useHistory();

	useEffect(() => {
		if (isAuth) history.replace(Routes.ORDERS);
	}, [isAuth]);

	return (
		<div className={classes.root}>
			<div className={classes.body}>
				<Logo className={classes.logo} />
				<Background className={classes.background} />
				<div className={classes.form}>{children}</div>
			</div>
		</div>
	);
};

export default LoginLayout;
