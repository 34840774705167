import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DebtTable from 'containers/Accounts/DebtTable/DebtTable';
import AccountsFilters from 'containers/Accounts/Filters/InvoiceFilters';
import InvoiceTable from 'containers/Accounts/InvoiceTable';
import PaymentTable from 'containers/Accounts/PaymentTable';
import NotificationAuthOtherUser from 'containers/NotificationAuthOtherUser';
import clsx from 'helpers/clsx';
import { useInvoices } from 'hooks/useInvoices';
import { TEST_IDS } from 'tests/config';

import Loader from 'components/Loader';

import classes from './Accounts.module.scss';

const Accounts = () => {
	const { t } = useTranslation('accounts');
	const { onload, wasLoaded, filter, invoicesPagionation, paymentsPagionation, fetchInvoices, fetchPayments } = useInvoices();
	const [activeTab, setActiveTab] = useState(false);

	const handleActive = (param: boolean) => () => setActiveTab(param);

	const emptyFilter = (): boolean => {
		const emptyFilterCondition = filter.edrpou || filter.contractNumber || (activeTab ? filter.status : filter.statuses?.length);
		if (emptyFilterCondition) return false;
		return true;
	};

	useEffect(() => {
		if (activeTab) return fetchPayments();
		fetchInvoices();
	}, [filter, invoicesPagionation, paymentsPagionation, activeTab]);

	return (
		<div className={classes.root} data-testid={TEST_IDS.ACCOUNTS_PAGE}>
			<NotificationAuthOtherUser />
			<DebtTable />
			<div className={classes.paymentsTable}>
				<div className={classes.tabs}>
					<button onClick={handleActive(false)} className={clsx({ [classes.active]: !activeTab })}>
						{t('invoiceTab')}
					</button>
					<button onClick={handleActive(true)} className={clsx({ [classes.active]: activeTab })}>
						{t('paymentTab')}
					</button>
				</div>
				<AccountsFilters activeTab={activeTab} />
				{onload && <Loader />}
				{wasLoaded && activeTab ? <PaymentTable emptyFilter={emptyFilter()} /> : <InvoiceTable emptyFilter={emptyFilter()} />}
			</div>
		</div>
	);
};

export default Accounts;
