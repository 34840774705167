import React from 'react';
import { useTranslation } from 'react-i18next';
import { vchasnoLinkRegExp } from 'const';
import { getNewestContract, normilizePartnerContractInput } from 'helpers/partnersFile';
import { isUrlValid } from 'helpers/validation';
import { usePartnersFile } from 'hooks/usePartnersFile';
import { IFormEdit } from 'interfaces/partnersFile';
import { isEqual } from 'underscore';

import { Checkbox, Input, Select } from 'components/FormControl';
import { IPropsSelect } from 'components/FormControl/types';
import Modal from 'components/modals/Modal';

import { IPropsTemplate } from './types';

import classes from './CreateContract.module.scss';

const initContractForm = {
	taxId: '',
	contractLink: '',
	contractFormat: 0,
};

const initVersionForm = {
	...initContractForm,
	fillDataFromPreviusVersion: true,
	contractNumberBase: '',
};

const contractFormatEnum = {
	electronic: 1,
	paper: 2,
};

const CreateContract: React.FC<IPropsTemplate> = ({ onClose, contractNumber }) => {
	const { t } = useTranslation(['partners', 'form']);
	const { createContract, items, dictionaries } = usePartnersFile();
	const [form, setForm] = React.useState<IFormEdit>(contractNumber ? initVersionForm : initContractForm);

	const isValidFields = () => {
		if (!isUrlValid(form.contractLink) || !form.contractFormat || form.taxId.length < 7) return false;
		if (form.contractFormat === contractFormatEnum.electronic) {
			const regex = new RegExp(vchasnoLinkRegExp);
			return regex.test(form.contractLink);
		}
		if (form.contractFormat === contractFormatEnum.paper) {
			return form.contractLink.includes('https://drive.google.com/');
		}
		return true;
	};

	const handleChangeForm = (key: keyof IFormEdit) => (value: IPropsSelect['value']) => {
		setForm({ ...form, [key]: value });
	};

	const toggleCheckBox = () => setForm({ ...form, fillDataFromPreviusVersion: !form.fillDataFromPreviusVersion });

	const handleSubmit = async () => {
		let formData = { ...form };
		const contractNumberBase = baseContract?.contractNumberBase;
		if (contractNumberBase) {
			if (form.fillDataFromPreviusVersion) {
				const prevVersion = getNewestContract(items.filter((item) => item.contractNumberBase === contractNumberBase));
				formData = {
					...formData,
					pickUpCommission: prevVersion.pickUpCommission,
					exclusivity: prevVersion.exclusivity,
					invoicing: prevVersion.invoicing,
					paymentTerm: prevVersion.paymentTerm,
					responsibleSales: prevVersion.responsibleSales,
					responsibleAccountManager: prevVersion.responsibleAccountManager,
					names: prevVersion.names,
					contractType: prevVersion.contractType,
					paymentType: +prevVersion.paymentType,
					contractVersion: prevVersion.contractVersion,
					commission: prevVersion.commission.map((field) => {
						const f = { ...field };
						delete f.__typename;
						return f;
					}),
					trial: prevVersion.trial,
					platformFee: prevVersion.platformFee,
					isCommissionValid: prevVersion.isCommissionValid,
				};
			}
		}
		const res = await createContract(normilizePartnerContractInput({ ...formData, contractNumberBase }));
		if (res) onClose();
	};

	const baseContract = items.find((item) => item.contractNumber === contractNumber);

	return (
		<Modal
			modalClassName={classes.modal}
			title={t(contractNumber ? 'newVersionOfContract' : 'newContract') + ` ${contractNumber ? baseContract?.contractNumberBase : ''}`}
			open
			onClose={onClose}
			buttons={{
				cancel: {},
				confirm: {
					text: t('save'),
					onClick: handleSubmit,
					disabled: !(isValidFields() && !isEqual([form], contractNumber ? [initVersionForm] : [initContractForm])),
				},
			}}
		>
			<div className={classes.newContract}>
				<Input fullWidth value={form.taxId} label={t('fields.taxId')} onChange={handleChangeForm('taxId')} />
				<Select
					withoutClear
					fullWidth
					value={form.contractFormat}
					rootClassName={classes.contractFormat}
					label={t('fields.contractFormat')}
					onChange={handleChangeForm('contractFormat')}
					options={dictionaries.find((d) => d.type === 'contractFormat')?.data.map((option) => ({ label: option.value, value: option.id })) || []}
				/>
				<Input fullWidth value={form.contractLink} label={t('fields.contractLink')} onChange={handleChangeForm('contractLink')} />
				{contractNumber && (
					<Checkbox isChecked={form.fillDataFromPreviusVersion || false} onChange={toggleCheckBox}>
						{t('fillDataFromPreviusVersion')}
					</Checkbox>
				)}
			</div>
		</Modal>
	);
};

export default CreateContract;
