import { IFiltration } from 'interfaces/partnersFile';
import { Column } from 'pages/PartnersFile/types';

export interface IProps {
	columns: Column[];
}

export const OPERATORS = {
	EQUALS: 'EQUALS',
	CONTAINS: 'CONTAINS',
	IS_EMPTY: 'IS_EMPTY',
	IS_NOT_EMPTY: 'IS_NOT_EMPTY',
};

export const HAS_OPERATOR = [
	'names',
	'comment',
	'contractName',
	'contractLink',
	'contractPaperStatus',
	'taxId',
	'iban',
	'platformFee',
	'weeklyInvoicingLink',
	'activationFee',
	'pickUpCommission',
	'totRegistration',
	'responsibleSales',
	'responsibleAccountManager',
	'commission',
	'legalName',
];

export interface IFilterItem {
	id: number;
	key: keyof IFiltration;
	value?: string | boolean | string[];
	operator?: keyof typeof OPERATORS;
}
