import { IFilterItem, OPERATORS } from 'containers/PartnerFilterSelect/types';
import { IFiltration } from 'interfaces/partnersFile';

export interface IProps {
	currentFilter: IFilterItem;
	onChangeKey(selectedValue: keyof IFiltration): void;
	onChangeOperator(selectedValue: keyof typeof OPERATORS): void;
	keyOption: {
		value: string;
		label: string;
	}[];
	onChangeValue(selectedValue: string | boolean | string[]): void;
	onRemove(): void;
	keyLabel: string;
	valueLabel: string;
}

export const booleanFields = ['exclusivity', 'autoProlongation', 'trial', 'totStatus', 'additionAgreementForPromo', 'promoWithoutAdditionAgreement'];
export const DateComponent = ['Date', 'openDataBotLastUpdate'];
