import { Column } from './types';

export const columns: Column[] = [
	{
		field: 'contractNumber',
		headerName: 'fields.contractNumber',
		width: 130,
	},
	{
		field: 'payingBulkStatus',
		headerName: 'fields.payingBulkStatus',
		component: 'select',
		width: 130,
	},
	{
		field: 'reasonDoNotPay',
		headerName: 'fields.reasonDoNotPay',
		width: 200,
		component: 'select',
	},
	{ field: 'names', headerName: 'fields.names', width: 160, component: 'input' },
	{ field: 'storeAddresses', headerName: 'fields.storeAddresses', width: 160, component: 'storeAddresses' },
	{ field: 'comment', headerName: 'fields.comment', width: 150, component: 'comment' },
	{ field: 'contractName', headerName: 'fields.contractName', width: 220, component: 'input' },
	{
		field: 'contractFormat',
		headerName: 'fields.contractFormat',
		component: 'select',
		width: 130,
	},
	{
		field: 'contractLink',
		headerName: 'fields.contractLink',
		width: 180,
		component: 'input',
		link: true,
	},
	{ field: 'contractStatus', headerName: 'fields.status', component: 'status', width: 270 },
	{ field: 'contractDate', headerName: 'fields.contractDate', component: 'date', width: 130 },
	{ field: 'contractEndDate', headerName: 'fields.contractEndDate', component: 'date', width: 130 },
	{ field: 'autoProlongation', headerName: 'fields.autoprolongation', component: 'checkbox' },
	{ field: 'allDocuments', headerName: 'fields.allDocuments', component: 'documents', width: 260 },
	{ field: 'contractType', headerName: 'fields.contractType', component: 'select' },
	{ field: 'paymentType', headerName: 'fields.paymentType', component: 'select' },
	{ field: 'contractVersion', headerName: 'fields.contractVersion', component: 'select' },
	{ field: 'contractPaperStatus', headerName: 'fields.contractPaperStatus', component: 'input' },
	{ field: 'legalName', headerName: 'fields.legalName', width: 370, component: 'input', showAll: true },
	{
		field: 'taxId',
		headerName: 'fields.taxId',
		component: 'input',
		pattern: /^[0-9]{1,10}$/,
	},
	{ field: 'iban', headerName: 'fields.IBAN', width: 265, copy: true, component: 'input' },
	{ field: 'ibanStatus', headerName: 'fields.iBAN1Cstatus', component: 'select' },
	{ field: 'commission', headerName: 'fields.commission', component: 'commission', width: 230 },
	{ field: 'pickUpCommission', headerName: 'fields.pickUpCommission', component: 'input', pattern: /^[0-9]{1,4}(\.\d{0,2})?$/ },
	{ field: 'exclusivity', headerName: 'fields.exclusivity', component: 'checkbox' },
	{ field: 'trial', headerName: 'fields.trial', width: 50, component: 'checkbox' },
	{ field: 'platformFee', headerName: 'fields.platformFee', component: 'input', pattern: /^[0-9]{1,9}$/ },
	{ field: 'platformFeeCurrentPayment', headerName: 'fields.platformFeeCurrentPayment', component: 'select' },
	{ field: 'activationFee', headerName: 'fields.activationFee', component: 'input', pattern: /^[0-9]{1,9}$/ },
	{ field: 'invoicing', headerName: 'fields.invoicing', component: 'select' },
	{ field: 'paymentTerm', headerName: 'fields.paymentTerms', component: 'select' },
	{
		field: 'weeklyInvoicingLink',
		headerName: 'fields.weeklyInvoicingLink',
		width: 180,
		component: 'input',
		link: true,
	},
	{ field: 'weeklyInvoicingStatus', headerName: 'fields.weeklyInvoicingStatus', component: 'select', width: 170, disabled: true },
	{ field: 'weeklyInvoicingDate', headerName: 'fields.weeklyInvoicingDate', component: 'date' },
	{ field: 'lastWarrantyLetterDate', headerName: 'fields.lastGarantieLetterDate', component: 'date' },
	{ field: 'totRegistration', headerName: 'fields.totRegistration' },
	{ field: 'totStatus', headerName: 'fields.totStatus', component: 'checkbox', disabled: true },
	{ field: 'openDataBotLastUpdate', headerName: 'fields.openDataBotLastUpdate', component: 'date', width: 140, disabled: true },
	{ field: 'openDataBotStatus', headerName: 'fields.openDataBotStatus', component: 'select', width: 170, disabled: true },
	{ field: 'responsibleSales', headerName: 'fields.responsibleSales', component: 'input' },
	{ field: 'responsibleAccountManager', headerName: 'fields.responsibleAccountManager', component: 'input' },
	{ field: 'additionAgreementForPromo', headerName: 'fields.additionAgreementForPromo', component: 'checkbox' },
	{ field: 'additionAgreementForPromoDate', headerName: 'fields.additionAgreementForPromoDate', component: 'date' },
	{ field: 'promoWithoutAdditionAgreement', headerName: 'fields.promoWithoutAdditionAgreement', component: 'checkbox' },
	{ field: 'promoWithoutAdditionAgreementDate', headerName: 'fields.promoWithoutAdditionAgreementDate', component: 'date' },
];
