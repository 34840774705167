import { useEffect, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetch } from 'api';
import { Routes } from 'const';
import { addDays } from 'date-fns';
import localStorage from 'helpers/localStorage';
import { useQuery } from 'hooks/useQuery';
import { IAnswer, IQuestionDTO } from 'interfaces/contractGeneration';
import { ReactComponent as ArrowRightIcon } from 'static/images/arrow_right.svg';
import { ReactComponent as EditIcon } from 'static/images/edit.svg';
import { ReactComponent as InfoIcon } from 'static/images/info.svg';
import { TEST_IDS } from 'tests/config';
import { sortBy } from 'underscore';

import { BackToContractList } from 'components/Contract/BackToContractList';
import { Checkbox, Radio } from 'components/FormControl';
import Loader from 'components/Loader';
import MainButton from 'components/MainButton';
import Confirm from 'components/modals/Confirm';
import Modal from 'components/modals/Modal';

import classes from './Questionnaire.module.scss';

const Questionnaire: React.FC = () => {
	const { t } = useTranslation(['contractGeneration', 'errors']);
	const query = useQuery();
	const template = query.get('template');
	const agreementId = query.get('id');
	const [questions, setQuestions] = useState<IQuestionDTO[]>([]);
	const [alreadyHasTemplate, setAlreadyHasTemplate] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);
	const currentQuestion = questions?.length > 0 ? questions[questions.length - 1] : null;
	const isFinish = currentQuestion?.question?.questionBody.find((a) => a.selected)?.lastAnswer;
	const history = useHistory();
	const selectedCount = currentQuestion?.question?.questionBody.filter((q) => q.selected).length || (0 as number);
	const [file, setFile] = useState<File | null>(null);
	const [zeroBookmark, setZeroBookmark] = useState(false);

	const isAlreadyHasTemplate: () => Promise<string | IQuestionDTO[] | null | undefined> = async () =>
		template && (await localStorage.getItem(template));

	const configure = async (answer?: IQuestionDTO) => {
		try {
			setLoading(true);
			const formData = new FormData();
			if (file) {
				formData.append('file', file);
			}
			if (answer) {
				delete answer.createdAt;
				formData.append('json', JSON.stringify(answer));
			} else {
				formData.append('json', JSON.stringify({ template }));
			}
			const { body }: { body: IQuestionDTO } = await fetch(
				'/agreement/configure',
				{
					method: 'post',
					body: formData,
					fintoolContracts: true,
				},
				false,
			);
			if (body.question) {
				const questionnaire = answer ? [...questions, body] : ([body] as IQuestionDTO[]);
				setQuestions(questionnaire);
				if (template) localStorage.setItem(body.template, questionnaire);
			}
			if (body.finish) {
				if (template) localStorage.removeItem(template);
				history.push(Routes.DOCUMENT_PREVIEW.replace(':id', body.agreementId));
			}
			setLoading(false);
		} catch {
			toast(t('errors:somethingWentWrong'), { type: 'error' });
		} finally {
			setLoading(false);
		}
	};

	const fetchAgreementConfigure = async (id: string) => {
		setLoading(true);
		const { body }: { body: IQuestionDTO[] } = await fetch(`/agreement/getConfigurationSteps/id/${id}`, { fintoolContracts: true }, false);
		if (body) {
			setQuestions(body.sort((a, b) => new Date(a.createdAt as string).getTime() - new Date(b.createdAt as string).getTime()));
		}
		setLoading(false);
	};

	useEffect(() => {
		if (template) {
			isAlreadyHasTemplate().then((res) => {
				if (res) {
					const firstItem = res[0] as IQuestionDTO;
					const expiredAt = addDays(new Date(firstItem.createdAt as string), 2).getTime();
					const todayTime = new Date().getTime();
					if (todayTime > expiredAt && template) {
						localStorage.removeItem(template);
						return configure();
					} else {
						return setAlreadyHasTemplate(true);
					}
				} else {
					configure();
				}
			});
		}
	}, [template]);

	useEffect(() => {
		if (agreementId) {
			fetchAgreementConfigure(agreementId);
		}
	}, [agreementId]);

	if (!query) return null;

	const handleChangeAnswer = (key: string, multiple?: boolean) => () => {
		setQuestions(
			questions.map((q) => {
				if (q.question?.questionNumber === currentQuestion?.question?.questionNumber) {
					return {
						...q,
						question: {
							...q.question,
							questionBody: q.question?.questionBody.map((a) =>
								multiple ? (a.key === key ? { ...a, selected: !a.selected } : a) : { ...a, selected: a.key === key },
							),
						},
					};
				}
				return q;
			}) as IQuestionDTO[],
		);
	};

	const goBack = (index?: number) => {
		const updatedQuestions = questions.slice(0, index ? index : questions.length - 1);
		setQuestions(updatedQuestions);
		if (template) {
			localStorage.setItem(template, updatedQuestions);
		}
	};

	const goNext = () => currentQuestion && configure(currentQuestion);

	const loadTemplate = () => {
		isAlreadyHasTemplate().then((res) => setQuestions(res as IQuestionDTO[]));
		setAlreadyHasTemplate(false);
	};

	const cancel = () => {
		configure();
		setAlreadyHasTemplate(false);
	};

	const generateDocumentNow = async () => {
		const finishCurrentQuestion = { ...currentQuestion, finish: true, addZeroBookmark: zeroBookmark } as IQuestionDTO;
		configure(finishCurrentQuestion);
	};

	const handleLoadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target;
		if (files && files[0]) {
			setFile(files[0]);
		}
	};

	const handleOnZeroBookmark = () => setZeroBookmark(!zeroBookmark);

	return (
		<div className={classes.root}>
			<Modal
				open={alreadyHasTemplate}
				onClose={cancel}
				title={t('localStorage.title')}
				rootClassName={classes.localStorageAsk}
				titleClassName={classes.headline}
				bodyClassName={classes.body}
				hideClose
				closeButtonClassName={classes.closeIcon}
				buttons={{
					cancel: {
						text: t('localStorage.yes'),
						onClick: loadTemplate,
					},
					confirm: {
						text: t('localStorage.no'),
						onClick: cancel,
					},
				}}
			>
				{t('localStorage.desc')}
			</Modal>
			<div className={classes.head}>
				<BackToContractList />
				<h4>{query.get('label')}</h4>
			</div>
			{loading && <Loader />}
			{!!questions?.length && (
				<div className={classes.body}>
					<div className={classes.questions}>
						<div className={classes.question}>
							<div className={classes.question_head}>
								<span className={classes.question_number} data-testid={TEST_IDS.QUESTION_NUMBER}>
									{questions.length}
								</span>
								<span>{currentQuestion?.question?.headerDto.header}</span>
							</div>
							{currentQuestion?.question?.headerDto.explain && <p className={classes.explain}>{currentQuestion?.question?.headerDto.explain}</p>}
						</div>
						<div className={classes.answerList}>
							{!!currentQuestion?.question?.questionBody.length &&
								sortBy(currentQuestion?.question.questionBody, 'priority').map((answer: IAnswer) => (
									<div key={answer.key}>
										{currentQuestion.question?.type === 'CHECK_BOX' ? (
											<Checkbox isChecked={answer.selected} onChange={handleChangeAnswer(answer.key, true)}>
												{answer.value}
											</Checkbox>
										) : (
											<Radio isChecked={answer.selected} onChange={handleChangeAnswer(answer.key)}>
												{answer.value}
											</Radio>
										)}
										{answer.explain && <p className={classes.explain}>{answer.explain}</p>}
									</div>
								))}
						</div>
						<div className={classes.actions}>
							{questions.length !== 1 && (
								<MainButton className={classes.back_button} onClick={goBack}>
									<ArrowRightIcon className={classes.backIcon} />
									{t('backButton')}
								</MainButton>
							)}
							<MainButton className={classes.continue_button} onClick={goNext} disabled={!selectedCount}>
								{t(isFinish ? 'generateDocument' : 'continueButton')} <ArrowRightIcon />
							</MainButton>
							<div className={classes.generation_now}>
								<input onChange={handleLoadFile} type="file" accept=".docx" />
								<Checkbox isChecked={zeroBookmark} onChange={handleOnZeroBookmark}>
									{t('zeroBookmark')}
								</Checkbox>
								<MainButton className={classes.continue_button} onClick={generateDocumentNow}>
									{t('generateDocumentNow')} <ArrowRightIcon />
								</MainButton>
							</div>
						</div>
					</div>
					<div className={classes.all_answers}>
						<div className={classes.notif}>
							<div className={classes.notif_body}>
								<InfoIcon />
								{t('topAlert')}
							</div>
						</div>
						<div className={classes.answerList}>
							{!!questions.length &&
								questions.map((q, index) => {
									const selected = q.question?.questionBody.filter((question) => question.selected);
									return (
										<div className={classes.answer} key={q.question?.questionNumber}>
											<div>
												{index + 1}. {q.question?.headerDto.header}
											</div>
											{selected && questions.length !== index + 1 && (
												<React.Fragment>
													<div className={classes.line} />
													<div className={classes.current_answer}>
														<div>
															{selected.map((a) => (
																<React.Fragment key={a.key}>
																	<span>{a.value}</span>
																	<br />
																</React.Fragment>
															))}
														</div>
														<Confirm
															title={t('returnTitle')}
															body={t('returnDescription')}
															confirm={{
																text: t('editButton'),
																onClick: async () => goBack(index + 1),
															}}
															icon="warning"
														>
															<EditIcon className={classes.current_answer_edit} color="#797A8D" />
														</Confirm>
													</div>
												</React.Fragment>
											)}
										</div>
									);
								})}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Questionnaire;
