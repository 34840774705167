import React from 'react';
import { useTranslation } from 'react-i18next';
import { OPERATORS } from 'containers/PartnerFilterSelect/types';
import { normalizeSingleDate } from 'helpers/invoices';
import { useDocuments } from 'hooks/useDocuments';
import { usePartnersFile } from 'hooks/usePartnersFile';
import { ReactComponent as ClearIcon } from 'static/images/clear.svg';
import { groupBy } from 'underscore';

import { DatePicker, Input, Radio, Select } from 'components/FormControl';
import { ISelectOption } from 'components/FormControl/types';

import { booleanFields, DateComponent, IProps } from './types';

import classes from './PartnerOption.module.scss';

const PartnerOption: React.FC<IProps> = ({
	currentFilter,
	onChangeOperator,
	onChangeKey,
	keyOption,
	onChangeValue,
	onRemove,
	keyLabel,
	valueLabel,
}) => {
	const { dictionaries } = usePartnersFile();
	const { getStatusStyle, getStatusText } = useDocuments();
	const { t } = useTranslation(['common', 'contractGeneration']);
	const selectItem = dictionaries.find((el) => el.type === currentFilter.key);
	const boolSelect = booleanFields.includes(currentFilter.key);

	const getOptionsByKey = (key: string) => {
		const options = Object.keys(OPERATORS);
		if (key === 'commission') {
			return options.filter((o) => o !== 'CONTAINS');
		}
		return options;
	};

	const getComponent = () => {
		if (currentFilter.key === 'contractStatus') {
			const options = groupBy(
				Object.keys(getStatusStyle).map((key) => ({ label: getStatusText(key), value: key })),
				'label',
			);

			return (
				<Select
					emptyLabel={valueLabel}
					label={valueLabel}
					value={currentFilter.value as string}
					onChange={onChangeValue}
					multiple
					portal
					multipleWithLabel
					multipleWithoutButton
					multipleTitle={t('selected')}
					options={Object.keys(options).map((label) => ({ label, value: options[label].map((option: ISelectOption) => option.value)[0] }))}
					rootClassName={classes.portal_select}
				/>
			);
		}

		if (DateComponent.some((s) => currentFilter.key.includes(s))) {
			const handleDate = (data: Date) => {
				onChangeValue(normalizeSingleDate(data));
			};

			return (
				<DatePicker
					datepickerProps={{ startDate: currentFilter.value ? new Date(currentFilter.value as string) : new Date() }}
					label={valueLabel}
					onChange={handleDate}
				/>
			);
		}
		if (boolSelect) {
			const handleChangeRadio = (param: boolean) => () => {
				onChangeValue(param);
			};

			return (
				<div className={classes.radioButton}>
					<Radio isChecked={currentFilter.value === true} onChange={handleChangeRadio(true)}>
						{t('yes')}
					</Radio>
					<Radio isChecked={currentFilter.value === false} onChange={handleChangeRadio(false)}>
						{t('no')}
					</Radio>
				</div>
			);
		}
		if (selectItem) {
			const options = selectItem?.data.map((item) => ({ value: item.id, label: item.value }));
			return (
				<Select
					emptyLabel={valueLabel}
					label={valueLabel}
					value={currentFilter.value as string}
					onChange={onChangeValue}
					multiple
					portal
					multipleWithLabel
					multipleWithoutButton
					rootClassName={classes.portal_select}
					multipleTitle={t('selected')}
					options={options.length ? [...options, { label: t('contractGeneration:empty'), value: 0 }] : []}
				/>
			);
		}
		return (
			<div className={classes.operator}>
				{currentFilter.operator && (
					<Select
						withoutClear
						fullWidth
						portal
						value={currentFilter.operator}
						options={getOptionsByKey(currentFilter.key).map((key) => ({ label: t(`contractGeneration:operator_${key.toLowerCase()}`), value: key }))}
						onChange={onChangeOperator}
						label={t('operator')}
						rootClassName={classes.operator_select}
					/>
				)}
				<Input
					label={valueLabel}
					onChange={onChangeValue as (selectedValue: string) => void}
					value={currentFilter.value as string}
					disabled={currentFilter.operator && [OPERATORS.IS_EMPTY, OPERATORS.IS_NOT_EMPTY].includes(currentFilter.operator)}
				/>
			</div>
		);
	};

	return (
		<div className={classes.optionsSelectors}>
			<Select
				withoutClear
				portal
				onCloseClear={false}
				label={keyLabel}
				emptyLabel={keyLabel}
				value={currentFilter.key}
				onChange={onChangeKey}
				options={keyOption || []}
				rootClassName={classes.portal_select}
			/>
			<div className={classes.secondField}>
				{getComponent()}
				<ClearIcon onClick={onRemove} className="selectOptionRemove" />
			</div>
		</div>
	);
};

export default PartnerOption;
