import { gql } from '@apollo/client';

export const UPDATE_PAYOUT_SCEDULE = gql`
	mutation UpdatePayoutSchedule($input: [PayoutScheduleInputDto]!) {
		updatePayoutSchedule(input: $input) {
			startDate
		}
	}
`;

export const GET_PAYOUT_SCHEDULES = gql`
	query PayoutSchedules($filter: PayoutScheduleFilter) {
		payoutSchedules(filter: $filter) {
			id
			accountingType
			startDate
			endDate
			expectedPaymentDate
			lastPaymentDate
			lastDiffInputDate
		}
	}
`;

export const GET_ACCOUNTING_TYPE = gql`
	query {
		getAccountingType {
			accountingType
		}
	}
`;
