import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'helpers/clsx';
import { useAuth } from 'hooks/useAuth';
import { useUser } from 'hooks/useUser';
import { ReactComponent as TemporaryIcon } from 'static/images/temporary.svg';

import UserEmail from 'components/UserEmail';

import { IProps } from './types';

import classes from './NotificationAuthOtherUser.module.scss';

const NotificationAuthOtherUser: React.FC<IProps> = ({ rootClassName }) => {
	const { user } = useUser();
	const { backToParentAuth } = useAuth();
	const { t } = useTranslation(['authOtherUser']);

	if (!user?.parentUser) return null;

	return (
		<div className={clsx(classes.root, rootClassName)}>
			<TemporaryIcon className={classes.icon} />
			<Trans
				t={t}
				i18nKey="text"
				components={{
					email: <UserEmail textClassName={classes.email_text} rootClassName={classes.email} />,
					button: <button className={classes.button} onClick={backToParentAuth} />,
					underline: <span className={classes.underline} />,
				}}
				values={{
					email: user.email,
					parentEmail: user.parentUser,
				}}
			/>
		</div>
	);
};

export default NotificationAuthOtherUser;
