import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import Template from './template';
import { IProps } from './types';

const Modal: React.FC<IProps> = (props) => {
	const onKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			props.onClose();
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', onKeyDown);
		return () => {
			document.removeEventListener('keydown', onKeyDown);
		};
	});

	if (!props.open) return null;

	return ReactDOM.createPortal(<Template {...props} />, document.body);
};

export default Modal;
