import React, { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { copyText } from 'helpers';
import clsx from 'helpers/clsx';
import { ReactComponent as CopyIcon } from 'static/images/copy.svg';

import { IProps } from './types';

import classes from './CopyButton.module.scss';

const CopyButton: React.ForwardRefRenderFunction<HTMLButtonElement, IProps> = ({ children, text, className, copiedPosition }, _) => {
	const [copied, setCopied] = useState<boolean>(false);
	const { t } = useTranslation('common');

	const handleCopyText = (code: string) => (e: MouseEvent) => {
		e.stopPropagation();
		copyText(code).then(() => setCopied(true));
	};

	useEffect(() => {
		if (copied) setTimeout(() => setCopied(false), 2000);
	}, [copied]);

	return (
		<div onClick={handleCopyText(text)} className={clsx(classes.copyIco, className)}>
			<CopyIcon />
			{copied && <div className={clsx(classes.copied, copiedPosition)}>{t('common:copy')}</div>}
			{children && children}
		</div>
	);
};

export default React.forwardRef(CopyButton);
