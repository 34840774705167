import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_CUSTOMER_HISTORY } from 'api/customers';
import CustomerInfo from 'containers/CustomerInfo';
import NotificationAuthOtherUser from 'containers/NotificationAuthOtherUser';
import clsx from 'helpers/clsx';
import { useCustomers } from 'hooks/useCustomers';
import { useExport } from 'hooks/useExport';
import { ICustomer } from 'interfaces/customer';
import { ReactComponent as CloseIcon } from 'static/images/close.svg';
import { ReactComponent as Empty } from 'static/images/fielder.svg';
import noSelectedAddressSrc from 'static/images/no-selected-address.svg';
import { ReactComponent as PlaceIcon } from 'static/images/place.svg';
import { ReactComponent as SearchIcon } from 'static/images/search.svg';
import { TEST_IDS } from 'tests/config';

import ExportButton from 'components/ExportButton';
import { Input } from 'components/FormControl';
import IconButton from 'components/IconButton';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination';

import classes from './Customers.module.scss';

const Customers: React.FC = () => {
	const { onload, wasLoaded, fetchCustomers, filter, pagination, items, total, changeFilter, setPage, setAbortRef, aborterRef } = useCustomers();
	const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>();
	const { t } = useTranslation(['customers', 'roles', 'form', 'common']);
	const { exportCustomers, onload: onloadExport } = useExport();

	useEffect(fetchCustomers, [fetchCustomers, filter, pagination]);

	const searchCustomer = (value: string) => {
		if (aborterRef && typeof setAbortRef === 'function') {
			aborterRef.abort();
			setAbortRef(new AbortController());
		}
		changeFilter('search', value);
	};

	const start = pagination.page * pagination.pageSize + 1;
	const end = start + pagination.pageSize - 1;

	const handleClickPrev = () => {
		setPage(pagination.page - 1);
	};

	const handleClickNext = () => {
		setPage(pagination.page + 1);
	};

	const renderPagination = () => {
		if (!items.length) return null;
		return (
			<Pagination start={start} end={end} total={total} onClickNext={handleClickNext} onClickPrev={handleClickPrev} className={classes.pagination} />
		);
	};

	const handleCloseSelectedCustomer = () => setSelectedCustomer(undefined);

	useEffect(() => {
		if (selectedCustomer) {
			const it = items.find(({ customerId }) => customerId === selectedCustomer.customerId);
			setSelectedCustomer(it);
		}
	}, [items, selectedCustomer]);

	const handleSelectCustomer = (idCustomer: ICustomer['id']) => () => {
		if (idCustomer === selectedCustomer?.customerId) {
			return setSelectedCustomer(undefined);
		}
		const it = items.find(({ customerId }) => customerId === idCustomer);
		setSelectedCustomer(it);
	};

	return (
		<div className={classes.root} data-testid={TEST_IDS.STORE_ADDRESSES_PAGE}>
			<div className={classes.body}>
				<NotificationAuthOtherUser />
				<div className={classes.title}>{t('title')}</div>
				<div className={clsx(classes.flex, classes.justifyContentBetween, classes.nav)}>
					<Input
						onChange={searchCustomer}
						value={filter.search}
						placeholder={t('search')}
						rootClassName={classes.inputRoot}
						controlClassName={classes.input}
						Icon={SearchIcon}
						positionIcon="right"
						delayOnChange={500}
					/>
				</div>
				{onload && !wasLoaded ? (
					<Loader simple />
				) : (
					<>
						{(onload || onloadExport) && <Loader />}
						<table className={classes.items}>
							<thead>
								<tr>
									<th>{t('fields.customerName')}</th>
									<th>Id</th>
									<th className={classes.address}>{t('fields.address')}</th>
									<th>
										{items.length > 0 && (
											<ExportButton onClick={exportCustomers} className={classes.export}>
												{t('export')}
											</ExportButton>
										)}
									</th>
								</tr>
							</thead>
							<tbody>
								{!items.length ? (
									<tr className={classes.empty}>
										<td colSpan={4}>
											<div className={classes.inner}>
												<Empty />
												<div>{t('emptyFilters')}</div>
											</div>
										</td>
									</tr>
								) : (
									<>
										{items.map((item) => {
											const row = (
												<>
													<td>
														<div className={clsx(classes.flex, classes.alignItemsCenter)}>{item.name}</div>
													</td>
													<td className={classes.id}>{item.customerId}</td>
													<td colSpan={2} className={classes.address}>
														{item.address}
													</td>
												</>
											);
											return (
												<React.Fragment key={item.id}>
													<tr className={classes.spacer}>
														<td colSpan={3} />
													</tr>
													<tr
														onClick={handleSelectCustomer(item.customerId)}
														className={clsx({
															[classes.selected]: item.customerId === selectedCustomer?.customerId,
														})}
													>
														{row}
													</tr>
												</React.Fragment>
											);
										})}
									</>
								)}
							</tbody>
						</table>
					</>
				)}
				{renderPagination()}
			</div>
			<div className={clsx(classes.sidebar, classes.flex, classes.justifyContentCenter, classes.alignItemsCenter)}>
				{selectedCustomer ? (
					<div className={classes.selectedStoreAddress}>
						<div className={classes.storeInfo}>
							<div className={clsx(classes.flex, classes.alignItemsCenter, classes.subtitleWrap, classes.justifyContentBetween)}>
								<div className={classes.subtitle}>{t('selectedCustomerTitle')}</div>
								<IconButton onClick={handleCloseSelectedCustomer} testId={TEST_IDS.STORE_ADDRESSES_PAGE_CLOSE_ITEM}>
									<CloseIcon className={classes.closeIcon} />
								</IconButton>
							</div>
							<div className={clsx(classes.flex)}>
								<PlaceIcon className={classes.placeIcon} />
								{selectedCustomer.address}
							</div>
						</div>
						<CustomerInfo
							customer={selectedCustomer}
							refetchQueries={[
								{
									query: GET_CUSTOMER_HISTORY,
									fetchPolicy: 'network-only',
									variables: { customerId: selectedCustomer.customerId },
								},
							]}
						/>
					</div>
				) : (
					<div className={classes.noSelectedAddress}>
						<img src={noSelectedAddressSrc} alt="" />
						{t('noSelectedCustomer')}
					</div>
				)}
			</div>
		</div>
	);
};

export default Customers;
