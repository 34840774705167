import { getMonthRange } from 'helpers';
import { merge } from 'helpers/contractGeneration';

import { IAction } from './types';
import {
	CHANGE_CONTRACT_FIELDS,
	CHANGE_CONTRACTS_FILTER,
	DELETE_CONTRACT_BY_ID,
	DUBLICATE_CONTRACT,
	FETCH_CONTRACTS_FAILURE,
	FETCH_CONTRACTS_START,
	FETCH_CONTRACTS_SUCCESS,
	IState,
	SET_CONTRACTS_PAGE,
	SET_CONTRACTS_PAGE_SIZE,
	UPDATE_CONTRACT_NAME,
} from './types';

const initialState: IState = {
	items: [],
	total: 0,
	onload: true,
	wasLoaded: false,
	filter: ((): IState['filter'] => {
		const [startDate, endDate] = getMonthRange(3);
		return {
			startDate,
			endDate,
		};
	})(),
	pagination: {
		page: 0,
		pageSize: 10,
	},
};

const reducer = (state = initialState, action: IAction): IState => {
	switch (action.type) {
		case FETCH_CONTRACTS_START:
			return {
				...state,
				onload: true,
			};
		case FETCH_CONTRACTS_SUCCESS:
			return {
				...state,
				items: !state.pagination.page ? action.payload.items : merge(state.items, action.payload.items),
				total: action.payload.total,
				onload: false,
				wasLoaded: true,
			};
		case FETCH_CONTRACTS_FAILURE:
			return {
				...state,
				onload: false,
				wasLoaded: true,
				items: [],
				total: 0,
			};
		case SET_CONTRACTS_PAGE:
			return {
				...state,
				pagination: {
					...state.pagination,
					page: action.payload,
				},
			};
		case SET_CONTRACTS_PAGE_SIZE:
			return {
				...state,
				pagination: {
					...state.pagination,
					pageSize: action.payload,
				},
			};
		case DELETE_CONTRACT_BY_ID:
			return {
				...state,
				items: state.items.filter((c) => c.agreementId !== action.payload),
				total: state.total - 1,
			};
		case UPDATE_CONTRACT_NAME:
			return {
				...state,
				items: state.items.map((c) => (c.agreementId === action.payload.agreementId ? { ...c, agreementName: action.payload.newAgreementName } : c)),
			};
		case CHANGE_CONTRACTS_FILTER:
			return {
				...state,
				filter: {
					...state.filter,
					[action.payload.field]: action.payload.value || undefined,
				},
				pagination: {
					page: 0,
					pageSize: 10,
				},
			};
		case DUBLICATE_CONTRACT:
			return {
				...state,
				items: [action.payload, ...state.items],
				total: state.total + 1,
			};
		case CHANGE_CONTRACT_FIELDS:
			return {
				...state,
				items: state.items.map((i) => (i.agreementId === action.payload.agreementId ? action.payload : i)),
			};
		default:
			return state;
	}
};

export default reducer;
