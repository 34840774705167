import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CreateEditUser from 'containers/modals/CreateEditUser';
import ResetPassword from 'containers/modals/ResetPassword';
import NotificationAuthOtherUser from 'containers/NotificationAuthOtherUser';
import UserCustomerData from 'containers/UserCustomerData';
import UserStoreAddresses from 'containers/UserStoreAddresses';
import clsx from 'helpers/clsx';
import { getAddressesUsers, getAddressUser, getCustomerAddresses, getCustomerIds, getRolesUser } from 'helpers/users';
import { useAuth } from 'hooks/useAuth';
import { useUser } from 'hooks/useUser';
import { useUsers } from 'hooks/useUsers';
import { IStoreAddress } from 'interfaces/storeAddress';
import { ICustomerData, IUser, RoleEnum } from 'interfaces/users';
import { ReactComponent as AuthIcon } from 'static/images/auth.svg';
import { ReactComponent as DeleteIcon } from 'static/images/delete.svg';
import { ReactComponent as EditIcon } from 'static/images/edit.svg';
import { ReactComponent as MoreIcon } from 'static/images/more_vert.svg';
import { ReactComponent as SearchIcon } from 'static/images/search.svg';
import { TEST_IDS } from 'tests/config';

import { Input } from 'components/FormControl';
import IconButton from 'components/IconButton';
import Loader from 'components/Loader';
import LoadMore from 'components/LoadMore';
import Confirm from 'components/modals/Confirm';
import Roles from 'components/Roles';
import Tooltip from 'components/Tooltip';
import UserEmail from 'components/UserEmail';

import classes from './Users.module.scss';

const Users: React.FC = () => {
	const {
		fetchUsers,
		filter,
		pagionation,
		setPageSize,
		items,
		onload,
		wasLoaded,
		total,
		changeFilter,
		deleteUser,
		fetchStoreDate,
		aborterRef,
		setAbortRef,
		fetchCustomersData,
	} = useUsers();
	const { t } = useTranslation(['users', 'roles', 'form', 'errors']);
	const { authWith, loading: loadingUser } = useAuth();
	const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
	const [loadingAddresses, setLoadingAddresses] = useState<boolean>(false);
	const loading = loadingUser || loadingDelete || loadingAddresses;
	const { hasPermissionToManageUsers, hasPermissionAuth, hasPermissionToRemove, isServiceUser, user: userData } = useUser();
	const [allUserAddresses, setAllUserAddresses] = useState<IStoreAddress[]>([]);
	const [allCustomerData, setAllCustomerData] = useState<ICustomerData[]>([]);

	useEffect(() => {
		if (userData) {
			fetchUsers();
		}
	}, [fetchUsers, filter, pagionation, userData]);

	const handleChangeEmail = (value: string) => {
		if (aborterRef && typeof setAbortRef === 'function') {
			aborterRef.abort();
			setAbortRef(new AbortController());
		}
		changeFilter('storeAddressIdAndStoreNameAndEmail', value);
	};

	const getAllStoreAddress = useCallback(() => {
		const allUserStoreIds: number[] = items.map((user) => getAddressUser(user)).flat();
		if (!allUserStoreIds) return;
		setLoadingAddresses(true);
		fetchStoreDate(allUserStoreIds)
			.then(({ storeAddressData }) => {
				setAllUserAddresses(storeAddressData);
				setLoadingAddresses(false);
			})
			.catch(() => setLoadingAddresses(false));
	}, [items]);

	const getAllCustomerData = useCallback(() => {
		const customerIds: number[] = items.map((user: IUser) => getCustomerIds(user)).flat();
		if (!customerIds) return;
		setLoadingAddresses(true);
		fetchCustomersData(customerIds)
			.then(({ customersData }) => {
				setAllCustomerData(customersData);
				setLoadingAddresses(false);
			})
			.catch(() => setLoadingAddresses(false));
	}, [items]);

	useEffect(getAllStoreAddress, [getAllStoreAddress, items]);
	useEffect(getAllCustomerData, [getAllCustomerData, items]);

	const loadMore = () => {
		setPageSize(pagionation.pageSize + 100);
	};

	const handleDelete = (id: string) => () => {
		setLoadingDelete(true);
		return deleteUser(id).finally(() => setLoadingDelete(false));
	};

	const handleAuthWith = (email: string) => () => authWith(email);

	const handleChangeRole = (newRole: RoleEnum) => {
		if (filter.roles.includes(newRole)) {
			changeFilter(
				'roles',
				filter.roles.filter((role) => role !== newRole),
			);
		} else {
			changeFilter('roles', [...filter.roles, newRole]);
		}
	};

	const canLoadMore = items.length < total;

	return (
		<>
			<div className={classes.root} data-testid={TEST_IDS.USERS_PAGE}>
				{loading && <Loader />}
				<div className={classes.body}>
					<NotificationAuthOtherUser />
					<div className={classes.title}>{t('title')}</div>
					<div className={clsx(classes.flex, classes.justifyContentBetween)}>
						<Input
							onChange={handleChangeEmail}
							value={filter.storeAddressIdAndStoreNameAndEmail}
							placeholder={t('search')}
							rootClassName={classes.inputRoot}
							controlClassName={classes.input}
							Icon={SearchIcon}
							positionIcon="right"
							delayOnChange={500}
							autoComplete="nope"
							testId={TEST_IDS.USERS_PAGE_EMAIL_INPUT}
						/>
						<CreateEditUser />
					</div>
					<Roles isFilter roles={filter.roles} onChangeRole={handleChangeRole} className={classes.filters} checkboxClassName={classes.checkbox} />
					{onload && !wasLoaded ? (
						<Loader simple />
					) : (
						<table className={classes.users}>
							{onload && <Loader />}
							<thead>
								<tr>
									<th>{t('fields.email')}</th>
									<th>{t('fields.role')}</th>
									<th>{t('fields.actions')}</th>
								</tr>
							</thead>
							<tbody>
								{items.map((user, indx) => (
									<React.Fragment key={user.id}>
										<tr className={classes.spacer}>
											<td colSpan={3}></td>
										</tr>
										<tr>
											<td title={user.email}>
												<UserEmail textClassName={clsx(classes.email, classes.ellipsis)}>{user.email}</UserEmail>
												<div className={classes.flex}>
													<UserStoreAddresses isLastItem={items.length - indx <= 3} storeAddresses={getAddressesUsers(user, allUserAddresses)} />
													<UserCustomerData isLastItem={items.length - indx <= 3} data={getCustomerAddresses(user, allCustomerData)} />
												</div>
											</td>
											<td
												className={clsx(classes.role, classes.ellipsis)}
												title={isServiceUser ? getRolesUser(user) : getRolesUser(user, ', ', [RoleEnum.MENUTOOL, RoleEnum.CUSTOMER])}
											>
												{isServiceUser ? getRolesUser(user) : getRolesUser(user, ', ', [RoleEnum.MENUTOOL, RoleEnum.CUSTOMER])}
											</td>
											<td>
												<div className={clsx(classes.actions, classes.flex)}>
													<ResetPassword user={user} />
													{hasPermissionAuth() && (
														<Confirm
															confirm={{ onClick: handleAuthWith(user.email), text: t('confirm.auth.ok') }}
															title={t('confirm.auth.title')}
															body={<UserEmail isOnline>{user.email}</UserEmail>}
														>
															<button className={clsx(classes.action, classes.flex, classes.alignItemsCenter)}>
																<AuthIcon className={classes.actionIcon} />
																{t('confirm.auth.btnText')}
															</button>
														</Confirm>
													)}
													{hasPermissionToManageUsers() && (
														<Tooltip
															outside
															withClick
															placement="left"
															rootClassName={classes.actions__more}
															className={classes.tooltipMore}
															text={
																<>
																	<CreateEditUser user={user}>
																		<button className={clsx(classes.action, classes.flex, classes.alignItemsCenter)}>
																			<EditIcon className={clsx(classes.actionIcon)} />
																			{t('actions.editRoles')}
																		</button>
																	</CreateEditUser>
																	<>
																		{hasPermissionToRemove() && (
																			<Confirm
																				confirm={{ onClick: handleDelete(user.id), text: t('confirm.deleteUser.ok') }}
																				title={t('confirm.deleteUser.title')}
																				body={t('confirm.deleteUser.body')}
																				titleSuccess={<Trans t={t} i18nKey="confirm.deleteUser.titleSuccess" values={{ email: user.email }} />}
																				icon="delete"
																			>
																				<button className={clsx(classes.action, classes.flex, classes.alignItemsCenter)}>
																					<DeleteIcon className={clsx(classes.actionIcon)} />
																					{t('actions.delete')}
																				</button>
																			</Confirm>
																		)}
																	</>
																</>
															}
														>
															<IconButton testId={TEST_IDS.USERS_PAGE_MORE_BUTTON}>
																<MoreIcon className={clsx(classes.actionIcon, classes.actionIcon_delete)} />
															</IconButton>
														</Tooltip>
													)}
												</div>
											</td>
										</tr>
									</React.Fragment>
								))}
							</tbody>
						</table>
					)}
					{canLoadMore && !!items.length && (
						<LoadMore onClick={loadMore} className={clsx(classes.flex, classes.justifyContentCenter, classes.loadMore)} />
					)}
				</div>
				<div className={classes.sidebar}>
					<div className={classes.title}>{t('titleOnline')}</div>
					<table className={clsx(classes.users, classes.users_online)}>
						<thead>
							<tr>
								<th>{t('fields.email')}</th>
								<th>{t('fields.countSessions')}</th>
							</tr>
						</thead>
						<tbody>
							{new Array(15)
								.fill(1)
								.map((_, index) => index)
								.map((el) => (
									<React.Fragment key={el}>
										<tr className={classes.spacer}>
											<td colSpan={2}></td>
										</tr>
										<tr>
											<td>
												<UserEmail isOnline>saleshata@glovo.com</UserEmail>
											</td>
											<td>{el + 1}</td>
										</tr>
									</React.Fragment>
								))}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

export default Users;
