import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { clientGraphql } from 'api';
import { GET_CUSTOMER_HISTORY } from 'api/customers';
import CreateEditCustomer from 'containers/modals/CreateEditCustomer';
import { formatDate, formatTime } from 'helpers';
import clsx from 'helpers/clsx';
import { useCustomers } from 'hooks/useCustomers';
import useToast from 'hooks/useToast';
import { ValueOf } from 'interfaces';
import { ICustomer } from 'interfaces/customer';
import { TEST_IDS } from 'tests/config';

import Loader from 'components/Loader';
import MainButton from 'components/MainButton';
import Confirm from 'components/modals/Confirm';

import { IHistory, IHistoryRow, IProps, IResponseHistory } from './types';

import classes from './CustomerInfo.module.scss';

const CustomerInfo: React.FC<IProps> = ({ customer, refetchQueries = [] }) => {
	const [loadingDelete, setLoadingDelete] = useState(false);
	const { deleteCustomerHistory } = useCustomers();
	const { t } = useTranslation(['customers', 'errors']);
	const { toast } = useToast();
	const [fetchStoreAddressHistory, { data, loading: loadingHistory }] = useLazyQuery<IResponseHistory>(GET_CUSTOMER_HISTORY, {
		fetchPolicy: 'network-only',
	});

	const filterKeysForHistory = ([key]: [string, ValueOf<ICustomer>]) =>
		!['__typename', 'createdAt', 'id', 'createdBy', 'createdBy', 'effectiveDate', 'effectiveUntil', 'reason'].includes(key);

	const getHistory = (): IHistory[] | undefined => {
		if (!data || !Array.isArray(data?.customerHistory)) {
			return [];
		}
		const storeAddressHistory = [...data.customerHistory].reverse();
		return storeAddressHistory.reduce((result: IHistory[], item, currentIndex: number, origin) => {
			const previousRow: ICustomer | undefined = origin[currentIndex - 1];
			if (!previousRow) {
				const historyRow = (Object.entries(item).filter(filterKeysForHistory) as [keyof ICustomer, ValueOf<ICustomer>][]).reduce(
					(acc: IHistoryRow[], [key, value]) => {
						if (value === null) return acc;
						return [
							...acc,
							{
								key,
								new: value,
							},
						];
					},
					[],
				);
				return [
					...result,
					{
						createdAt: item.createdAt,
						createdBy: item.createdBy,
						history: historyRow,
						reason: item.reason,
						effectiveDate: item.effectiveDate,
					},
				];
			} else {
				const historyRow = (Object.entries(item).filter(filterKeysForHistory) as [keyof ICustomer, ValueOf<ICustomer>][]).reduce(
					(acc: IHistoryRow[], [key, value]) => {
						if (previousRow[key as keyof ICustomer] !== value) {
							return [
								...acc,
								{
									key,
									old: previousRow[key as keyof ICustomer],
									new: value,
								},
							];
						}
						return acc;
					},
					[],
				);
				if (!historyRow.length) return result;
				return [
					...result,
					{
						createdAt: item.createdAt,
						createdBy: item.createdBy,
						history: historyRow,
						reason: item.reason,
						effectiveDate: item.effectiveDate,
					},
				];
			}
		}, []);
	};

	const isSet = (value: ValueOf<ICustomer>) => value !== undefined && value !== null;
	const formatValue = (value: ValueOf<ICustomer>) => {
		if (typeof value === 'boolean') {
			return value ? t('common:yes') : t('common:no');
		}
		return value;
	};

	const history = getHistory();
	const hasHistory = history && Object.keys(history).length > 0;
	const renderHistoryRow = (row: IHistoryRow) => {
		switch (row.key) {
			default:
				return `${t(`list.${row.key}`)} ${isSet(row.old) ? `${t('common:with')} ${formatValue(row.old)} ${t('common:on')}` : ''} ${formatValue(row.new)}`;
		}
	};
	const createdDate = data?.customerHistory ? [...data.customerHistory].reverse()[0].effectiveDate : undefined;

	useEffect(() => {
		if (customer) {
			fetchStoreAddressHistory({
				variables: { customerId: customer.customerId },
			});
		}
	}, [customer]);

	const handleError = () => {
		toast(t('errors:serverError'), { type: 'error' });
	};

	const handleSuccess = async () => {
		toast(t('create/edit.edit.success'), { type: 'success' });
		await clientGraphql.refetchQueries({
			include: [GET_CUSTOMER_HISTORY],
		});
		await clientGraphql.clearStore();
	};

	const handleDelete = (effectiveDate: string) => async () => {
		setLoadingDelete(true);
		return deleteCustomerHistory(customer?.customerId, effectiveDate, [
			{ query: GET_CUSTOMER_HISTORY, fetchPolicy: 'network-only', variables: { customerId: customer?.customerId } },
			...refetchQueries,
		])
			.then(handleSuccess)
			.catch(handleError)
			.finally(() => setLoadingDelete(false));
	};

	return (
		<>
			<div className={classes.upperSection}>
				<ul className={classes.selectedStoreAddressList}>
					<li>
						<span>{t('list.customerId')}</span>
						<span>{customer.customerId}</span>
					</li>
					<li>
						<span>{t('list.name')}</span>
						<span>{customer.name}</span>
					</li>
					<li>
						<span>{t('list.companyName')}</span>
						<span>{customer.companyName}</span>
					</li>
					<li>
						<span>{t('list.taxId')}</span>
						<span>{customer.taxId}</span>
					</li>
					<li>
						<span>{t('list.contractNumber')}</span>
						<span>{customer.contractNumber}</span>
					</li>
					<li>
						<span>{t('list.city')}</span>
						<span>{customer.city}</span>
					</li>
					<li>
						<span>{t('list.email')}</span>
						<span>{customer.email}</span>
					</li>
					{createdDate && (
						<li>
							<span>{t('list.createdDate')}</span>
							<span>{formatDate(new Date(createdDate))}</span>
						</li>
					)}
				</ul>
			</div>
			<div className={classes.controls}>
				<CreateEditCustomer
					customer={customer}
					refetchQueries={[
						{
							query: GET_CUSTOMER_HISTORY,
							fetchPolicy: 'network-only',
							variables: { customerId: customer.customerId },
						},
						...refetchQueries,
					]}
				>
					<MainButton className={clsx(classes.flex, classes.alignItemsCenter)}>{t('customers:edit')}</MainButton>
				</CreateEditCustomer>
			</div>
			{loadingHistory ? (
				<Loader simple />
			) : (
				hasHistory && (
					<div className={classes.history}>
						<div className={classes.history_title}>{t('history.title')}</div>
						{[...history].reverse().map((historyRow, index) => (
							<div className={classes.history_row} key={historyRow.createdAt} data-testid={TEST_IDS.STORE_ADDRESSES_HISTORY_ROW}>
								<div className={classes.history_row_inner}>
									<div className={classes.history_row_text}>
										{t('history.rowText')} {historyRow.history.map(renderHistoryRow).join(', ')}&nbsp;&nbsp;
										<span>
											{formatDate(new Date(historyRow.createdAt))}&nbsp;{t('common:at')}&nbsp;
											{formatTime(new Date(historyRow.createdAt))}
										</span>
									</div>
								</div>
								{historyRow.reason && <div className={clsx(classes.history_row_text, classes.reason)}>{historyRow.reason}</div>}
								<div className={classes.history_row_createdBy}>{historyRow.createdBy}</div>
								<div className={clsx(classes.history_row_text, classes.effectiveDate)}>
									{t('history.effectiveDate')} {formatDate(new Date(historyRow.effectiveDate))}
								</div>
								{index !== history.length - 1 && (
									<Confirm
										confirm={{
											onClick: handleDelete(historyRow.effectiveDate),
											text: t('confirm.delete.ok'),
											disabled: loadingDelete,
										}}
										title={t('confirm.delete.title')}
										titleSuccess={t('confirm.delete.titleSuccess')}
									>
										<button className={classes.buttonDelete}>{t('delete')}</button>
									</Confirm>
								)}
							</div>
						))}
					</div>
				)
			)}
		</>
	);
};

export default CustomerInfo;
