import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Routes } from 'const';
import { ReactComponent as ArrowRightIcon } from 'static/images/arrow_right.svg';

import classes from './BackToContractList.module.scss';

export const BackToContractList = () => {
	const { t } = useTranslation('contractGeneration');
	const goToDocumentList = () => history.push(Routes.CONTRACT_GENERATION);
	const history = useHistory();

	return (
		<button className={classes.back_button} onClick={goToDocumentList}>
			<ArrowRightIcon className={classes.back_button_icon} /> {t('goToDocumentList')}
		</button>
	);
};
