import { toast } from 'react-toastify';
import { clientGraphql, fetch } from 'api';
import { GET_DICTIONARY, GET_PARTNER_FILE, GET_PARTNER_HISTORY } from 'api/partnersFile';
import { AccessData, DictionaryData, IFiltration, PartnerDataFullDto, PartnerHistory } from 'interfaces/partnersFile';
import { IFilter } from 'interfaces/partnersFile';
import { Dispatch } from 'redux';

import {
	CHANGE_FILTRATION,
	CHANGE_PARTNER_FILE_FILTER,
	CLEAR_PARTNER_FILE_FILTER,
	CLEAR_PARTNER_FILTRATION,
	CREATE_CONTRACT,
	DELETE_CONTRACT_BY_NUMBER,
	FETCH_ACCESS_DATA,
	FETCH_ACCESS_DATA_FAILURE,
	FETCH_ACCESS_DATA_SUCCESS,
	FETCH_DICTIONARY,
	FETCH_DICTIONARY_FAILURE,
	FETCH_DICTIONARY_SUCCESS,
	FETCH_HISTORY_FAILURE,
	FETCH_HISTORY_START,
	FETCH_HISTORY_SUCCESS,
	FETCH_PARTNER_FILE_FAILURE,
	FETCH_PARTNER_FILE_START,
	FETCH_PARTNER_FILE_SUCCESS,
	RESET_SELECTED_ROW,
	SET_COMMISSION_VALID,
	SET_PARTNER_FILE_PAGE,
	SET_PARTNER_FILE_PAGE_SIZE,
	SET_PARTNER_HISTORY_PAGE,
	UPDATE_CONTRACT_AND_HISTORY,
} from './types';

export const fetchFile =
	(filter: IFilter, { page, pageSize }: { page: number; pageSize: number }) =>
	(dispatch: Dispatch): Promise<PartnerDataFullDto[] | undefined> => {
		dispatch({ type: FETCH_PARTNER_FILE_START });
		return clientGraphql
			.query({
				query: GET_PARTNER_FILE,
				variables: { page, pageSize, filter },
				fetchPolicy: 'no-cache',
			})
			.then(({ data }) => {
				dispatch({
					payload: {
						data: data.partnerData.data,
						total: data.partnerData.total,
					},
					type: FETCH_PARTNER_FILE_SUCCESS,
				});
				return data;
			})
			.catch(() => {
				dispatch({ type: FETCH_PARTNER_FILE_FAILURE });
				return undefined;
			});
	};

export const fetchHistory =
	(page: number, contractNumber: PartnerDataFullDto['contractNumber']) =>
	(dispatch: Dispatch): Promise<PartnerHistory[] | undefined> => {
		dispatch({ type: FETCH_HISTORY_START });
		return clientGraphql
			.query({
				query: GET_PARTNER_HISTORY,
				variables: { page, pageSize: 3, contractNumber },
				fetchPolicy: 'no-cache',
			})
			.then(({ data }) => {
				dispatch({
					payload: {
						partnerHistory: data.getPartnerHistory,
					},
					type: FETCH_HISTORY_SUCCESS,
				});
				return data;
			})
			.catch(() => {
				dispatch({ type: FETCH_HISTORY_FAILURE });
				return undefined;
			});
	};

export const fetchDictionary =
	() =>
	(dispatch: Dispatch): Promise<DictionaryData[] | undefined> => {
		dispatch({ type: FETCH_DICTIONARY });
		return clientGraphql
			.query({
				query: GET_DICTIONARY,
			})
			.then(({ data }) => {
				dispatch({
					payload: {
						dictionaries: data.dictionaries,
					},
					type: FETCH_DICTIONARY_SUCCESS,
				});
				return data;
			})
			.catch(() => {
				dispatch({ type: FETCH_DICTIONARY_FAILURE });
				return undefined;
			});
	};

export const getAccessData = () => async (dispatch: Dispatch) => {
	dispatch({ type: FETCH_ACCESS_DATA });
	try {
		const { body }: { body: AccessData & { message?: string } } = await fetch('/partner/getAccessData', { auth: true });
		if (body.emails) {
			dispatch({ type: FETCH_ACCESS_DATA_SUCCESS, payload: body });
		} else {
			toast(body.message, { type: 'error' });
			dispatch({ type: FETCH_ACCESS_DATA_FAILURE });
		}
	} catch {
		dispatch({ type: FETCH_ACCESS_DATA_FAILURE });
	}
};

export const changeFiltrationAction = (payload: IFiltration) => ({
	payload,
	type: CHANGE_FILTRATION,
});

export const deleteContractByNumber = (payload: PartnerDataFullDto['contractNumber']) => ({
	payload,
	type: DELETE_CONTRACT_BY_NUMBER,
});

export const createContract = (payload: PartnerDataFullDto) => ({
	payload,
	type: CREATE_CONTRACT,
});

export const setPartnersFilePage = (payload: number) => ({
	payload,
	type: SET_PARTNER_FILE_PAGE,
});

export const setPageHistory = (payload: number) => ({
	payload,
	type: SET_PARTNER_HISTORY_PAGE,
});

export const setPartnersFilePageSize = (payload: number) => ({
	payload,
	type: SET_PARTNER_FILE_PAGE_SIZE,
});

export const changePartnersFileFilter = (field: keyof IFilter, value: IFilter[typeof field]) => ({
	payload: { field, value },
	type: CHANGE_PARTNER_FILE_FILTER,
});

export const clearPartnersFileFilter = () => ({
	payload: null,
	type: CLEAR_PARTNER_FILE_FILTER,
});

export const clearPartnersFiltration = () => ({
	payload: null,
	type: CLEAR_PARTNER_FILTRATION,
});

export const updateContractAndhistory = (payload: PartnerHistory[]) => ({
	payload,
	type: UPDATE_CONTRACT_AND_HISTORY,
});

export const resetSelectedRow = () => ({
	payload: null,
	type: RESET_SELECTED_ROW,
});

export const setCommissionValid = (payload: { contractNumber: PartnerDataFullDto['contractNumber']; isCommissionValid: boolean }) => ({
	payload,
	type: SET_COMMISSION_VALID,
});
