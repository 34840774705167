import React from 'react';
import clsx from 'helpers/clsx';
import { TEST_IDS } from 'tests/config';

import { IProps } from './types';

import classes from './OnloadDotted.module.scss';

const OnloadDotted: React.FC<IProps> = ({ className, children }) => {
	return (
		<div className={clsx(classes.root, className)} data-testid={TEST_IDS.ONLOAD_DOTTED}>
			{children && <div className={classes.text}>{children}</div>}
			<span />
			<span />
			<span />
		</div>
	);
};

export default OnloadDotted;
