import React, { createRef, useEffect } from 'react';
import { useGetStartedContext } from 'contexts/GetStarted';
import { IStep } from 'contexts/GetStarted/types';
import clsx from 'helpers/clsx';

import { IPropsProvider } from './types';

import classes from './GetStarted.module.scss';

const GetStartedProvider: React.FC<IPropsProvider> = ({ children, canPush, additionally, ...props }) => {
	const ref = createRef<HTMLDivElement>();
	const { pushStep, deleteStep, currentStep, steps } = useGetStartedContext();

	const modifyChildren = (child: React.ReactElement) => {
		const className = clsx(child.props.className, { [classes.getStarted]: currentStep?.element?.className === child.props.className });

		return React.cloneElement(child, { className, ref });
	};

	const childrenClone = React.Children.map(children, (child) => {
		return React.isValidElement(child) && modifyChildren(child);
	});

	useEffect(() => {
		const everyAdditionallyIsReady = additionally ? additionally.every(({ element }) => !!element.current) : true;
		if (canPush === false && deleteStep) {
			deleteStep(props as IStep);
		} else if (ref.current && everyAdditionallyIsReady && pushStep) {
			pushStep({
				...props,
				canPush,
				additionally: additionally?.map(({ element }) => ({ element: element.current as HTMLElement })),
				element: ref.current,
			});
		}
	}, [canPush]);

	useEffect(() => {
		steps.forEach((s) => {
			if (!document.body.contains(s.element) && deleteStep) {
				deleteStep(s as IStep);
			}
		});
	}, [currentStep]);

	return <>{childrenClone}</>;
};

export default GetStartedProvider;
