import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { fetch } from 'api';
import { Routes } from 'const';
import { ITemplate } from 'interfaces/contractGeneration';
import { TEST_IDS } from 'tests/config';

import { Input, Radio } from 'components/FormControl';
import Loader from 'components/Loader';
import Modal from 'components/modals/Modal';

import { IPropsTemplate } from './types';

import classes from './CreateDocument.module.scss';

const CreateDocument: React.FC<IPropsTemplate> = ({ onClose }) => {
	const { t } = useTranslation('contractGeneration');
	const [templateId, setTemplateId] = useState<string>('');
	const [templates, setTemplates] = useState<ITemplate[]>([]);
	const history = useHistory();
	const [searchTemplate, setSearchTemplate] = useState('');
	const [loading, setLoading] = useState(false);

	const handleChangeTemplate = (id: string) => () => setTemplateId(id);

	const fetchTemplates = async () => {
		setLoading(true);
		const { body }: { body: ITemplate[] } = await fetch('/template/getAll', { fintoolContracts: true }, false);
		if (body) {
			setTemplates(body);
		}
		setLoading(false);
	};

	const handleSubmit = () => {
		history.push(`${Routes.QUESTIONNAIRE}?template=${templateId}&label=${templates.find((template) => template.id === templateId)?.title}`);
		onClose();
	};

	useEffect(() => {
		fetchTemplates();
	}, []);

	const filterTemplates = (value: string) => setSearchTemplate(value);

	const filteredTemplates = templates.filter((template) => (searchTemplate ? template.title.includes(searchTemplate) : template));

	return (
		<Modal
			modalClassName={classes.modal}
			title={t('selectTemplate')}
			open
			onClose={onClose}
			buttons={{
				cancel: {},
				confirm: {
					text: t('createDocument'),
					onClick: handleSubmit,
					disabled: !templateId,
				},
			}}
		>
			{loading ? (
				<Loader simple />
			) : (
				<>
					<Input
						testId={TEST_IDS.CREATE_DOCUMENT_SEARCH}
						value={searchTemplate}
						onChange={filterTemplates}
						type="text"
						label={t('search')}
						delayOnChange={500}
					/>
					<div className={classes.template_list}>
						{!!filteredTemplates.length &&
							filteredTemplates.map((template) => (
								<div key={template.id}>
									<Radio isChecked={templateId === template.id} onChange={handleChangeTemplate(template.id)}>
										{template.title}
									</Radio>
								</div>
							))}
					</div>
				</>
			)}
		</Modal>
	);
};

export default CreateDocument;
