import React from 'react';
import { useTranslation } from 'react-i18next';
import { parseDate } from 'helpers';
import { useDeepCompareEffect } from 'hooks/useDeepCompareEffect';
import { usePartnersFile } from 'hooks/usePartnersFile';
import { PartnerCommission, PartnerDataFullDto } from 'interfaces/partnersFile';
import { ReactComponent as DeleteIcon } from 'static/images/deleteIcon.svg';
import { ReactComponent as PlusIcon } from 'static/images/plus.svg';
import { isEqual } from 'underscore';

import { DatePicker, Input } from 'components/FormControl';
import Textarea from 'components/FormControl/Textarea';
import MainButton from 'components/MainButton';
import Modal from 'components/modals/Modal';

import { IPropsTemplate } from './types';

import classes from './Commission.module.scss';

const initCommission = {
	startDate: null,
	endDate: null,
	comment: '',
	value: 0,
	contractNumber: 0,
};

const CommissionList: React.FC<IPropsTemplate> = ({ onClose, contractNumber, hasAccess }) => {
	const { t } = useTranslation(['partners', 'form']);
	const { items } = usePartnersFile();
	const currentCommissionList = (items.find((item) => item.contractNumber === contractNumber)?.commission || []).sort(
		(a, b) => new Date(b.startDate || 0).getTime() - new Date(a.startDate || 0).getTime(),
	) as PartnerDataFullDto['commission'];
	const [commissionList, setCommissionList] = React.useState<PartnerDataFullDto['commission']>(currentCommissionList);
	const { editContractCommission, deleteContractCommission } = usePartnersFile();
	const isValidData = () => commissionList.every((comission) => comission.value);

	useDeepCompareEffect(() => {
		setCommissionList(currentCommissionList);
	}, [currentCommissionList]);

	const handleChangeForm = (key: keyof PartnerCommission, id: number) => (value: string | Date | [Date, Date] | boolean | number) => {
		setCommissionList(
			commissionList.map((commission) => {
				if (commission.id === +id) {
					return { ...commission, [key]: value };
				}
				return commission;
			}),
		);
	};

	const handleSubmit = () => {
		if (commissionList.length >= currentCommissionList.length) {
			editContractCommission(
				commissionList.map(({ __typename, id, ...commission }) => (__typename ? { ...commission, id } : { ...commission })),
				contractNumber,
			);
		} else {
			const ids = currentCommissionList
				.filter((comission: PartnerCommission) => !commissionList.map((c) => c.id).includes(comission.id))
				.map((commission: PartnerCommission) => commission.id);
			deleteContractCommission(
				ids.map((id) => (id ? +id : 0)),
				contractNumber,
			);
		}
	};

	const addNewPeriod = () => {
		setCommissionList([...commissionList, { ...initCommission, id: new Date().getTime(), contractNumber }]);
	};

	const removePeriod = (id: number) => {
		setCommissionList(commissionList.filter((commission) => commission.id !== +id));
	};

	return (
		<Modal
			open
			modalClassName={classes.modal}
			title={`${t('сontractCommission')} ${contractNumber}`}
			onClose={onClose}
			buttons={{
				cancel: {},
				confirm: hasAccess
					? {
							text: t('save'),
							onClick: handleSubmit,
							disabled: isEqual(currentCommissionList, commissionList) || !isValidData(),
						}
					: {},
			}}
			rootClassName={classes.container}
			titleClassName={classes.title}
			closeButtonClassName={classes.closeButton}
			bodyClassName={classes.body}
		>
			<div className={classes.commission_list}>
				{commissionList.map(
					(commission) =>
						commission.id && (
							<div key={commission.id} className={classes.commission_form}>
								{hasAccess && (
									<div onClick={() => commission.id && removePeriod(commission.id)} className={classes.rmBtn}>
										<DeleteIcon color="#797A8D" />
									</div>
								)}
								<div className={classes.commission_form_top}>
									<DatePicker
										rootClassName={classes.datePicker}
										onChange={handleChangeForm('startDate', commission.id)}
										label={t('startDate')}
										disabled={!hasAccess}
										datepickerProps={{
											startDate:
												typeof commission.startDate === 'string' ? parseDate(commission.startDate, 'yyyy-MM-dd') : commission.startDate || undefined,
										}}
									/>
									<DatePicker
										disabled={!hasAccess}
										rootClassName={classes.datePicker}
										onChange={handleChangeForm('endDate', commission.id)}
										label={t('endDate')}
										datepickerProps={{
											startDate:
												typeof commission.endDate === 'string' ? parseDate(commission.endDate, 'yyyy-MM-dd') : commission.endDate || undefined,
										}}
									/>
									<Input
										fullWidth
										value={commission.value || ''}
										label={t('value') + ' *'}
										onChange={handleChangeForm('value', commission.id)}
										type="number"
										disabled={!hasAccess}
										pattern={/^[0-9]{1,4}(\.\d{0,2})?$/}
									/>
								</div>
								<Textarea
									fullWidth
									disabled={!hasAccess}
									rootClassName={classes.textarea}
									value={commission.comment || ''}
									label={t('fields.comment')}
									onChange={(e) => commission.id && handleChangeForm('comment', commission.id)(e.target.value)}
								/>
							</div>
						),
				)}
			</div>
			{hasAccess && (
				<MainButton className={classes.button} onClick={addNewPeriod}>
					<PlusIcon className={classes.addIcon} /> {t('addNewPeriod')}
				</MainButton>
			)}
		</Modal>
	);
};

export default CommissionList;
