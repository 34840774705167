import React, { createRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyQueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import {
	GET_COMPANY_NAMES,
	GET_DATE_PICKER_SCHEDULE,
	GET_STORE_ADDRESS,
	GET_STORE_NAMES,
	GET_TOTALS_AFTER_PERIOD,
	GET_TOTALS_DISPUTES,
} from 'api/orders';
import { GLOVO_ROLES } from 'const';
import GetStartedProvider from 'containers/GetStarted/Provider';
import { useGetStartedContext } from 'contexts/GetStarted';
import clsx from 'helpers/clsx';
import { getCountry } from 'helpers/jwt';
import { compareDate, getDateRange, getDateRangeSchedule, normalizeFilter, normalizeReceivedDate } from 'helpers/order';
import { hasRole } from 'helpers/users';
import { useOrders } from 'hooks/useOrders';
import { useUser } from 'hooks/useUser';
import { AccountingTypes, CountryCodeEnum, ValueOf } from 'interfaces';
import { ICompanyNames, IDateRangeSchedule, IStoreAddress, IStoreNames } from 'interfaces/filter';
import { IFilter, OrderStatus } from 'interfaces/order';
import { ReactComponent as ApprovedIcon } from 'static/images/approved.svg';
import { ReactComponent as AutoIcon } from 'static/images/autoapprove.svg';
import { ReactComponent as CanceledIcon } from 'static/images/canceled.svg';
import { ReactComponent as EditIcon } from 'static/images/edit.svg';
import { ReactComponent as PendingIcon } from 'static/images/pending.svg';
import { TEST_IDS } from 'tests/config';
import { isEqual } from 'underscore';

import { Clear, DatePicker, Input, Select } from 'components/FormControl';
import { IPropsSelect } from 'components/FormControl/types';

import classes from './OrdersContainer.module.scss';

const OrdersFilters: React.FC = ({ children }) => {
	const { filter, changeFilter, clearFilters, items, fetchTotals } = useOrders();
	const { roles } = useUser();
	const { startDate: filterStartDate, endDate: filterEndDate, ...filterRest } = filter;
	const refFilters = useRef<IFilter>({ ...filterRest });
	const { open: openGetStarted, currentStep, onOpenModalOrder } = useGetStartedContext();
	const forceOpenDatepicker = openGetStarted && currentStep?.id === 'date';
	const forceOpenSelectStoreName = openGetStarted && currentStep?.id === 'counterparty';
	const forceOpenSelectOrderStatus = openGetStarted && currentStep?.id === 'status';
	const { t } = useTranslation(['orders', 'accountingTypes']);
	const [[startMountDate, endMountDate], setMountDate] = useState<[Date, Date] | []>([]);
	const { hasMarketplace } = useUser();
	const [fetchTotalsAfterPeriodCount, { data: totalsAfterPeriod }] = useLazyQuery(GET_TOTALS_AFTER_PERIOD);
	const [fetchDisputesTabTotals, { data: totalsDisputes }] = useLazyQuery(GET_TOTALS_DISPUTES);
	const [fetchStoreNames, { loading: loadingStoreNames, data: storeNames }] = useLazyQuery<IStoreNames>(GET_STORE_NAMES);
	const [fetchCompanyNames, { loading: loadingCompanyNames, data: companyNames }] = useLazyQuery<ICompanyNames>(GET_COMPANY_NAMES);
	const [fetchStoreAddress, { loading: loadingStoreAddress, data: storeAddress }] = useLazyQuery<IStoreAddress>(GET_STORE_ADDRESS);
	const { loading: loadingDateRange, data: dateRange } = useQuery<IDateRangeSchedule>(GET_DATE_PICKER_SCHEDULE, {
		variables: { filter: { accountingType: getDateRangeSchedule() } },
	});
	const isGlovoRoles = hasRole(roles, [...GLOVO_ROLES]);
	const isUA = getCountry() === CountryCodeEnum.UA;
	const isKZ = getCountry() === CountryCodeEnum.KZ;

	const params: LazyQueryHookOptions = {
		variables: { filter: normalizeFilter(filter) },
		fetchPolicy: 'network-only',
	};

	const handleShowAll = (param: boolean) => () => changeFilter('approvedAfterPeriodTab', param);

	const handleShowAllDisputes = (param: boolean) => () => changeFilter('disputesTab', param);

	const fetchData = () => {
		fetchStoreNames(params);
		fetchCompanyNames(params);
		fetchStoreAddress(params);
		fetchTotals();
		isUA &&
			fetchTotalsAfterPeriodCount({
				variables: { filter: { ...normalizeFilter(filter), approvedAfterPeriodTab: true } },
				fetchPolicy: 'network-only',
			});
		isKZ &&
			fetchDisputesTabTotals({
				variables: { filter: { ...normalizeFilter(filter), disputesTab: true } },
				fetchPolicy: 'network-only',
			});
	};

	useEffect(() => {
		if (filter.startDate && filter.endDate) fetchData();
	}, [filter]);

	const handleChangeForm = (field: keyof IFilter) => (value: IPropsSelect['value']) => {
		changeFilter(field, value as ValueOf<IFilter>);
	};

	const handleChangeCompany = (value: string) => {
		const selectedCompany = companyNames?.companyNames.find((company) => company.companyName === value);
		changeFilter('companyName', selectedCompany?.companyName);
		changeFilter('taxId', selectedCompany?.taxId);
	};

	const handleChangeAdress = (value: string) => {
		changeFilter('storeAddressId', value);
		const addr = storeAddress?.storeAddress.filter(({ id }) => value === id).map(({ address }) => address)[0];
		changeFilter('storeAddress', addr);
	};

	const handleChangeInput = (value: string) => {
		changeFilter('code', value);
	};

	const handleChangeDatePicker = (dates: [Date, Date]) => {
		const [start, end] = dates;
		if (start && end) {
			changeFilter('startDate', start);
			changeFilter('endDate', end);
		}
	};

	const handleChangeContractNumber = (value: string) => {
		changeFilter('contractNumber', value);
	};

	const handleClearFilters = () => {
		const { startDate, endDate } = dateRange?.datePickerSchedule ? normalizeReceivedDate(dateRange?.datePickerSchedule) : getDateRange(new Date());
		clearFilters([startDate, endDate]);
	};

	useEffect(() => {
		if (!loadingDateRange) {
			const { startDate, endDate } = dateRange?.datePickerSchedule ? normalizeReceivedDate(dateRange?.datePickerSchedule) : getDateRange(new Date());
			handleChangeDatePicker([startDate, endDate]);
			setMountDate([startDate, endDate]);
		}
	}, [dateRange, loadingDateRange]);

	const isChanged =
		!compareDate(filterStartDate, startMountDate) || !compareDate(filterEndDate, endMountDate) || !isEqual(filterRest, refFilters.current);
	const refCompanySelect = createRef<HTMLDivElement>();
	const refAddress = createRef<HTMLDivElement>();

	return (
		<div className={classes.filters} data-testid={TEST_IDS.ORDERS_FILTERS}>
			<GetStartedProvider sort={7} id="date" position="right" selectorDiff=".react-datepicker-popper" borderRadiusDiff={20}>
				<DatePicker
					onChange={handleChangeDatePicker}
					forceOpenDatepicker={forceOpenDatepicker}
					datepickerProps={{
						twoMonth: true,
						endDate: filter.endDate,
						startDate: filter.startDate,
						maxDate: new Date(),
					}}
					rootClassName={clsx(classes.filterItem, { [classes.getStarted]: currentStep?.id === 'date' })}
				/>
			</GetStartedProvider>
			<GetStartedProvider sort={8} id="id" position="bottom">
				<Input
					onChange={handleChangeInput}
					disabled={!filter.code && !items.length}
					value={filter.code}
					placeholder={t('filter.id.placeholder')}
					rootClassName={clsx(classes.filterItem, { [classes.getStarted]: currentStep?.id === 'id' })}
					delayOnChange={500}
					minLengthWhenCallOnChange={9}
					testId={TEST_IDS.ORDERS_FILTERS_CODE}
				/>
			</GetStartedProvider>
			<GetStartedProvider
				sort={9}
				canPush={!!items.length}
				id="counterparty"
				position="left"
				selectorDiff=".Select__inner-dropdown"
				borderRadiusDiff={20}
				additionally={[{ element: refCompanySelect }, { element: refAddress }]}
			>
				<Select
					onChange={handleChangeForm('storeName')}
					value={filter.storeName}
					emptyLabel={t('filter.storeName.emptyLabel')}
					rootClassName={clsx(classes.filterItem, { [classes.getStarted]: currentStep?.id === 'counterparty' })}
					empty={!filter.storeName}
					forceOpen={forceOpenSelectStoreName}
					withSearch
					disabled={(!storeNames?.storeNames.length && !filter.storeName) || loadingStoreNames}
					options={storeNames?.storeNames.map((name) => ({ value: name, label: name })) || []}
					testId={TEST_IDS.ORDERS_FILTERS_STORE_NAME}
				/>
			</GetStartedProvider>
			<Select
				onChange={handleChangeAdress}
				value={filter.storeAddress}
				emptyLabel={t('filter.storeAddress.emptyLabel')}
				empty={!filter.storeAddressId}
				rootClassName={clsx(classes.filterItem, { [classes.getStarted]: currentStep?.id === 'counterparty' })}
				withSearch
				disabled={!storeAddress?.storeAddress?.length || loadingStoreAddress}
				options={
					(filter.contractNumber
						? storeAddress?.storeAddress.filter(({ contractNumber }) => contractNumber === filter.contractNumber)
						: storeAddress?.storeAddress
					)?.map(({ id, address }, index) => ({ value: id, label: address, additionalLabel: `${index}` })) || []
				}
				ref={refAddress}
				renderOption={(option) => `${option.value}, ${option.label}`}
				testId={TEST_IDS.ORDERS_FILTERS_STORE_ADDRESS}
			/>
			<Select
				onChange={handleChangeCompany}
				value={filter.companyName}
				emptyLabel={t('filter.companyName.emptyLabel')}
				empty={!filter.companyName}
				rootClassName={clsx(classes.filterItem, { [classes.getStarted]: currentStep?.id === 'counterparty' })}
				withSearch
				disabled={!companyNames?.companyNames.length || loadingCompanyNames}
				options={
					companyNames?.companyNames.map((company) => ({
						value: company.companyName,
						label: company.companyName,
						additionalLabel: company.taxId,
					})) || []
				}
				testId={TEST_IDS.ORDERS_FILTERS_COMPANY_NAME}
				ref={refCompanySelect}
			/>
			<GetStartedProvider
				sort={10}
				id="status"
				position="right"
				selectorDiff=".Select__inner-dropdown"
				borderRadiusDiff={20}
				beforeNext={onOpenModalOrder}
			>
				<Select
					onChange={handleChangeForm('orderStatus')}
					value={filter.orderStatus || []}
					disabled={(!filter.orderStatus?.length && !items.length) || filter.approvedAfterPeriodTab || filter.disputesTab}
					emptyLabel={t('filter.orderStatus.emptyLabel')}
					empty={!filter.orderStatus?.length}
					rootClassName={clsx(classes.filterItem, { [classes.getStarted]: currentStep?.id === 'status' })}
					multiple
					multipleTitle={t('filter.orderStatus.multipleTitle')}
					forceOpen={forceOpenSelectOrderStatus}
					testId={TEST_IDS.ORDERS_FILTERS_ORDER_STATUS}
					options={
						isGlovoRoles && isUA
							? [
									{ value: OrderStatus.PENDING, label: t('filter.orderStatus.options.pending'), Icon: PendingIcon },
									{ value: OrderStatus.AMENDING, label: t('filter.orderStatus.options.amending'), Icon: EditIcon },
									{ value: OrderStatus.AUTO_APPROVAL, label: t('filter.orderStatus.options.autoApproved'), Icon: AutoIcon },
									{ value: OrderStatus.REJECTED, label: t('filter.orderStatus.options.rejected'), Icon: CanceledIcon },
									{ value: OrderStatus.APPROVED, label: t('filter.orderStatus.options.approved'), Icon: ApprovedIcon },
								]
							: [
									{ value: OrderStatus.PENDING, label: t('filter.orderStatus.options.pending'), Icon: PendingIcon },
									{ value: OrderStatus.AMENDING, label: t('filter.orderStatus.options.amending'), Icon: EditIcon },
									{ value: OrderStatus.REJECTED, label: t('filter.orderStatus.options.rejected'), Icon: CanceledIcon },
									{ value: OrderStatus.APPROVED, label: t('filter.orderStatus.options.approved'), Icon: ApprovedIcon },
								]
					}
				/>
			</GetStartedProvider>
			<Select
				onChange={handleChangeForm('accountingTypes')}
				value={filter.accountingTypes || []}
				emptyLabel={t('accountingTypes:emptyLabel')}
				disabled={(!filter.accountingTypes?.length && !items.length) || filter.disputesTab}
				empty={!filter.accountingTypes?.length}
				rootClassName={classes.filterItem}
				multiple
				multipleTitle={t('accountingTypes:multipleLabel')}
				testId={TEST_IDS.ORDERS_FILTERS_ACCOUNTING_TYPES}
				options={[
					{ value: AccountingTypes.ONCE_A_WEEK, label: t('accountingTypes:options.onceWeek') },
					{ value: AccountingTypes.TWICE_A_MONTH, label: t('accountingTypes:options.twiceMonth') },
					{ value: AccountingTypes.ONCE_A_MONTH, label: t('accountingTypes:options.onceMonth') },
				]}
			/>
			<Select
				withSearch
				onChange={handleChangeContractNumber}
				value={filter.contractNumber}
				emptyLabel={t('filter.contractNumber.emptyLabel')}
				empty={!filter.contractNumber}
				rootClassName={classes.filterItem}
				disabled={!storeAddress?.storeAddress?.length || loadingStoreAddress}
				options={
					[...new Set(storeAddress?.storeAddress.filter(({ contractNumber }) => contractNumber).map((store) => store.contractNumber))].map(
						(contractNumber, index) => ({ value: contractNumber, label: contractNumber, additionalLabel: `${index}` }),
					) || []
				}
				renderOption={(option) => option.label}
				testId={TEST_IDS.ORDERS_FILTERS_CONTRACT_NUMBER}
			/>
			<div
				className={clsx(classes.filterItem, classes.filterItem_control, {
					[classes.filterItem_control__hasMarketplace]: hasMarketplace,
					[classes.filterItem_control_afterPeriod]: isUA || isKZ,
				})}
			>
				{children}
				{isUA && (
					<div className={classes.tabs}>
						<GetStartedProvider beforePrev={onOpenModalOrder} sort={13} id="tabsAll" position="right" borderRadius="20px 0 0 20px">
							<button onClick={handleShowAll(false)} className={clsx({ [classes.active]: !filter.approvedAfterPeriodTab })}>
								{t('tabs.all')}
							</button>
						</GetStartedProvider>
						<GetStartedProvider sort={14} id="tabsAfterApprovedPeriod" position="right" borderRadius="0 20px 20px 0">
							<button onClick={handleShowAll(true)} className={clsx({ [classes.active]: filter.approvedAfterPeriodTab })}>
								{t('tabs.afterApprovedPeriod')}&nbsp;
								<span className={classes.count}>{totalsAfterPeriod?.approvedAfterPeriodTotals || 0}</span>
							</button>
						</GetStartedProvider>
					</div>
				)}
				{isKZ && (
					<div className={classes.tabs}>
						<GetStartedProvider beforePrev={onOpenModalOrder} sort={13} id="tabsAll" position="right" borderRadius="20px 0 0 20px">
							<button onClick={handleShowAllDisputes(false)} className={clsx({ [classes.active]: !filter.disputesTab })}>
								{t('tabs.all')}
							</button>
						</GetStartedProvider>
						<GetStartedProvider sort={14} id="disputesTab" position="right" borderRadius="0 20px 20px 0">
							<button onClick={handleShowAllDisputes(true)} className={clsx({ [classes.active]: filter.disputesTab })}>
								{t('tabs.disputesTab')}&nbsp;
								<span className={classes.count}>{totalsDisputes?.disputesTabTotals}</span>
							</button>
						</GetStartedProvider>
					</div>
				)}
				<div className={classes.end}>{isChanged && <Clear onClick={handleClearFilters} rootClassName={classes.clear} />}</div>
			</div>
		</div>
	);
};

export default OrdersFilters;
