import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'helpers/clsx';
import useToast from 'hooks/useToast';
import { useUser } from 'hooks/useUser';
import { ReactComponent as RestorePasswordIcon } from 'static/images/restore-password.svg';

import Confirm from 'components/modals/Confirm';

import { IProps } from './types';

import classes from './ResetPassword.module.scss';

const ResetPassword: React.FC<IProps> = ({ children, user }) => {
	const { t } = useTranslation(['resetPassword', 'validation', 'form', 'errors']);
	const { toast } = useToast();
	const { resetPassword } = useUser();

	const handleError = () => {
		toast(t('errors:serverError'), { type: 'error' });
	};

	const handleResetPassword = () => {
		return resetPassword(user.email).catch(handleError);
	};

	return (
		<Confirm
			confirm={{ onClick: handleResetPassword, text: t('btnText') }}
			title={t('title')}
			titleSuccess={t('successfull.title')}
			bodySuccess={t('successfull.text')}
			icon="resetPassword"
		>
			{children || (
				<button className={clsx(classes.action, classes.flex, classes.alignItemsCenter)}>
					<RestorePasswordIcon className={classes.actionIcon} />
					{t('btnText')}
				</button>
			)}
		</Confirm>
	);
};

export default ResetPassword;
