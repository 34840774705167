import { useTranslation } from 'react-i18next';
import { IFild } from 'containers/Orders/types';
import clsx from 'helpers/clsx';
import { TEST_IDS } from 'tests/config';

import classes from 'pages/Accounts/Accounts.module.scss';

const AccountsTableHead = () => {
	const { t } = useTranslation('accounts');

	const fields: IFild[] = [
		{
			id: 'icon',
			title: '',
			render: true,
			className: classes.iconCell,
		},
		{
			id: 'type',
			title: t('mainTableHead.type'),
			render: true,
			className: classes.typeCell,
		},
		{
			id: 'docNum',
			title: t('mainTableHead.docNum'),
			render: true,
			className: clsx(classes.docNumCell, classes.alignRight),
		},
		{
			id: 'agent',
			title: t('mainTableHead.agent'),
			render: true,
			className: clsx(classes.agentNumCell),
		},
		{
			id: 'offerNum',
			title: t('mainTableHead.contractNum'),
			render: true,
			className: clsx(classes.alignRight, classes.offerCell),
		},
		{
			id: 'approvedPayment',
			title: t('mainTableHead.payment'),
			className: clsx(classes.alignRight, classes.numCell),
			render: true,
		},
		{
			id: 'paymentDate',
			title: t('mainTableHead.paymentDate'),
			render: true,
			className: clsx(classes.paymentDateCell),
		},
		{
			id: 'Appointment',
			title: t('mainTableHead.appointment'),
			render: true,
		},
	];

	return (
		<thead data-testid={TEST_IDS.ACCOUNTS_PAYMENT_TABLE_HEAD}>
			<tr className={classes.sticky}>
				{fields.map((it) => {
					if (!it.render) return null;
					return (
						<td key={it.id} className={clsx(it.className, {})}>
							<div>
								{it.title.split('\n').map((h) => (
									<div key={h}>{h}</div>
								))}
							</div>
						</td>
					);
				})}
			</tr>
		</thead>
	);
};

export default AccountsTableHead;
