import React from 'react';
import clsx from 'helpers/clsx';
import { ReactComponent as UserCurrent } from 'static/images/user.svg';
import { ReactComponent as User } from 'static/images/user-type-1.svg';

import { IProps } from './types';

import classes from './UserEmail.module.scss';

const UserEmail: React.FC<IProps> = ({ rootClassName, children, isOnline, isCurrent, textClassName }) => {
	return (
		<div
			className={clsx(classes.root, classes.flex, classes.alignItemsCenter, rootClassName, {
				[classes.isOnline]: isOnline,
				[classes.isCurrent]: isCurrent,
			})}
		>
			<div className={clsx(classes.iconWrap, classes.flex, classes.justifyContentCenter, classes.alignItemsCenter)}>
				{isCurrent ? <UserCurrent className={classes.icon} /> : <User className={classes.icon} />}
			</div>
			<div className={clsx(classes.text, classes.flex, classes.justifyContentCenter, classes.alignItemsCenter, textClassName)}>{children}</div>
		</div>
	);
};

export default UserEmail;
