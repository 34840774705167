import { ValueOf } from 'interfaces';
import { IDebtWithChildren, IFilter, IInvoicesModify, IPaymentModify } from 'interfaces/invoices';

export const FETCH_INVOICES_START = 'FETCH_INVOICES_START';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE';
export const SET_INVOICES_PAGE_SIZE = 'SET_INVOICES_PAGE_SIZE';

export const FETCH_PAYMENTS_START = 'FETCH_PAYMENTS_START';
export const FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS';
export const FETCH_PAYMENTS_FAILURE = 'FETCH_PAYMENTS_FAILURE';
export const SET_PAYMENTS_PAGE_SIZE = 'SET_PAYMENTS_PAGE_SIZE';

export const CHANGE_INVOICES_FILTER = 'CHANGE_INVOICES_FILTER';

export const FETCH_DEBT_GLOVO_START = 'FETCH_DEBT_GLOVO_START';
export const FETCH_DEBT_GLOVO_SUCCESS = 'FETCH_DEBT_GLOVO_SUCCESS';
export const FETCH_DEBT_GLOVO_FAILURE = 'FETCH_DEBT_GLOVO_FAILURE';

export const FETCH_DEBT_COMPANIES_START = 'FETCH_DEBT_COMPANIES_START';
export const FETCH_DEBT_COMPANIES_SUCCESS = 'FETCH_DEBT_COMPANIES_SUCCESS';
export const FETCH_DEBT_COMPANIES_FAILURE = 'FETCH_DEBT_COMPANIES_FAILURE';

export interface IState {
	invoices: {
		items: IInvoicesModify[];
		total: number;
		pagionation: {
			page: number;
			pageSize: number;
		};
	};
	payments: {
		items: IPaymentModify[];
		total: number;
		pagionation: {
			page: number;
			pageSize: number;
		};
	};
	filter: IFilter;
	glovoDebts: IDebtWithChildren[];
	companiesDebts: IDebtWithChildren[];
	onload: boolean;
	debtsLoad: boolean;
	wasLoaded: boolean;
}

export type IAction =
	| {
			type: typeof FETCH_INVOICES_SUCCESS;
			payload: { invoices: IInvoicesModify[]; total: number };
	  }
	| {
			type: typeof FETCH_PAYMENTS_SUCCESS;
			payload: { payments: IPaymentModify[]; total: number };
	  }
	| {
			type: typeof FETCH_DEBT_GLOVO_SUCCESS | typeof FETCH_DEBT_COMPANIES_SUCCESS;
			payload: { debts: IDebtWithChildren[]; total: number };
	  }
	| {
			type: typeof SET_INVOICES_PAGE_SIZE | typeof SET_PAYMENTS_PAGE_SIZE;
			payload: number;
	  }
	| {
			type:
				| typeof FETCH_INVOICES_START
				| typeof FETCH_INVOICES_FAILURE
				| typeof FETCH_PAYMENTS_START
				| typeof FETCH_PAYMENTS_FAILURE
				| typeof FETCH_DEBT_GLOVO_START
				| typeof FETCH_DEBT_GLOVO_FAILURE
				| typeof FETCH_DEBT_COMPANIES_START
				| typeof FETCH_DEBT_COMPANIES_FAILURE;
	  }
	| {
			type: typeof CHANGE_INVOICES_FILTER;
			payload: { field: string; value: ValueOf<IFilter> };
	  };
