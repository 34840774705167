import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'helpers/clsx';
import { useClickAwayListener } from 'hooks/useClickAwayListener';
import { ReactComponent as ClearIcon } from 'static/images/clear.svg';
import { ReactComponent as Arr } from 'static/images/keyboard_arrow_down.svg';
import { TEST_IDS } from 'tests/config';

import Checkbox from './Checkbox';
import Input from './Input';
import { IPropsSelect, ISelectOption } from './types';

import classes from './FormControl.module.scss';

const Select: React.ForwardRefRenderFunction<HTMLDivElement, IPropsSelect> = (
	{
		rootClassName,
		controlClassName,
		Icon = null,
		value: valueProps = '',
		defaultValue,
		emptyLabel = '',
		empty = false,
		label,
		withSearch = false,
		options: dataOptions,
		onChange,
		multiple,
		multipleWithLabel,
		multipleWithoutButton,
		multipleTitle = '',
		forceOpen,
		disabled = false,
		withoutClear = false,
		error,
		renderOption: renderOptionProp,
		iconColor = 'default',
		onlyView = false,
		testId,
		limit = 100,
		onCloseClear = false,
		selectAll = false,
		portal = false,
		isExport = false,
	},
	refRoot,
) => {
	const [open, setOpen] = useState<boolean>(false);
	const ref = useRef<HTMLDivElement>(null);
	const [query, setQuery] = useState<string>('');
	const [value, setValue] = useState<IPropsSelect['value']>(valueProps);
	const { t } = useTranslation('form');

	useEffect(() => {
		if (!valueProps && multiple) {
			return setValue([]);
		}
		setValue(valueProps);
	}, [valueProps, multiple]);

	useEffect(() => {
		if (!valueProps && defaultValue) {
			setValue(defaultValue);
		}
	}, [valueProps, defaultValue]);

	const toggleOpen = () => {
		setOpen((state) => !state);
	};

	const closeDropwDown = () => {
		setOpen(false);
		setQuery('');
	};

	const handleChangeSearch = (newValue: string) => {
		setQuery(newValue);
	};

	useClickAwayListener(ref, closeDropwDown);

	const handleChangeValue = useCallback(
		(v: ISelectOption['value']) => () => {
			if (!multiple) {
				setValue(v);
				onChange(v);
				closeDropwDown();
			} else if (Array.isArray(value)) {
				if (value.includes(v as never)) {
					setValue((state) => Array.isArray(state) && (state.filter((it: ISelectOption['value']) => it !== v) as string[] | number[] | boolean[]));
				} else {
					setValue([...value, v] as string[] | number[] | boolean[]);
				}
			}
		},
		[multiple, onChange, value],
	);

	const apply = () => {
		if ((Array.isArray(value) && value?.length > 0) || selectAll) onChange(value);
		else onChange(undefined);
		if (multipleWithoutButton) return;
		closeDropwDown();
	};

	const cancel = useCallback(() => {
		setValue(valueProps);
		closeDropwDown();
	}, [valueProps]);

	useEffect(() => {
		if (!open && onCloseClear) cancel();
	}, [open, onCloseClear, cancel]);

	const filterOptions = useCallback(
		(option: ISelectOption) => {
			return Object.values(option)
				.map(String)
				.find((v) => v.toLowerCase().includes(query.toLowerCase()));
		},
		[query],
	);

	const renderOption = useCallback(
		(option: ISelectOption) => {
			if (renderOptionProp) return renderOptionProp(option);
			else return option.label;
		},
		[renderOptionProp],
	);

	useEffect(() => {
		if (multipleWithoutButton) apply();
	}, [value]);

	const createOptions = useCallback(() => {
		return [...dataOptions]
			.filter(filterOptions)
			.slice(0, limit)
			.map((option) => {
				const active = multiple && Array.isArray(value) ? value.includes(option.value as never) : value === option.value;
				return (
					<div
						className={clsx(classes.selectOption, {
							[classes.selectOption_active]: active && !multiple,
							[classes.selectOption_onlyView]: onlyView,
						})}
						key={`${option.value}${option.additionalLabel || ''}`}
						onClick={handleChangeValue(option.value)}
						aria-label={String(option.value)}
					>
						{isExport && option.Icon && (
							<React.Fragment>
								<option.Icon className={classes.exportIcon} />
							</React.Fragment>
						)}
						{!multiple ? (
							renderOption(option)
						) : (
							<Checkbox isChecked={active} rootClassName={classes.selectCheckboxRoot} textClassName={classes.selectCheckboxText}>
								{renderOption(option)}
								{option.Icon && <option.Icon />}
							</Checkbox>
						)}
					</div>
				);
			});
	}, [dataOptions, filterOptions, handleChangeValue, limit, multiple, renderOption, value, onlyView, isExport]);

	const handleSelectAll = () => {
		if (Array.isArray(value) && value.length === dataOptions.length) {
			setValue([]);
		} else {
			setValue(dataOptions.map((option) => option.value) as string[] | number[] | boolean[]);
		}
	};

	const options = createOptions();

	const renderValue = () => {
		if (multiple && Array.isArray(value)) {
			if (multipleWithLabel && value.length === 1) return dataOptions.find((option) => option.value === value[0])?.label;
			return value.length ? `${multipleTitle} (${value.length})` : emptyLabel;
		}
		if (defaultValue) {
			return defaultValue;
		}
		return dataOptions?.find((option) => option.value === value)?.label || emptyLabel;
	};

	const renderButtons = () => {
		if (!multiple || multipleWithoutButton) return null;
		return (
			<div className={clsx(classes.buttons, 'buttons')}>
				<button onClick={cancel} className={classes.buttonCancel} data-testid={TEST_IDS.SELECT_CANCEL_BUTTON}>
					{t('cancel')}
				</button>
				<button onClick={apply} data-testid={TEST_IDS.SELECT_APPLY_BUTTON}>
					{t('apply')}
				</button>
			</div>
		);
	};

	const handleClickClear = () => {
		if (multiple) setValue([]);
		else setValue(undefined);
		onChange(undefined);
	};

	const clear = <ClearIcon className={classes.clearValue} onClick={handleClickClear} />;

	const hasClear = () => {
		if (withoutClear) return false;
		if (Array.isArray(value) && multiple) {
			return !!value.length;
		}
		if (value) {
			return true;
		}
		return false;
	};

	const renderClear = () => {
		if (hasClear()) {
			return clear;
		}
		return null;
	};

	const optionsContainer = (
		<div className="options">
			{selectAll && (
				<div
					className={clsx(classes.selectOption, {
						[classes.selectOption_active]: Array.isArray(value) && value.length === dataOptions.length,
					})}
					onClick={handleSelectAll}
					aria-label="select-all"
				>
					<Checkbox
						isChecked={Array.isArray(value) && value.length === dataOptions.length}
						rootClassName={classes.selectCheckboxRoot}
						textClassName={classes.selectCheckboxText}
					>
						{t('selectAll')}
					</Checkbox>
				</div>
			)}
			{options}
		</div>
	);

	const refDivProperties = ref.current?.getBoundingClientRect();
	const portalOptionsLeft = (refDivProperties?.left || 0) + (refDivProperties?.width || 0) / 2;
	const portalOptionsTop = (refDivProperties?.top || 0) + (refDivProperties?.height || 0);

	return (
		<div className={clsx(classes.root, rootClassName)} ref={refRoot}>
			<div ref={ref}>
				{label && <div className={classes.label}>{label}</div>}
				<div
					className={clsx(classes.inner, {
						[classes.disabled]: disabled,
						[classes.hasClear]: hasClear(),
					})}
				>
					{Icon && (
						<Icon
							className={clsx(classes.icon, {
								[classes.icon_color__success]: iconColor === 'success',
							})}
							onClick={toggleOpen}
						/>
					)}
					<div
						className={clsx(classes.control, classes.controlSelect, controlClassName, {
							[classes.withIcon]: !!Icon,
							[classes.focus]: !!open,
							[classes.control_empty]: empty,
							[classes.error]: error,
						})}
						onClick={toggleOpen}
						data-testid={testId}
						aria-label={renderValue() as string}
					>
						{renderClear()}
						<span>{renderValue()}</span>
						<Arr
							className={clsx(classes.icon, classes.selectArr, {
								[classes.rotateArr]: open,
							})}
						/>
					</div>
				</div>
				{(open || forceOpen) && !!dataOptions.length && (
					<div
						className={clsx('Select__inner-dropdown', classes.dropDownSelect, {
							[classes.bottomGap]: multipleWithoutButton,
							[classes.empty]: portal,
						})}
					>
						{withSearch && (
							<Input
								clear
								positionIcon="left"
								value={query}
								onChange={handleChangeSearch}
								rootClassName={classes.selectSearchRoot}
								controlClassName={classes.selectSearchControl}
								placeholder={`${t('search')}...`}
							/>
						)}
						{portal
							? createPortal(
									<div
										style={{
											width: refDivProperties?.width,
											left: portalOptionsLeft,
											top: portalOptionsTop,
										}}
										className={clsx(classes.dropDownSelect, classes.dropDownSelect_portal, rootClassName)}
									>
										{optionsContainer}
									</div>,
									document.body,
								)
							: optionsContainer}
						{renderButtons()}
					</div>
				)}
				{error && <div className={classes.errorText}>{error}</div>}
			</div>
		</div>
	);
};

export default React.forwardRef(Select);
