import React from 'react';
import clsx from 'helpers/clsx';
import { ReactComponent as Icon } from 'static/images/check_box.svg';
import { TEST_IDS } from 'tests/config';

import { IPropsCheckbox } from './types';

import classes from './FormControl.module.scss';

const Checkbox: React.FC<IPropsCheckbox> = ({ rootClassName, textClassName, isChecked, onChange, children, disabled }) => {
	return (
		<div
			className={clsx(classes.checkbox, rootClassName, {
				[classes.disabled]: disabled,
			})}
			onClick={!disabled ? onChange : undefined}
			data-testid={clsx({
				[TEST_IDS.CHECKBOX]: !isChecked,
				[TEST_IDS.CHECKBOX_CHECKED]: isChecked,
			})}
		>
			<div
				className={clsx(classes.checkboxIcon, {
					[classes.checkboxIcon_active]: isChecked,
					[classes.checkboxIcon_noActive]: !isChecked,
				})}
			>
				<Icon />
			</div>
			<div className={clsx(classes.checkboxText, textClassName)}>{children}</div>
		</div>
	);
};

export default Checkbox;
