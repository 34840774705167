import React from 'react';
import clsx from 'helpers/clsx';
import { ReactComponent as Icon } from 'static/images/export.svg';

import { IProps } from './types';

import classes from './ExportButton.module.scss';

const ExportButton: React.ForwardRefRenderFunction<HTMLButtonElement, IProps> = ({ children, className, onClick, disabled }, ref) => {
	return (
		<button disabled={disabled} className={clsx(classes.root, className)} onClick={onClick} ref={ref}>
			<Icon className={classes.icon} />
			{children}
		</button>
	);
};

export default React.forwardRef(ExportButton);
