import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery, useSubscription } from '@apollo/client';
import { clientGraphql } from 'api';
import { SUBSCRIPTION_GET_NEW_CONTRACT, SUBSCRIPTION_GET_NEW_HISTORY, TAG_COUNT } from 'api/partnersFile';
import { Routes } from 'const';
import CreateContract from 'containers/modals/CreateContract';
import NotificationAuthOtherUser from 'containers/NotificationAuthOtherUser';
import PartnerFilterSelect from 'containers/PartnerFilterSelect/PartnerFilterSelect';
import CollapsiblePartnersFileTable from 'containers/PartnersFileTable';
import clsx from 'helpers/clsx';
import { useDeepCompareEffect } from 'hooks/useDeepCompareEffect';
import { useExport } from 'hooks/useExport';
import { usePartnersFile } from 'hooks/usePartnersFile';
import { useQuery as useQueryUrl } from 'hooks/useQuery';
import { useUser } from 'hooks/useUser';
import { useUsers } from 'hooks/useUsers';
import { IFilter, PartnerDataFullDto, PartnerHistory } from 'interfaces/partnersFile';
import { ReactComponent as EyeIco } from 'static/images/eye-stroke.svg';
import { ReactComponent as SearchIcon } from 'static/images/search.svg';
import { ReactComponent as SettingsIcon } from 'static/images/settings.svg';
import { ReactComponent as WarnIco } from 'static/images/warn.svg';
import { groupBy } from 'underscore';

import ExportButton from 'components/ExportButton';
import { Checkbox, Input, Select } from 'components/FormControl';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination';
import { VchasnoSync } from 'components/VchasnoSync';

import { columns } from './columns';

import classes from './PartnersFile.module.scss';

const PartnersFile: React.FC = () => {
	const {
		fetchFile,
		filter,
		filtration,
		changeFilter,
		pagination,
		setPage,
		onload,
		wasLoaded,
		items,
		total,
		fetchDictionary,
		updateTable,
		updateContractAndhistory,
		fetchAccessData,
		loadingAccessData,
		currentUserHasAccess,
	} = usePartnersFile();
	const { t } = useTranslation('partners');
	const { data: partnerContract } = useSubscription<{ partnerContract: PartnerDataFullDto }>(SUBSCRIPTION_GET_NEW_CONTRACT);
	const { data: history } = useSubscription<{ partnerHistory: PartnerHistory[] }>(SUBSCRIPTION_GET_NEW_HISTORY);
	const { data: partnerTag } = useQuery<{ partnerTagCount: number }>(TAG_COUNT);
	const { filter: usersFilter, fetchUsers } = useUsers();
	const { user, isCredentialsAdmin } = useUser();
	const query = useQueryUrl();
	const hasSearch = query.get('search');
	const browserHistory = useHistory();
	const location = useLocation();
	const [partnerTagCount, setPartnerTagCount] = useState<number>(partnerTag?.partnerTagCount || 0);
	const getPartnerTagsCount = async () => {
		const { data } = await clientGraphql.query({
			query: TAG_COUNT,
			fetchPolicy: 'no-cache',
		});
		setPartnerTagCount(data.partnerTagCount as number);
	};
	const { exportPartnerFile, onload: onloadExport } = useExport();

	useEffect(fetchFile, [filter.isOrderAsc, filter.search, filter.withActiveStoreAddresses, filtration, pagination.page]);

	useEffect(fetchDictionary, []);

	useEffect(() => {
		if (hasSearch) {
			changeFilter('search', hasSearch);
		}
	}, [hasSearch]);

	useDeepCompareEffect(() => {
		if (partnerContract) {
			updateTable(partnerContract.partnerContract);
		}
	}, [partnerContract]);

	useDeepCompareEffect(() => {
		if (history?.partnerHistory.length) {
			updateContractAndhistory(history.partnerHistory);
			if (history.partnerHistory.find((row) => row.field === 'comment')) {
				getPartnerTagsCount();
			}
		}
	}, [history?.partnerHistory]);

	useEffect(fetchUsers, [usersFilter.storeAddressIdAndStoreNameAndEmail]);

	useEffect(fetchAccessData, []);

	const handleClickPrev = () => {
		setPage(pagination.page - 1);
	};

	const handleClickNext = () => {
		setPage(pagination.page + 1);
	};

	const start = pagination.page * pagination.pageSize + 1;
	const end = start + pagination.pageSize - 1;

	const renderPagination = () => {
		if (!items.length) return null;
		return (
			<Pagination start={start} end={end} total={total} onClickNext={handleClickNext} onClickPrev={handleClickPrev} className={classes.pagination} />
		);
	};

	const search = (value: string) => {
		changeFilter('search', value);
		if (value) {
			browserHistory.push(`${location.pathname}?search=${value}`);
		} else {
			browserHistory.push(location.pathname);
		}
	};

	const handleChangeFilter = (field: keyof IFilter) => (value: IFilter[typeof field]) => {
		changeFilter(field, value);
	};

	const columnDefs = columns.map((c) => ({ ...c, headerName: t(c.headerName) }));

	const mapColumns = columnDefs.map((column) => ({ value: column.field || '', label: column.headerName || '' }));

	const groupedRows = groupBy(items, 'contractNumberBase');

	const handleAllTaggedContracts = () => {
		changeFilter('search', user?.email);
	};

	const columnOptions = mapColumns.slice(1, mapColumns.length);

	return (
		<div className={classes.root}>
			<NotificationAuthOtherUser />
			<div className={classes.containerSelect}>
				<div className={classes.container}>
					<Input
						fullWidth
						onChange={search}
						value={filter.search}
						placeholder={t('search')}
						rootClassName={classes.inputRoot}
						controlClassName={classes.input}
						Icon={SearchIcon}
						positionIcon="right"
						delayOnChange={500}
						disabled={onload}
					/>
				</div>
				<div className={classes.container}>
					<Select
						withSearch
						multiple
						withoutClear
						fullWidth
						selectAll
						emptyLabel={t('columns')}
						onChange={handleChangeFilter('columns')}
						value={
							filter.columns
								? columnOptions.map((column) => column.value).filter((id) => filter.columns?.includes(id))
								: columnOptions.map((column) => column.value)
						}
						disabled={onload || !items.length}
						empty={false}
						rootClassName={classes.filters}
						multipleTitle={t('columns')}
						// testId={TEST_IDS.ORDERS_FILTERS_ACCOUNTING_TYPES}
						options={columnOptions}
					/>
				</div>
				<div className={classes.container}>
					<PartnerFilterSelect columns={columnDefs.filter((column) => column.field !== 'allDocuments')} />
				</div>
				<div className={classes.container}>
					<Checkbox
						isChecked={filter.withActiveStoreAddresses || false}
						onChange={() => handleChangeFilter('withActiveStoreAddresses')(!filter.withActiveStoreAddresses)}
					>
						{t('withActiveStoreAddresses')}
					</Checkbox>
				</div>
				<div className={classes.container}>
					{(isCredentialsAdmin || currentUserHasAccess) && <CreateContract />}
					{isCredentialsAdmin && (
						<button className={classes.permissions_button}>
							<SettingsIcon color="#797A8D" onClick={() => browserHistory.push(Routes.PARTNERS_FILE_PERMISSIONS)} />
						</button>
					)}
				</div>
			</div>
			<div className={clsx(classes.containerSelect, classes.containerSelect_second)}>
				<div className={classes.container}>
					<ExportButton disabled={onload || !items.length} className={classes.exportButton} onClick={() => exportPartnerFile(true)}>
						{t('exports:partners')}
					</ExportButton>
					<ExportButton disabled={onload || !items.length} className={classes.exportButton} onClick={() => exportPartnerFile()}>
						{t('exports:compare')}
					</ExportButton>
				</div>
			</div>
			{((onload && wasLoaded) || onloadExport || loadingAccessData) && <Loader />}
			{onload && !wasLoaded ? (
				<Loader simple />
			) : (
				<div className={classes.table_wrapper}>
					<CollapsiblePartnersFileTable groupedRows={groupedRows} columns={columnDefs} />
				</div>
			)}
			<div className={classes.bottom}>
				{renderPagination()}
				{!!partnerTagCount && user?.email && !filter.search && !onload && (
					<div className={classes.signature}>
						<span>
							<WarnIco />
							{t(partnerTagCount > 1 ? 'partnerTagCount' : 'partnerTagCountSingle', { count: partnerTagCount })}
						</span>
						<span onClick={handleAllTaggedContracts}>
							<EyeIco />
							{t('partnerTagCountСheck')}
						</span>
					</div>
				)}
				{items.length > 0 && <VchasnoSync />}
			</div>
		</div>
	);
};

export default PartnersFile;
