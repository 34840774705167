import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import { GET_POWER_OUTAGES } from 'api/powerOutage';
import NotificationAuthOtherUser from 'containers/NotificationAuthOtherUser';
import clsx from 'helpers/clsx';
import guide1 from 'static/guide/guide-1.jpg';
import guide2 from 'static/guide/guide-2.jpg';
import guide3 from 'static/guide/guide-3.jpg';
import guide4 from 'static/guide/guide-4.jpg';
import guide5 from 'static/guide/guide-5.jpg';
import { ReactComponent as CircleIcon } from 'static/images/circle.svg';
import { ReactComponent as DocIcon } from 'static/images/doc.svg';
import { ReactComponent as InfoIcon } from 'static/images/info.svg';
import { ReactComponent as PlaceIcon } from 'static/images/place.svg';
import { ReactComponent as SearchIcon } from 'static/images/search.svg';
import { ReactComponent as TgLogoIcon } from 'static/images/tg-logo.svg';
import { TEST_IDS } from 'tests/config';

import CopyButton from 'components/CopyButton';
import { Input } from 'components/FormControl';
import Loader from 'components/Loader';
import LoadMore from 'components/LoadMore';
import Modal from 'components/modals/Modal';

import classes from './PowerOutage.module.scss';

interface IFilter {
	pageNumber: number;
	pageSize: number;
	filter: {
		searchQuery: string;
	};
}

interface IAddress {
	storeAddressId: number;
	storeName: string;
	address: string;
	hash: string;
}

interface IResponse {
	storeAddressesBot: {
		storeAddressBots: IAddress[];
		total: number;
		totalPages: number;
	};
}

const PowerOutage: React.FC = () => {
	const [items, setItems] = useState<IAddress[] | null>(null);
	const [aborterRef, setAbortRef] = useState(new AbortController());
	const [powerFilter, setPowerFilter] = useState<IFilter>({
		pageNumber: 0,
		pageSize: 100,
		filter: {
			searchQuery: '',
		},
	});
	const [openModal, setOpenModal] = useState<boolean>(false);
	const { t } = useTranslation('powerOutage');
	const [fetchPowerOutages, { loading: loadingPowerOutages, data: powerOutages }] = useLazyQuery<IResponse>(GET_POWER_OUTAGES);

	const params: LazyQueryHookOptions = {
		variables: powerFilter,
		fetchPolicy: 'network-only',
		context: {
			fetchOptions: {
				signal: aborterRef.signal,
			},
		},
	};

	const fetchOutagesAction = () => {
		fetchPowerOutages(params);
	};

	const itemsRecord = () => {
		if (powerOutages?.storeAddressesBot) {
			setItems((it) =>
				powerFilter.pageNumber && it
					? [...it, ...powerOutages.storeAddressesBot.storeAddressBots]
					: [...powerOutages.storeAddressesBot.storeAddressBots],
			);
		}
	};

	useEffect(fetchOutagesAction, [powerFilter]);
	useEffect(() => {
		itemsRecord();
		return () => setItems([]);
	}, [powerOutages]);

	const articles = [
		t('article1'),
		t('article2'),
		t('article3'),
		t('article4'),
		t('article5'),
		t('article6'),
		t('article7'),
		t('article8'),
		t('article9'),
	];

	const handlePageParams = (field: keyof Pick<IFilter, 'pageNumber' | 'pageSize'>, count: number) => () =>
		setPowerFilter((prevFilter) => ({
			...prevFilter,
			[field]: count,
		}));

	const handleChangeFilter = (field: keyof IFilter['filter']) => (query: string) => {
		if (aborterRef && typeof setAbortRef === 'function') {
			aborterRef.abort();
			setAbortRef(new AbortController());
		}
		setItems(null);
		setPowerFilter((prevFilter) => ({
			pageNumber: 0,
			pageSize: 100,
			filter: {
				...prevFilter.filter,
				[field]: query,
			},
		}));
	};

	const handleOpenModal = () => setOpenModal(true);
	const handleCloseModal = () => setOpenModal(false);

	const getImage = (i: number) => {
		switch (i) {
			case 1:
				return <img src={guide1} alt="bot usage screenshot" />;
			case 2:
				return <img src={guide2} alt="bot usage screenshot" />;
			case 4:
				return <img src={guide3} alt="bot usage screenshot" />;
			case 5:
				return <img src={guide4} alt="bot usage screenshot" />;
			case 7:
				return <img src={guide5} alt="bot usage screenshot" />;
			default:
				return null;
		}
	};

	const hashLink = 'https://t.me/GlovoUABlackoutBot?start=';

	const start = powerFilter.pageNumber * powerFilter.pageSize + 1;
	const end = start + powerFilter.pageSize - 1;
	const canLoadMore = !!powerOutages?.storeAddressesBot.storeAddressBots?.length && end < powerOutages?.storeAddressesBot.total;

	return (
		<div className={classes.root} data-testid={TEST_IDS.POWER_OUTAGES_PAGE}>
			<div className={classes.body}>
				<NotificationAuthOtherUser />
				<Modal
					overlayClassName={classes.guide_overlay}
					modalClassName={classes.guide_modal}
					rootClassName={classes.guide_modalRoot}
					open={openModal}
					onClose={handleCloseModal}
				>
					<div className={classes.guide_body}>
						<h1 className={classes.guide_title}>{t('guide.title')}</h1>
						{articles.map((article, i) => (
							<div key={article + i} className={classes.guide_step}>
								<div className={classes.guide_article}>
									<i className={classes.guide_ico}>
										<CircleIcon />
										<div>{i + 1}</div>
									</i>
									<p>{article}</p>
								</div>
								{getImage(i + 1)}
							</div>
						))}
					</div>
				</Modal>
				<div className={classes.header}>
					<div className={classes.title}>{t('title')}</div>
					<div onClick={handleOpenModal} className={classes.guide_button}>
						<InfoIcon />
						{t('guide.button')}
					</div>
				</div>
				<div className={classes.infoLine}>
					<div>
						<DocIcon />
					</div>
					<div className={classes.infoLine_text}>
						{t('info')}
						<span onClick={handleOpenModal}>{t('guideInfoLink')}</span>
					</div>
				</div>
				<div className={clsx(classes.flex, classes.justifyContentBetween)}>
					<Input
						onChange={handleChangeFilter('searchQuery')}
						value={powerFilter.filter.searchQuery}
						placeholder={t('search')}
						rootClassName={classes.inputRoot}
						controlClassName={classes.input}
						Icon={SearchIcon}
						positionIcon="right"
						delayOnChange={500}
					/>
				</div>
				{!items && loadingPowerOutages ? (
					<Loader />
				) : (
					<>
						{loadingPowerOutages && <Loader />}
						<table className={classes.items}>
							<thead>
								<tr>
									<th>{t('fields.file')}</th>
									<th>{t('fields.id')}</th>
									<th className={classes.address}>{t('fields.address')}</th>
									<th>{t('fields.link')}</th>
									<th>{t('fields.code')}</th>
								</tr>
							</thead>
							<tbody>
								{items?.map((item) => {
									const row = (
										<>
											<td>
												<div className={clsx(classes.flex, classes.alignItemsCenter)}>
													<PlaceIcon className={classes.placeIcon} /> {item.storeName}
												</div>
											</td>
											<td className={classes.id}>{item.storeAddressId}</td>
											<td className={classes.address}>{item.address}</td>
											<td>
												<div className={classes.botInner}>
													<a href={hashLink + item.hash} target="_blank" className={classes.bot} rel="noreferrer">
														<TgLogoIcon />
														GlovoBlackoutBot
													</a>
													<CopyButton text={hashLink + item.hash} className={classes.copyHash} />
												</div>
											</td>
											<td>
												<div className={classes.hash}>
													{item.hash}
													<CopyButton text={item.hash} className={classes.copyHash} />
												</div>
											</td>
										</>
									);
									return (
										<React.Fragment key={item.hash}>
											<tr className={classes.spacer}>
												<td colSpan={3}></td>
											</tr>
											<tr>{row}</tr>
										</React.Fragment>
									);
								})}
							</tbody>
						</table>
						{canLoadMore && <LoadMore onClick={handlePageParams('pageNumber', powerFilter.pageNumber + 1)} className={classes.loadMore} />}
					</>
				)}
			</div>
		</div>
	);
};

export default PowerOutage;
