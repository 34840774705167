import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { MixedTags } from '@yaireo/tagify/dist/react.tagify';
import { clientGraphql } from 'api';
import { IBAN_VALIDATION } from 'api/partnersFile';
import AllDocuments from 'containers/modals/AllDocuments';
import Commission from 'containers/modals/Commission';
import ContractHistory from 'containers/modals/ContractHistory';
import CreateContract from 'containers/modals/CreateContract';
import { format } from 'date-fns';
import clsx from 'helpers/clsx';
import { getNewestContract, parseCommentTextWithTags } from 'helpers/partnersFile';
import { delay } from 'helpers/utis';
import { useClickAwayListener } from 'hooks/useClickAwayListener';
import { useDocuments } from 'hooks/useDocuments';
import { usePartnersFile } from 'hooks/usePartnersFile';
import { useUser } from 'hooks/useUser';
import { useUsers } from 'hooks/useUsers';
import { ERRORS } from 'interfaces';
import { PartnerDataFullDto } from 'interfaces/partnersFile';
import { Column } from 'pages/PartnersFile/types';
import { ReactComponent as ArrowDownIcon } from 'static/images/arrow_down.svg';
import { ReactComponent as DeleteIcon } from 'static/images/deleteIcon.svg';
import { ReactComponent as Dots } from 'static/images/dots.svg';
import { ReactComponent as PlusIcon } from 'static/images/plusIcon.svg';
import { ReactComponent as LinkIcon } from 'static/images/target_link.svg';
import { ReactComponent as TimeIcon } from 'static/images/time.svg';
import { ReactComponent as WarningIcon } from 'static/images/warningIcon.svg';

import CopyButton from 'components/CopyButton';
import EmptyResult from 'components/EmptyResult';
import { Checkbox, DatePicker } from 'components/FormControl';
import Confirm from 'components/modals/Confirm';
import { LightTooltip } from 'components/StyledLightTooltip';
import { StyledMenuItem } from 'components/StyledMenuItem';
import { StyledTableCell } from 'components/StyledTableCell';
import { StyledTableRow } from 'components/StyledTableRow';

import { RowInterface, TableProps } from './types';

import '@yaireo/tagify/dist/tagify.css';
import classes from './Table.module.scss';

const MAX_TEXT_LENGTH = 33;
const DEFAULT_COLUMN_WIDTH = 130;

function Row({ row, columns, handleOpenMenu, childRows, handleEditContractData, ibanErrors, editableInput, setEditableInput }: RowInterface) {
	const [open, setOpen] = React.useState(false);
	const { t } = useTranslation(['partners', 'errors']);
	const { getStatusText, getStatusStyle } = useDocuments();
	const { selectedRow, resetSelectedRow, accessData, currentUserHasAccess } = usePartnersFile();
	const { items, changeFilter } = useUsers();
	const { dictionaries } = usePartnersFile();
	const [inputsData, setInputText] = React.useState<{ [key: string]: string }>({});
	const editableInputRef = React.useRef<HTMLDivElement | null>(null);
	const [mixedTagsSettings, setMixedTagsSettings] = React.useState({});
	const { isCredentialsAdmin } = useUser();

	React.useEffect(() => {
		setMixedTagsSettings((lastProps) => ({
			...lastProps,
			whitelist: items.map((usr) => ({ id: usr.id, value: usr.email, title: usr.email })),
			loading: false,
		}));
	}, [items]);

	const handleEditInputData = (key: string, contractNumber: string, value: string) => {
		setInputText({ ...inputsData, [contractNumber + key]: value });
	};

	const closeEditableWindow = (event: MouseEvent | TouchEvent) => {
		const target = event.target as HTMLElement;
		if (!target.className.includes('tagify')) {
			setEditableInput(null);
		}
	};

	useClickAwayListener(editableInputRef, closeEditableWindow);
	const isCurrentRowSelected = selectedRow?.contractNumber === row.contractNumber;

	React.useEffect(() => {
		if (selectedRow && isCurrentRowSelected) {
			setTimeout(resetSelectedRow, 1500);
		}
	}, [selectedRow]);

	const changeCommentInput = React.useCallback((e) => {
		const value = (e.detail.event.target.innerText + (e.detail.event.key.length === 1 ? e.detail.event.key : '')).replaceAll('\n', '');
		const regex = /[^@]*\S$/gm;
		const containEmail = value.match(regex);
		const searchString = containEmail && containEmail[0].split(' ')[0];
		if (searchString && value.includes('@')) {
			delay('onChangeInput', () => changeFilter('storeAddressIdAndStoreNameAndEmail', searchString), 400);
		}
	}, []);

	const renderColumn = (column: Column, currentRow: PartnerDataFullDto) => {
		const key = column.field as keyof PartnerDataFullDto;
		const value = currentRow[key] as string;
		const contractNumber = currentRow.contractNumber;
		const columnAccess = accessData.fields?.find((field) => field.fieldName === column.field);
		let disabled = true;
		if ((isCredentialsAdmin || !columnAccess?.adminAccess || (columnAccess?.adminAccess && currentUserHasAccess)) && !column.disabled) {
			disabled = false;
		}
		if (column.disabled) {
			disabled = true;
		}
		switch (column.component) {
			case 'link':
				if (!value) return '-';
				return (
					<div className={classes.link}>
						<a href={value} target="_blank" rel="noreferrer">
							<LinkIcon color="#797A8D" />
						</a>
					</div>
				);
			case 'status':
				return <div className={clsx(classes.statusText, getStatusStyle[value as keyof typeof getStatusStyle])}>{getStatusText(value)}</div>;
			case 'checkbox': {
				let checked = currentRow[key] as boolean;
				if (typeof currentRow[key] !== 'boolean') {
					checked = JSON.parse(currentRow[key] as string);
				}
				return <Checkbox disabled={disabled} isChecked={checked} onChange={() => handleEditContractData(key, !checked, contractNumber)} />;
			}
			case 'date':
				return (
					<DatePicker
						isClearable
						rootClassName={classes.datePicker}
						onChange={(date) => handleEditContractData(key, date ? format(date as Date, 'yyyy-MM-dd') : date, contractNumber)}
						datepickerProps={{
							startDate: !isNaN(Date.parse(value)) ? new Date(value) : undefined,
						}}
						disabled={disabled}
					/>
				);
			case 'select': {
				const options = dictionaries.find((d) => d.type === key);
				if (!options) return;
				if (column.field === 'reasonDoNotPay' && (+currentRow.payingBulkStatus === 11 || currentRow.payingBulkStatus === null)) return;
				return (
					<Select
						fullWidth
						value={value ? value.toString() : ''}
						onChange={(e: SelectChangeEvent) => handleEditContractData(key, e.target.value, contractNumber)}
						variant="standard"
						disabled={disabled}
						disableUnderline
						startAdornment={
							value &&
							!disabled && (
								<button
									className={clsx(classes.select_clear, 'react-datepicker__close-icon')}
									onClick={() => handleEditContractData(key, null, contractNumber)}
								/>
							)
						}
						sx={{
							'&': {
								fontSize: 14,
								color: '#493F58',
								fontFamily: 'Montserrat',
								fontWeight: 500,
								'&:hover': {
									'& > .react-datepicker__close-icon': {
										visibility: 'visible',
									},
								},
							},
							'.MuiInput-input:focus': {
								backgroundColor: 'transparent',
							},
							'.Mui-disabled': {
								color: '#493F58',
								WebkitTextFillColor: '#493F58 !important',
							},
						}}
					>
						{options.data?.map((option) => (
							<StyledMenuItem key={option.id} value={option.id}>
								{option.value}
							</StyledMenuItem>
						))}
					</Select>
				);
			}
			case 'commission':
				return (
					<div className={classes.commission}>
						{currentRow.isCommissionValid ? (
							<span>{currentRow.commission.length ? `${t('current')}: ${currentRow.currentPartnerCommission || 0}%` : null}</span>
						) : (
							<LightTooltip
								arrow
								color="#F65D3B"
								title={<Trans t={t} i18nKey={`errors:${ERRORS.PARTNER_COMMISSION_IS_NOT_VALID}`} />}
								placement="top"
							>
								<WarningIcon />
							</LightTooltip>
						)}
						<Commission hasAccess={!disabled} contractNumber={currentRow.contractNumber} currentCommissionList={currentRow.commission} />
					</div>
				);
			case 'documents': {
				const sum = (currentRow.allDocuments?.signed || 0) + (currentRow.allDocuments?.unSigned || 0);
				return (
					<div className={classes.documents}>
						{currentRow.allDocuments ? (
							<React.Fragment>
								<span>{t('signed')}</span>
								<span
									className={clsx({ [classes.unsigned]: sum !== currentRow.allDocuments?.signed })}
								>{`${currentRow.allDocuments?.signed}/${sum}`}</span>
								<AllDocuments contractNumber={currentRow.contractNumber} />
							</React.Fragment>
						) : (
							'-'
						)}
					</div>
				);
			}
			case 'input': {
				const currentInputValue = inputsData[contractNumber + key] || value;
				const input = (
					<textarea
						onChange={(e) => {
							if ((column.pattern && column.pattern.test(e.target.value)) || !column.pattern || !e.target.value) {
								handleEditInputData(key, contractNumber, e.target.value);
							}
						}}
						className={classes.input}
						value={currentInputValue}
						onBlur={() => {
							handleEditContractData(key, currentInputValue, contractNumber);
							setEditableInput(null);
						}}
						rows={15}
						cols={40}
						disabled={disabled}
					/>
				);
				if (editableInput === contractNumber + key) {
					return (
						<div ref={editableInputRef} className={classes.textarea_absolute}>
							{input}
						</div>
					);
				}
				return (
					<div
						className={classes.inputWrapper}
						style={{ width: column.link && column.width ? column.width - 30 : column.width || DEFAULT_COLUMN_WIDTH }}
						onClick={() => !disabled && setEditableInput(contractNumber + key)}
					>
						{value}
					</div>
				);
			}
			case 'storeAddresses': {
				const ids = currentRow[key] as number[];
				const idsString = ids.join(', ');
				return ids.length > 3 ? (
					<LightTooltip title={idsString} placement="right" arrow>
						<div className={classes.inputWrapper}>{idsString}</div>
					</LightTooltip>
				) : (
					idsString
				);
			}
			case 'comment': {
				const tagMix = (
					<MixedTags
						onBlur={(e: CustomEvent) => {
							const newValue = e.detail.tagify.DOM.originalInput.tagifyValue.trim();
							const oldValue = (value ? value : '').trim();
							if (oldValue !== newValue) {
								handleEditContractData(key, newValue.replaceAll('\n'), contractNumber);
								setEditableInput(null);
							}
						}}
						onKeydown={changeCommentInput}
						value={value || ''}
						className={classes.tags}
						settings={{
							pattern: /@/,
							dropdown: {
								enabled: 1,
								position: 'input',
								classname: classes.tagify_dropdown,
							},
							whitelist: [],
						}}
						{...mixedTagsSettings}
					/>
				);
				if (editableInput === contractNumber + key) {
					return (
						<div ref={editableInputRef} className={clsx(classes.textarea_absolute, classes.tagify_wrap_absolute)}>
							{tagMix}
						</div>
					);
				}
				const valueWithTags = parseCommentTextWithTags(value, true);
				return (
					<div
						className={classes.inputWrapper}
						style={{ width: column.link && column.width ? column.width - 30 : column.width || DEFAULT_COLUMN_WIDTH }}
						onClick={() => !disabled && setEditableInput(contractNumber + key)}
					>
						{valueWithTags}
					</div>
				);
			}
			default:
				return (
					<React.Fragment>
						{typeof value !== 'object' ? (
							value && value.length > MAX_TEXT_LENGTH ? (
								<LightTooltip title={value} placement="right" arrow>
									<div>{value.slice(0, MAX_TEXT_LENGTH)}...</div>
								</LightTooltip>
							) : (
								value
							)
						) : null}
					</React.Fragment>
				);
		}
	};

	return (
		<React.Fragment>
			<StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }} selected={isCurrentRowSelected}>
				<StyledTableCell className={classes.sticky} component="th" scope="row" sx={{ padding: '6px 16px 6px 4px' }}>
					<div className={classes.first_cell}>
						<IconButton
							aria-label="expand row"
							size="small"
							onClick={(e) => handleOpenMenu(e, row.contractNumber)}
							className={classes.dots}
							id={row.contractNumber}
							data-open="menu"
						>
							<Dots />
						</IconButton>
						<span>{row.contractNumber}</span>
						{childRows.length > 0 && (
							<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} sx={{ padding: '10px 8px' }}>
								{open ? <ArrowDownIcon /> : <ArrowDownIcon style={{ transform: 'rotate(180deg)' }} />}
							</IconButton>
						)}
					</div>
				</StyledTableCell>
				{columns.map((column: Column) => {
					const value = row[column.field as keyof PartnerDataFullDto] as string;
					return (
						<StyledTableCell id={column.field + row.contractNumber} key={column.field + row.contractNumber}>
							<div
								className={clsx({
									[classes.cell_link]: column.component === 'input',
									[classes.relative]: column.component === 'comment',
								})}
							>
								{renderColumn(column, row)}
								{ibanErrors && ibanErrors[row.contractNumber] && column.field === 'iban' && (
									<LightTooltip arrow color="#F65D3B" title={<Trans t={t} i18nKey={`errors:${ibanErrors[row.contractNumber]}`} />} placement="top">
										<WarningIcon />
									</LightTooltip>
								)}
								{column.link && value && value.includes('http') && (
									<a href={value} target="_blank" rel="noreferrer">
										<LinkIcon color="#797A8D" />
									</a>
								)}
								{!(ibanErrors && ibanErrors[row.contractNumber]) && column.copy && value && <CopyButton text={value} className={classes.copyIco} />}
							</div>
						</StyledTableCell>
					);
				})}
			</StyledTableRow>
			{childRows?.map((child) => (
				<StyledTableRow key={child.contractNumberBase + child.contractNumber}>
					<StyledTableCell style={{ padding: 0, borderBottom: 'none', backgroundColor: '#f1f1f1' }} colSpan={columns.length + 1}>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<Table size="small" sx={{ tableLayout: 'fixed' }}>
								<TableBody>
									<TableRow>
										<StyledTableCell className={classes.sticky} style={{ backgroundColor: '#f1f1f1' }} sx={{ padding: '6px 28px 6px 4px' }}>
											<div className={classes.first_cell}>
												<IconButton
													aria-label="expand row"
													size="small"
													onClick={(e) => handleOpenMenu(e, child.contractNumber)}
													className={classes.dots}
													id={child.contractNumber}
													data-open="menu"
												>
													<Dots />
												</IconButton>
												<span>{child.contractNumber}</span>
											</div>
										</StyledTableCell>
										{columns.map((column: Column) => {
											const value = row[column.field as keyof PartnerDataFullDto] as string;
											return (
												<StyledTableCell
													id={column.field + child.contractNumber}
													key={column.field + child.contractNumber}
													width={column.width || DEFAULT_COLUMN_WIDTH}
												>
													<div
														className={clsx({
															[classes.cell_link]: column.component === 'input',
															[classes.relative]: column.component === 'comment',
														})}
													>
														{renderColumn(column, child)}
														{column.link && value && value.includes('http') && (
															<a href={value} target="_blank" rel="noreferrer">
																<LinkIcon color="#797A8D" />
															</a>
														)}
														{ibanErrors && ibanErrors[row.contractNumber] && column.field === 'iban' && (
															<LightTooltip
																arrow
																color="#F65D3B"
																title={<Trans t={t} i18nKey={`errors:${ibanErrors[row.contractNumber]}`} />}
																placement="top"
															>
																<WarningIcon />
															</LightTooltip>
														)}
														{!(ibanErrors && ibanErrors[row.contractNumber]) && column.copy && value && (
															<CopyButton text={value} className={classes.copyIco} />
														)}
													</div>
												</StyledTableCell>
											);
										})}
									</TableRow>
								</TableBody>
							</Table>
						</Collapse>
					</StyledTableCell>
				</StyledTableRow>
			))}
		</React.Fragment>
	);
}

export default function CollapsiblePartnersFileTable({ groupedRows, columns }: TableProps) {
	const { t } = useTranslation(['partners', 'errors']);
	const { isCredentialsAdmin } = useUser();
	const [ibanErrors, setIbanErrors] = React.useState<{ [key: string]: string } | null>(null);
	const [openMenu, setOpenMenu] = React.useState<{ top: number; contractNumber: string } | null>(null);
	const [isOpenModal, setOpenModal] = React.useState<{ modal: string; contractNumber: string } | null>(null);
	const { deleteContract, editContractData, changeFilter, currentUserHasAccess } = usePartnersFile();
	const [editableInput, setEditableInput] = React.useState<string | null>(null);
	const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, contractNumber: string) => {
		if (contractNumber === openMenu?.contractNumber) {
			return closeMenu();
		}
		setOpenMenu({ top: (e.target as HTMLElement).getBoundingClientRect().top + 1, contractNumber });
	};
	const { filter } = usePartnersFile();
	const filteredColumns = columns.filter((column) =>
		filter.columns ? filter.columns?.includes(column.field) && column.field !== 'contractNumber' : column.field !== 'contractNumber',
	);

	const onKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'Escape' && openMenu) {
			setOpenMenu(null);
		}
	};

	const handleCloseMenuOnClick = (event: MouseEvent | TouchEvent) => {
		const target = event.target as HTMLElement;
		if (event.type === 'click' && !(target.dataset.open || target.parentElement?.dataset.open)) {
			closeMenu();
		}
	};

	React.useEffect(() => {
		document.addEventListener('keydown', onKeyDown);
		document.addEventListener('click', handleCloseMenuOnClick);
		return () => {
			document.removeEventListener('keydown', onKeyDown);
			document.removeEventListener('click', handleCloseMenuOnClick);
		};
	});

	const deleteAction = async () => {
		if (openMenu?.contractNumber) {
			const res = await deleteContract(openMenu.contractNumber);
			if (res) setOpenMenu(null);
		}
	};

	const closeMenu = () => {
		if (openMenu && !isOpenModal) {
			setOpenMenu(null);
		}
	};

	const handleEditContractData = async (key: keyof PartnerDataFullDto, value: string | number | boolean | null, contractNumber: string) => {
		const currentRow = groupedRows[contractNumber.split('-')[0]].find((contract) => contract.contractNumber === contractNumber);
		if (currentRow && currentRow[key] !== value) {
			if (key === 'iban') {
				const isValid = await clientGraphql
					.query({
						query: IBAN_VALIDATION,
						variables: { iban: value },
					})
					.then(({ data }: { data: { validIban: boolean } }) => {
						setIbanErrors(null);
						return data.validIban;
					})
					.catch((err) => {
						setIbanErrors({ [contractNumber]: err.message });
						return false;
					});
				if (!isValid) return;
			}
			editContractData({ contractNumber, key, value });
		}
	};

	const openModal = (e: React.MouseEvent<HTMLElement>) => {
		const target = e.currentTarget as HTMLElement;
		if (target.dataset.open && openMenu) {
			setOpenModal({ modal: target.dataset.open, contractNumber: openMenu?.contractNumber });
		}
	};

	const closeModal = () => {
		setOpenModal(null);
	};

	const changeSortDirection = () => changeFilter('isOrderAsc', !filter.isOrderAsc);

	return (
		<React.Fragment>
			{isOpenModal?.modal === 'history' && <ContractHistory contractNumber={isOpenModal?.contractNumber} onClose={closeModal} />}
			{Object.keys(groupedRows).length > 0 ? (
				<TableContainer
					onScroll={closeMenu}
					component={Paper}
					sx={{
						boxShadow: '0px 0px 0px 1px #E0E0E0',
						borderRadius: '24px',
						maxHeight: '68vh',
						minHeight: '68vh',
						clipPath: 'view-box',
					}}
				>
					{openMenu?.contractNumber && (
						<ul className={classes.menu} style={{ top: openMenu.top }}>
							{(isCredentialsAdmin || currentUserHasAccess) && (
								<CreateContract
									onClose={() => {
										closeModal();
										setOpenMenu(null);
									}}
									contractNumber={openMenu.contractNumber}
								>
									<li onClick={openModal} data-open="version">
										<PlusIcon />
										{t('newVersionOfContract')}
									</li>
								</CreateContract>
							)}
							<li onClick={openModal} data-open="history">
								<TimeIcon color="rgba(0, 0, 0, 0.56)" />
								{t('history')}
							</li>
							{(isCredentialsAdmin || currentUserHasAccess) && (
								<Confirm
									title={`${t('confirmDeleteContract')} ${openMenu.contractNumber}?`}
									body={t('confirmInfoDeleteContract')}
									confirm={{
										text: t('delete'),
										onClick: deleteAction,
									}}
									icon="delete"
									onCloseFunc={closeModal}
								>
									<li onClick={openModal} data-open="delete">
										<DeleteIcon color="rgba(0, 0, 0, 0.56)" />
										{t('deleteContract')}
									</li>
								</Confirm>
							)}
						</ul>
					)}
					<Table stickyHeader aria-label="collapsible table" size="small" sx={{ tableLayout: 'fixed' }}>
						<TableHead>
							<TableRow>
								<StyledTableCell align="center" className={classes.sticky} style={{ zIndex: 3 }}>
									<TableSortLabel
										active={true}
										direction={filter.isOrderAsc ? 'asc' : 'desc'}
										onClick={changeSortDirection}
										style={{ fontSize: 12, color: '#493F58', padding: '8px 16px' }}
									>
										{columns[0]?.headerName}
									</TableSortLabel>
								</StyledTableCell>
								{filteredColumns.map((column: Column) => (
									<StyledTableCell
										style={{ fontSize: 12, padding: '8px 16px' }}
										width={column.width || DEFAULT_COLUMN_WIDTH}
										key={column.field}
										size="small"
										variant="head"
									>
										{column.headerName.length > MAX_TEXT_LENGTH ? (
											<LightTooltip title={column.headerName} placement="top" arrow>
												<div>{column.headerName.slice(0, MAX_TEXT_LENGTH)}...</div>
											</LightTooltip>
										) : (
											column.headerName
										)}
									</StyledTableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{(filter.isOrderAsc ? Object.keys(groupedRows) : Object.keys(groupedRows).reverse())?.map((key: string) => {
								const newestContract = getNewestContract(groupedRows[key]);

								return (
									<Row
										key={key}
										row={newestContract}
										handleEditContractData={handleEditContractData}
										childRows={groupedRows[key].filter((row) => row.contractNumber !== newestContract.contractNumber)}
										columns={filteredColumns}
										handleOpenMenu={handleOpenMenu}
										ibanErrors={ibanErrors}
										setEditableInput={setEditableInput}
										editableInput={editableInput}
									/>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<EmptyResult>{t('emptyList')}</EmptyResult>
			)}
		</React.Fragment>
	);
}
