import { gql } from '@apollo/client';

export default gql`
	type UserDto {
		id: ID!
		email: String
		countries: [UserCountryRolesOutputDTO]
		parentUser: String
	}

	type UserCountryRolesOutputDTO {
		selected: Boolean
		country: CountryCodeEnum!
		roles: [RoleEnum]
		userCountryStoreIds: [Int]
		userCountryCustomerIds: [Int]
		storeAddresses: [StoreAddressDto]
	}

	type UserCountryRolesInputDTO {
		selected: Boolean
		country: CountryCodeEnum!
		roles: [RoleEnum]
		userCountryStoreIds: [Int]
		userCountryCustomerIds: [Int]
		storeAddresses: [StoreAddressDto]
		userCountryCustomerIds: [Long]
	}

	input UserInputDTO {
		email: String!
		roles: [RoleEnum]
	}

	enum RoleEnum {
		ACCOUNT
		FINANCE
		ADMIN
		STORE_ADMIN
		STORE_MANAGER
		ACCOUNTANT
		MENUTOOL
	}

	enum CountryCodeEnum {
		UA
		BY
		AM
	}

	input UserFilter {
		storeAddressIdAndStoreNameAndEmail: String
		roles: [RoleEnum!]
	}

	type UserDtoPage {
		users: [UserDto!]
		total: Int
		totalPages: Int
	}

	input UserRegisterRequest {
		email: String
		countries: [UserCountryRolesInputDTO]
	}
`;
