import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { parseDate } from 'helpers';
import { parseCommentTextWithTags } from 'helpers/partnersFile';
import { usePartnersFile } from 'hooks/usePartnersFile';
import { PartnerHistory } from 'interfaces/partnersFile';
import { groupBy } from 'underscore';

import EmptyResult from 'components/EmptyResult';
import Loader from 'components/Loader/template';
import LoadMore from 'components/LoadMore';
import Modal from 'components/modals/Modal';

import { GroupedHistory, IPropsTemplate } from './types';

import classes from './ContractHistory.module.scss';

const ContractHistory: React.FC<IPropsTemplate> = ({ onClose, contractNumber }) => {
	const { t } = useTranslation(['partners']);
	const { fetchHistory, partnerHistory, dictionaries, loadingHistory, partnerHistoryTotal, partnerHistoryPagination, setPageHistory } =
		usePartnersFile();

	useEffect(() => {
		fetchHistory(partnerHistoryPagination.page, contractNumber);
	}, [partnerHistoryPagination.page]);

	const loadMore = () => {
		setPageHistory(partnerHistoryPagination.page + 1);
	};

	const start = partnerHistoryPagination.page * partnerHistoryPagination.pageSize + 1;
	const end = start + partnerHistoryPagination.pageSize - 1;
	const canLoadMore = end < partnerHistoryTotal;

	const getDictionaryLabel = (row: PartnerHistory, id: string) => dictionaries.find((d) => d.type === row.field)?.data.find((d) => d.id === +id);

	const groupHistoryByDate: GroupedHistory = groupBy(partnerHistory, 'createdAt');

	return (
		<Modal
			open
			modalClassName={classes.modal}
			title={t('contractHistory') + ` ${contractNumber}`}
			onClose={onClose}
			rootClassName={classes.container}
			titleClassName={classes.title}
			closeButtonClassName={classes.closeButton}
		>
			<div className={classes.contract_history}>
				{partnerHistory.length > 0 && !loadingHistory
					? Object.keys(groupHistoryByDate)
							.sort((a, b) => parseDate(b, 'dd.MM.yyyy').getTime() - parseDate(a, 'dd.MM.yyyy').getTime())
							.map((group) => (
								<div key={group} className={classes.notice}>
									<div className={classes.notice_top}>
										<span className={classes.dateTime}>{group}</span>
									</div>
									{groupHistoryByDate[group].map((date) =>
										date.data.map((user) => (
											<table key={user.createdBy} className={classes.notice_table}>
												<thead>
													<tr>
														<td colSpan={3}>
															<span className={classes.email}>{user.createdBy}</span>
														</td>
													</tr>
												</thead>
												<tbody>
													{user.data.reverse().map((row, index) => {
														if (row.commissionId && row.field.includes('commission.')) {
															const allCommissions = groupBy(user.data, 'commissionId');
															const commission = allCommissions[row.commissionId] as PartnerHistory[];
															if (commission.indexOf(row) === 0) {
																return (
																	<tr key={row.createdAt + row.field + index}>
																		<td width={35}>
																			<div className={classes.time}>{format(new Date(row.createdAt), 'HH:mm')}</div>
																		</td>
																		<td width={200}>
																			<div className={classes.field}>
																				{t(`fields.commission`)} [{Object.values(allCommissions).indexOf(commission) + 1}]
																			</div>
																		</td>
																		<td>
																			{commission.map((c: PartnerHistory, i) => (
																				<div key={c.field + c.commissionId + i} className={classes.value}>
																					<div className={classes.field}>{t(c.field.replace('commission.', ''))}</div>
																					<span className={classes.before}>
																						{getDictionaryLabel(c, c.oldValue)?.value || parseCommentTextWithTags(c.oldValue)}
																					</span>
																					<span>&#8594;</span>
																					<span>{getDictionaryLabel(c, c.newValue)?.value || parseCommentTextWithTags(c.newValue)}</span>
																				</div>
																			))}
																		</td>
																	</tr>
																);
															}
															return null;
														}
														if (row.commissionId) return null;
														return (
															<tr key={row.createdAt + row.field + index}>
																<td width={35}>
																	<div className={classes.time}>{format(new Date(row.createdAt), 'HH:mm')}</div>
																</td>
																<td width={200}>
																	<div className={classes.field}>{t(`fields.${row.field}`)}</div>
																</td>
																<td>
																	<div className={classes.value}>
																		<span className={classes.before}>
																			{getDictionaryLabel(row, row.oldValue)?.value || parseCommentTextWithTags(row.oldValue)}
																		</span>
																		<span>&#8594;</span>
																		<br />
																		<span>{getDictionaryLabel(row, row.newValue)?.value || parseCommentTextWithTags(row.newValue)}</span>
																	</div>
																</td>
															</tr>
														);
													})}
												</tbody>
											</table>
										)),
									)}
								</div>
							))
					: !loadingHistory && <EmptyResult>{t('emptyHistory')}</EmptyResult>}
			</div>
			{loadingHistory && <Loader simple />}
			{!!partnerHistory?.length && canLoadMore && !loadingHistory && <LoadMore onClick={loadMore} className={classes.loadMore} />}
		</Modal>
	);
};

export default ContractHistory;
