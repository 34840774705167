import { IAction } from './types';
import {
	CHANGE_USERS_FILTER,
	DELETE_USER_BY_ID,
	FETCH_CURRENT_USER_FAILURE,
	FETCH_CURRENT_USER_START,
	FETCH_CURRENT_USER_SUCCESS,
	FETCH_USERS_FAILURE,
	FETCH_USERS_START,
	FETCH_USERS_SUCCESS,
	IState,
	RESET_USERS_FILTER,
	SET_USERS_PAGE,
	SET_USERS_PAGE_SIZE,
	UPDATE_USER_BY_ID,
} from './types';

const initialState: IState = {
	items: [],
	total: 0,
	onload: true,
	wasLoaded: false,
	filter: {
		storeAddressIdAndStoreNameAndEmail: '',
		roles: [],
	},
	pagionation: {
		page: 0,
		pageSize: 100,
	},
	current: {
		loading: true,
	},
};

const reducer = (state = initialState, action: IAction): IState => {
	switch (action.type) {
		case FETCH_USERS_START:
			return {
				...state,
				onload: true,
			};
		case FETCH_USERS_SUCCESS:
			return {
				...state,
				items: action.payload.users,
				total: action.payload.total,
				onload: false,
				wasLoaded: true,
			};
		case FETCH_USERS_FAILURE:
			return {
				...state,
				onload: false,
				wasLoaded: true,
				items: [],
				total: 0,
			};
		case SET_USERS_PAGE:
			return {
				...state,
				pagionation: {
					...state.pagionation,
					page: action.payload,
				},
			};
		case SET_USERS_PAGE_SIZE:
			return {
				...state,
				pagionation: {
					...state.pagionation,
					pageSize: action.payload,
				},
			};
		case CHANGE_USERS_FILTER:
			return {
				...state,
				filter: {
					...state.filter,
					[action.payload.field]: action.payload.value || undefined,
				},
				pagionation: {
					page: 0,
					pageSize: 100,
				},
			};
		case FETCH_CURRENT_USER_START:
			return {
				...state,
				current: {
					...state.current,
					loading: true,
				},
			};
		case FETCH_CURRENT_USER_SUCCESS:
			return {
				...state,
				current: {
					data: action.payload,
					loading: false,
				},
			};
		case FETCH_CURRENT_USER_FAILURE:
			return {
				...state,
				current: {
					...state.current,
					loading: false,
				},
			};
		case DELETE_USER_BY_ID:
			return {
				...state,
				items: state.items.filter((it) => it.id !== action.payload),
			};
		case RESET_USERS_FILTER:
			return {
				...state,
				filter: initialState.filter,
			};
		case UPDATE_USER_BY_ID:
			return {
				...state,
				items: state.items.map((it) => {
					if (it.id === action.payload.id) {
						return {
							...action.payload,
						};
					}
					return it;
				}),
			};
		default:
			return state;
	}
};

export default reducer;
