import { gql } from '@apollo/client';

export default gql`
	type VchasnoDoc {
		id: Number
		docId: String
		docType: String
		status: String
		title: String
		extension: String
		type: String
		number: String
		date: Date
		amount: Float
		firstSignBy: String
		signatureToFinish: Number
		dateCreated: Date
		dateDelivered: Date
		isDelivered: Boolean
		previewUrl: String
		url: String
		isMultilateral: Boolean
		category: String
		edrpouOwners: [String]
		companyName: String
		pathToFile: String
		pathToArchive: String
	}

	type VchasnoDocsPage {
		vchasnoDocsItems: [VchasnoDoc]
		total: Int
		totalPages: Int
	}

	input VchasnoDocsFilter {
		startDate: String!
		endDate: String!
		statuses: [String]
		categories: [String]
		edrpouOwner: String
		storeAddresses: [Int]
		contractNumber: String
	}

	type VchasnoDocFilterIpnItem {
		ipn: String
		companyName: String
	}

	input VchasnoDocsLastSync {
		date: Date
	}

	input VchasnoDocsFilterItem {
		value: String
		title: String
	}
`;
