import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatDate } from 'helpers';
import { getDateRange } from 'helpers/order';

import { CommonTemplate } from './types';

import classes from './GetStarted.module.scss';

const GetStartedCommon: React.FC<CommonTemplate> = ({ entity, testId }) => {
	const { t } = useTranslation('getStarted');
	const { startDate } = getDateRange(new Date());
	return (
		<div data-testid={testId}>
			<div className={classes.title}>{t(`${entity}.title`)}</div>
			<div className={classes.text}>
				<Trans
					values={{ date: formatDate(startDate) }}
					t={t}
					i18nKey={`${entity}.text`}
					components={{ ul: <ul />, li: <li />, bold: <span className={classes.bold} />, div: <div /> }}
				/>
			</div>
		</div>
	);
};

export default GetStartedCommon;
