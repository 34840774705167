import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { clientGraphql } from 'api';
import { GET_STORE_ADRESS_HISTORY } from 'api/storeAddresses';
import CreateEditStoreAddress from 'containers/modals/CreateEditStoreAddress';
import { formatDate, formatTime, getAccountingTypes } from 'helpers';
import clsx from 'helpers/clsx';
import { getCountry } from 'helpers/jwt';
import { useStoreAddresses } from 'hooks/useStoreAddresses';
import useToast from 'hooks/useToast';
import { AgreementTypes, CountryCodeEnum, ValueOf } from 'interfaces';
import { IStoreAddress } from 'interfaces/storeAddress';
import { TEST_IDS } from 'tests/config';

import Loader from 'components/Loader';
import MainButton from 'components/MainButton';
import Confirm from 'components/modals/Confirm';

import { IHistory, IHistoryRow, IPromoCalculationType, IProps, IResponseHistory, PromoType } from './types';

import classes from './StoreListInfo.module.scss';

const StoreListInfo: React.FC<IProps> = ({ store, refetchQueries = [] }) => {
	const [loadingDelete, setLoadingDelete] = useState(false);
	const { t } = useTranslation('storeAddresses');
	const { toast } = useToast();
	const { deleteStoreAddress } = useStoreAddresses();
	const [fetchStoreAddressHistory, { data: historyAddress, loading: loadingHistory }] = useLazyQuery<IResponseHistory>(GET_STORE_ADRESS_HISTORY, {
		fetchPolicy: 'network-only',
	});
	const isKZ = getCountry() === CountryCodeEnum.KZ;
	const isKG = getCountry() === CountryCodeEnum.KG;

	const filterKeysForHistory = ([key]: [string, ValueOf<IStoreAddress>]) =>
		!['__typename', 'createdAt', 'id', 'createdBy', 'createdByEmail', 'effectiveDate', 'effectiveUntil', 'reason', 'openDataBotSyncStatus'].includes(
			key,
		);

	const getHistory = (): IHistory[] | undefined => {
		if (!historyAddress || !Array.isArray(historyAddress.storeAddressHistory)) {
			return [];
		}
		const storeAddressHistory = [...historyAddress.storeAddressHistory].reverse();
		return storeAddressHistory.reduce((result: IHistory[], item, currentIndex: number, origin) => {
			const previousRow: IStoreAddress | undefined = origin[currentIndex - 1];
			if (!previousRow) {
				const historyRow = (Object.entries(item).filter(filterKeysForHistory) as [keyof IStoreAddress, ValueOf<IStoreAddress>][]).reduce(
					(acc: IHistoryRow[], [key, value]) => {
						if (value === null) return acc;
						return [
							...acc,
							{
								key,
								new: value,
							},
						];
					},
					[],
				);
				return [
					...result,
					{
						createdAt: item.createdAt,
						createdByEmail: item.createdByEmail,
						reason: item.reason,
						history: historyRow,
						effectiveDate: item.effectiveDate,
					},
				];
			} else {
				const historyRow = (Object.entries(item).filter(filterKeysForHistory) as [keyof IStoreAddress, ValueOf<IStoreAddress>][]).reduce(
					(acc: IHistoryRow[], [key, value]) => {
						if (previousRow[key as keyof IStoreAddress] !== value) {
							return [
								...acc,
								{
									key,
									old: previousRow[key as keyof IStoreAddress],
									new: value,
								},
							];
						}
						return acc;
					},
					[],
				);
				if (!historyRow.length) return result;
				return [
					...result,
					{
						createdAt: item.createdAt,
						createdByEmail: item.createdByEmail,
						history: historyRow,
						reason: item.reason,
						effectiveDate: item.effectiveDate,
					},
				];
			}
		}, []);
	};

	const isSet = (value: ValueOf<IStoreAddress>) => value !== undefined && value !== null;
	const formatValue = (value: ValueOf<IStoreAddress>) => {
		if (typeof value === 'boolean') {
			return value ? t('common:yes') : t('common:no');
		}
		return value;
	};

	const history = getHistory();
	const hasHistory = history && Object.keys(history).length > 0;
	const renderHistoryRow = (row: IHistoryRow) => {
		switch (row.key) {
			case 'partnerCommissionPercentage':
			case 'pickUpCommissionPercentage':
				return `${t(`list.${row.key}`)} ${row.old ? `${t('common:with')} ${row.old}% ${t('common:on')}` : ''} ${row.new}%`;
			case 'accountingType': {
				return `${t(`list.${row.key}`)} ${row.old ? `${t('common:with')} ${getAccountingTypes(row.old)} ${t('common:on')}` : ''} ${getAccountingTypes(row.new)}`;
			}
			case 'agreementType': {
				return `${t(`list.contractType`)} ${row.old ? `${t('common:with')} ${getAgreementType(row.old)} ${t('common:on')}` : ''} ${getAgreementType(row.new)}`;
			}
			case 'promoCalculationType':
				return `${t(`list.${row.key}`)} ${row.old ? `${t('common:with')} ${getPromoCalculationText(row.old)} ${t('common:on')}` : ''} ${getPromoCalculationText(row.new)}`;
			case 'promoProductCalculationType':
				return `${t(`list.${row.key}`)} ${row.old ? `${t('common:with')} ${getPromoCalculationText(row.old, PromoType.PRODUCT)} ${t('common:on')}` : ''} ${getPromoCalculationText(row.new, PromoType.PRODUCT)}`;
			default:
				return `${t(`list.${row.key}`)} ${isSet(row.old) ? `${t('common:with')} ${formatValue(row.old)} ${t('common:on')}` : ''} ${formatValue(row.new)}`;
		}
	};
	const createdDate = historyAddress?.storeAddressHistory ? [...historyAddress.storeAddressHistory].reverse()[0].effectiveDate : undefined;

	const getPromoCalculationText = (value: string, type?: PromoType.PRODUCT) => {
		if (!value) return t('promoCalculationType.notDefined');
		if (type)
			return value === IPromoCalculationType.FULL ? t('storeAddresses:promoCalculationType.full') : t('storeAddresses:promoCalculationType.reduced');
		return value === IPromoCalculationType.FULL ? t('storeAddresses:promoCalculationType.act') : t('storeAddresses:promoCalculationType.double');
	};
	const getAgreementType = (type?: string) => (type === AgreementTypes.AGENT ? t('contractType.agent') : t('contractType.info'));

	useEffect(() => {
		if (store) {
			fetchStoreAddressHistory({
				variables: { storeAddressId: store.storeAddressId },
			});
		}
	}, [store]);

	const handleError = () => {
		toast(t('errors:serverError'), { type: 'error' });
	};

	const handleDelete = (effectiveDate: string) => async () => {
		setLoadingDelete(true);
		return deleteStoreAddress(store?.storeAddressId, effectiveDate, [
			{ query: GET_STORE_ADRESS_HISTORY, fetchPolicy: 'network-only', variables: { storeAddressId: store?.storeAddressId } },
			...refetchQueries,
		])
			.then(async () => {
				toast(t('create/edit.edit.success'), { type: 'success' });
				await clientGraphql.refetchQueries({
					include: [GET_STORE_ADRESS_HISTORY],
				});
				await clientGraphql.clearStore();
				return true;
			})
			.catch(() => {
				handleError();
				return false;
			})
			.finally(() => setLoadingDelete(false));
	};

	return (
		<>
			<div className={classes.upperSection}>
				<ul className={classes.selectedStoreAddressList}>
					<li>
						<span>{t('storeAddresses:list.storeName')}</span>
						<span>{store.storeName}</span>
					</li>
					<li>
						<span>{t('storeAddresses:list.companyName')}</span>
						<span>{store.companyName}</span>
					</li>
					{isKZ && (
						<li>
							<span>{t('storeAddresses:list.legalAddress')}</span>
							<span>{store.legalAddress}</span>
						</li>
					)}
					{store.category && (
						<li>
							<span>{t('storeAddresses:list.category')}</span>
							<span>{store.category}</span>
						</li>
					)}
					{store.gen && (
						<li>
							<span>{t('storeAddresses:list.gen')}</span>
							<span>{store.gen}</span>
						</li>
					)}
					<li>
						<span>{t('storeAddresses:list.taxId')}</span>
						<span>{store.taxId}</span>
					</li>
					{(isKZ || isKG) && (
						<li>
							<span>{t('storeAddresses:list.iban')}</span>
							<span>{store.iban}</span>
						</li>
					)}
					<li>
						<span>{t('storeAddresses:list.partnerCommissionPercentage')}, %</span>
						<span>{store.partnerCommissionPercentage}%</span>
					</li>
					<li>
						<span>{t('list.pickUpCommissionPercentage')}, %</span>
						<span>{store.pickUpCommissionPercentage}%</span>
					</li>
					<li>
						<span>{t('storeAddresses:list.partnerContractNumber')}</span>
						<span>{store.partnerContractNumber}</span>
					</li>
					<li>
						<span>{t('list.contractType')}</span>
						<span>{getAgreementType(store.agreementType)}</span>
					</li>
					<li>
						<span>{t('storeAddresses:list.incidentsPaidByGlovo')}</span>
						<span>{store.incidentsPaidByGlovo ? t('common:yes') : t('common:no')}</span>
					</li>
					<li>
						<span>{t('storeAddresses:list.email')}</span>
						<span>{store.email}</span>
					</li>
					{store.email2 && (
						<li>
							<span>{t('storeAddresses:list.email2')}</span>
							<span>{store.email2}</span>
						</li>
					)}
					<li>
						<span>{t('storeAddresses:list.accountManagerName')}</span>
						<span>{store.accountManagerName}</span>
					</li>
					<li>
						<span>{t('storeAddresses:list.accountingType')}</span>
						<span>{getAccountingTypes(store.accountingType)}</span>
					</li>
					<li>
						<span>{t('storeAddresses:list.promoCalculationType')}</span>
						<span className={classes.promoText}>{getPromoCalculationText(store.promoCalculationType)}</span>
					</li>
					<li>
						<span>{t('storeAddresses:list.promoProductCalculationType')}</span>
						<span className={classes.promoText}>{getPromoCalculationText(store.promoProductCalculationType, PromoType.PRODUCT)}</span>
					</li>
					{createdDate && (
						<li>
							<span>{t('list.createdDate')}</span>
							<span>{formatDate(new Date(createdDate))}</span>
						</li>
					)}
				</ul>
			</div>
			<div className={classes.controls}>
				<CreateEditStoreAddress
					item={store}
					refetchQueries={[
						{
							query: GET_STORE_ADRESS_HISTORY,
							fetchPolicy: 'network-only',
							variables: { storeAddressId: store.storeAddressId },
						},
						...refetchQueries,
					]}
				>
					<MainButton className={clsx(classes.flex, classes.alignItemsCenter)}>{t('storeAddresses:edit')}</MainButton>
				</CreateEditStoreAddress>
			</div>
			{loadingHistory ? (
				<Loader simple />
			) : (
				hasHistory && (
					<div className={classes.history}>
						<div className={classes.history_title}>{t('history.title')}</div>
						{[...history].reverse().map((historyRow) => (
							<div className={classes.history_row} key={historyRow.createdAt} data-testid={TEST_IDS.STORE_ADDRESSES_HISTORY_ROW}>
								<div className={classes.history_row_inner}>
									<div className={classes.history_row_text}>
										{t('history.rowText')} {historyRow.history.map(renderHistoryRow).join(', ')}&nbsp;&nbsp;
										<span>
											{formatDate(new Date(historyRow.createdAt))}&nbsp;{t('common:at')}&nbsp;
											{formatTime(new Date(historyRow.createdAt))}
										</span>
									</div>
								</div>
								{historyRow.reason && <div className={clsx(classes.history_row_text, classes.reason)}>{historyRow.reason}</div>}
								<div className={classes.history_row_createdBy}>{historyRow.createdByEmail}</div>
								<div className={clsx(classes.history_row_text, classes.effectiveDate)}>
									{t('history.effectiveDate')} {formatDate(new Date(historyRow.effectiveDate))}
									<Confirm
										confirm={{
											onClick: handleDelete(historyRow.effectiveDate),
											text: t('confirm.delete.ok'),
											disabled: loadingDelete,
										}}
										title={t('confirm.delete.title')}
										titleSuccess={t('confirm.delete.titleSuccess')}
									>
										<button className={classes.buttonDelete}>{t('delete')}</button>
									</Confirm>
								</div>
							</div>
						))}
					</div>
				)
			)}
		</>
	);
};

export default StoreListInfo;
