import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useSubscription } from '@apollo/client';
import { clientGraphql, fetch } from 'api';
import { GET_BULK_HISTORY, GET_BULK_SUBSCRIPTION } from 'api/bulk';
import { createLink, formatDate, formatTime, hasErrorMessage } from 'helpers';
import clsx from 'helpers/clsx';
import { getLang } from 'helpers/lang';
import useToast from 'hooks/useToast';
import { useUser } from 'hooks/useUser';
import { ReactComponent as CheckIcon } from 'static/images/check.svg';
import { ReactComponent as ExampleIcon } from 'static/images/download_exemple.svg';
import { ReactComponent as EditFile } from 'static/images/editFile.svg';
import { ReactComponent as ErrorUploadIcon } from 'static/images/error-file.svg';
import { ReactComponent as FirstIcon } from 'static/images/first.svg';
import { ReactComponent as ProgressIco } from 'static/images/in_progress.svg';
import { ReactComponent as SecondIcon } from 'static/images/second.svg';
import { ReactComponent as UploadIcon } from 'static/images/synchronization.svg';
import { ReactComponent as WarningIco } from 'static/images/warn.svg';
import { TEST_IDS } from 'tests/config';

import { AttachFile, File, FileLoaded } from 'components/FormControl';
import Textarea from 'components/FormControl/Textarea';
import Loader from 'components/Loader';
import LoadMore from 'components/LoadMore';
import MainButton from 'components/MainButton';
import Modal from 'components/modals/Modal';

import {
	DELIVERY_EN_URL,
	DELIVERY_RU_URL,
	DELIVERY_URL,
	MBS_EN_URL,
	MBS_RU_URL,
	MBS_URL,
	MP_EN_URL,
	MP_RU_URL,
	MP_URL,
	ORDERS_EN_URL,
	ORDERS_RU_URL,
	ORDERS_URL,
	SSP_EN_URL,
	SSP_RU_URL,
	SSP_URL,
	STORE_EN_URL,
	STORE_RU_URL,
	STORE_URL,
} from './templates';
import { IBulkHistory, IbulkUploadHistory } from './types';

import classes from './BulkUpdate.module.scss';

const templateLanguage: { [key: string]: { [key: string]: string } } = {
	en: {
		ordersTable: ORDERS_EN_URL,
		ssp: SSP_EN_URL,
		mbs: MBS_EN_URL,
		deliveryDiscount: DELIVERY_EN_URL,
		marketplaceRecharge: MP_EN_URL,
		storeAddress: STORE_EN_URL,
	},
	uk: {
		ordersTable: ORDERS_URL,
		ssp: SSP_URL,
		mbs: MBS_URL,
		deliveryDiscount: DELIVERY_URL,
		marketplaceRecharge: MP_URL,
		storeAddress: STORE_URL,
	},
	ru: {
		ordersTable: ORDERS_RU_URL,
		ssp: SSP_RU_URL,
		mbs: MBS_RU_URL,
		deliveryDiscount: DELIVERY_RU_URL,
		marketplaceRecharge: MP_RU_URL,
		storeAddress: STORE_RU_URL,
	},
};

const BulkUpdate = () => {
	const { t } = useTranslation('bulkUpdate');
	const { toast } = useToast();
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [load, setLoad] = useState<boolean>(false);
	const [errorId, setErrorId] = useState<string>('');
	const [comment, setComment] = useState<string>('');
	const [template, setTemplate] = useState<File | null>(null);
	const { isCredentialsAdmin, isAdmin, isFinance } = useUser();
	const [page, setPage] = useState(0);
	const [pagination, setPagination] = useState({
		total: 0,
		totalPages: 0,
	});
	const [fetchHistory, { loading: loadingBulkHistory, data: bulkHistory }] = useLazyQuery<IBulkHistory>(GET_BULK_HISTORY, {
		fetchPolicy: 'no-cache',
		variables: {
			page,
			pageSize: 25,
		},
	});
	const { data: bulkUploadHistory } = useSubscription<{ bulkUploadHistory: IbulkUploadHistory }>(GET_BULK_SUBSCRIPTION);
	const [list, setList] = useState<IBulkHistory['getBulkUploadHistory']['data']>([]);
	const fields = [t('fields.file'), t('fields.date'), t('fields.status'), t('fields.user'), t('fields.comment'), t('fields.errorFile')];
	const lang = getLang() || 'uk';
	const nameList = [
		{ fileName: t('files.ordersTable'), url: templateLanguage[lang].ordersTable },
		{ fileName: t('files.ssp'), url: templateLanguage[lang].ssp },
		{ fileName: t('files.mbs'), url: templateLanguage[lang].mbs },
		{ fileName: t('files.deliveryDiscount'), url: templateLanguage[lang].deliveryDiscount },
		{ fileName: t('files.marketplaceRecharge'), url: templateLanguage[lang].marketplaceRecharge },
		{ fileName: t('files.storeAddress'), url: templateLanguage[lang].storeAddress },
	];
	const filesName =
		isCredentialsAdmin || isAdmin || isFinance ? nameList : nameList.filter((fileName) => fileName.fileName !== t('files.storeAddress'));

	const handleChangeComment = (e: ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value);

	const handleUploadFile = (files: FileList) => {
		const [loadedTemplate] = files;
		setTemplate(loadedTemplate);
	};

	const clearFileChange = () => {
		setTemplate(null);
	};

	const closeErrorModal = () => {
		setOpenModal(false);
		setErrorId('');
	};

	const sendTemplate = async () => {
		if (load || openModal) return;
		if (template) {
			const formData = new FormData();
			formData.append('file', template);
			formData.append('comment', comment);
			setLoad(true);
			await fetch('/bulk/start/file_upload', {
				method: 'post',
				body: formData,
			})
				.then((data) => {
					setOpenModal(true);
					if (data.body) setErrorId(String(data.body));
				})
				.catch(async (res) => {
					const message = await res.response.text();
					const errorKeyForLocize = hasErrorMessage({ message });
					if (errorKeyForLocize) toast(t(`errors:${errorKeyForLocize}`), { type: 'error' });
					else toast(t('errors:somethingWentWrong'), { type: 'error' });
				})
				.finally(async () => {
					setLoad(false);
					await clientGraphql.clearStore();
					fetchHistoryAction();
				});
		}
	};

	const params = 'type=BULK_UPLOAD';

	const downloadErrorFile = () => {
		fetch(`/reports/download/${errorId}?${params}`)
			.then((res) => {
				const url = window.URL.createObjectURL(res.body as Blob);
				createLink(url, 'download');
			})
			.catch(() => toast(`${t('errors:somethingWentWrong')}`, { type: 'error' }))
			.finally(closeErrorModal);
	};

	const handleCloseModal = () => {
		clearFileChange();
		setOpenModal(false);
	};

	const getStatus = (status: boolean) => (status ? t('success') : status === null ? t('inProgress') : t('hasError'));

	const fetchHistoryAction = () => {
		fetchHistory();
	};

	const itemsRecord = () => {
		if (bulkHistory) {
			setList(list.length ? [...list, ...bulkHistory.getBulkUploadHistory.data] : bulkHistory?.getBulkUploadHistory.data);
			setPagination({
				total: bulkHistory.getBulkUploadHistory.total,
				totalPages: bulkHistory.getBulkUploadHistory.totalPages,
			});
		}
	};

	useEffect(fetchHistoryAction, [page]);

	useEffect(itemsRecord, [bulkHistory]);

	useEffect(() => {
		if (bulkUploadHistory) {
			const uploadingFile = bulkUploadHistory.bulkUploadHistory;
			const uploadingFileId = uploadingFile.fileId;
			if (list.find((file) => file.fileId === uploadingFileId)) {
				setList(list.map((file) => (file.fileId === uploadingFileId ? uploadingFile : file)));
				getToastMessage(uploadingFile.status);
			} else {
				setList([uploadingFile, ...list]);
			}
		}
	}, [bulkUploadHistory]);

	const getToastMessage = (status: boolean | null) => {
		if (status === null) return;
		return toast(status ? t('successUpload') : t('errors:hasError'), { type: status ? 'success' : 'error' });
	};

	const isStoreAddressFile = template?.name.includes('Store address');

	const loadMore = () => setPage(page + 1);

	const canLoadMore = list.length < pagination.total;

	return (
		<>
			{load && <Loader />}
			<Modal open={openModal} onClose={handleCloseModal} modalClassName={classes.innerModal}>
				<div className={classes.uploadModal}>
					<div className={clsx(classes.circle, { [classes.error]: errorId, [classes.progress]: isStoreAddressFile })}>
						{isStoreAddressFile ? <ProgressIco /> : errorId ? <WarningIco /> : <CheckIcon />}
					</div>
					<div className={classes.title}>{isStoreAddressFile ? t('progress') : errorId ? t('failLoad') : t('successLoad')}</div>
					{isStoreAddressFile && <div className={classes.description}>{t('progressDesc')}</div>}
					{errorId ? (
						<button className={classes.button} onClick={downloadErrorFile}>
							{t('loadErrorFile')}
						</button>
					) : (
						<button className={classes.button} onClick={handleCloseModal}>
							{t('confirm')}
						</button>
					)}
				</div>
			</Modal>

			<div className={classes.root} data-testid={TEST_IDS.BULK_UPDATE_PAGE}>
				<div className={classes.title}>{t('title')}</div>
				<div className={classes.container}>
					<div className={classes.point}>
						<FirstIcon />
						<div className={classes.point_rightSide}>
							<p className={classes.point_text}>{t('loadTemplate')}</p>
							<ul className={classes.point_list}>
								{filesName.map((file) => (
									<React.Fragment key={file.fileName}>
										<FileLoaded
											Icon={ExampleIcon}
											rootClassName={classes.downloadTemplate}
											id={file.fileName}
											filename={file.fileName}
											url={file.url}
											testId={TEST_IDS.BULK_UPDATE_FILE}
										>
											{file.fileName}
										</FileLoaded>
									</React.Fragment>
								))}
							</ul>
						</div>
					</div>
					<div className={classes.point}>
						<SecondIcon className={classes.point_second} />
						<div className={classes.pointSec_rightSide}>
							<p className={classes.point_text}>{t('uploadTemplate')}</p>
							<div className={classes.sideContainer}>
								<div className={classes.loaderWrapper}>
									<File Icon={UploadIcon} rootClassName={classes.uploadTemplate} onChange={handleUploadFile} limitSize={0}>
										{t('upload')}
									</File>
									{template && <AttachFile rootClassName={classes.attach} file={template} onRemove={clearFileChange} Icon={EditFile} />}
								</div>
								<Textarea value={comment} onChange={handleChangeComment} rootClassName={classes.fullWidth} placeholder={t('message')} />
								<MainButton
									className={clsx(classes.applyButton, { [classes.off]: !template || load })}
									onClick={sendTemplate}
									disabled={load || openModal}
								>
									{t('apply')}
								</MainButton>
							</div>
						</div>
					</div>
				</div>
				<div className={classes.title}>{t('historyEditing')}</div>
				<div className={classes.table}>
					{loadingBulkHistory && <Loader />}
					<table className={classes.items}>
						<thead>
							<tr>
								{fields.map((field) => (
									<th key={field}>{field}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{!!list.length &&
								list.map((item) => (
									<React.Fragment key={item.fileId}>
										<tr className={classes.spacer}>
											<td colSpan={6}></td>
										</tr>
										<tr>
											<td>
												<div className={clsx(classes.loadFile)}>
													<FileLoaded params={params} id={item.fileId} filename={item.filename} Icon={EditFile} />
												</div>
											</td>
											<td>
												<div className={classes.data}>
													{formatDate(new Date(item.createdAt))},&nbsp;{formatTime(new Date(item.createdAt))}
												</div>
											</td>
											<td>
												<div className={clsx(classes.status, { [classes.hasError]: !item.status, [classes.inProgress]: item.status === null })}>
													{getStatus(item.status)}
												</div>
											</td>
											{item.status === true || item.status === false ? (
												<>
													<td>
														<div className={classes.user}>{item.createdBy}</div>
													</td>
													<td className={classes.commentRow}>
														<div className={classes.comment}>{item.comment}</div>
													</td>
													<td>
														{item.errorFileId && (
															<FileLoaded id={item.errorFileId} params={params} filename={t('errorFile')} Icon={ErrorUploadIcon} />
														)}
													</td>
												</>
											) : (
												<td colSpan={3}>
													<div className={classes.progressBar}>
														<div className={classes.progressBar_top}>
															<span className={classes.progressBar_top_title}>{t('inProgress')}</span>
															<span className={classes.progressBar_top_percentage}>{Math.round((item.position / item.total) * 100)} %</span>
														</div>
														<div className={classes.progressBar_wrap}>
															<div className={classes.progressBar_line} style={{ width: `${(item.position / item.total) * 100}%` }} />
														</div>
													</div>
												</td>
											)}
										</tr>
									</React.Fragment>
								))}
						</tbody>
					</table>
					{list.length > 0 && canLoadMore && <LoadMore onClick={loadMore} className={classes.loadMore} />}
				</div>
			</div>
		</>
	);
};

export default BulkUpdate;
