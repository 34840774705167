import { useDispatch } from 'react-redux';
import { FetchResult, useMutation } from '@apollo/client';
import { IFetchResponse } from 'api';
import { fetch } from 'api';
import { RESET_PASSWORD, SET_USER_LANG } from 'api/users';
import { contextAuth, GLOVO_ROLES, PARTNER_ROLES } from 'const';
import { getAvailableRoles, getMaxRoleForEdit, getRoles, hasRole } from 'helpers/users';
import { IErrorResponse } from 'interfaces/error';
import { IUser, RoleEnum } from 'interfaces/users';
import { useSelector } from 'store';
import { fetchCurrentUser } from 'store/users/actions';

import { useOrders } from './useOrders';

interface IUserReserPassword {
	resetPassword: IUser;
}

export interface IUseUser {
	user: IUser | undefined;
	isCredentialsAdmin: boolean;
	isAdmin: boolean;
	isStoreAdmin: boolean;
	isStoreManager: boolean;
	isAccountant: boolean;
	isMenuManager: boolean;
	isFinance: boolean;
	isAccount: boolean;
	hasMarketplace: boolean;
	isCustomer: boolean;
	loading: boolean;
	isServiceUser: boolean;
	roles: RoleEnum[] | undefined;
	fetchUser(): void;
	resetPassword(email: string): Promise<void | FetchResult<IUserReserPassword>>;
	sendEmail(email: string): Promise<IFetchResponse<IErrorResponse>>;
	changePassword(email: string, oldPassword: string, newPassword: string): Promise<unknown>;
	resetPasswordByEmail(email: string, token: string, newPassword: string): Promise<IFetchResponse<IErrorResponse>>;
	hasPermissionToChangeOrders(): boolean;
	hasPermissionToManageUsers(): boolean;
	hasPermissionToRemove(): boolean;
	hasPermissionAuth(): boolean;
	getRolesForEdit(): RoleEnum[] | undefined;
	changeUserLang(newlang: string): Promise<void | FetchResult>;
	hasOnlyChosenRole(role: RoleEnum): boolean;
	canSeeLaasPartnerExport: boolean;
	isPartner: boolean;
}

export const useUser = (): IUseUser => {
	const { data, loading } = useSelector((state) => state.users.current);
	const [resetPasswordMutation] = useMutation(RESET_PASSWORD, {
		context: contextAuth,
	});
	const [switchUserLang] = useMutation(SET_USER_LANG, {
		context: contextAuth,
	});
	const { totals } = useOrders();
	const dispatch = useDispatch();

	const resetPassword = (email: string): Promise<void | FetchResult<IUserReserPassword>> => {
		return resetPasswordMutation({
			variables: { email },
		});
	};

	const fetchUser = () => {
		return dispatch(fetchCurrentUser());
	};

	const changePassword = (email: string, oldPassword: string, newPassword: string): Promise<unknown> => {
		return fetch('/users', {
			method: 'put',
			body: JSON.stringify({ email, oldPassword, newPassword }),
			headers: {
				'content-type': 'application/json',
			},
			auth: true,
		});
	};

	const sendEmail = (email: string): Promise<IFetchResponse<IErrorResponse>> => {
		return fetch('/users/forgotPassword/reset-link', {
			method: 'post',
			body: JSON.stringify({ email }),
			headers: {
				'Content-Type': 'application/json',
			},
			auth: true,
		});
	};

	const resetPasswordByEmail = (email: string, token: string, newPassword: string): Promise<IFetchResponse<IErrorResponse>> => {
		return fetch('/users/forgotPassword', {
			method: 'post',
			body: JSON.stringify({ email, token, newPassword }),
			headers: {
				'Content-Type': 'application/json',
			},
			auth: true,
		});
	};

	const hasPermissionToChangeOrders = () => hasRole(roles, [...GLOVO_ROLES, ...PARTNER_ROLES]);

	const hasOnlyChosenRole = (role: RoleEnum) => {
		if (!roles?.length) return false;
		const OnlyProjectRoles = roles?.filter((userRole) => Object.values(RoleEnum).find((enumRole) => userRole === enumRole));
		const userHaveChosenRole = OnlyProjectRoles.find((projRole) => role === projRole);
		if (!!userHaveChosenRole && OnlyProjectRoles.length === 1) return true;
		else return false;
	};

	const hasPermissionToManageUsers = () => hasRole(roles, [RoleEnum.CREDENTIALS_ADMIN, RoleEnum.STORE_ADMIN, RoleEnum.STORE_MANAGER, ...GLOVO_ROLES]);

	const hasPermissionToRemove = () => hasRole(roles, [RoleEnum.CREDENTIALS_ADMIN, RoleEnum.ADMIN, RoleEnum.FINANCE, RoleEnum.STORE_ADMIN]);

	const hasPermissionAuth = () => hasRole(roles, [RoleEnum.CREDENTIALS_ADMIN, ...GLOVO_ROLES]);

	const isServiceUser = () => hasRole(roles, [RoleEnum.CREDENTIALS_ADMIN, ...GLOVO_ROLES]);

	const isPartner = () => hasRole(roles, PARTNER_ROLES);

	const canSeeLaasPartnerExport = () => hasRole(roles, [RoleEnum.CUSTOMER, RoleEnum.CREDENTIALS_ADMIN]);

	const getRolesForEdit = (): RoleEnum[] | undefined => {
		if (!roles) return;
		if (hasRole(roles, [RoleEnum.ADMIN, RoleEnum.FINANCE])) return Object.values(RoleEnum);
		const highestRole = getMaxRoleForEdit(roles);
		if (!isServiceUser()) return getAvailableRoles(highestRole)?.filter((role) => ![RoleEnum.MENUTOOL, RoleEnum.CUSTOMER].includes(role));
		return getAvailableRoles(highestRole);
	};

	const changeUserLang = (newLang: string): Promise<void | FetchResult> => {
		return switchUserLang({
			variables: { id: data?.id, newLang },
		});
	};

	const roles = getRoles(data);

	return {
		user: data,
		isCredentialsAdmin: roles?.includes(RoleEnum.CREDENTIALS_ADMIN) || false,
		isAdmin: roles?.includes(RoleEnum.ADMIN) || false,
		isStoreAdmin: roles?.includes(RoleEnum.STORE_ADMIN) || false,
		isStoreManager: roles?.includes(RoleEnum.STORE_MANAGER) || false,
		isAccountant: roles?.includes(RoleEnum.ACCOUNTANT) || false,
		isMenuManager: roles?.includes(RoleEnum.MENUTOOL) || false,
		isFinance: roles?.includes(RoleEnum.FINANCE) || false,
		isAccount: roles?.includes(RoleEnum.ACCOUNT) || false,
		isCustomer: roles?.includes(RoleEnum.CUSTOMER) || false,
		isPartner: isPartner(),
		roles,
		hasMarketplace: !!totals?.main.deliveryFeeTotal || !!totals?.main.deliveryDiscountTotal || !!totals?.main.partnerPaymentTotal,
		loading,
		isServiceUser: isServiceUser(),
		hasOnlyChosenRole,
		fetchUser,
		resetPassword,
		changePassword,
		sendEmail,
		resetPasswordByEmail,
		hasPermissionToChangeOrders,
		hasPermissionToManageUsers,
		hasPermissionToRemove,
		getRolesForEdit,
		hasPermissionAuth,
		changeUserLang,
		canSeeLaasPartnerExport: canSeeLaasPartnerExport(),
	};
};
