import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Routes } from 'const';
import CountrySwitcher from 'containers/CountrySwitcher';
import LangSwitcher from 'containers/LangSwitcher';
import Notifications from 'containers/Notifications';
import { useGetStartedContext } from 'contexts/GetStarted';
import clsx from 'helpers/clsx';
import { getCountry } from 'helpers/jwt';
import { setDatadogRumUser } from 'helpers/rum';
import { useAuth } from 'hooks/useAuth';
import { useOrders } from 'hooks/useOrders';
import { useUser } from 'hooks/useUser';
import { CountryCodeEnum } from 'interfaces';
import { ReactComponent as Faq } from 'static/images/faq.svg';
import { ReactComponent as Info } from 'static/images/info_header.svg';
import { ReactComponent as LogoDefault } from 'static/images/Logo.svg';
import { ReactComponent as LogoPartner } from 'static/images/Logo-partner.svg';
import { ReactComponent as Logout } from 'static/images/logout.svg';
import { TEST_IDS } from 'tests/config';

import IconButton from 'components/IconButton';
import UserEmail from 'components/UserEmail';

import classes from './Header.module.scss';

const Header: React.FC = () => {
	const { t } = useTranslation('common');
	const { isServiceUser, user, loading } = useUser();
	const { onOpen } = useGetStartedContext();
	const { logout } = useAuth();
	const { onload } = useOrders();
	const renderLogo = () => {
		if (!isServiceUser) return <LogoPartner className={classes.logo} data-testid={TEST_IDS.LOGO_PARTNER} />;
		return <LogoDefault className={classes.logo} data-testid={TEST_IDS.LOGO} />;
	};
	const { pathname } = useLocation();
	const isOrdersPage = pathname === Routes.ORDERS;
	const isPartnerFilePage = pathname === Routes.PARTNERS_FILE;
	const isUA = getCountry() === CountryCodeEnum.UA;

	useEffect(() => {
		if (user) setDatadogRumUser({ id: user.id });
	}, [user]);

	return (
		<div className={classes.root}>
			{!loading && renderLogo()}
			<div className={classes.userInfo}>
				{!onload && isOrdersPage && (
					<IconButton onClick={onOpen} testId={TEST_IDS.OPEN_GET_STARTED} className={classes.info_header}>
						<Info className={classes.icon} />
						<span>{t('guide')}</span>
					</IconButton>
				)}
				{isUA && (
					<Link to={Routes.FAQ}>
						<IconButton testId={TEST_IDS.OPEN_FAQ} className={classes.info_header}>
							<Faq className={classes.icon} />
							<span>{t('faq')}</span>
						</IconButton>
					</Link>
				)}
				{!isPartnerFilePage && <CountrySwitcher rootClassName={classes.countrySwitcher} />}
				<LangSwitcher />
				<Notifications />
				{user?.email && (
					<UserEmail isCurrent rootClassName={classes.userInfoCell}>
						<span>{user?.email}</span>
						<IconButton onClick={logout} className={classes.logoutIcon} testId={TEST_IDS.LOGOUT_BUTTON_HEADER}>
							<Logout className={clsx(classes.icon)} />
						</IconButton>
					</UserEmail>
				)}
			</div>
		</div>
	);
};

export default Header;
