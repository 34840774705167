import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetch } from 'api';
import { getDecodedJwt } from 'helpers/jwt';
import { setToken } from 'helpers/token';
import useToast from 'hooks/useToast';
import { useUser } from 'hooks/useUser';
import { CountryCodeEnum } from 'interfaces';
import { ReactComponent as GlobeIcon } from 'static/images/globe.svg';
import { TEST_IDS } from 'tests/config';

import { Select } from 'components/FormControl';
import Loader from 'components/Loader';

import { IProps } from './types';

const CountrySwitcher: React.FC<IProps> = ({ rootClassName }) => {
	const { isAdmin } = useUser();
	const { t } = useTranslation(['common', 'errors']);
	const jwt = getDecodedJwt();
	const [value, setValue] = useState<CountryCodeEnum | undefined>(jwt?.Country);
	const [loading, setLoading] = useState<boolean>(false);
	const { toast } = useToast();

	const handleChangeCountry = (countryCode: CountryCodeEnum) => {
		setValue(countryCode);
		setLoading(true);
		fetch<{ errorType?: string; jwt?: string }>(`/users/country?countryCode=${countryCode}`, {
			auth: true,
			method: 'PUT',
		})
			.then(({ body }) => {
				if (!body?.jwt) {
					throw new Error('Failed to change country');
				}
				setToken(body.jwt);
				window.location.reload();
			})
			.catch(() => {
				toast(t('errors:changeCountry'), { type: 'error' });
				setValue(jwt?.Country);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const availableCountries = jwt?.AvailableCountries?.filter((c) => CountryCodeEnum[c as keyof typeof CountryCodeEnum]);

	if (!isAdmin || !availableCountries || availableCountries.length < 2) return null;

	const options = availableCountries.map((countryCode) => ({
		value: countryCode,
		label: t(`countries.${countryCode}`),
	}));

	return (
		<>
			{loading && <Loader />}
			<Select
				value={value}
				options={options}
				onChange={handleChangeCountry}
				rootClassName={rootClassName}
				testId={TEST_IDS.COUNTRY_SWITCHER_SELECT}
				Icon={GlobeIcon}
				iconColor="success"
				withoutClear
			/>
		</>
	);
};

export default CountrySwitcher;
