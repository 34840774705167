import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatPrice } from 'helpers';
import clsx from 'helpers/clsx';
import { IDebt } from 'interfaces/invoices';
import { ReactComponent as InfoIco } from 'static/images/info.svg';
import { ReactComponent as WarningIco } from 'static/images/warn.svg';
import { TEST_IDS } from 'tests/config';

import Tooltip from 'components/Tooltip';

import classes from './DebtTable.module.scss';

const DebtLineChild: FC<{ debtChild: IDebt; activeTab: boolean }> = ({ debtChild: debt, activeTab }) => {
	const { t } = useTranslation('accounts');

	return (
		<div className={classes.tableHeadRow} data-testid={TEST_IDS.ACCOUNTS_TABLE_BODY_DEBT_ROW_CHILD}>
			<div className={clsx(classes.tableHeadColl, classes.documentNameColl)}>
				<div className={classes.companyInfo}>
					<div className={classes.name}>{debt.documentName}</div>
				</div>
			</div>
			<div className={classes.tableHeadColl}>
				<div className={classes.institutions}>
					<span className={classes.institutions_address}>{t('date')}</span>
					<div className={classes.name}>{debt.documentDate}</div>
				</div>
				<div className={classes.selectPlace}></div>
			</div>
			<div className={classes.tableHeadColl}>
				<div className={classes.contractNumber}>{debt.contractNumber}</div>
			</div>
			<div className={classes.tableHeadColl}>
				<div>
					<span className={clsx(classes.sum, { [classes.emptyState]: !debt.unexpiredDebtAmount })}>
						{formatPrice(debt.unexpiredDebtAmount, 2, ',', '0,00')}
					</span>
				</div>
			</div>
			<div className={classes.tableHeadColl}>
				<div className={classes.expired}>
					<div className={clsx({ [classes.case]: debt.expiredDebtAmount < 0, [classes.emptyState]: !debt.expiredDebtAmount })}>
						{formatPrice(debt.expiredDebtAmount, 2, ',', '0,00')}
					</div>
					{!(debt.expiredDebt30DaysAmount <= 0) && !activeTab && (
						<Tooltip
							className={classes.warnTooltip}
							placement="bottom"
							text={<Trans t={t} i18nKey="tooltips.warning" components={{ bold: <span className={classes.bold} /> }} />}
							nowrap
						>
							<div>
								<WarningIco />
							</div>
						</Tooltip>
					)}
				</div>
			</div>
			<div className={classes.tableHeadColl}>
				<div className={classes.agree}>
					<div
						className={clsx(classes.sum, {
							[classes.agreeSum]: debt.totalDebtAmount < 0,
							[classes.emptyState]: !debt.totalDebtAmount,
						})}
					>
						{formatPrice(debt.totalDebtAmount, 2, ',', '0,00')}
					</div>
					{debt.totalDebtAmount < 0 && activeTab && (
						<Tooltip placement="bottom-end" text={<Trans t={t} i18nKey="tooltips.overpayment" />} nowrap>
							<div>
								<InfoIco />
							</div>
						</Tooltip>
					)}
				</div>
			</div>
		</div>
	);
};

export default DebtLineChild;
