import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetch } from 'api';
import { Routes } from 'const';
import SendVchasno from 'containers/modals/SendVchasno';
import clsx from 'helpers/clsx';
import { getCountry } from 'helpers/jwt';
import { useContractGeneration } from 'hooks/useContractGeneration';
import { useDocuments } from 'hooks/useDocuments';
import { useExport } from 'hooks/useExport';
import { CountryCodeEnum } from 'interfaces';
import { AgreementStatus, IAgreement } from 'interfaces/contractGeneration';
import { ReactComponent as DeleteIcon } from 'static/images/delete.svg';
import { ReactComponent as DublicateFileIcon } from 'static/images/dublicateFile.svg';
import { ReactComponent as EditIcon } from 'static/images/edit.svg';
import { ReactComponent as ExportPDF } from 'static/images/exportPDF.svg';
import { ReactComponent as ExportWORD } from 'static/images/exportWORD.svg';
import { ReactComponent as MoreIcon } from 'static/images/more_vert.svg';
import { ReactComponent as SettingIcon } from 'static/images/settings.svg';
import { TEST_IDS } from 'tests/config';

import { BackToContractList } from 'components/Contract/BackToContractList';
import { Status } from 'components/Contract/Status';
import { ToastDublicateAgreement } from 'components/Contract/ToastDublicateAgreement';
import IconButton from 'components/IconButton';
import Loader from 'components/Loader';
import MainButton from 'components/MainButton';
import Confirm from 'components/modals/Confirm';
import Tooltip from 'components/Tooltip';

import classes from './DocumentPreview.module.scss';

const DocumentPreview: React.FC = () => {
	const { id }: { id: string } = useParams();
	const [loading, setLoading] = useState<boolean>(false);
	const { t } = useTranslation(['contractGeneration', 'errors']);
	const [contractFiels, setContractFields] = useState<IAgreement>();
	const { getContractById, deleteContract, dublicateContract } = useContractGeneration();
	const history = useHistory();
	const { exportAgreement, onload: loadingExport } = useExport();
	const { getStatusText, getStatusStyle } = useDocuments();

	useEffect(() => {
		if (id) {
			setLoading(true);
			getContractById(id)
				.then(({ body }) => {
					setContractFields(body);
					fetch(`/document/id/${body.agreementId}?type=PDF`, { fintoolContracts: true }, false)
						.then((res) => {
							const blobLink = URL.createObjectURL(res.body as Blob);
							const iFrame: HTMLElement | null = document.getElementById('previewFrame');
							if (iFrame) iFrame.setAttribute('src', blobLink);
						})
						.catch(() => {
							toast(t('errors:somethingWentWrong'), { type: 'error' });
						})
						.finally(() => setLoading(false));
					// if (iFrame) iFrame.setAttribute('src', viewUrlOffice + `${prefixApiFintoolContracts}/document/id/${body.agreementId}?type=WORD`);
				})
				.catch(() => {
					toast(t('errors:somethingWentWrong'), { type: 'error' });
				});
		}

		return () => setContractFields(undefined);
	}, [id]);

	if (!id) return null;

	const goToForm = () => {
		if (contractFiels?.agreementId) {
			history.push(`${Routes.CONTRACT_FORM.replace(':id', contractFiels?.agreementId)}`);
		}
	};

	const goToConfigure = () => {
		if (contractFiels?.agreementId) {
			history.push(`${Routes.QUESTIONNAIRE}?label=${contractFiels.agreementName}&id=${contractFiels.agreementId}`);
		}
	};

	const handleDelete = (agreementId: string) => () => {
		setLoading(true);
		return deleteContract(agreementId).finally(() => history.push(Routes.CONTRACT_GENERATION));
	};

	const copyAgreement = (agreementId: string) => async () => {
		const data = await dublicateContract(agreementId);
		if (data) {
			toast(<ToastDublicateAgreement data={data} />, { type: 'success', autoClose: 5000 });
			return true;
		}
	};

	const disableFillDocumentFor = [AgreementStatus.CANCELLED, AgreementStatus.FILLED, AgreementStatus.DELETED_FROM_VCHASNO].every(
		(status) => status !== contractFiels?.agreementStatus,
	);

	return (
		<div className={classes.root}>
			{(loading || loadingExport) && <Loader />}
			<div className={classes.header}>
				<BackToContractList />
				{contractFiels && (
					<React.Fragment>
						<h4>{contractFiels?.agreementName}</h4>
						<div className={classes.right_part}>
							{disableFillDocumentFor && !contractFiels.vchasnoSync && <MainButton onClick={goToForm}>{t('fillDocument')}</MainButton>}
							{contractFiels?.agreementStatus === AgreementStatus.FILLED && getCountry() === CountryCodeEnum.UA && (
								<SendVchasno setFields={setContractFields} fields={contractFiels} />
							)}
							<Tooltip
								outside
								withClick
								placement="bottom-end"
								rootClassName={classes.actions__more}
								className={classes.tooltipMore}
								text={
									<>
										{contractFiels?.canConfigure && (
											<button className={clsx(classes.action, classes.flex, classes.alignItemsCenter)} onClick={goToConfigure}>
												<SettingIcon color="#797A8D" />
												{t('goToConfigure')}
											</button>
										)}
										{contractFiels?.agreementStatus === AgreementStatus.FILLED && !contractFiels.vchasnoSync && (
											<button className={clsx(classes.action, classes.flex, classes.alignItemsCenter)} onClick={goToForm}>
												<EditIcon color="#797A8D" />
												{t('goToEditForm')}
											</button>
										)}
										<Confirm
											title={t('actions.dublicateAgreement', { agreementName: contractFiels.agreementName })}
											icon="copy"
											confirm={{
												onClick: copyAgreement(contractFiels.agreementId),
												text: t('actions.dublicateDocument'),
											}}
										>
											<button className={clsx(classes.action, classes.flex, classes.alignItemsCenter)}>
												<DublicateFileIcon color="#797A8D" />
												{t('actions.dublicateDocument')}
											</button>
										</Confirm>
										{contractFiels.agreementStatus !== AgreementStatus.DELETED_FROM_VCHASNO && (
											<>
												<button
													onClick={() => exportAgreement(contractFiels.agreementId, 'PDF', contractFiels.agreementName)}
													className={clsx(classes.action, classes.flex, classes.alignItemsCenter)}
												>
													<ExportPDF className={clsx(classes.actionIcon)} />
													{t('actions.exportPDF')}
												</button>
												<button
													onClick={() => exportAgreement(contractFiels.agreementId, 'WORD', contractFiels.agreementName)}
													className={clsx(classes.action, classes.flex, classes.alignItemsCenter)}
												>
													<ExportWORD className={clsx(classes.actionIcon)} />
													{t('actions.exportWORD')}
												</button>
												<Confirm
													confirm={{
														onClick: handleDelete(contractFiels.agreementId),
														text: t('confirm.deleteDocument.ok'),
													}}
													title={t('confirm.deleteDocument.title')}
													body={t('confirm.deleteDocument.body')}
													icon="delete"
												>
													<button className={clsx(classes.action, classes.flex, classes.alignItemsCenter)}>
														<DeleteIcon className={clsx(classes.actionIcon)} />
														{t('actions.delete')}
													</button>
												</Confirm>
											</>
										)}
									</>
								}
							>
								<IconButton>
									<MoreIcon className={clsx(classes.actionIcon, classes.actionIcon_delete)} />
								</IconButton>
							</Tooltip>
						</div>
					</React.Fragment>
				)}
			</div>
			{contractFiels && (
				<div className={classes.status_wrap}>
					{contractFiels.vchasnoSync ? (
						<div className={clsx(classes.statusText, getStatusStyle[contractFiels.vchasnoSync.status as keyof typeof getStatusStyle])}>
							<span>{getStatusText(contractFiels.vchasnoSync.status)}</span>
						</div>
					) : (
						<Status status={contractFiels.agreementStatus} />
					)}
				</div>
			)}
			<div className={classes.document_wrap}>
				<iframe className={classes.preview_frame} data-testid={TEST_IDS.DOCUMENT_FRAME} title="preview" id="previewFrame" />
			</div>
		</div>
	);
};

export default DocumentPreview;
