import { MouseEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatPrice } from 'helpers';
import clsx from 'helpers/clsx';
import { IPaymentModify } from 'interfaces/invoices';
import { ReactComponent as PinIcon } from 'static/images/pin.svg';
import { TEST_IDS } from 'tests/config';

import CopyButton from 'components/CopyButton';
import Tooltip from 'components/Tooltip';

import classes from 'pages/Accounts/Accounts.module.scss';

const AdditionalPayment: React.FC<{ item: IPaymentModify }> = ({ item }: { item: IPaymentModify }) => {
	const { t } = useTranslation('accounts');
	const [visibleTip, setVisibleTip] = useState(false);

	const getSideForTooltip = (e: MouseEvent) => {
		if (window.innerHeight - e.clientY > 150) {
			return setVisibleTip(true);
		}
		setVisibleTip(false);
	};

	return (
		<tr className={classes.collapseRow} data-testid={TEST_IDS.ACCOUNTS_TABLE_BODY_INVOICE_ROW_CHILD}>
			<td className={classes.iconCell}></td>
			<td colSpan={11} className={classes.additionalPayment}>
				<table>
					<tbody>
						<tr>
							<td className={clsx(classes.light, classes.typeCell)}>
								<Tooltip text={<Trans t={t} i18nKey="tooltips.pinPayment" />} nowrap>
									<div className={classes.typeInnerCollapse}>
										<PinIcon />
										<div className={clsx(classes.typeText, classes.alignCenter)}>{t('payment')}</div>
									</div>
								</Tooltip>
							</td>
							<td className={clsx(classes.light, classes.dateCell)}>
								<div className={clsx(classes.flex, classes.lightTextSmall)}></div>
							</td>
							<td className={clsx(classes.light, classes.docNumCell, classes.alignRight)}>
								<div className={clsx(classes.lightTextSmall, classes.flex, classes.right)}>{item.number}</div>
							</td>
							<td className={clsx(classes.light, classes.agentNumCell)}>
								<div className={clsx(classes.partner)}>{item.companyName}</div>
								<div className={clsx(classes.agreeText)}>{item.edrpou}</div>
							</td>
							<td className={clsx(classes.light, classes.offerCell)}>
								<div className={clsx(classes.lightText, classes.flex, classes.right)}>{item.contractNumber}</div>
							</td>
							<td className={clsx(classes.light, classes.numCell)}>
								<div className={clsx(classes.iconWrap, classes.flex, classes.right, { [classes.fulfilled]: item.totalAmount })}>
									{formatPrice(item.totalAmount, 2, ',', '0,00')}
								</div>
							</td>
							<td className={clsx(classes.light, classes.numCell)}>
								<div className={clsx(classes.mdText, classes.flex, classes.alignCenter)}></div>
							</td>
							<td className={clsx(classes.light, classes.numCell)}>
								<div className={clsx(classes.mdText, classes.flex, classes.right)}></div>
							</td>
							<td className={clsx(classes.light, classes.paymentDateCell)}>
								<div className={clsx(classes.mdText, classes.flex, classes.center)}></div>
							</td>
							<td className={clsx(classes.light, classes.statusCell)}></td>
							<td onMouseEnter={getSideForTooltip} className={clsx(classes.light, classes.AppointmentCell)}>
								<Tooltip
									className={clsx({ [classes.appointmentTolltip]: visibleTip })}
									text={<Trans t={t} i18nKey={item.purpose} />}
									placement={visibleTip ? 'bottom-end' : 'left'}
									nowrap
								>
									<div className={clsx(classes.flex, classes.appointmentInner)}>
										<CopyButton className={classes.copyIcon} text={item.purpose} />
										<span className={classes.appointmentText}>{item.purpose}</span>
									</div>
								</Tooltip>
							</td>
						</tr>
					</tbody>
				</table>
			</td>
		</tr>
	);
};

export default AdditionalPayment;
