import { breakPoints } from 'const';

import { useMediaQuery } from './useMediaQuery';

const useDeviceType = () => {
	const isMobile = useMediaQuery(`(max-width: ${breakPoints.MOBILE}px)`);
	const isTablet = useMediaQuery(`(max-width: ${breakPoints.TABLET}px)`);
	const isTabletOnly = useMediaQuery(`(max-width: ${breakPoints.TABLET}px) and (min-width: ${breakPoints.MOBILE + 1}px)`);
	const isDesktop = !isMobile && !isTablet && !isTabletOnly;

	return {
		isDesktop,
		isMobile,
		isTablet,
		isTabletOnly,
	};
};

export default useDeviceType;
