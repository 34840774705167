import { RefObject, useEffect } from 'react';

export function useClickAwayListener(ref: RefObject<HTMLDivElement>, callback: (event: MouseEvent | TouchEvent) => void, deps?: unknown | undefined) {
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent | TouchEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				callback(event);
			}
		};
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [ref, deps]);
}
