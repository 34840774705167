import { gql } from '@apollo/client';

export const GET_DICTIONARY = gql`
	query GetDictionary {
		dictionaries {
			type
			typeId
			data {
				id
				value
			}
		}
	}
`;

export const GET_PARTNER_FILE = gql`
	query PartnerData($page: Int, $pageSize: Int, $filter: PartnerFilter) {
		partnerData(page: $page, pageSize: $pageSize, filter: $filter) {
			data {
				contractNumberBase
				contractNumber
				payingBulkStatus
				reasonDoNotPay
				names
				comment
				contractName
				contractType
				contractFormat
				contractLink
				contractStatus
				contractPaperStatus
				allDocuments {
					signed
					unSigned
				}
				legalName
				taxId
				iban
				ibanStatus
				commission {
					id
					contractNumber
					startDate
					endDate
					value
					comment
				}
				trial
				platformFee
				weeklyInvoicingLink
				weeklyInvoicingStatus
				weeklyInvoicingDate
				platformFeeCurrentPayment
				activationFee
				pickUpCommission
				contractDate
				contractEndDate
				paymentType
				paymentTerm
				contractVersion
				invoicing
				totRegistration
				totStatus
				responsibleSales
				responsibleAccountManager
				lastWarrantyLetterDate
				contractLink
				storeAddresses
				additionAgreementForPromo
				additionAgreementForPromoDate
				promoWithoutAdditionAgreement
				promoWithoutAdditionAgreementDate
				autoProlongation
				exclusivity
				openDataBotLastUpdate
				openDataBotStatus
				isCommissionValid
				currentPartnerCommission
			}
			total
			totalPages
		}
	}
`;

export const GET_PARTNER_HISTORY = gql`
	query GetPartnerHistory($page: Int, $pageSize: Int, $contractNumber: String!) {
		getPartnerHistory(page: $page, pageSize: $pageSize, contractNumber: $contractNumber) {
			data {
				createdAt
				data {
					createdBy
					data {
						field
						oldValue
						newValue
						createdBy
						createdAt
						commissionId
					}
				}
			}
			total
			totalPages
		}
	}
`;

export const DELETE_CONTRACT = gql`
	mutation DeletePartnerContract($contractNumber: String) {
		deletePartnerContract(contractNumber: $contractNumber)
	}
`;

export const CREATE_CONTRACT = gql`
	mutation NewPartnerContract($input: PartnerDataInput!) {
		newPartnerContract(input: $input)
	}
`;

export const EDIT_CONTRACT = gql`
	mutation EditPartnerData($input: PartnerDataInput!) {
		editPartnerData(input: $input)
	}
`;

export const EDIT_PARTNER_COMMISSION = gql`
	mutation EditPartnerCommissions($input: [PartnerInputCommission]!) {
		editPartnerCommissions(input: $input)
	}
`;

export const DELETE_PARTNER_COMMISSION = gql`
	mutation DeletePartnerCommission($commissionId: [Int]!) {
		deletePartnerCommission(commissionId: $commissionId)
	}
`;

export const SUBSCRIPTION_GET_NEW_CONTRACT = gql`
	subscription PartnerContract {
		partnerContract {
			contractNumberBase
			contractNumber
			payingBulkStatus
			reasonDoNotPay
			names
			comment
			contractName
			contractType
			contractFormat
			contractLink
			contractStatus
			contractPaperStatus
			allDocuments {
				signed
				unSigned
			}
			legalName
			taxId
			iban
			ibanStatus
			commission {
				id
				contractNumber
				startDate
				endDate
				value
				comment
			}
			trial
			platformFee
			weeklyInvoicingLink
			weeklyInvoicingStatus
			weeklyInvoicingDate
			platformFeeCurrentPayment
			activationFee
			pickUpCommission
			contractDate
			contractEndDate
			paymentType
			paymentTerm
			contractVersion
			invoicing
			totRegistration
			totStatus
			responsibleSales
			responsibleAccountManager
			lastWarrantyLetterDate
			contractLink
			storeAddresses
			additionAgreementForPromo
			additionAgreementForPromoDate
			promoWithoutAdditionAgreement
			promoWithoutAdditionAgreementDate
			autoProlongation
			exclusivity
			openDataBotLastUpdate
			openDataBotStatus
		}
	}
`;

export const SUBSCRIPTION_GET_NEW_HISTORY = gql`
	subscription PartnerHistory {
		partnerHistory {
			contractNumber
			field
			oldValue
			newValue
			createdBy
			createdAt
			commissionId
		}
	}
`;

export const IBAN_VALIDATION = gql`
	query validIban($iban: String!) {
		validIban(iban: $iban)
	}
`;

export const TAG_COUNT = gql`
	query partnerTagCount {
		partnerTagCount
	}
`;
