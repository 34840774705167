import React, { useRef, useState } from 'react';
import clsx from 'helpers/clsx';
import { useClickAwayListener } from 'hooks/useClickAwayListener';

import { IProps } from './types';

import classes from './Popover.module.scss';

const Popover: React.FC<IProps> = ({ children, popoverClassName, rootClassName, placement = 'left', body, withOverlay }) => {
	const [open, setOpen] = useState<boolean>(false);
	const ref = useRef<HTMLDivElement | null>(null);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const childrenClone = React.Children.map(children, (child) => {
		return (
			React.isValidElement(child) &&
			React.cloneElement(child as React.ReactElement, {
				onClick: handleOpen,
			})
		);
	});

	useClickAwayListener(ref, handleClose);

	return (
		<div className={clsx(classes.root, rootClassName)} ref={ref}>
			{childrenClone}
			{open && (
				<>
					{withOverlay && <div className={classes.overlay} onClick={handleClose}></div>}
					<div
						className={clsx(classes.popover, popoverClassName, {
							[classes[`popover_${placement}`]]: placement,
						})}
					>
						{body}
					</div>
				</>
			)}
		</div>
	);
};

export default Popover;
