import { gql } from '@apollo/client';

export default gql`
	enum InvoiceType {
		INVOICE
		PAYMENT
	}

	type InvoiceDto {
		id: ID
		date: Date
		number: String
		companyName: String
		edrpou: String
		contractNumber: String
		totalAmount: String
		paidAmount: String
		paidDate: Date
		purpose: String
		residualAmount: String
		status: InvoiceStatus
		children: [PaymentDto]
	}

	type PaymentPage {
		paymentDtos: [PaymentDto]
		total: Int
		totalPages: Int
	}

	type PaymentDto {
		id: ID
		date: String
		number: String
		companyName: String
		edrpou: String
		contractNumber: String
		invoiceNumber: String
		totalAmount: String
		purpose: String
	}

	type DebtDto {
		id: ID
		edrpou: String
		companyName: String
		storeAddressesIds: [String]
		contractNumber: String
		unexpiredDebtAmount: Int
		expiredDebtAmount: Int
		totalebtAmount: Int
		documentName: String
		documentDate: String
	}

	enum InvoiceStatus {
		PAID
		NOT_PAID
		PARTIALLY_PAID
	}

	type InvoicePage {
		invoiceDtos: [InvoiceDto]
		total: Int
		totalPages: Int
	}

	type CompanyWithInvoiceFilterDto {
		taxId: String
		companyName: String
		contractInfo: [FilterItemDto]
		storeAddressesIds: [Int]
	}

	type OverdueDebtForUserDto {
		expiredDebtAmount: BigDecimal
		expiredDebt30DaysAmount: BigDecimal
		totalDebtAmount: BigDecimal
	}
`;
