import { fetch } from 'api';
import { normalizeFilter, queryParamsToString } from 'helpers/contractGeneration';
import { IPagination } from 'interfaces';
import { IContract, IContractFilter } from 'interfaces/contractGeneration';
import { Dispatch } from 'redux';

import {
	CHANGE_CONTRACT_FIELDS,
	CHANGE_CONTRACTS_FILTER,
	DELETE_CONTRACT_BY_ID,
	DUBLICATE_CONTRACT,
	FETCH_CONTRACTS_FAILURE,
	FETCH_CONTRACTS_START,
	FETCH_CONTRACTS_SUCCESS,
	SET_CONTRACTS_PAGE,
	SET_CONTRACTS_PAGE_SIZE,
	UPDATE_CONTRACT_NAME,
} from './types';

export const fetchContracts = (filter: IContractFilter, pagination: IPagination) => async (dispatch: Dispatch) => {
	dispatch({ type: FETCH_CONTRACTS_START });
	try {
		const params: { [key: string]: string | string[] | undefined } = {
			...normalizeFilter(filter),
			page: pagination.page.toString(),
			size: pagination.pageSize.toString(),
		};
		const queryParams = queryParamsToString(params);
		const {
			body,
		}: {
			body: {
				total: number;
				dataList: IContract[];
			};
		} = await fetch(`/agreement/getAll?${queryParams}`, { fintoolContracts: true }, false);
		if (body) {
			dispatch({
				payload: {
					items: body.dataList,
					total: body.total,
				},
				type: FETCH_CONTRACTS_SUCCESS,
			});
		}
	} catch {
		dispatch({ type: FETCH_CONTRACTS_FAILURE });
	}
};

export const changeContractsFilter = (field: keyof IContractFilter, value: IContractFilter[typeof field]) => ({
	payload: { field, value },
	type: CHANGE_CONTRACTS_FILTER,
});

export const deleteContractById = (payload: IContract['agreementId']) => ({
	payload,
	type: DELETE_CONTRACT_BY_ID,
});

export const updateContractNameById = (payload: { agreementId: IContract['agreementId']; newAgreementName: IContract['agreementName'] }) => ({
	payload,
	type: UPDATE_CONTRACT_NAME,
});

export const setContractsPage = (payload: number) => ({
	payload,
	type: SET_CONTRACTS_PAGE,
});

export const setContractsPageSize = (payload: number) => ({
	payload,
	type: SET_CONTRACTS_PAGE_SIZE,
});

export const dublicateContractById = (payload: IContract) => ({
	payload,
	type: DUBLICATE_CONTRACT,
});

export const changeFields = (payload: IContract) => ({
	payload,
	type: CHANGE_CONTRACT_FIELDS,
});
