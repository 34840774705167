import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'helpers';
import clsx from 'helpers/clsx';
import { ReactComponent as Arr } from 'static/images/keyboard_arrow_down.svg';
import { TEST_IDS } from 'tests/config';

import IconButton from 'components/IconButton';

import { IProps } from './types';

import classes from './Pagination.module.scss';

const Pagination: React.FC<IProps> = ({ start, end, total, className, onClickPrev, onClickNext }) => {
	const { t } = useTranslation('common');

	const activePrev = start > 1;
	const activeNext = end < total;

	return (
		<div className={clsx(classes.root, className)} data-testid={TEST_IDS.PAGINATION}>
			{t('records')}&nbsp;<span>{Math.max(start, 1)}</span>–<span>{Math.min(end, total)}</span>&nbsp;{t('from')}&nbsp;
			<span>{formatPrice(total)}</span>
			<IconButton onClick={onClickPrev} className={classes.prev} size="average" disabled={!activePrev} testId={TEST_IDS.PAGINATION_PREV}>
				<Arr
					className={clsx(classes.icon, classes.icon_prev, {
						[classes.active]: activePrev,
					})}
				/>
			</IconButton>
			<IconButton onClick={onClickNext} size="average" disabled={!activeNext} testId={TEST_IDS.PAGINATION_NEXT}>
				<Arr
					className={clsx(classes.icon, classes.icon_next, {
						[classes.active]: activeNext,
					})}
				/>
			</IconButton>
		</div>
	);
};

export default Pagination;
