import { QueryOptions } from '@apollo/client';
import { ValueOf } from 'interfaces';
import { IStoreAddress } from 'interfaces/storeAddress';

export interface IProps {
	store: IStoreAddress;
	refetchQueries?: QueryOptions[];
}

export interface IHistoryRow {
	key: keyof IStoreAddress;
	old?: ValueOf<IStoreAddress>;
	new: ValueOf<IStoreAddress>;
}

export interface IHistory {
	createdAt: IStoreAddress['createdAt'];
	createdByEmail: IStoreAddress['createdByEmail'];
	effectiveDate: IStoreAddress['effectiveDate'];
	reason: IStoreAddress['reason'];
	history: IHistoryRow[];
}

export interface IResponseHistory {
	storeAddressHistory: IStoreAddress[];
}

export enum IPromoCalculationType {
	FULL = 'FULL',
	REDUCED = 'REDUCED',
}

export enum PromoType {
	DELIVERY = 'DELIVERY',
	PRODUCT = 'PRODUCT',
}
