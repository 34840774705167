import { clientGraphql } from 'api';
import { GET_LAAS_ORDERS, GET_LAAS_TOTALS } from 'api/laasOrders';
import { normalizeFilter } from 'helpers/order';
import { IFilter, ILaaSOrder } from 'interfaces/laasOrder';
import { Dispatch } from 'redux';

import {
	CHANGE_LAAS_ORDERS_FILTER,
	CLEAR_ALL_LAAS_ORDERS,
	CLEAR_LAAS_ORDERS_FILTER,
	FETCH_LAAS_ORDERS_FAILURE,
	FETCH_LAAS_ORDERS_START,
	FETCH_LAAS_ORDERS_SUCCESS,
	FETCH_LAAS_ORDERS_TOTALS_FAILURE,
	FETCH_LAAS_ORDERS_TOTALS_START,
	FETCH_LAAS_ORDERS_TOTALS_SUCCESS,
	SET_LAAS_ORDERS_PAGE,
	SET_LAAS_ORDERS_PAGE_SIZE,
	UPDATE_LAAS_ORDER,
} from './types';

export const fetchOrders =
	(filter: IFilter, { page, pageSize }: { page: number; pageSize: number }) =>
	(dispatch: Dispatch): Promise<ILaaSOrder[] | undefined> => {
		dispatch({ type: FETCH_LAAS_ORDERS_START });
		return clientGraphql
			.query({
				query: GET_LAAS_ORDERS,
				variables: { page, pageSize, filter: normalizeFilter(filter) },
			})
			.then((res) => {
				dispatch({
					payload: {
						orders: res.data.laasOrders.data,
						total: res.data.laasOrders.total,
					},
					type: FETCH_LAAS_ORDERS_SUCCESS,
				});
				return res.data.laasOrders.data;
			})
			.catch(() => {
				dispatch({ type: FETCH_LAAS_ORDERS_FAILURE });
				return undefined;
			});
	};

export const fetchTotals =
	(filter: IFilter, aborterRef: AbortController) =>
	(dispatch: Dispatch): Promise<ILaaSOrder[] | undefined> => {
		dispatch({ type: FETCH_LAAS_ORDERS_TOTALS_START });
		return clientGraphql
			.query({
				query: GET_LAAS_TOTALS,
				variables: { filter: normalizeFilter(filter) },
				fetchPolicy: 'network-only',
				context: {
					fetchOptions: {
						signal: aborterRef.signal,
					},
				},
			})
			.then((res) => {
				dispatch({
					payload: res.data.laasOrderTotals,
					type: FETCH_LAAS_ORDERS_TOTALS_SUCCESS,
				});
				return res.data.laasOrderTotals;
			})
			.catch(() => {
				dispatch({ type: FETCH_LAAS_ORDERS_TOTALS_FAILURE });
				return undefined;
			});
	};

export const setOrdersPage = (payload: number) => ({
	payload,
	type: SET_LAAS_ORDERS_PAGE,
});

export const setOrdersPageSize = (payload: number) => ({
	payload,
	type: SET_LAAS_ORDERS_PAGE_SIZE,
});

export const changeOrdersFilter = (field: keyof IFilter, value: IFilter[typeof field]) => ({
	payload: { field, value },
	type: CHANGE_LAAS_ORDERS_FILTER,
});

export const clearAllOrders = () => ({
	payload: null,
	type: CLEAR_ALL_LAAS_ORDERS,
});

export const clearOrdersFilter = (dates?: [Date, Date]) => ({
	payload: dates,
	type: CLEAR_LAAS_ORDERS_FILTER,
});

export const updateOrder = (payload: ILaaSOrder) => ({
	payload,
	type: UPDATE_LAAS_ORDER,
});
