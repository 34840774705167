import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'helpers/clsx';
import { TEST_IDS } from 'tests/config';

import { Field, IField } from './types';

import classes from './Documents.module.scss';

const DocumentsHead: React.FC = () => {
	const { t } = useTranslation('document');

	const fields: IField[] = [
		{
			id: Field.Date,
			title: t('fields.date'),
			render: true,
		},
		{
			id: Field.NameDocument,
			title: t('fields.nameDocument'),
			render: true,
		},
		{
			id: Field.Type,
			title: t('fields.type'),
			render: true,
		},
		{
			id: Field.DocumentNo,
			title: t('fields.documentNo'),
			render: true,
		},
		{
			id: Field.CodeCompany,
			title: t('fields.codeCompany'),
			render: true,
		},
		{
			id: Field.Partner,
			title: t('fields.partner'),
			render: false,
		},
		{
			id: Field.Status,
			title: t('fields.status'),
			render: true,
		},
		{
			id: Field.LinkToVchasno,
			title: '',
			render: true,
		},
	];

	return (
		<thead data-testid={TEST_IDS.DOCUMENTS_TABLE_HEAD}>
			<tr>
				{fields.map((field) => {
					if (!field.render) return null;

					return (
						<td
							key={field.id}
							className={clsx(classes.tableData, {
								[classes.date]: field.id === Field.Date,
								[classes.nameDocument]: field.id === Field.NameDocument,
								[classes.type]: field.id === Field.Type,
								[classes.sum]: field.id === Field.Sum,
								[classes.documentNo]: field.id === Field.DocumentNo,
								[classes.codeCompany]: field.id === Field.CodeCompany,
								[classes.status]: field.id === Field.Status,
								[classes.statusPay]: field.id === Field.StatusPay,
								[classes.linkToVchasno]: field.id === Field.LinkToVchasno,
							})}
						>
							<div>
								{field.title.split('\n').map((h) => (
									<div key={h}>{h}</div>
								))}
							</div>
						</td>
					);
				})}
			</tr>
		</thead>
	);
};

export default DocumentsHead;
