import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isLimitString, isSetString } from 'helpers/validation';
import { useAuth } from 'hooks/useAuth';
import { useFeedback } from 'hooks/useFeedback';
import useToast from 'hooks/useToast';
import { ReactComponent as FeedbackIcon } from 'static/images/feedback.svg';

import Textarea from 'components/FormControl/Textarea';
import Confirm from 'components/modals/Confirm';

import classes from './Feedback.module.scss';

const Feedback: React.FC = () => {
	const [message, setMessage] = useState<string>('');
	const [error, setError] = useState<string>('');
	const [loading, setLoading] = useState(false);
	const { isAuth } = useAuth();
	const { t } = useTranslation(['feedback', 'errors', 'validation']);
	const { sendMessage } = useFeedback();
	const { toast } = useToast();

	const handleChangeMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setError('');
		setMessage(event.target.value);
	};

	const submitMessage = async () => {
		if (!isSetString(message.trim())) {
			setError(t('validation:errors.required'));
			return false;
		}
		if (!isLimitString(message.trim(), 300)) {
			setError(t('validation:errors.limitStringMessage', { count: 300 }));
			return false;
		}
		setLoading(true);
		return sendMessage(message.trim())
			.then(() => {
				setMessage('');
				return true;
			})
			.catch(() => {
				toast(t('errors:somethingWentWrong'), { type: 'error' });
				return false;
			})
			.finally(() => setLoading(false));
	};

	if (!isAuth) return null;

	const body = (
		<div className={classes.body}>
			<div className={classes.text} dangerouslySetInnerHTML={{ __html: t('text').replace('\n', '<br />') }} />
			<Textarea value={message} onChange={handleChangeMessage} rootClassName={classes.fullWidth} placeholder={t('message')} error={error} />
			{!error && <div className={classes.hint}>{t('max')}</div>}
		</div>
	);

	return (
		<Confirm
			title={t('title')}
			body={body}
			confirm={{
				text: t('submit'),
				onClick: submitMessage,
				disabled: loading,
			}}
		>
			<button className={classes.button}>
				<FeedbackIcon />
			</button>
		</Confirm>
	);
};

export default Feedback;
