import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'helpers/clsx';
import { ReactComponent as IconClose } from 'static/images/close.svg';
import { TEST_IDS } from 'tests/config';

import { IProps } from './types';

import classes from './modal.module.scss';

const ModalTemplate: React.FC<IProps> = (props) => {
	const {
		title,
		children,
		rootClassName,
		modalClassName,
		overlayClassName,
		closeClassName,
		closeButtonClassName,
		titleClassName,
		bodyClassName,
		onClose,
		customPosition,
		hideClose,
		alignTitle,
		withoutShadowTitle,
		buttons,
	} = props;
	const { t } = useTranslation('form');
	return (
		<div
			className={clsx(classes.root, rootClassName, {
				[classes.customPosition]: !!customPosition,
			})}
			data-testid={TEST_IDS.MODAL}
		>
			<div className={clsx(classes.overlay, overlayClassName)} data-testid={TEST_IDS.MODAL_OVERLAY} onClick={onClose} />
			<div className={classes.container}>
				<div
					className={clsx(classes.modal, modalClassName)}
					style={{
						...customPosition,
					}}
				>
					{!hideClose && (
						<div className={clsx(classes.closeButton, closeButtonClassName)} onClick={onClose}>
							<IconClose data-testid={TEST_IDS.MODAL_CLOSE} className={clsx(classes.iconClose, closeClassName)} />
						</div>
					)}
					{title && (
						<div
							className={clsx(classes.title, titleClassName, {
								[classes.title_center]: alignTitle === 'center',
								[classes.title_withoutShadow]: withoutShadowTitle,
							})}
						>
							{title}
						</div>
					)}
					<div className={clsx(classes.body, bodyClassName)}>
						{children}
						{buttons && (
							<div
								className={clsx(classes.buttons, classes.flex, {
									[classes.justifyContentCenter]: !buttons.cancel,
									[classes.justifyContentBetween]: buttons.cancel,
								})}
							>
								{buttons.cancel && (
									<button onClick={buttons.cancel.onClick || onClose} className={classes.buttonCancel} data-testid={TEST_IDS.MODAL_BUTTON_CANCEL}>
										{buttons.cancel.text || t('cancel')}
									</button>
								)}
								<button
									onClick={buttons.confirm.onClick}
									className={buttons.confirm.className}
									data-testid={TEST_IDS.MODAL_BUTTON_CONFIRM}
									disabled={buttons.confirm.disabled}
								>
									{buttons.confirm.text}
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModalTemplate;
