import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'helpers/clsx';
import { ReactComponent as Icon } from 'static/images/clear_filters.svg';

import { IPropsClear } from './types';

import classes from './FormControl.module.scss';

const Clear: React.FC<IPropsClear> = ({ rootClassName, onClick, children }) => {
	const { t } = useTranslation('form');
	return (
		<div className={clsx(classes.clear, rootClassName)} onClick={onClick}>
			<Icon className={classes.clearIcon} />
			<div className={classes.clearText}>{children || t('clearFilter')}</div>
		</div>
	);
};

export default Clear;
