import { gql } from '@apollo/client';

export default gql`
	type PayoutScheduleNotification {
		periodStart: Date!
		periodEnd: Date!
		reconciliationDeadline: Date!
		daysLeft: Int!
		accountingType: Int!
	}
`;
