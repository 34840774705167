import { useTranslation } from 'react-i18next';
import clsx from 'helpers/clsx';
import { AgreementStatus } from 'interfaces/contractGeneration';

import classes from './Status.module.scss';

export const Status = ({ status }: { status: string }) => {
	if (!status) return null;

	const { t } = useTranslation('contractGeneration');
	return (
		<div
			className={clsx(classes.status, {
				[classes.status_success]: status === AgreementStatus.FILLED,
				[classes.status_cancelled]: status === AgreementStatus.CANCELLED || status === AgreementStatus.DELETED_FROM_VCHASNO,
				[classes.status_partially_filled]: status === AgreementStatus.PARTIALLY_FILLED,
			})}
		>
			{t(`status.${status.toLowerCase()}`)}
		</div>
	);
};
