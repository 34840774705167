import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'helpers/clsx';
import useToast from 'hooks/useToast';
import { ReactComponent as Icon } from 'static/images/file-upload.svg';
import { ReactComponent as InfoIcon } from 'static/images/info.svg';

import { IPropsFile } from './types';

import classes from './FormControl.module.scss';

const File: React.FC<IPropsFile> = ({ children, rootClassName, onChange, validTypes, limitSize = 20, testId, Icon: PropsIco }) => {
	const { t } = useTranslation('errors');
	const { toast } = useToast();

	const getIcon = () => (PropsIco ? <PropsIco className={classes.fileIcon} /> : <Icon className={classes.fileIcon} />);

	const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target;
		if (!files?.length) return;
		if (!validTypes?.length) return onChange(files);
		const validFiles = Array.from(files).filter((file) => {
			const globalTypes = validTypes.filter((type) => type.includes('*'));
			let isValid;
			for (const type of globalTypes) if (file.type.includes(type.replace('*', ''))) return (isValid = true);
			if (isValid) return true;
			return validTypes.includes(file.type);
		});

		if (validFiles.length === files.length) onChange(files);
		else toast(`${t('errors:incorrectFileType')}`, { type: 'error' });
	};

	return (
		<div className={clsx(classes.root, classes.file, rootClassName)}>
			<div className={classes.fileInner}>
				<input
					multiple
					type="file"
					onChange={handleUploadFile}
					role="button"
					data-testid={testId}
					accept="image/*, video/*, .xls, .xlsx, .doc, .docx, .pdf"
				/>
				{getIcon()}
				<div className={classes.filename}>{children}</div>
				<div className={classes.uploadFiles}></div>
			</div>
			{!!limitSize && limitSize > 0 && (
				<div className={clsx(classes.info, classes.verticalAlignCenter)}>
					<InfoIcon />
					{t('fileSizeLimit', { limitSize })}
				</div>
			)}
		</div>
	);
};

export default File;
