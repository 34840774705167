import { ValueOf } from 'interfaces';
import { AccessData, DictionaryData, IFiltration, PartnerDataFullDto, PartnerHistory, PartnerHistoryRow } from 'interfaces/partnersFile';
import { IFilter } from 'interfaces/partnersFile';

export const FETCH_PARTNER_FILE_START = 'FETCH_PARTNER_FILE_START';
export const FETCH_PARTNER_FILE_SUCCESS = 'FETCH_PARTNER_FILE_SUCCESS';
export const FETCH_PARTNER_FILE_FAILURE = 'FETCH_PARTNER_FILE_FAILURE';
export const CHANGE_PARTNER_FILE_FILTER = 'CHANGE_PARTNER_FILE_FILTER';
export const CLEAR_PARTNER_FILE_FILTER = 'CLEAR_PARTNER_FILE_FILTER';
export const CLEAR_PARTNER_FILTRATION = 'CLEAR_PARTNER_FILTRATION';
export const SET_PARTNER_FILE_PAGE = 'SET_PARTNER_FILE_PAGE';
export const SET_PARTNER_FILE_PAGE_SIZE = 'SET_PARTNER_FILE_PAGE_SIZE';
export const DELETE_CONTRACT_BY_NUMBER = 'DELETE_CONTRACT_BY_NUMBER';
export const CREATE_CONTRACT = 'CREATE_CONTRACT';
export const FETCH_HISTORY_START = 'FETCH_HISTORY_START';
export const FETCH_HISTORY_SUCCESS = 'FETCH_HISTORY_SUCCESS';
export const FETCH_HISTORY_FAILURE = 'FETCH_HISTORY_FAILURE';
export const FETCH_DICTIONARY = 'FETCH_DICTIONARY';
export const FETCH_DICTIONARY_SUCCESS = 'FETCH_DICTIONARY_SUCCESS';
export const FETCH_DICTIONARY_FAILURE = 'FETCH_DICTIONARY_FAILURE';
export const UPDATE_CONTRACT_AND_HISTORY = 'UPDATE_CONTRACT_AND_HISTORY';
export const SET_PARTNER_HISTORY_PAGE = 'SET_PARTNER_HISTORY_PAGE';
export const CHANGE_FILTRATION = 'CHANGE_FILTRATION';
export const RESET_SELECTED_ROW = 'RESET_SELECTED_ROW';
export const FETCH_ACCESS_DATA = 'FETCH_ACCESS_DATA';
export const FETCH_ACCESS_DATA_SUCCESS = 'FETCH_ACCESS_DATA_SUCCESS';
export const FETCH_ACCESS_DATA_FAILURE = 'FETCH_ACCESS_DATA_FAILURE';
export const SET_COMMISSION_VALID = 'SET_COMMISSION_VALID';

export interface IState {
	items: PartnerDataFullDto[];
	partnerHistory: PartnerHistoryRow[];
	filter: IFilter;
	filtration: IFiltration;
	total: number;
	partnerHistoryTotal: number;
	onload: boolean;
	wasLoaded: boolean;
	loadingHistory: boolean;
	dictionaries: DictionaryData[];
	pagination: {
		page: number;
		pageSize: number;
	};
	partnerHistoryPagination: {
		page: number;
		pageSize: number;
	};
	selectedRow: PartnerDataFullDto | null;
	accessData: AccessData;
	loadingAccessData: boolean;
}

export type IAction =
	| {
			type: typeof FETCH_PARTNER_FILE_SUCCESS;
			payload: { data: PartnerDataFullDto[]; total: number };
	  }
	| {
			type: typeof SET_PARTNER_FILE_PAGE_SIZE | typeof SET_PARTNER_FILE_PAGE | typeof SET_PARTNER_HISTORY_PAGE;
			payload: number;
	  }
	| {
			type:
				| typeof FETCH_PARTNER_FILE_START
				| typeof FETCH_PARTNER_FILE_FAILURE
				| typeof CLEAR_PARTNER_FILE_FILTER
				| typeof CLEAR_PARTNER_FILTRATION
				| typeof FETCH_HISTORY_START
				| typeof RESET_SELECTED_ROW
				| typeof FETCH_ACCESS_DATA
				| typeof FETCH_ACCESS_DATA_FAILURE;
	  }
	| {
			type: typeof CHANGE_PARTNER_FILE_FILTER | typeof DELETE_CONTRACT_BY_NUMBER;
			payload: { field: string; value: ValueOf<IFilter> };
	  }
	| { type: typeof DELETE_CONTRACT_BY_NUMBER; payload: string }
	| {
			type: typeof FETCH_HISTORY_SUCCESS;
			payload: {
				partnerHistory: { data: PartnerHistoryRow[]; total: number };
			};
	  }
	| {
			type: typeof FETCH_DICTIONARY_SUCCESS;
			payload: { dictionaries: DictionaryData[] };
	  }
	| { type: typeof UPDATE_CONTRACT_AND_HISTORY; payload: PartnerHistory[] }
	| { type: typeof FETCH_ACCESS_DATA_SUCCESS; payload: AccessData }
	| {
			type: typeof SET_COMMISSION_VALID;
			payload: {
				contractNumber: PartnerDataFullDto['contractNumber'];
				isCommissionValid: boolean;
			};
	  }
	| { type: typeof CHANGE_FILTRATION; payload: IFiltration }
	| { type: typeof CREATE_CONTRACT; payload: PartnerDataFullDto };
