import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { fetch } from 'api';
import { format } from 'date-fns';
import clsx from 'helpers/clsx';
import { isValidEmail } from 'helpers/validation';
import { useContractGeneration } from 'hooks/useContractGeneration';
import { IVchasnoForm, IVchasnoResponse } from 'interfaces/contractGeneration';
import { ReactComponent as WarningIcon } from 'static/images/warningIcon.svg';
import { TEST_IDS } from 'tests/config';

import CopyButton from 'components/CopyButton';
import { DatePicker, Input, Select } from 'components/FormControl';
import { IPropsSelect } from 'components/FormControl/types';
import Loader from 'components/Loader';
import Modal from 'components/modals/Modal';

import { IPropsTemplate } from './types';

import classes from './SendVchasno.module.scss';

const SendVchasno: React.FC<IPropsTemplate> = ({ onClose, fields, setFields, contractId }) => {
	const { t } = useTranslation(['contractGeneration', 'validation', 'errors']);
	const { getContractById } = useContractGeneration();
	const [contractFiels, setContractFields] = useState(fields);
	const hasTins = Object.keys(contractFiels.placeholderMap).filter((k) => contractFiels.placeholderMap[k].configuration?.tinType === 'taxId');
	const hasEmails = Object.keys(contractFiels.placeholderMap).filter((k) => contractFiels.placeholderMap[k].type === 'email');
	const [vchasnoForm, setVchasnoForm] = useState<IVchasnoForm>({
		agreementId: contractFiels.agreementId,
		edrpouOwner: '',
		recipients: hasTins.map((tin, i) => ({
			edrpou: contractFiels.placeholderMap[tin].value,
			email: hasEmails.length ? contractFiels.placeholderMap[hasEmails[i]].value : '',
		})),
		documentDate: new Date().toISOString(),
		firstSignBy: '',
		documentNumber: contractFiels.placeholderMap.automaticAgreementNumbering?.value
			? contractFiels.placeholderMap.automaticAgreementNumbering?.value.split(' ')[1]
			: '',
	});
	const [glovoEdrpou, setGlovoEdrpou] = useState<{ [key: string]: string }>({});
	const [firstSignBy, setFirstSignBy] = useState<string[]>([]);
	const [responseData, setResponseData] = useState<IVchasnoResponse>({
		syncStatus: '',
		createdAt: '',
		url: '',
	});
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState<{ [key: string]: string | object }>({});

	const fetchGlovoEdrpou = async () => {
		const { body }: { body: { [key: string]: string } } = await fetch('/util/getGlovoEdrpou', { fintoolContracts: true }, false);
		if (body) {
			setGlovoEdrpou(body);
		}
	};

	const fetchFirstSignBy = async () => {
		const { body }: { body: string[] } = await fetch('/util/getFirstSignBy', { fintoolContracts: true }, false);
		if (body) {
			setFirstSignBy(body);
		}
	};

	const sendForm = async () => {
		try {
			setLoading(true);
			const data = { ...vchasnoForm };
			if (hasTins.length > 1) {
				delete data.firstSignBy;
			}
			data.documentDate = format(new Date(data.documentDate), 'yyyy-MM-dd');
			const { body }: { body: IVchasnoResponse } = await fetch(
				'/document/uploadToVchasno',
				{
					fintoolContracts: true,
					method: 'post',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(data),
				},
				false,
			);
			if (body) {
				if (body.url) {
					setFields({
						...fields,
						vchasnoSync: body,
						canConfigure: false,
					});
				}
				setResponseData(body);
				if (body.key === 'emailIsRequired') {
					setErrors({
						recipients: data.recipients.map(() => ({
							email: t('validation:errors.required'),
						})),
					});
				}
				setLoading(false);
			}
		} catch {
			toast(t('errors:somethingWentWrong'), { type: 'error' });
		}
	};

	useEffect(() => {
		setLoading(true);
		fetchGlovoEdrpou();
		fetchFirstSignBy();
		setLoading(false);
	}, []);

	useEffect(() => {
		if (contractId) {
			setLoading(true);
			getContractById(contractId)
				.then(({ body }) => {
					setContractFields(body);
					const tins = Object.keys(body.placeholderMap).filter((k) => body.placeholderMap[k].configuration?.tinType === 'taxId');
					const emails = Object.keys(body.placeholderMap).filter((k) => body.placeholderMap[k].type === 'email');
					setVchasnoForm((prevState) => ({
						...prevState,
						recipients: tins.map((tin, i) => ({
							edrpou: body.placeholderMap[tin].value,
							email: emails.length ? body.placeholderMap[emails[i]].value : '',
						})),
						documentNumber: body.placeholderMap.automaticAgreementNumbering?.value
							? body.placeholderMap.automaticAgreementNumbering?.value.split(' ')[1]
							: '',
					}));
				})
				.finally(() => setLoading(false));
		}
	}, [contractId]);

	const handleChangeVchasnoFields = (key: string, value: IPropsSelect['value'] | Date, edrpou?: string, errorKey?: string) => {
		if (errors[key] || (errorKey && errors[errorKey])) {
			const clearErorrs = { ...errors };
			if (errorKey) {
				delete clearErorrs[errorKey];
			} else {
				delete clearErorrs[key];
			}
			setErrors(clearErorrs);
		}

		if (edrpou) {
			setVchasnoForm({
				...vchasnoForm,
				recipients: vchasnoForm.recipients.map((recipient) => {
					if (recipient.edrpou === edrpou) {
						return { ...recipient, [key]: value };
					}
					return recipient;
				}),
			});
		} else {
			setVchasnoForm({ ...vchasnoForm, [key]: value });
		}
	};

	const handleSubmit = () => {
		const newErrors: { [key: string]: string } = {};
		Object.keys(vchasnoForm).forEach((key) => {
			if (key === 'firstSignBy' && hasTins.length > 0) {
				return;
			}
			if (key === 'recipients' && hasTins.length > 0) {
				vchasnoForm.recipients.forEach((recipient) => {
					if (recipient.email && !isValidEmail(recipient.email)) {
						newErrors[`${key}_${recipient.edrpou}`] = t('validation:errors.email');
					}
				});
			}
			if (!vchasnoForm[key as keyof typeof vchasnoForm] && key !== 'recipients') {
				newErrors[key] = t('validation:errors.required');
			}
		});

		if (Object.keys(newErrors).length) {
			setErrors(newErrors);
		} else {
			sendForm();
		}
	};

	const isEmptyForm = !!(
		vchasnoForm.documentNumber &&
		vchasnoForm.documentDate &&
		vchasnoForm.edrpouOwner &&
		vchasnoForm.recipients.every((recepient) => recepient.edrpou && recepient.email)
	);

	return (
		<Modal
			modalClassName={classes.modal}
			title={t('sendVchasnoTitle')}
			open
			onClose={onClose}
			buttons={{
				cancel: responseData.url ? undefined : {},
				confirm: responseData.url
					? {}
					: {
							text: t('sendVchasnoButtonText'),
							onClick: handleSubmit,
							disabled: loading || !isEmptyForm,
						},
			}}
		>
			{loading && <Loader />}
			{responseData.syncStatus && (
				<div className={classes.vchasno}>
					<div
						className={clsx(classes.vchasno_notice, {
							[classes.vchasno_notice_error]: responseData.syncStatus === 'SOMETHING_WENT_WRONG',
						})}
					>
						<WarningIcon />
						{responseData.reason
							? responseData.reason
							: t(`vchasnoResponse.${responseData.syncStatus}`, {
									date: responseData.createdAt ? format(new Date(responseData.createdAt), 'yyyy-MM-dd HH:mm') : null,
								})}
					</div>
					{responseData.url && (
						<div className={classes.vchasno_copy}>
							<label>{t('fields.vchasnoUrl')}</label>
							<div className={classes.vchasno_copy_input}>
								<span>{responseData.url.slice(0, 55)}...</span>
								<CopyButton className={classes.copyIcon} text={responseData.url} />
							</div>
						</div>
					)}
				</div>
			)}
			<div className={classes.vchasno_body}>
				{hasTins.length !== 1 && (
					<Select
						fullWidth
						withoutClear
						onChange={(value) => handleChangeVchasnoFields('edrpouOwner', value)}
						options={Object.keys(glovoEdrpou).map((value) => ({
							label: `${value} ${glovoEdrpou[value]}`,
							value,
						}))}
						label={t('fields.edrpouOwner')}
						error={errors.edrpouOwner as string}
						value={vchasnoForm.edrpouOwner}
						disabled={!!responseData.url}
						testId={TEST_IDS.EDRPOU_SELECT}
					/>
				)}
				<div className={classes.vchasno_body_row}>
					{hasTins.length === 1 && (
						<Select
							fullWidth
							withoutClear
							onChange={(value) => handleChangeVchasnoFields('edrpouOwner', value)}
							options={Object.keys(glovoEdrpou).map((value) => ({
								label: `${value} ${glovoEdrpou[value]}`,
								value,
							}))}
							label={t('fields.edrpouOwner')}
							error={errors.edrpouOwner as string}
							value={vchasnoForm.edrpouOwner}
							disabled={!!responseData.url}
							testId={TEST_IDS.EDRPOU_SELECT}
						/>
					)}
					{hasTins.length > 0 &&
						hasTins.map((tin, index) => (
							<Input disabled key={tin} value={vchasnoForm.recipients[index]?.edrpou} label={contractFiels.placeholderMap[tin].label} type="number" />
						))}
				</div>
				{hasTins.length > 0 &&
					hasTins.map((tin, index) => {
						const errorKey = `recipients_${contractFiels.placeholderMap[tin].value}`;
						return (
							<Input
								fullWidth
								key={'emailFor' + tin + index}
								value={vchasnoForm.recipients[index]?.email ? vchasnoForm.recipients[index]?.email : ''}
								onChange={(value) => handleChangeVchasnoFields('email', value, contractFiels.placeholderMap[tin].value, errorKey)}
								label={t('emailRecipient', {
									count: hasTins.length > 1 ? index + 1 : null,
								})}
								type="email"
								error={errors[errorKey] as string}
								disabled={!!responseData.url}
							/>
						);
					})}
				{hasTins.length === 1 && (
					<Select
						withoutClear
						onChange={(value) => handleChangeVchasnoFields('firstSignBy', value)}
						options={firstSignBy.map((value) => ({
							label: t(value),
							value,
						}))}
						label={t('fields.firstSignBy')}
						error={errors.firstSignBy as string}
						value={vchasnoForm.firstSignBy}
						disabled={!!responseData.url}
					/>
				)}
				<div className={classes.vchasno_body_row}>
					<Input
						fullWidth
						value={vchasnoForm.documentNumber}
						onChange={(value) => handleChangeVchasnoFields('documentNumber', value)}
						label={t('fields.documentNumber')}
						type="text"
						error={errors.documentNumber as string}
						disabled={!!responseData.url}
					/>
					<DatePicker
						withPortal
						datepickerProps={{
							startDate: new Date(vchasnoForm.documentDate),
						}}
						format="dd.MM.yyyy"
						onChange={(value) => handleChangeVchasnoFields('documentDate', value as Date)}
						label={t('fields.documentDate')}
						error={errors.documentDate as string}
						disabled={!!responseData.url}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default SendVchasno;
