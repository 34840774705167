import { getLang } from 'helpers/jwt';
import { getLangByTimeZone } from 'helpers/lang';
import { CustomDetector } from 'i18next-browser-languagedetector';

class LangDetector implements CustomDetector {
	public name = 'langDetector';
	public lookup() {
		return getLangByTimeZone() || getLang();
	}
}

const LangDetectorExport = new LangDetector();
export default LangDetectorExport;
