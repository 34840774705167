import React from 'react';
import { fetch } from 'api';
import { bytesToSize, createLink } from 'helpers';
import clsx from 'helpers/clsx';
import { ReactComponent as Icon } from 'static/images/file-loaded.svg';
import { TEST_IDS } from 'tests/config';

import { IPropsFileLoaded } from './types';

import classes from './FormControl.module.scss';

const FileLoaded: React.FC<IPropsFileLoaded> = ({ rootClassName, filename, id, size, url: propsUrl = null, params = '', Icon: PropsIco }) => {
	const getIcon = () => (PropsIco ? <PropsIco className={classes.fileLoadedIcon} /> : <Icon className={classes.fileLoadedIcon} />);

	const Download = () => {
		if (propsUrl) return createLink(propsUrl, filename);

		fetch(`/reports/download/${id}${params ? `?${params}` : ''}`).then((res) => {
			const url = window.URL.createObjectURL(res.body as Blob);
			createLink(url, filename);
		});
	};

	return (
		<div data-testid={TEST_IDS.FILE_LOADED} className={clsx(classes.fileLoaded, rootClassName)} onClick={Download}>
			{getIcon()}
			<div className={classes.fileLoadedName}>{filename}</div>
			{!!size && <div className={classes.fileSize}>{bytesToSize(size)}</div>}
		</div>
	);
};

export default FileLoaded;
