import { AccountingTypes } from 'interfaces';

export enum TypeIcon {
	DOCS = 'DOCS',
	DEBT = 'DEBT',
	COMMENT = 'COMMENT',
}

export interface IProps {
	className?: string;
	type: AccountingTypes | TypeIcon;
}
