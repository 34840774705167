import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyQueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import { GET_STORE_ADDRESSES_DATA } from 'api/orders';
import { GET_STORE_ADRESSES, GET_STORE_DATA_BY_TAX_ID } from 'api/storeAddresses';
import StoreListInfo from 'containers/StoreListInfo';
import clsx from 'helpers/clsx';
import { useUser } from 'hooks/useUser';
import { IStoreAddress, IStoreAddressShort } from 'interfaces/storeAddress';
import { ReactComponent as ArrowDownIcon } from 'static/images/arrow_down.svg';
import { ReactComponent as LocationIco } from 'static/images/location.svg';

import { Select } from 'components/FormControl';
import Loader from 'components/Loader';

import { IProps, ITaxIdItem } from './types';

import classes from './DocumentsStores.module.scss';

const DocumentStores: React.FC<IProps> = ({ taxIds }) => {
	const { t } = useTranslation('document');
	const { isServiceUser } = useUser();
	const [openStore, setOpenStore] = useState<boolean>(false);
	const [selectedStoreId, setSelectedStoreId] = useState<number | null>(null);
	const [selectedStoreAddress, setSelectedStoreAddress] = useState<IStoreAddress | null>(null);
	const [fetchFullStoreInfo, { loading: loadingFullStoreInfo, data: fullStoreInfo }] = useLazyQuery(GET_STORE_ADRESSES);
	const [fetchStoresData, { loading: loadingStoresData, data: storesData }] = useLazyQuery(GET_STORE_ADDRESSES_DATA);
	const { loading: loadingDocStoresIds, data: docStoresIds } = useQuery(GET_STORE_DATA_BY_TAX_ID, {
		variables: { taxIds },
	});

	const params: LazyQueryHookOptions = {
		fetchPolicy: 'network-only',
	};

	const handleStoreIdChange = (id: number) => setSelectedStoreId(id);
	const addressesOptions = storesData?.storeAddressData?.map((store: IStoreAddressShort) => ({
		value: store.storeAddressId,
		label: `${store.storeAddressId} — ${store.address}`,
	}));

	const setDefaultId = () => {
		if (loadingDocStoresIds) return;
		const defaultStoreId = docStoresIds?.storeAddressByTaxId[0]?.storeAddressIds[0];
		if (!selectedStoreId && defaultStoreId) handleStoreIdChange(defaultStoreId);
	};

	const fetchFullStoreInfoAction = () => {
		fetchFullStoreInfo({ ...params, variables: { page: 0, pageSize: 100, filter: { searchQuery: selectedStoreId } } });
	};
	const handleSelectedFullAddress = () => {
		if (loadingFullStoreInfo) return;
		const searchResponse = fullStoreInfo?.storeAddresses?.lastStoreAddressValuesDtos;
		const selectedStore = searchResponse?.filter((store: IStoreAddress) => store.storeAddressId === selectedStoreId)[0];
		setSelectedStoreAddress(selectedStore);
	};

	const handleLoadStoresIds = () => {
		if (loadingDocStoresIds) return;
		const storeAddressIds = docStoresIds.storeAddressByTaxId.reduce((acc: number[], item: ITaxIdItem) => [...acc, ...item.storeAddressIds], []);
		if (storeAddressIds.length) fetchStoresData({ ...params, variables: { storeAddressIds } });
	};

	useEffect(fetchFullStoreInfoAction, [selectedStoreId]);
	useEffect(setDefaultId, [selectedStoreId, docStoresIds, loadingDocStoresIds]);
	useEffect(handleSelectedFullAddress, [fullStoreInfo]);
	useEffect(handleLoadStoresIds, [docStoresIds]);

	return (
		<>
			{!!docStoresIds?.storeAddressByTaxId.length && isServiceUser && selectedStoreAddress ? (
				<div className={clsx(classes.container, { [classes.open]: openStore })}>
					<div className={classes.labelWrapper}>
						<div onClick={() => setOpenStore((prev) => !prev)} className={clsx(classes.label)}>
							<LocationIco />
							{t('addressInfo')}
							<ArrowDownIcon />
						</div>
						{openStore && addressesOptions && (
							<Select
								withoutClear
								value={selectedStoreId as number}
								onChange={handleStoreIdChange}
								options={addressesOptions}
								disabled={!loadingStoresData && !storesData?.storeAddressData?.length}
							/>
						)}
					</div>
					{openStore && selectedStoreAddress && (
						<>
							<div className={classes.storeInfo}>
								{loadingFullStoreInfo ? (
									<Loader simple />
								) : (
									<StoreListInfo
										store={selectedStoreAddress}
										refetchQueries={[
											{
												query: GET_STORE_ADRESSES,
												fetchPolicy: 'network-only',
												variables: { page: 0, pageSize: 100, filter: { searchQuery: selectedStoreId } },
											},
										]}
									/>
								)}
							</div>
						</>
					)}
				</div>
			) : isServiceUser ? (
				<Loader className={classes.storeLoader} simple />
			) : null}
		</>
	);
};

export default DocumentStores;
