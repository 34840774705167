import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { clientGraphql } from 'api';
import { fetch } from 'api';
import { Routes } from 'const';
import { removeLang } from 'helpers/lang';
import storage from 'helpers/localStorage';
import {
	backTokensFromParent,
	hasToken,
	removeRefreshToken,
	removeToken,
	setRefreshToken,
	setRefreshTokenParent,
	setToken,
	setTokenParent,
} from 'helpers/token';

import { useOrders } from './useOrders';
import useToast from './useToast';

export enum ErrorTypes {
	changePassword = 'changePassword',
}

interface IResponse {
	jwt: string;
	refreshToken: string;
	expireInSeconds: number;
	message?: string;
	errorType?: string;
}

export interface IUseAuth {
	login(email: string, password: string, remember: boolean): Promise<ErrorTypes | void>;
	authWith(email: string): Promise<boolean>;
	logout(): void;
	backToParentAuth(): void;
	isAuth: boolean;
	loading: boolean;
}

export const useAuth = (): IUseAuth => {
	const history = useHistory();
	const { toast } = useToast();
	const { clearFilters, clearOrders } = useOrders();
	const [loading, setLoading] = useState<boolean>(false);
	const { t } = useTranslation(['errors']);

	const login = (email: string, password: string, remember: boolean): Promise<ErrorTypes | void> => {
		setLoading(true);
		return fetch<IResponse>('/authenticate', {
			method: 'post',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ username: email, password }),
			auth: true,
		})
			.then(({ body }) => {
				if (body.message === 'Bad credentials') {
					throw new Error('Bad credentials');
				}
				if (body.message === 'Please change the password') {
					return ErrorTypes.changePassword;
				}
				setToken(body.jwt);
				setRefreshToken(body.refreshToken);
				if (remember) {
					storage.setItem('remember', 'true');
				}
				window.location.reload();
			})
			.catch(() => {
				toast(t('login'), { type: 'error' });
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const authWith = (email: string): Promise<boolean> => {
		setLoading(true);
		return fetch<IResponse>('/authenticateWith/', {
			method: 'post',
			body: JSON.stringify({ withUsername: email }),
			headers: {
				'Content-Type': 'application/json',
			},
			auth: true,
		})
			.then(({ body }) => {
				if (body.message) {
					throw new Error('Bad credentials');
				}
				setTokenParent();
				setRefreshTokenParent();
				setToken(body.jwt);
				setRefreshToken(body.refreshToken);
				window.location.reload();
				return true;
			})
			.catch(() => {
				toast(t('somethingWentWrong'), { type: 'error' });
				return false;
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const backToParentAuth = () => {
		backTokensFromParent();
		window.location.reload();
	};

	const logout = async () => {
		removeToken();
		removeRefreshToken();
		removeLang();
		storage.removeItem('remember');
		history.replace(Routes.LOGIN);
		clearFilters();
		clearOrders();
		await clientGraphql.clearStore();
	};

	return {
		isAuth: hasToken(),
		loading,
		login,
		logout,
		authWith,
		backToParentAuth,
	};
};
