import { INotificationPayoutSchedule } from 'interfaces/notification';

import localStorage from './localStorage';

const key = 'payoutScheduleNotifications';

export const getViewedPayoutScheduleNotifications = (): string[] => {
	return (localStorage.getItem<string[]>(key) as string[]) || [];
};

export const getPayoutScheduleNotificationId = (n: INotificationPayoutSchedule): string => {
	return Object.entries(n)
		.sort(([k1], [k2]) => k1.localeCompare(k2))
		.map(([, v]) => v)
		.sort()
		.join('');
};

export const saveViewedPayoutScheduleNotification = (notification: INotificationPayoutSchedule): void => {
	const items = getViewedPayoutScheduleNotifications();
	const id = getPayoutScheduleNotificationId(notification);
	localStorage.setItem(key, [...items, id]);
};

export const isViewedNotification = (notification: INotificationPayoutSchedule) => {
	const savedNoifications = getViewedPayoutScheduleNotifications();
	const id = getPayoutScheduleNotificationId(notification);
	return savedNoifications.includes(id);
};

export const filterPayoutScheduleNotifications = (notifications: INotificationPayoutSchedule[]): INotificationPayoutSchedule[] => {
	return notifications.filter((notification) => !isViewedNotification(notification));
};

export const sendNotification = (title: string) => {
	if (!('Notification' in window)) {
		return;
	}
	if (Notification.permission === 'granted') {
		return new Notification(title);
	}

	if (Notification.permission !== 'denied') {
		Notification.requestPermission((permission) => {
			if (permission === 'granted') {
				return new Notification(title);
			}
		});
	}
};
