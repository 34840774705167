import { gql } from '@apollo/client';

export const GET_CUSTOMERS = gql`
	query customers($page: Int!, $pageSize: Int!, $search: String) {
		customers(page: $page, pageSize: $pageSize, search: $search) {
			totalPages
			total
			data {
				id
				customerId
				effectiveDate
				name
				companyName
				taxId
				address
				city
				email
				contractNumber
				createdAt
				createdBy
				reason
			}
		}
	}
`;

export const GET_CUSTOMER_HISTORY = gql`
	query customerHistory($customerId: Long!) {
		customerHistory(customerId: $customerId) {
			id
			customerId
			effectiveDate
			name
			companyName
			taxId
			address
			city
			email
			contractNumber
			createdAt
			createdBy
			reason
		}
	}
`;

export const EDIT_CUSTOMER = gql`
	mutation EditCustomer($input: CustomerInputDto!) {
		editCustomer(input: $input) {
			customerId
			effectiveDate
			name
			companyName
			taxId
			address
			city
			email
			contractNumber
			reason
		}
	}
`;

export const DELETE_CUSTOMER_HISTORY = gql`
	mutation DeleteCustomerHistory($customerId: Long!, $date: Date!) {
		deleteCustomerHistory(customerId: $customerId, date: $date) {
			customerId
			effectiveDate
			name
			companyName
			taxId
			address
			city
			email
			contractNumber
			reason
		}
	}
`;
