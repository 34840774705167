import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetch } from 'api';
import { IPagination } from 'interfaces';
import { IAgreement, IContract, IContractFilter } from 'interfaces/contractGeneration';
import { useSelector } from 'store';
import {
	changeContractsFilter,
	changeFields as changeFieldsAction,
	deleteContractById as deleteContractByIdAction,
	dublicateContractById as dublicateContractByIdAction,
	fetchContracts as fetchContractsAction,
	setContractsPage,
	setContractsPageSize,
	updateContractNameById as updateContractNameByIdAction,
} from 'store/contracts/actions';

export interface IUseContracts {
	items: IContract[];
	filter: IContractFilter;
	total: number;
	onload: boolean;
	wasLoaded: boolean;
	pagination: IPagination;
	fetchContracts(): void;
	changeFields(fields: IContract): void;
	changeFilter(field: keyof IContractFilter, value: IContractFilter[typeof field]): void;
	setPageSize(pageSize: number): void;
	setPage(page: number): void;
	deleteContract(id: IContract['agreementId']): Promise<boolean>;
	updateContractName({
		agreementId,
		newAgreementName,
	}: {
		agreementId: IContract['agreementId'];
		newAgreementName: IContract['agreementName'];
	}): Promise<boolean>;
	generateDocument(data: FormData): Promise<boolean>;
	getContractById(agreementId: string): Promise<{ body: IAgreement }>;
	dublicateContract(agreementId: string): Promise<{ id: IAgreement['agreementId']; name: IAgreement['agreementName'] }>;
}

export const useContractGeneration = (): IUseContracts => {
	const { items, filter, pagination, total, onload, wasLoaded } = useSelector((state) => state.contractGeneration);
	const dispatch = useDispatch();
	const { t } = useTranslation(['contractGeneration', 'errors']);

	const getContractById = async (agreementId: string): Promise<{ body: IAgreement }> =>
		await fetch(`/agreement/id/${agreementId}`, { fintoolContracts: true }, false);

	const fetchContracts = useCallback(() => {
		return dispatch(fetchContractsAction(filter, pagination));
	}, [dispatch, filter, pagination]);

	const generateDocument = (data: FormData): Promise<boolean> => {
		return new Promise((resolve, reject) => {
			return fetch(
				`/agreement/generate`,
				{
					method: 'post',
					body: data,
					fintoolContracts: true,
				},
				false,
			)
				.then(() => {
					resolve(true);
					return true;
				})
				.catch(() => {
					toast(t('errors:somethingWentWrong'), { type: 'error' });
					reject(false);
				});
		});
	};

	const deleteContract = (id: IContract['agreementId']): Promise<boolean> => {
		return new Promise((resolve, reject) => {
			return fetch(
				`/agreement/id/${id}`,
				{
					method: 'delete',
					fintoolContracts: true,
				},
				false,
			)
				.then(() => {
					toast(t('successAction'), { type: 'success' });
					dispatch(deleteContractByIdAction(id));
					resolve(true);
					return true;
				})
				.catch(() => {
					toast(t('errors:somethingWentWrong'), { type: 'error' });
					reject(false);
				});
		});
	};

	const dublicateContract = (id: IContract['agreementId']): Promise<{ id: IAgreement['agreementId']; name: IAgreement['agreementName'] }> => {
		return new Promise((resolve, reject) => {
			return fetch<IContract>(
				`/agreement/duplicate`,
				{
					method: 'post',
					body: JSON.stringify({
						id,
					}),
					headers: {
						'content-type': 'application/json',
					},
					fintoolContracts: true,
				},
				false,
			)
				.then(({ body }: { body: IContract }) => {
					dispatch(dublicateContractByIdAction(body));
					const data = { id: body.agreementId, name: body.agreementName };
					resolve(data);
					return data;
				})
				.catch(() => {
					toast(t('errors:somethingWentWrong'), { type: 'error' });
					reject(false);
				});
		});
	};

	const updateContractName = ({
		agreementId,
		newAgreementName,
	}: {
		agreementId: IContract['agreementId'];
		newAgreementName: IContract['agreementName'];
	}): Promise<boolean> => {
		return new Promise((resolve, reject) => {
			return fetch(
				'/agreement/updateName/',
				{
					method: 'PATCH',
					body: JSON.stringify({ name: newAgreementName, id: agreementId }),
					headers: {
						'content-type': 'application/json',
					},
					fintoolContracts: true,
				},
				false,
			)
				.then(() => {
					toast(t('successAction'), { type: 'success' });
					dispatch(updateContractNameByIdAction({ agreementId, newAgreementName: newAgreementName.replaceAll('.', '-') }));
					resolve(true);
					return true;
				})
				.catch(() => {
					toast(t('errors:somethingWentWrong'), { type: 'error' });
					reject(false);
				});
		});
	};

	const changeFilter = (field: keyof IContractFilter, value: IContractFilter[typeof field]) => {
		dispatch(changeContractsFilter(field, value));
	};

	const setPageSize = (pageSize: number) => {
		dispatch(setContractsPageSize(pageSize));
	};

	const setPage = (page: number) => {
		dispatch(setContractsPage(page));
	};

	const changeFields = (fields: IContract) => {
		dispatch(changeFieldsAction(fields));
	};

	return {
		items,
		filter,
		pagination,
		total,
		onload,
		wasLoaded,
		fetchContracts,
		changeFilter,
		setPageSize,
		setPage,
		deleteContract,
		updateContractName,
		generateDocument,
		getContractById,
		dublicateContract,
		changeFields,
	};
};
