import styled from '@emotion/styled';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

export const LightTooltip = styled(({ className, ...props }: TooltipProps & { backgroundColor?: string }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ color, backgroundColor }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: backgroundColor ? backgroundColor : '#fff',
		color: color ? color : '#797A8D',
		fontSize: 14,
		padding: 8,
		fontWeight: 500,
		textAlign: 'center',
		fontFamily: 'Montserrat',
		filter: 'drop-shadow(-4px 4px 16px rgba(78, 69, 92, 0.15))',
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: backgroundColor ? backgroundColor : '#fff',
	},
}));
