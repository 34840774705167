import React, { useContext, useState } from 'react';
import { wrapPromise } from 'helpers/suspense';
import { readTempTokens } from 'helpers/token';
import useDeviceType from 'hooks/useDeviceType';

import { IAppContext } from './types';

export const AppContext = React.createContext<IAppContext>({});
export const useAppContext = () => useContext<IAppContext>(AppContext);

const readTempTokensPromise = wrapPromise(readTempTokens());

const Provider: React.FC = ({ children }) => {
	const { isMobile, isTablet, isTabletOnly, isDesktop } = useDeviceType();
	const [isOpenedMenu, setIsOpenedMenu] = useState<boolean>(false);
	readTempTokensPromise.read();

	const openMenu = () => {
		setIsOpenedMenu(true);
	};

	const closeMenu = () => {
		setIsOpenedMenu(false);
	};

	const toggleMenu = () => {
		setIsOpenedMenu((state) => !state);
	};

	const value: IAppContext = {
		isDesktop,
		isMobile,
		isTablet,
		isTabletOnly,
		isOpenedMenu,
		openMenu,
		closeMenu,
		toggleMenu,
	};

	return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default Provider;
