import React, { ReactText, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLazyQuery } from '@apollo/client';
import { fetch } from 'api';
import { GET_ORDERS } from 'api/orders';
import { Routes } from 'const';
import Provider from 'containers/GetStarted/Provider';
import { useAppContext } from 'contexts/App';
import { createLink } from 'helpers';
import clsx from 'helpers/clsx';
import { middlewareOrder } from 'helpers/order';
import { useOrders } from 'hooks/useOrders';
import { useQuery } from 'hooks/useQuery';
import { useUser } from 'hooks/useUser';
import { IOrder, OrderStatus } from 'interfaces/order';
import { TEST_IDS } from 'tests/config';

import EmptyResult from 'components/EmptyResult';
import Loader from 'components/Loader';
import LoadMore from 'components/LoadMore';
import MainButton from 'components/MainButton';
import Confirm from 'components/modals/Confirm';
import Pagination from 'components/Pagination';

// import { ReactComponent as PendingIcon } from 'static/images/pending.svg';
import Filters from './Filters';
import OrderModal from './Modal';
import Order from './Order';
import TableHead from './TableHead';

import classes from './OrdersContainer.module.scss';

const OrdersContainer: React.FC = () => {
	const { items, fetchOrders, pagionation, filter, setPage, setPageSize, total, onload, wasLoaded, approveAllOrders, approveAllLoading } =
		useOrders();
	const { hasPermissionToChangeOrders } = useUser();
	const { t } = useTranslation(['orders', 'exports', 'form', 'errors']);
	const { isOpenedMenu } = useAppContext();
	const query = useQuery();
	const history = useHistory();
	const toastId = useRef<ReactText>();
	const { code }: { code: string } = useParams();
	const [orderByCode, setOrderByCode] = useState<IOrder>();
	const [openOrderByCode, setOpenOrderByCode] = useState(false);
	const lastRenderId = useRef(0);

	/**
	 * TODO suggest migration to @tanstak/react-table https://tanstack.com/table/v8
	 * TODO rework the page so thead and tbody are rendered simultaneously
	 */
	// for now we need this to rerender THead properly
	lastRenderId.current = performance.now();

	const [getOrderByCode] = useLazyQuery(GET_ORDERS, {
		variables: {
			page: 0,
			pageSize: 1,
			filter: { code },
		},
	});

	useEffect(() => {
		if (code) {
			getOrderByCode()?.then(({ data }) => {
				if (data?.orders?.orderDtos[0]) {
					setOrderByCode(middlewareOrder(data?.orders?.orderDtos)[0]);
					setOpenOrderByCode(true);
				} else {
					history.push(Routes.ORDERS);
				}
			});
		}
	}, [code]);

	const handleCloseModal = () => {
		history.push(Routes.ORDERS);
		setOpenOrderByCode(false);
	};

	useEffect(() => {
		if (query.get('exportLink') && query.get('type') && [...new Set(query.keys())].length === 2) {
			const exportUrl = `${query.get('exportLink')}?type=${query.get('type')}`;
			toastId.current = toast(t('exports:startExport'), {
				type: 'success',
				progress: 1,
			});
			fetch(`/reports/download/${exportUrl}`, {
				headers: { 'content-type': 'application/zip' },
			})
				.then((res) => {
					if (res.body) {
						const blob = new Blob([res.body as Blob], {
							type: 'application/zip',
						});
						const url = URL.createObjectURL(blob);
						createLink(url, query.get('exportLink') || '');
					}
				})
				.catch(() => {
					toast(t('errors:somethingWentWrong'), { type: 'error' });
				})
				.finally(() => {
					toast.dismiss(toastId.current);
					history.push(Routes.ORDERS);
				});
		}
	}, [query.get('exportLink')]);

	const loadMore = () => {
		setPageSize(pagionation.pageSize + 100);
	};

	const handleClickPrev = () => {
		setPage(pagionation.page - 1);
	};

	const handleClickNext = () => {
		setPage(pagionation.page + 1);
	};

	useEffect(() => {
		if (filter.startDate && filter.endDate) fetchOrders();
	}, [filter, pagionation]);

	const start = pagionation.page * pagionation.pageSize + 1;
	const end = start + pagionation.pageSize - 1;
	const canLoadMore = !!items.length && end < total;

	const renderPagination = () => {
		if (!items.length) return null;
		return (
			<Pagination
				start={start}
				end={end}
				total={total}
				onClickNext={handleClickNext}
				onClickPrev={handleClickPrev}
				className={clsx(classes.pagination, 'orderTest', {
					[classes.paginationAbsolute]: hasPermissionToChangeOrders(),
					[classes.paginationOpen]: isOpenedMenu,
				})}
			/>
		);
	};

	const orderList = (
		<>
			{onload && !wasLoaded ? (
				<Loader simple />
			) : (
				<div className={classes.orders}>
					<table>
						<TableHead key={lastRenderId.current} />
						<tbody data-testid={TEST_IDS.ORDERS_TABLE_BODY}>
							{items.map((it) => (
								<Order item={it} key={it.id} />
							))}
						</tbody>
					</table>
					{!onload && !items.length && <EmptyResult />}
					{canLoadMore && <LoadMore onClick={loadMore} className={classes.loadMore} />}
				</div>
			)}
		</>
	);

	const filterAmending = filter.orderStatus?.includes(OrderStatus.AMENDING);
	const filterApproved = filter.orderStatus?.includes(OrderStatus.APPROVED);

	const disabledApplyAll =
		(filter.orderStatus?.length === 1 && (filterAmending || filterApproved)) ||
		(filter.orderStatus?.length === 2 && filterAmending && filterApproved);

	const getApproveAllBody = () => {
		if (filterAmending) {
			return (
				<div>
					{t('confirmApproveAll.body')}
					<br />
					<Trans t={t} i18nKey="confirmApproveAll.withAmending" components={{ bold: <span className={classes.bold} /> }} />
				</div>
			);
		}
		return t('confirmApproveAll.body');
	};

	const bottom =
		hasPermissionToChangeOrders() && items.length > 0 ? (
			<div className={classes.bottom}>
				{renderPagination()}
				<Confirm
					title={t('form:applyOrdersAll')}
					body={getApproveAllBody()}
					confirm={{
						text: t('confirmApproveAll.ok'),
						onClick: approveAllOrders,
						disabled: approveAllLoading,
					}}
				>
					<MainButton className={classes.applyAll} disabled={disabledApplyAll}>
						<Provider sort={15} id="applyAll" position="top" sticky="bottom" canPush={!!items.length}>
							<div className={classes.applyAll_inner}>{t('form:applyOrdersAll')}</div>
						</Provider>
					</MainButton>
				</Confirm>
				{/* <div className={classes.unapprovedOrders}>
				<PendingIcon />
				<Trans t={t} i18nKey={'countNotApprovedOrder'} values={{ count: 95 }}/>
			</div> */}
			</div>
		) : null;

	return (
		<>
			{approveAllLoading && <Loader />}
			<div className={clsx(classes.root)} data-testid={TEST_IDS.ORDERS_CONTAINER}>
				<Filters>{!hasPermissionToChangeOrders() && renderPagination()}</Filters>
				{orderList}
				{bottom}
				{orderByCode && <OrderModal item={orderByCode} onClose={handleCloseModal} open={openOrderByCode} />}
			</div>
		</>
	);
};

export default OrdersContainer;
