import format from 'date-fns/format';
import { groupVchasnoStatuses } from 'helpers';
import { ValueOf } from 'interfaces';
import { CreateContract, IFilter, IFiltration, IFormEdit, PartnerCommission, PartnerDataFullDto } from 'interfaces/partnersFile';

export const normalizeFilter = (filter: IFilter, filtration: IFiltration) => {
	return {
		...filter,
		...filtration,
		columns: undefined,
		contractStatus: filtration.contractStatus ? groupVchasnoStatuses(filtration.contractStatus) : undefined,
	};
};

export const normilizeInput = (inputArray: PartnerCommission[]) => {
	return inputArray.map((input) => {
		return {
			...input,
			startDate: input.startDate ? format(new Date(input.startDate), 'yyyy-MM-dd') : undefined,
			endDate: input.endDate ? format(new Date(input.endDate), 'yyyy-MM-dd') : undefined,
		};
	});
};

export const toCamelCase = (str: string) => {
	return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

export const normilizePartnerContractInput = (input: IFormEdit): CreateContract => {
	const data: CreateContract = {};
	delete input.fillDataFromPreviusVersion;
	delete input.__typename;
	delete input.contractNumber;

	Object.keys(input).forEach((key) => {
		const value = input[key as keyof IFormEdit];
		if (value) {
			(data[key as keyof IFormEdit] as ValueOf<CreateContract>) =
				(typeof value === 'number' || typeof value === 'string') && key !== 'contractNumberBase' ? { value } : value;
		}
	});

	return data;
};

export const getNewestContract = (contracts: PartnerDataFullDto[]) => {
	return contracts.sort((a, b) => {
		return Number(b.contractNumber.replace(`${b.contractNumberBase}-`, '')) - Number(a.contractNumber.replace(`${a.contractNumberBase}-`, ''));
	})[0];
};

export const parseCommentTextWithTags = (value: string, withStrongTag = false) => {
	const parseRegex = /(\[\[.*?]])/gm;
	const findTags: string | RegExpMatchArray | null = value ? value.match(parseRegex) : value;
	if (findTags && findTags.length) {
		const tags = findTags as RegExpMatchArray;
		tags.forEach((tag) => {
			const getValue = JSON.parse(tag);
			value = value.replaceAll(tag, withStrongTag ? ` ${getValue[0][0].value}` : getValue[0][0].value);
		});
	}

	return value;
};
