import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'helpers/clsx';
import icon from 'static/images/empty-result.svg';

import { IProps } from './types';

import classes from './EmptyResult.module.scss';

const EmptyResult: React.FC<IProps> = ({ className, children }) => {
	const { t } = useTranslation('errors');
	return (
		<div className={clsx(classes.root, className)}>
			<img src={icon} alt="" />
			<div className={classes.text}>{children || t('emptyResult')}</div>
		</div>
	);
};

export default EmptyResult;
