import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener } from '@mui/material';
import PartnerOption from 'containers/PartnerOption/PartnerOption';
import clsx from 'helpers/clsx';
import { usePartnersFile } from 'hooks/usePartnersFile';
import { IFiltration } from 'interfaces/partnersFile';
import { ReactComponent as Arr } from 'static/images/keyboard_arrow_down.svg';
import { ReactComponent as PlusIcon } from 'static/images/plus.svg';
import { TEST_IDS } from 'tests/config';

import { HAS_OPERATOR, IFilterItem, IProps, OPERATORS } from './types';

import classes from './PartnerFilterSelect.module.scss';

const DEFAULT_OPERATOR = 'EQUALS';
const DEFAUL_FILTER = {
	id: 1,
	key: 'contractNumber' as keyof IFiltration,
	value: undefined,
};

const PartnerFilterSelect: React.FC<IProps> = ({ columns }) => {
	const columnsOptionData = columns?.map((column) => ({ value: column.field || '', label: column.headerName || '' }));
	const [open, setOpen] = useState<boolean>(false);
	const [mapColumns, setMapColumns] = useState<{ value: string; label: string }[]>(columnsOptionData);
	const initialState: IFilterItem[] = [DEFAUL_FILTER];
	const { t } = useTranslation('partners');
	const { filtration, changeFiltration, clearFiltration } = usePartnersFile();
	const [filters, setFilters] = useState<IFilterItem[]>([...initialState]);

	const toggleOpen = () => {
		setOpen((state) => !state);
	};
	const closeDropDown = () => {
		setOpen(false);
	};

	const closeOutsideDropDown = () => setOpen(false);

	const handelCancel = () => {
		setFilters([...initialState]);
		clearFiltration();
		closeDropDown();
	};

	const renderValue = () => {
		const filterLength = Object.values(filtration).filter((val) => val).length;
		return filterLength ? `${t('filtration')} (${filterLength})` : t('filtration');
	};

	const handleChangeFiltration = () => {
		const localFilter: IFiltration = filters.reduce((acc, v) => {
			return {
				...acc,
				[v.key]: v.operator ? { value: v.value, operator: v.operator } : v.value,
			};
		}, {});
		changeFiltration(localFilter);
		closeDropDown();
	};

	const isEmpty = filters.every((filter) => {
		if (filter.operator && [OPERATORS.IS_EMPTY, OPERATORS.IS_NOT_EMPTY].includes(filter.operator)) {
			return false;
		}
		return filter.value === undefined;
	});

	const renderButtons = () => {
		return (
			<div className={clsx(classes.buttons, 'buttons')}>
				<button onClick={handelCancel} className={classes.buttonCancel} data-testid={TEST_IDS.SELECT_CANCEL_BUTTON}>
					{t('form:cancelAll')}
				</button>
				<button disabled={isEmpty} onClick={handleChangeFiltration} data-testid={TEST_IDS.SELECT_APPLY_BUTTON}>
					{t('form:apply')}
				</button>
			</div>
		);
	};

	const hasOperator = (filterName: string) => HAS_OPERATOR.find((field) => field === filterName);

	const handleCreateOption = () => {
		const newPossibleFilter = getExecuteOptions();
		if (!newPossibleFilter?.length) return;

		setFilters((prev) => [
			{
				id: prev[0].id + 1,
				key: newPossibleFilter[0] as keyof IFiltration,
				operator: hasOperator(newPossibleFilter[0]) ? DEFAULT_OPERATOR : undefined,
			},
			...prev,
		]);
	};

	const getExecuteOptions = () => {
		const executeFilterKeys = filters.map((filter) => filter.key);
		const columnsNaming = columns.map((field) => field.field);
		const newPossibleFilter = columnsNaming.filter((name) => !executeFilterKeys.find((filterKey) => filterKey === name));
		return newPossibleFilter;
	};

	const handleChangeValue = (key?: string) => (selectedValue: string | string[]) => {
		setFilters((prev) =>
			prev.map((innerFilter) => {
				if (innerFilter.key === key) return { ...innerFilter, value: selectedValue };
				return { ...innerFilter };
			}),
		);
	};

	const onChangeOperator = (key?: string) => (selectedValue: keyof typeof OPERATORS) => {
		setFilters((prev) =>
			prev.map((innerFilter) => {
				if (innerFilter.key === key) return { ...innerFilter, operator: selectedValue };
				return { ...innerFilter };
			}),
		);
	};

	const handleChangeColumn = (key?: string) => (selectedValue: keyof IFiltration) => {
		setFilters((prev) =>
			prev.map((innerFilter) => {
				if (innerFilter.key === key) {
					return {
						...innerFilter,
						key: selectedValue,
						value: undefined,
						operator: hasOperator(selectedValue) ? DEFAULT_OPERATOR : undefined,
					};
				}
				return { ...innerFilter };
			}),
		);
	};

	const handleRemoveOption = (id: number) => () => {
		if (filters.length > 1) return setFilters((prev) => prev.filter((filter) => filter.id !== id));
		setFilters((prev) => prev.map((filter) => ({ ...filter, value: undefined })));
	};

	useEffect(() => {
		const exOption = getExecuteOptions();
		const possibleToChooseColumns = exOption
			?.map((option) => {
				const fullColumnData = columns?.find((col) => col.field === option);
				if (fullColumnData) return { value: fullColumnData.field, label: fullColumnData.headerName };
				return undefined;
			})
			.filter((val) => val);
		if (!possibleToChooseColumns?.length) return;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-expect-error
		setMapColumns([...possibleToChooseColumns]);
	}, [filters]);

	const getOptionsForEntire = (key: keyof IFiltration) => {
		const selected = columns.find((col) => key === col.field);
		if (!selected) return;
		const fullField = { value: selected.field, label: selected.headerName };
		const isInList = mapColumns.find((col) => col.label === fullField.label);
		if (isInList) return [...mapColumns];
		return [{ ...fullField }, ...mapColumns];
	};

	return (
		<div className={clsx(classes.root)}>
			<div>
				<div
					className={clsx(classes.inner, {
						// if need disabling in future
						[classes.disabled]: false,
					})}
				>
					<div
						className={clsx(classes.control, classes.controlSelect, {
							[classes.focus]: !!open,
						})}
						onClick={toggleOpen}
						aria-label={`${renderValue()}`}
					>
						<span>{renderValue()}</span>
						<Arr className={clsx(classes.icon, classes.selectArr, { [classes.rotateArr]: open })} />
					</div>
				</div>
				{open && (
					<ClickAwayListener onClickAway={closeOutsideDropDown}>
						<div className={clsx('Select__inner-dropdown', classes.dropDownSelect)}>
							<div className={classes.optionContainer}>
								{filters.map((currentFilter) => (
									<PartnerOption
										key={currentFilter.id}
										currentFilter={currentFilter}
										keyLabel={t('filtrationColumn')}
										keyOption={getOptionsForEntire(currentFilter.key) || []}
										valueLabel={t('filtrationValue')}
										onChangeKey={handleChangeColumn(currentFilter.key)}
										onChangeValue={handleChangeValue(currentFilter.key)}
										onChangeOperator={onChangeOperator(currentFilter.key)}
										onRemove={handleRemoveOption(currentFilter.id)}
									/>
								))}
							</div>
							<div className={classes.addButtonWrapper}>
								<button onClick={handleCreateOption} className={classes.addButton}>
									<PlusIcon />
									{t('addParam')}
								</button>
							</div>
							{renderButtons()}
						</div>
					</ClickAwayListener>
				)}
			</div>
		</div>
	);
};

export default PartnerFilterSelect;
