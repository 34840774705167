import React from 'react';
import clsx from 'helpers/clsx';

import { IProps } from './types';

import classes from './MainButton.module.scss';

const MainButton: React.ForwardRefRenderFunction<HTMLButtonElement, IProps> = ({ children, className, type = 'button', onClick, disabled }, ref) => {
	const createRipple = (event: React.MouseEvent<HTMLButtonElement>) => {
		const button = event.currentTarget;
		const circle = document.createElement('span');
		const diameter = Math.max(button.clientWidth, button.clientHeight);
		const radius = diameter / 2;
		circle.style.width = circle.style.height = `${diameter}px`;
		circle.style.left = `${event.nativeEvent.offsetX - radius}px`;
		circle.style.top = `${event.nativeEvent.offsetY - radius}px`;
		circle.classList.add(classes.ripple);

		const ripple = button.getElementsByClassName(classes.ripple)[0];

		if (ripple) {
			ripple.remove();
		}

		button.appendChild(circle);
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		createRipple(event);
		onClick?.();
	};

	return (
		<button
			className={clsx(classes.root, className, {
				[classes.disabled]: disabled,
			})}
			onClick={handleClick}
			ref={ref}
			type={type}
		>
			{children}
		</button>
	);
};

export default React.forwardRef(MainButton);
