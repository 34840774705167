export interface IPagination {
	page: number;
	pageSize: number;
}

export enum AccountingTypes {
	ONCE_A_WEEK = 4,
	TWICE_A_MONTH = 2,
	ONCE_A_MONTH = 1,
}
export enum AgreementTypes {
	AGENT = 'AGENT',
	INFO = 'INFORMATION',
}

export enum StatusUnsigned {
	GLOVO_UNSIGNED = '7001,7003',
	PARTNER_UNSIGNED = '7002,7004,7007',
}

export enum PromoCalculationType {
	FULL = 'FULL',
	REDUCED = 'REDUCED',
}

export type ErrorsType<T> = {
	[key in keyof T]?: string;
};

export type DisabledType<T> = {
	[key in keyof T]?: boolean;
};

export enum PaymentMethods {
	CARD = 'Card',
	CASH = 'Cash',
}

export enum FinalStatus {
	DELIVERED = 'DeliveredStatus',
	CANCELED = 'CanceledStatus',
	NOT_PAID = 'NotPaidStatus',
}

export enum ERRORS {
	STORE_ALREADY = 'StoreAddressAlreadyExists',
	STORE_DEACTIVATED = 'StoreIdIsDeactivated',
	FILE_TYPE = 'incorrectFileType',
	FILE_STATUS = 415,
	BULK_UPLOAD_FAILED = 'BulkUploadTemplateNotFound',
	PARTNER_EDIT_ACCESS_DENIED = 'PartnerEditFieldAccessDenied',
	PARTNER_MODIFY_ACCESS_DENIED = 'PartnerModifyAccessDenied',
	PARTNER_COMMISSION_IS_NOT_VALID = 'PartnerCommissionsIsNotValid',
}

export enum CountryCodeEnum {
	UA = 'UA',
	AM = 'AM',
	KZ = 'KZ',
	GE = 'GE',
	KG = 'KG',
	MA = 'MA',
}

export enum OpenDataSyncStatuses {
	SUCCESS = 'SUCCESS',
	NOT_FOUND = 'NOT_FOUND',
	NOT_EXECUTED = 'NOT_EXECUTED',
	SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
}

export interface IFetchOptions extends RequestInit {
	auth?: boolean;
	feedback?: boolean;
	fintoolContracts?: boolean;
	'content-type'?: string;
}

export type ValueOf<T> = T[keyof T];

export type Entries<T> = {
	[K in keyof T]: [K, T[K]];
}[keyof T][];
