import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import parse from 'html-react-parser';
import { ReactComponent as ArrowDownIcon } from 'static/images/arrow_down.svg';

import classes from './Faq.module.scss';

const Faq = () => {
	const { t } = useTranslation('faq');
	const list = t('list', { returnObjects: true });

	return (
		<div className={classes.root}>
			<h4 className={classes.title}>{t('title')}</h4>
			{Array.isArray(list) && !!list?.length && (
				<div className={classes.listWrap}>
					{list.map(({ title }, index) => (
						<Accordion
							square={false}
							disableGutters
							key={index}
							elevation={0}
							sx={{
								borderRadius: 5,
								'&::before': {
									display: 'none',
								},
								'&:last-of-type': {
									borderBottomLeftRadius: 20,
									borderBottomRightRadius: 20,
								},
								'&:first-of-type': {
									borderTopLeftRadius: 20,
									borderTopRightRadius: 20,
								},
							}}
						>
							<AccordionSummary
								expandIcon={<ArrowDownIcon />}
								aria-controls={`panel${index}-content`}
								id={`panel${index}-header`}
								sx={{
									flexDirection: 'row-reverse',
									'& .MuiAccordionSummary-expandIconWrapper': {
										transform: 'rotate(180deg)',
										'&.Mui-expanded': {
											transform: 'rotate(0deg)',
										},
									},
									'& .MuiAccordionSummary-content': {
										marginLeft: 3,
									},
								}}
							>
								<h5 className={classes.listWrap_title}>{title}</h5>
							</AccordionSummary>
							<AccordionDetails
								sx={{
									padding: '0 50px 16px',
								}}
							>
								<div className={classes.listWrap_text}>{parse(t(`list.${index}.text`))}</div>
							</AccordionDetails>
						</Accordion>
					))}
				</div>
			)}
		</div>
	);
};

export default Faq;
