import React from 'react';
import clsx from 'helpers/clsx';

import { CategoryGenEnum, IProps } from './types';

import classes from './CategoryGen.module.scss';

const CategoryGen: React.FC<IProps> = ({ gen, category }) => {
	return (
		<>
			<div className={classes.category}>
				{gen && (
					<div
						className={clsx({
							[classes.genOne]: gen === CategoryGenEnum.genOne,
							[classes.genTwo]: gen === CategoryGenEnum.genTwo,
						})}
					>
						{gen}
					</div>
				)}
				{category && (
					<div
						className={clsx({
							[classes.food]: category === CategoryGenEnum.food,
							[classes.commerce]: category === CategoryGenEnum.commerce,
						})}
					>
						{category}
					</div>
				)}
			</div>
		</>
	);
};

export default CategoryGen;
