import { useTranslation } from 'react-i18next';
import { TEST_IDS } from 'tests/config';

import classes from './DebtTable.module.scss';

const DebtHead = () => {
	const { t } = useTranslation('accounts');
	return (
		<div className={classes.tableHead} data-testid={TEST_IDS.ACCOUNTS_DEBT_TABLE_HEAD}>
			<div className={classes.tableHeadColl}>{t('tableHead.agent')}</div>
			<div className={classes.tableHeadColl}>{t('tableHead.institutions')}</div>
			<div className={classes.tableHeadColl}>{t('tableHead.contractNum')}</div>
			<div className={classes.tableHeadColl}>{t('tableHead.unexpired')}</div>
			<div className={classes.tableHeadColl}>{t('tableHead.expired')}</div>
			<div className={classes.tableHeadColl}>{t('tableHead.sum')}</div>
		</div>
	);
};
export default DebtHead;
