import React, { useState } from 'react';
import clsx from 'helpers/clsx';

import { IProps } from './types';

import classes from './SwitchComponent.module.scss';

const SwitchComponent: React.FC<IProps> = ({ titleAct, className, classNameSpan, titleDis, isActive = true, onChange, testId }) => {
	const [currentState, setCurrentState] = useState(isActive);

	const handleChange = () => {
		onChange(!currentState);
		setCurrentState(!currentState);
	};

	return (
		<div className={clsx(classes.root, className, { [classes.disable]: !currentState })} onClick={handleChange} data-testid={testId}>
			{currentState ? titleAct : titleDis}
			<span className={clsx(classNameSpan)}>
				<span />
			</span>
		</div>
	);
};

export default SwitchComponent;
