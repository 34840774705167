import { ValueOf } from 'interfaces';
import { IFilter } from 'interfaces/document';

import { IDocument } from 'components/DocumentsListItem/types';

export const FETCH_DOCUMENTS_START = 'FETCH_DOCUMENTS_START';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_FAILURE = 'FETCH_DOCUMENTS_FAILURE';
export const CHANGE_DOCUMENTS_FILTER = 'CHANGE_DOCUMENTS_FILTER';
export const CLEAR_DOCUMENTS_FILTER = 'CLEAR_DOCUMETS_FILTER';
export const SET_DOCUMENTS_PAGE = 'SET_DOCUMENTS_PAGE';
export const SET_DOCUMENTS_PAGE_SIZE = 'SET_DOCUMENTS_PAGE_SIZE';

export interface IState {
	items: IDocument[];
	filter: IFilter;
	total: number;
	onload: boolean;
	wasLoaded: boolean;
	loadingTotals: boolean;
	pagination: {
		page: number;
		pageSize: number;
	};
}

export type IAction =
	| {
			type: typeof FETCH_DOCUMENTS_SUCCESS;
			payload: { documents: IDocument[]; total: number };
	  }
	| {
			type: typeof SET_DOCUMENTS_PAGE | typeof SET_DOCUMENTS_PAGE_SIZE;
			payload: number;
	  }
	| {
			type: typeof FETCH_DOCUMENTS_START | typeof FETCH_DOCUMENTS_FAILURE | typeof CLEAR_DOCUMENTS_FILTER;
	  }
	| {
			type: typeof CHANGE_DOCUMENTS_FILTER;
			payload: { field: string; value: ValueOf<IFilter> };
	  };
