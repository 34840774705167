import { gql } from '@apollo/client';

export default gql`
	input PayoutScheduleFilter {
		dateByMonth: Date!
		accountingType: Int
	}

	type PayoutScheduleDtoPage {
		payoutScheduleDtos: [PayoutScheduleDto]
		total: Int
		totalPages: Int
	}

	type PayoutScheduleDto {
		id: ID!
		accountingType: Int
		startDate: Date
		endDate: Date
		expectedPaymentDate: Date
		lastPaymentDate: Date
		lastDiffInputDate: Date
	}

	input PayoutScheduleInputDto {
		accountingType: Int!
		startDate: Date!
		endDate: Date!
		expectedPaymentDate: Date!
		lastPaymentDate: Date!
		lastDiffInputDate: Date!
	}

	type PayoutScheduleNotification {
		periodStart: Date!
		periodEnd: Date!
		reconciliationDeadline: Date!
		daysLeft: Int!
		accountingType: Int!
	}
`;
