import { gql } from '@apollo/client';

export const GET_CURRENT_USEER = gql`
	query GetCurrentUser {
		getCurrentUser {
			id
			email
			countries {
				roles
				country
				selected
			}
			parentUser
		}
	}
`;

export const REGISTER_USER = gql`
	query RegisterUser($input: UserDto) {
		registerUser(input: $input) {
			id
			email
			countries {
				roles
				country
				selected
			}
		}
	}
`;

export const RESET_PASSWORD = gql`
	mutation ResetPassword($email: String!) {
		resetPassword(email: $email) {
			id
			email
			countries {
				roles
				country
				selected
			}
		}
	}
`;

export const GET_USERS = gql`
	query GetUsers($pageNumber: Int!, $pageSize: Int!, $filter: UserFilter) {
		getUsers(pageNumber: $pageNumber, pageSize: $pageSize, filter: $filter) {
			users {
				id
				email
				countries {
					roles
					country
					selected
					userCountryStoreIds
					userCountryCustomerIds
				}
			}
			total
			totalPages
		}
	}
`;

export const CREATE_USER = gql`
	mutation RegisterUser($input: UserRegisterRequest!) {
		registerUser(input: $input) {
			id
		}
	}
`;

export const DELETE_USER = gql`
	mutation DeleteUser($id: String) {
		deleteUser(id: $id) {
			id
		}
	}
`;
export const SET_USER_LANG = gql`
	mutation ChangePreferredLang($id: ID, $newLang: String) {
		changePreferredLang(userId: $id, newLang: $newLang)
	}
`;

export const UPDATE_USER_ROLES = gql`
	mutation UpdateUserCountryRoles(
		$id: ID!
		$country: CountryCodeEnum!
		$roles: [RoleEnum!]!
		$storeAddressIds: [Int!]!
		$storeAddresses: [StoreAddressDto!]!
		$customerIds: [Long]
	) {
		updateUserCountryRolesStoreIds(
			id: $id
			country: $country
			roles: $roles
			storeAddressIds: $storeAddressIds
			storeAddresses: $storeAddresses
			customerIds: $customerIds
		) {
			id
		}
	}
`;

export const GET_CUSTOMERS_DATA = gql`
	query customersData($customerIds: [Long]) {
		customersData(customerIds: $customerIds) {
			customerId
			name
			address
		}
	}
`;
