import React from 'react';
import { useTranslation } from 'react-i18next';
import { TEST_IDS } from 'tests/config';

import classes from './NotFoundPage.module.scss';

const NotFound: React.FC = () => {
	const { t } = useTranslation('errors');
	return (
		<div className={classes.root} data-testid={TEST_IDS.NOT_FOUND_PAGE}>
			<h2>{t('notFound')}</h2>
		</div>
	);
};

export default NotFound;
