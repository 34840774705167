import styled from '@emotion/styled';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

export const DarkTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ color }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#493F58',
		color: color ? color : '#797A8D',
		fontSize: 14,
		padding: 8,
		fontWeight: 500,
		textAlign: 'left',
		fontFamily: 'Montserrat',
		filter: 'drop-shadow(-4px 4px 16px rgba(78, 69, 92, 0.15))',
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: '#493F58',
	},
}));
