import React, { createRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyQueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import { GET_LAAS_ORDERS_FILTER } from 'api/laasOrders';
import { GET_DATE_PICKER_SCHEDULE } from 'api/orders';
import { compareDate, getDateRange, getDateRangeSchedule, normalizeFilter, normalizeReceivedDate } from 'helpers/order';
import { useLaaSOrders } from 'hooks/useLaaSOrders';
import { ValueOf } from 'interfaces';
import { IDateRangeSchedule } from 'interfaces/filter';
import { IFilter, IlaasOrderSelectFilter } from 'interfaces/laasOrder';
import { TEST_IDS } from 'tests/config';
import { isEqual } from 'underscore';

import { Clear, DatePicker, Input, Select } from 'components/FormControl';
import { IPropsSelect } from 'components/FormControl/types';

import classes from './OrdersContainer.module.scss';

const OrdersFilters: React.FC = ({ children }) => {
	const { filter, changeFilter, clearFilters, items, fetchTotals } = useLaaSOrders();
	const { startDate: filterStartDate, endDate: filterEndDate, ...filterRest } = filter;
	const refFilters = useRef<IFilter>({ ...filterRest });
	const { t } = useTranslation('orders');
	const [[startMountDate, endMountDate], setMountDate] = useState<[Date, Date] | []>([]);
	const { loading: loadingDateRange, data: dateRange } = useQuery<IDateRangeSchedule>(GET_DATE_PICKER_SCHEDULE, {
		variables: { filter: { accountingType: getDateRangeSchedule() } },
	});
	const [fetchCompanyNames, { loading: loadingCompanyNames, data: companyNames }] = useLazyQuery<IlaasOrderSelectFilter>(GET_LAAS_ORDERS_FILTER, {
		variables: {
			type: 'taxId',
		},
	});
	const [fetchStoreNames, { loading: loadingStoreNames, data: storeNames }] = useLazyQuery<IlaasOrderSelectFilter>(GET_LAAS_ORDERS_FILTER, {
		variables: {
			type: 'storeName',
		},
	});
	const [fetchStoreAddress, { loading: loadingStoreAddress, data: storeAddress }] = useLazyQuery<IlaasOrderSelectFilter>(GET_LAAS_ORDERS_FILTER, {
		variables: {
			type: 'pickUpPoint',
		},
	});

	const params: LazyQueryHookOptions = {
		variables: { filter: normalizeFilter(filter) },
		fetchPolicy: 'network-only',
	};

	const fetchData = () => {
		fetchTotals();
		fetchStoreNames(params);
		fetchCompanyNames(params);
		fetchStoreAddress(params);
	};

	useEffect(() => {
		if (filter.startDate && filter.endDate) fetchData();
	}, [filter]);

	const handleChangeForm = (field: keyof IFilter) => (value: IPropsSelect['value']) => {
		changeFilter(field, value as ValueOf<IFilter>);
	};

	const handleChangeDatePicker = (dates: [Date, Date]) => {
		const [start, end] = dates;
		if (start && end) {
			changeFilter('startDate', start);
			changeFilter('endDate', end);
		}
	};

	const handleClearFilters = () => {
		const { startDate, endDate } = dateRange?.datePickerSchedule ? normalizeReceivedDate(dateRange?.datePickerSchedule) : getDateRange(new Date());
		clearFilters([startDate, endDate]);
	};

	useEffect(() => {
		if (!loadingDateRange) {
			const { startDate, endDate } = dateRange?.datePickerSchedule ? normalizeReceivedDate(dateRange?.datePickerSchedule) : getDateRange(new Date());
			handleChangeDatePicker([startDate, endDate]);
			setMountDate([startDate, endDate]);
		}
	}, [dateRange, loadingDateRange]);

	const isChanged =
		!compareDate(filterStartDate, startMountDate) || !compareDate(filterEndDate, endMountDate) || !isEqual(filterRest, refFilters.current);
	const refCompanySelect = createRef<HTMLDivElement>();

	return (
		<div className={classes.filters} data-testid={TEST_IDS.ORDERS_FILTERS}>
			<DatePicker
				onChange={handleChangeDatePicker}
				datepickerProps={{
					twoMonth: true,
					endDate: filter.endDate,
					startDate: filter.startDate,
					maxDate: new Date(),
				}}
				rootClassName={classes.filterItem}
			/>
			<Input
				onChange={handleChangeForm('code')}
				disabled={!filter.code && !items.length}
				value={filter.code}
				placeholder={t('filter.id.placeholder')}
				rootClassName={classes.filterItem}
				delayOnChange={500}
				minLengthWhenCallOnChange={9}
				testId={TEST_IDS.ORDERS_FILTERS_CODE}
			/>
			<Input
				onChange={handleChangeForm('trackingNumber')}
				disabled={!filter.trackingNumber && !items.length}
				value={filter.trackingNumber}
				placeholder={t('filter.trackingNumber.placeholder')}
				rootClassName={classes.filterItem}
				delayOnChange={500}
				minLengthWhenCallOnChange={9}
				testId={TEST_IDS.ORDERS_FILTERS_TRACKING_NUMBER}
			/>

			<Select
				onChange={handleChangeForm('storeName')}
				value={filter.storeName}
				emptyLabel={t('filter.storeName.emptyLabel')}
				rootClassName={classes.filterItem}
				empty={!filter.storeName}
				withSearch
				disabled={!storeNames?.laasOrderSelect.length || loadingStoreNames}
				options={storeNames?.laasOrderSelect.map(({ name }) => ({ value: name, label: name })) || []}
				testId={TEST_IDS.ORDERS_FILTERS_STORE_NAME}
			/>
			<Select
				onChange={handleChangeForm('pickUpPoint')}
				value={filter.pickUpPoint}
				emptyLabel={t('filter.storeAddress.emptyLabel')}
				empty={!filter.pickUpPoint}
				rootClassName={classes.filterItem}
				withSearch
				disabled={!storeAddress?.laasOrderSelect?.length || loadingStoreAddress}
				options={
					storeAddress?.laasOrderSelect?.map(({ pickUpPoint }, index) => ({ value: pickUpPoint, label: pickUpPoint, additionalLabel: `${index}` })) ||
					[]
				}
				testId={TEST_IDS.ORDERS_FILTERS_STORE_ADDRESS}
			/>
			<Select
				onChange={handleChangeForm('taxId')}
				value={filter.taxId}
				emptyLabel={t('filter.companyName.emptyLabel')}
				empty={!filter.taxId}
				rootClassName={classes.filterItem}
				withSearch
				disabled={!companyNames?.laasOrderSelect.length || loadingCompanyNames}
				options={
					companyNames?.laasOrderSelect.map(({ taxId, companyName }, index) => ({
						value: taxId,
						label: companyName,
						additionalLabel: `${index}`,
					})) || []
				}
				testId={TEST_IDS.ORDERS_FILTERS_COMPANY_NAME}
				ref={refCompanySelect}
			/>
			<div className={classes.filterItem} />
			<div className={classes.filterItem}>
				{children}
				<div className={classes.end}>{isChanged && <Clear onClick={handleClearFilters} rootClassName={classes.clear} />}</div>
			</div>
		</div>
	);
};

export default OrdersFilters;
