import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { CREATE_CONTRACT, DELETE_CONTRACT, DELETE_PARTNER_COMMISSION, EDIT_CONTRACT, EDIT_PARTNER_COMMISSION } from 'api/partnersFile';
import { normalizeFilter, normilizeInput } from 'helpers/partnersFile';
import { ERRORS } from 'interfaces';
import {
	AccessData,
	CreateContract,
	DictionaryData,
	EditContractData,
	IFiltration,
	PartnerCommission,
	PartnerDataFullDto,
	PartnerHistory,
	PartnerHistoryRow,
} from 'interfaces/partnersFile';
import { IFilter } from 'interfaces/partnersFile';
import { useSelector } from 'store';
import {
	changeFiltrationAction,
	changePartnersFileFilter,
	clearPartnersFileFilter,
	clearPartnersFiltration,
	createContract as createContractAction,
	deleteContractByNumber as deleteContractByNumberAction,
	fetchDictionary as fetchDictionaryAction,
	fetchFile as fetchFileAction,
	fetchHistory as fetchHistoryAction,
	getAccessData as getAccessDataAction,
	resetSelectedRow as resetSelectedRowAction,
	setCommissionValid,
	setPageHistory as setPageHistoryAction,
	setPartnersFilePage,
	setPartnersFilePageSize,
	updateContractAndhistory as updateContractAndhistoryAction,
} from 'store/partnersFile/actions';

import { useUser } from './useUser';

export interface IUsePartnersFile {
	items: PartnerDataFullDto[];
	filter: IFilter;
	filtration: IFiltration;
	partnerHistory: PartnerHistoryRow[];
	total: number;
	onload: boolean;
	wasLoaded: boolean;
	loadingHistory: boolean;
	pagination: {
		page: number;
		pageSize: number;
	};
	partnerHistoryPagination: {
		page: number;
		pageSize: number;
	};
	dictionaries: DictionaryData[];
	partnerHistoryTotal: number;
	accessData: AccessData;
	loadingAccessData: boolean;
	fetchFile(): void;
	clearFilter(): void;
	setPage(page: number): void;
	setPageSize(pageSize: number): void;
	setPageHistory(page: number): void;
	changeFilter(field: keyof IFilter, value: IFilter[typeof field]): void;
	changeFiltration(fields: IFiltration): void;
	clearFiltration(): void;
	deleteContract(contractNumber: PartnerDataFullDto['contractNumber']): Promise<boolean>;
	createContract(contract: CreateContract): Promise<boolean>;
	fetchHistory(page: number, contractNumber: PartnerDataFullDto['contractNumber']): void;
	editContractCommission(commission: Omit<PartnerCommission, 'id'>[], contractNumber: PartnerDataFullDto['contractNumber']): void;
	deleteContractCommission(commissionIds: number[], contractNumber: PartnerDataFullDto['contractNumber']): void;
	editContractData(data: EditContractData): void;
	fetchDictionary(): void;
	updateTable(row: PartnerDataFullDto): void;
	updateContractAndhistory(history: PartnerHistory[]): void;
	selectedRow: PartnerDataFullDto | null;
	resetSelectedRow(): void;
	fetchAccessData(): void;
	currentUserHasAccess: boolean;
}

export const usePartnersFile = (): IUsePartnersFile => {
	const {
		items,
		filter,
		filtration,
		pagination,
		total,
		onload,
		wasLoaded,
		loadingHistory,
		partnerHistory,
		dictionaries,
		partnerHistoryTotal,
		partnerHistoryPagination,
		selectedRow,
		accessData,
		loadingAccessData,
	} = useSelector((state) => state.partnersFile);
	const [deleteContractMutation] = useMutation(DELETE_CONTRACT);
	const [createContractMutation] = useMutation(CREATE_CONTRACT);
	const [editContractCommissionMutation] = useMutation(EDIT_PARTNER_COMMISSION);
	const [deleteContractCommissionMutation] = useMutation(DELETE_PARTNER_COMMISSION);
	const [editContractDataMutation] = useMutation(EDIT_CONTRACT);
	const dispatch = useDispatch();
	const { user } = useUser();
	const { t } = useTranslation(['partners', 'errors']);

	const fetchFile = () => {
		return dispatch(fetchFileAction(normalizeFilter(filter, filtration), pagination));
	};

	const fetchHistory = (page: number, contractNumber: PartnerDataFullDto['contractNumber']) => {
		return dispatch(fetchHistoryAction(page, contractNumber));
	};

	const fetchDictionary = () => {
		return dispatch(fetchDictionaryAction());
	};

	const fetchAccessData = () => {
		return dispatch(getAccessDataAction());
	};

	const clearFilter = () => {
		dispatch(clearPartnersFileFilter());
	};

	const clearFiltration = () => {
		dispatch(clearPartnersFiltration());
	};

	const setPage = (page: number) => {
		dispatch(setPartnersFilePage(page));
	};

	const setPageHistory = (page: number) => {
		dispatch(setPageHistoryAction(page));
	};

	const setPageSize = (page: number) => {
		dispatch(setPartnersFilePageSize(page));
	};

	const changeFilter = (field: keyof IFilter, value: IFilter[typeof field]) => {
		dispatch(changePartnersFileFilter(field, value));
	};

	const changeFiltration = (fields: IFiltration) => {
		dispatch(changeFiltrationAction(fields));
	};

	const accessDenied = (message: string) => {
		const accessDeniedMessage = message === ERRORS.PARTNER_MODIFY_ACCESS_DENIED;
		toast(t(`errors:${accessDeniedMessage ? message : 'somethingWentWrong'}`), { type: 'error' });
		if (accessDeniedMessage) {
			window.location.reload();
		}
	};

	const deleteContract = (contractNumber: PartnerDataFullDto['contractNumber']): Promise<boolean> => {
		return new Promise((resolve, reject) => {
			deleteContractMutation({
				variables: { contractNumber },
			})
				.then(() => {
					toast(t('successAction'), { type: 'success' });
					dispatch(deleteContractByNumberAction(contractNumber));
					resolve(true);
				})
				.catch((e) => {
					accessDenied(e.message);
					reject(false);
				});
		});
	};

	const createContract = (contract: CreateContract): Promise<boolean> => {
		return new Promise((resolve, reject) => {
			createContractMutation({
				variables: { input: contract },
			})
				.then(() => {
					toast(t('successAction'), { type: 'success' });
					resolve(true);
				})
				.catch((e) => {
					accessDenied(e.message);
					reject(false);
				});
		});
	};

	const editContractCommission = (commissions: PartnerCommission[], contractNumber: PartnerDataFullDto['contractNumber']): Promise<boolean> => {
		return new Promise((resolve, reject) => {
			editContractCommissionMutation({
				variables: { input: normilizeInput(commissions) },
			})
				.then(() => {
					toast(t('successAction'), { type: 'success' });
					const isValidCommission = items.find((i) => i.contractNumber === contractNumber)?.isCommissionValid;
					if (!isValidCommission) {
						dispatch(setCommissionValid({ contractNumber, isCommissionValid: true }));
					}
					resolve(true);
				})
				.catch((e) => {
					const accessDeniedMessage = e.message === ERRORS.PARTNER_EDIT_ACCESS_DENIED;
					const commissionConflict = e.message === ERRORS.PARTNER_COMMISSION_IS_NOT_VALID;
					toast(t(`errors:${accessDeniedMessage || commissionConflict ? e.message : 'somethingWentWrong'}`), { type: 'error' });
					if (accessDeniedMessage) {
						window.location.reload();
					}
					reject(false);
				});
		});
	};

	const deleteContractCommission = (ids: number[], contractNumber: PartnerDataFullDto['contractNumber']): Promise<boolean> => {
		return new Promise((resolve, reject) => {
			deleteContractCommissionMutation({
				variables: { commissionId: ids },
			})
				.then(() => {
					toast(t('successAction'), { type: 'success' });
					const isValidCommission = items.find((i) => i.contractNumber === contractNumber)?.isCommissionValid;
					if (!isValidCommission) {
						dispatch(setCommissionValid({ contractNumber, isCommissionValid: true }));
					}
					resolve(true);
				})
				.catch((e) => {
					accessDenied(e.message);
					reject(false);
				});
		});
	};

	const editContractData = (data: EditContractData): Promise<boolean> => {
		return new Promise((resolve, reject) => {
			let value = null;
			if (data.value && data.value.toString().trim().length) {
				value = data.value;
			}
			editContractDataMutation({
				variables: { input: { contractNumber: data.contractNumber, [data.key]: typeof data.value === 'boolean' ? data.value : { value } } },
			})
				.then(() => {
					toast(t('successAction'), { type: 'success' });
					resolve(true);
				})
				.catch((e) => {
					accessDenied(e.message);
					reject(false);
				});
		});
	};

	const updateTable = (row: PartnerDataFullDto) => {
		dispatch(createContractAction(row));
	};

	const updateContractAndhistory = (history: PartnerHistory[]) => {
		dispatch(updateContractAndhistoryAction(history));
	};

	const resetSelectedRow = () => {
		dispatch(resetSelectedRowAction());
	};

	const currentUserHasAccess = !!accessData.emails.find((email) => email === user?.email);

	return {
		fetchFile,
		items,
		total,
		onload,
		wasLoaded,
		loadingHistory,
		partnerHistoryTotal,
		partnerHistoryPagination,
		loadingAccessData,
		clearFilter,
		clearFiltration,
		setPage,
		setPageSize,
		changeFilter,
		changeFiltration,
		pagination,
		filter,
		filtration,
		deleteContract,
		createContract,
		fetchHistory,
		partnerHistory,
		editContractCommission,
		editContractData,
		fetchDictionary,
		dictionaries,
		updateTable,
		updateContractAndhistory,
		deleteContractCommission,
		setPageHistory,
		selectedRow,
		resetSelectedRow,
		fetchAccessData,
		accessData,
		currentUserHasAccess,
	};
};
