import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { QueryOptions, useMutation } from '@apollo/client';
import { DELETE_CUSTOMER_HISTORY, EDIT_CUSTOMER } from 'api/customers';
import { normilizeInput } from 'helpers/storeAddresses';
import { IPagination } from 'interfaces';
import { ICustomer, ICustomerFilter, ICustomerForm } from 'interfaces/customer';
import { useSelector } from 'store';
import {
	changeCustomersPageFilter,
	fetchCustomers as fetchCustomersAction,
	setCustomerData,
	setCustomersPage,
	setCustomersPageSize,
} from 'store/customers/actions';

export interface IUseCustomers {
	items: ICustomer[];
	filter: ICustomerFilter;
	total: number;
	onload: boolean;
	wasLoaded: boolean;
	pagination: IPagination;
	fetchCustomers(): void;
	changeFilter(field: keyof ICustomerFilter, value: ICustomerFilter[typeof field]): void;
	setPageSize(pageSize: number): void;
	setPage(page: number): void;
	editCustomer(input: ICustomerForm, refetchQueries?: QueryOptions[]): Promise<boolean>;
	deleteCustomerHistory(customerId?: ICustomer['customerId'], date?: string, refetchQueries?: QueryOptions[]): Promise<boolean>;
	setAbortRef(AbortController: AbortController): void;
	aborterRef: AbortController;
}

export const useCustomers = (): IUseCustomers => {
	const { items, filter, pagination, total, onload, wasLoaded } = useSelector((state) => state.customers);
	const [editCustomerMutation] = useMutation(EDIT_CUSTOMER);
	const [deleteCustomerMutation] = useMutation(DELETE_CUSTOMER_HISTORY);
	const dispatch = useDispatch();
	const [aborterRef, setAbortRef] = useState(new AbortController());

	const fetchCustomers = useCallback(() => {
		return dispatch(fetchCustomersAction(filter, pagination, aborterRef));
	}, [dispatch, filter, pagination]);

	const changeFilter = (field: keyof ICustomerFilter, value: ICustomerFilter[typeof field]) => {
		dispatch(changeCustomersPageFilter(field, value));
	};

	const setPageSize = (pageSize: number) => {
		dispatch(setCustomersPageSize(pageSize));
	};

	const setPage = (page: number) => {
		dispatch(setCustomersPage(page));
	};

	const editCustomer = (input: ICustomerForm, refetchQueries?: QueryOptions[]) => {
		return editCustomerMutation({
			variables: { input: normilizeInput(input, true) },
			refetchQueries,
			awaitRefetchQueries: true,
		}).then((res) => {
			dispatch(setCustomerData(res.data.editCustomer));
			return true;
		});
	};

	const deleteCustomerHistory = (customerId?: ICustomer['customerId'], date?: string, refetchQueries?: QueryOptions[]) => {
		return deleteCustomerMutation({
			variables: { customerId, date },
			refetchQueries,
		}).then((res) => {
			dispatch(setCustomerData(res.data.deleteCustomerHistory));
			return true;
		});
	};

	return {
		items,
		filter,
		pagination,
		total,
		onload,
		wasLoaded,
		fetchCustomers,
		changeFilter,
		setPageSize,
		setPage,
		editCustomer,
		deleteCustomerHistory,
		setAbortRef,
		aborterRef,
	};
};
