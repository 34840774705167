import React from 'react';
import GetStarted from 'containers/GetStarted';
import NotificationAuthOtherUser from 'containers/NotificationAuthOtherUser';
import Orders from 'containers/Orders';
import { useOrders } from 'hooks/useOrders';
import { TEST_IDS } from 'tests/config';

import Loader from 'components/Loader';

import OrdersTotals from './Totals';

import classes from './Orders.module.scss';

const OrdersPage: React.FC = () => {
	const { onload: onloadOrders, wasLoaded } = useOrders();

	const showLoader = () => {
		return onloadOrders && wasLoaded;
	};

	return (
		<div className={classes.root} data-testid={TEST_IDS.ORDERS_PAGE}>
			<NotificationAuthOtherUser />
			{showLoader() && <Loader />}
			<GetStarted />
			<OrdersTotals />
			<Orders />
		</div>
	);
};

export default OrdersPage;
