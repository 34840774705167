import { IPagination } from 'interfaces';
import { IContract, IContractFilter } from 'interfaces/contractGeneration';

export const FETCH_CONTRACTS_START = 'FETCH_CONTRACTS_START';
export const FETCH_CONTRACTS_SUCCESS = 'FETCH_CONTRACTS_SUCCESS';
export const FETCH_CONTRACTS_FAILURE = 'FETCH_CONTRACTS_FAILURE';
export const CHANGE_CONTRACTS_FILTER = 'CHANGE_CONTRACTS_FILTER';
export const CLEAR_CONTRACTS_FILTER = 'CLEAR_CONTRACTS_FILTER';
export const DELETE_CONTRACT_BY_ID = 'DELETE_USER_BY_ID';
export const SET_CONTRACTS_PAGE = 'SET_CONTRACTS_PAGE';
export const SET_CONTRACTS_PAGE_SIZE = 'SET_CONTRACTS_PAGE_SIZE';
export const UPDATE_CONTRACT_NAME = 'UPDATE_CONTRACT_NAME';
export const DUBLICATE_CONTRACT = 'DUBLICATE_CONTRACT';
export const CHANGE_CONTRACT_FIELDS = 'CHANGE_CONTRACT_FIELDS';

export interface IState {
	items: IContract[];
	filter: IContractFilter;
	total: number;
	onload: boolean;
	wasLoaded: boolean;
	pagination: IPagination;
}

export type IAction =
	| {
			type: typeof FETCH_CONTRACTS_SUCCESS;
			payload: { items: IContract[]; total: number };
	  }
	| {
			type: typeof SET_CONTRACTS_PAGE | typeof SET_CONTRACTS_PAGE_SIZE;
			payload: number;
	  }
	| {
			type: typeof FETCH_CONTRACTS_FAILURE | typeof FETCH_CONTRACTS_START;
	  }
	| { type: typeof DELETE_CONTRACT_BY_ID; payload: string }
	| {
			type: typeof UPDATE_CONTRACT_NAME;
			payload: {
				agreementId: IContract['agreementId'];
				newAgreementName: IContract['agreementName'];
			};
	  }
	| {
			type: typeof CHANGE_CONTRACTS_FILTER;
			payload: { field: string; value: string };
	  }
	| {
			type: typeof DUBLICATE_CONTRACT | typeof CHANGE_CONTRACT_FIELDS;
			payload: IContract;
	  };
