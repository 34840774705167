import { CountryCodeEnum } from 'interfaces';

export enum RoleEnum {
	CREDENTIALS_ADMIN = 'CREDENTIALS_ADMIN',
	FINANCE = 'FINANCE',
	ADMIN = 'ADMIN',
	ACCOUNT = 'ACCOUNT',
	STORE_ADMIN = 'STORE_ADMIN',
	STORE_MANAGER = 'STORE_MANAGER',
	ACCOUNTANT = 'ACCOUNTANT',
	MENUTOOL = 'MENUTOOL',
	CUSTOMER = 'CUSTOMER',
}

export interface UserCountryRoles {
	selected: boolean;
	country: CountryCodeEnum;
	roles: RoleEnum[];
	userCountryStoreIds: number[];
	userCountryCustomerIds: number[];
}

export interface IUser {
	id: string;
	email: string;
	countries: UserCountryRoles[];
	parentUser?: string;
}

export interface IResponseFetchUsers {
	getUsers: {
		total: number;
		totalPages: number;
		userDtos: IUser[];
	};
}

export interface IUserFilter {
	storeAddressIdAndStoreNameAndEmail: string;
	roles: RoleEnum[];
}

export interface ICustomerData {
	address: string;
	customerId: number;
	name: string;
}
