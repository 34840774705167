import { clientGraphql } from 'api';
import { GET_CURRENT_USEER, GET_USERS } from 'api/users';
import { contextAuth } from 'const';
import { IPagination } from 'interfaces';
import { IUser, IUserFilter } from 'interfaces/users';
import { Dispatch } from 'redux';

import {
	CHANGE_USERS_FILTER,
	DELETE_USER_BY_ID,
	FETCH_CURRENT_USER_FAILURE,
	FETCH_CURRENT_USER_START,
	FETCH_CURRENT_USER_SUCCESS,
	FETCH_USERS_FAILURE,
	FETCH_USERS_START,
	FETCH_USERS_SUCCESS,
	RESET_USERS_FILTER,
	SET_USERS_PAGE,
	SET_USERS_PAGE_SIZE,
	UPDATE_USER_BY_ID,
} from './types';

export const fetchUsers =
	(filter: IUserFilter, { page, pageSize }: IPagination, aborterRef: AbortController) =>
	(dispatch: Dispatch) => {
		dispatch({ type: FETCH_USERS_START });
		return clientGraphql
			.query({
				query: GET_USERS,
				variables: { pageNumber: page, pageSize, filter },
				context: {
					...contextAuth,
					fetchOptions: {
						signal: aborterRef.signal,
					},
				},
			})
			.then((res) => {
				dispatch({
					payload: {
						users: res.data.getUsers.users,
						total: res.data.getUsers.total,
					},
					type: FETCH_USERS_SUCCESS,
				});
				return res.data.getUsers.users;
			})
			.catch(() => {
				dispatch({ type: FETCH_USERS_FAILURE });
			});
	};

export const setUsersPage = (payload: number) => ({
	payload,
	type: SET_USERS_PAGE,
});

export const setUsersPageSize = (payload: number) => ({
	payload,
	type: SET_USERS_PAGE_SIZE,
});

export const changeUsersFilter = (field: keyof IUserFilter, value: IUserFilter[typeof field]) => ({
	payload: { field, value },
	type: CHANGE_USERS_FILTER,
});

export const fetchCurrentUser = () => (dispatch: Dispatch) => {
	dispatch({ type: FETCH_CURRENT_USER_START });
	return clientGraphql
		.query({
			query: GET_CURRENT_USEER,
			context: contextAuth,
		})
		.then((res) => {
			dispatch({
				payload: res.data?.getCurrentUser,
				type: FETCH_CURRENT_USER_SUCCESS,
			});
			return res.data?.getCurrentUser;
		})
		.catch(() => {
			dispatch({ type: FETCH_CURRENT_USER_FAILURE });
		});
};

export const deleteUserById = (payload: IUser['id']) => ({
	payload,
	type: DELETE_USER_BY_ID,
});

export const updateUserById = (payload: IUser) => ({
	payload,
	type: UPDATE_USER_BY_ID,
});

export const resetUsersFilter = () => ({
	type: RESET_USERS_FILTER,
});
