import { useEffect, useRef, useState } from 'react';

export const useInterval = (callback: () => void, delay: number | null, maxTryCount = 0) => {
	const savedCallback = useRef<() => void>();
	const [tryCount, setTryCount] = useState<number>(1);

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		const tick = () => {
			setTryCount((state) => state + 1);
			if (savedCallback.current) {
				savedCallback.current();
			}
		};
		if (delay !== null && (!maxTryCount || tryCount <= maxTryCount)) {
			const id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay, maxTryCount, tryCount]);
};
