import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DebtFilters from 'containers/Accounts/Filters/DebtFilters';
import { subDays } from 'date-fns';
import clsx from 'helpers/clsx';
import { useInvoices } from 'hooks/useInvoices';
import useToast from 'hooks/useToast';
import { useUser } from 'hooks/useUser';
import { IStoreAddress } from 'interfaces/storeAddress';
import { ReactComponent as Debtico } from 'static/images/debt.svg';
import { ReactComponent as Emptyico } from 'static/images/empty.svg';
import { TEST_IDS } from 'tests/config';

import { DatePicker } from 'components/FormControl';
import Loader from 'components/Loader';

import DebtHead from './DebtHead';
import DebtLine from './DebtLine';

import classes from './DebtTable.module.scss';

const DebtTable = () => {
	const [activeTab, setActiveTab] = useState(false);
	const { isServiceUser, isCredentialsAdmin } = useUser();
	const { t } = useTranslation('accounts');
	const { fetchCompaniesDebt, fetchGlovoDebt, fetchStoreAddresses, glovoDebts, companiesDebts, debtsLoad } = useInvoices();
	const debtsTabView = activeTab ? glovoDebts : companiesDebts;
	const [debtStores, setDebtStores] = useState<IStoreAddress[] | []>([]);
	const [selectedDate, setStartDate] = useState<Date>(subDays(new Date(), 1));
	const { toast } = useToast();
	const { filter } = useInvoices();

	const handleActive = (param: boolean) => () => setActiveTab(param);

	const getAllStoreAddresses = () => {
		const allUserStoreIds: number[] = debtsTabView.reduce((acc: number[], debt) => [...acc, ...debt.storeAddressesIds], []);
		fetchStoreAddresses(allUserStoreIds)
			.then((res) => setDebtStores(res.storeAddressData))
			.catch(() => toast(t('errors:somethingWentWrong'), { type: 'error' }));
	};

	const getCurrentStoreAddresses = (storeAddressesIds: number[]) => {
		if (!debtStores.length) return [];
		return storeAddressesIds
			?.map((storeId) => debtStores.find((storeData: IStoreAddress) => storeId === storeData.storeAddressId) as IStoreAddress)
			.filter((store) => store);
	};

	const handleChangeStartDate = (newDate: Date) => {
		setStartDate(newDate);
	};

	useEffect(() => {
		getAllStoreAddresses();
	}, [glovoDebts, companiesDebts]);

	useEffect(() => {
		const currentFilter = {
			date: selectedDate,
			taxId: filter.taxId,
		};
		if (!activeTab) return fetchCompaniesDebt(currentFilter);
		fetchGlovoDebt(currentFilter);
	}, [activeTab, selectedDate, filter.taxId]);

	const tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() - 1);

	return (
		<div className={classes.arrearsTable} data-testid={TEST_IDS.ACCOUNTS_DEBT_TABLE}>
			{isServiceUser && (
				<div className={classes.header}>
					<h4>{t('title')}</h4>
					<DebtFilters />
				</div>
			)}
			<div className={classes.tabsSection}>
				<div className={classes.tabs}>
					<button onClick={handleActive(false)} className={clsx({ [classes.active]: !activeTab })}>
						{t('partnerTab')}
					</button>
					<button onClick={handleActive(true)} className={clsx({ [classes.active]: activeTab })}>
						{t('serviceTab')}
					</button>
				</div>
				<div className={classes.dateContainer}>
					<DatePicker
						onChange={handleChangeStartDate}
						datepickerProps={{
							startDate: selectedDate,
							maxDate: isCredentialsAdmin ? new Date() : tomorrow,
						}}
						rootClassName={classes.datePicker}
						calendarClassName={classes.calendarClassName}
						disabled={isServiceUser ? !filter.taxId : false}
					/>
				</div>
			</div>
			<DebtHead />
			{debtsLoad ? (
				<Loader simple />
			) : (
				<>
					{debtsTabView?.length ? (
						debtsTabView.map((debt) => (
							<DebtLine activeTab={activeTab} key={debt.id} debt={debt} storeData={getCurrentStoreAddresses(debt.storeAddressesIds)} />
						))
					) : (
						<div className={classes.emptyDebts}>
							{isServiceUser ? <Debtico /> : <Emptyico />}
							{t(isServiceUser ? 'emptyDebtsService' : 'emptyDebts')}
						</div>
					)}
				</>
			)}
		</div>
	);
};
export default DebtTable;
