import styled from '@emotion/styled';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import { keyframes } from '@mui/system';

const animation = keyframes`
  0%   {opacity: 0.4;}
  25%  {opacity: 0.6; background-color: #26ce4b;}
  50%  {opacity: 0.8;}
  80% {opacity: 0.9; background-color: transparent;}
  100% {opacity: 1;}
`;

export const StyledTableRow = styled(({ ...props }: TableRowProps) => <TableRow {...props} />)(() => ({
	'& > .MuiTableCell-root': {
		borderBottom: '1px solid #D8D9E4',
	},
	'&.Mui-selected': {
		backgroundColor: 'transparent',
		animation: `${animation} 1.5s ease-out`,
		'& > td, & > th': {
			backgroundColor: 'transparent',
			animation: `${animation} 1.5s ease-out`,
		},
	},
	'&:hover': {
		backgroundColor: '#F9F9FA',
		'& > .MuiTableCell-root': {
			backgroundColor: '#F9F9FA',
		},
	},
}));
