import React from 'react';
import { Id, toast as toastFn, ToastOptions } from 'react-toastify';

export interface IUseToast {
	toast(title: string, options?: ToastOptions): void;
}

const useToast = (): IUseToast => {
	const toastId = React.useRef<Id | null>(null);
	const toast = (title: string, options?: ToastOptions) => {
		if (!toastId.current || !toastFn.isActive(toastId.current)) {
			toastId.current = toastFn(title, options);
		}
	};

	return {
		toast,
	};
};

export default useToast;
