import styled from '@emotion/styled';
import TableCell, { TableCellProps } from '@mui/material/TableCell';

export const StyledTableCell = styled(({ className, ...props }: TableCellProps) => <TableCell classes={{ root: className }} {...props} />)(() => ({
	'&': {
		color: '#493F58',
		fontSize: 14,
		lineHeight: '14px',
		fontWeight: 500,
		fontFamily: 'Montserrat',
		borderBottom: '1px solid #D8D9E4',
	},
	'&[aria-selected="true"]': {
		border: '1px solid #00B794',
	},
}));
