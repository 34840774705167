import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetch } from 'api';
import { Routes } from 'const';
import CreateDocument from 'containers/modals/CreateDocument';
import SendVchasno from 'containers/modals/SendVchasno';
import NotificationAuthOtherUser from 'containers/NotificationAuthOtherUser';
import { formatDate, formatTime } from 'helpers';
import clsx from 'helpers/clsx';
import { normalizeFilter, queryParamsToString } from 'helpers/contractGeneration';
import { getCountry } from 'helpers/jwt';
import { useContractGeneration } from 'hooks/useContractGeneration';
import { useDocuments } from 'hooks/useDocuments';
import { useExport } from 'hooks/useExport';
import { CountryCodeEnum, ValueOf } from 'interfaces';
import { AgreementStatus, Filters, IContract, IContractFilter } from 'interfaces/contractGeneration';
import { ReactComponent as ContractIcon } from 'static/images/contractGeneration.svg';
import { ReactComponent as DeleteIcon } from 'static/images/delete.svg';
import { ReactComponent as DublicateFileIcon } from 'static/images/dublicateFile.svg';
import { ReactComponent as EditIcon } from 'static/images/edit.svg';
import { ReactComponent as ExportPDF } from 'static/images/exportPDF.svg';
import { ReactComponent as ExportWORD } from 'static/images/exportWORD.svg';
import { ReactComponent as InfoIcon } from 'static/images/info.svg';
import { ReactComponent as MoreIcon } from 'static/images/more_vert.svg';
import { ReactComponent as SearchIcon } from 'static/images/search.svg';
import { ReactComponent as SendIcon } from 'static/images/send.svg';
import { ReactComponent as SettingIcon } from 'static/images/settings.svg';
import { ReactComponent as LinkIcon } from 'static/images/target_link.svg';
import { TEST_IDS } from 'tests/config';
import { groupBy } from 'underscore';

import { Status } from 'components/Contract/Status';
import { ToastDublicateAgreement } from 'components/Contract/ToastDublicateAgreement';
import CopyButton from 'components/CopyButton';
import EmptyResult from 'components/EmptyResult';
import { DatePicker, Input, Select } from 'components/FormControl';
import { IPropsSelect } from 'components/FormControl/types';
import IconButton from 'components/IconButton';
import Loader from 'components/Loader';
import LoadMore from 'components/LoadMore';
import Confirm from 'components/modals/Confirm';
import Tooltip from 'components/Tooltip';

import classes from './ContractGeneration.module.scss';

const initFilters = {
	partners: [],
	statuses: [],
	groups: {},
};

const ContractGeneration: React.FC = () => {
	const {
		filter,
		pagination,
		items,
		onload,
		wasLoaded,
		total,
		changeFilter,
		setPage,
		deleteContract,
		fetchContracts,
		updateContractName,
		dublicateContract,
		changeFields,
	} = useContractGeneration();
	const { t } = useTranslation(['contractGeneration', 'form', 'errors', 'document']);
	const [loading, setLoading] = useState<boolean>(false);
	const history = useHistory();
	const [newAgreementName, setChangedName] = useState<string>('');
	const { exportAgreement, onload: loadingExport } = useExport();
	const [filters, setFilters] = useState<Filters>(initFilters);
	const { getStatusStyle, getStatusText } = useDocuments();

	const fetchFilters = async () => {
		try {
			const { body }: { body: Filters } = await fetch(
				`/agreement/getFilter?${queryParamsToString(normalizeFilter(filter))}`,
				{ fintoolContracts: true },
				false,
			);
			setFilters({ ...initFilters, ...body });
		} catch {
			throw new Error(t('errors:somethingWentWrong'));
		}
	};

	useEffect(() => {
		fetchFilters();
	}, [filter]);

	useEffect(fetchContracts, [pagination, filter]);

	const loadMore = () => setPage(pagination.page + 1);

	const handleDelete = (id: string) => () => {
		setLoading(true);
		return deleteContract(id).finally(() => setLoading(false));
	};

	const canLoadMore = items.length < total;

	const handleChangeSelect = (field: keyof IContractFilter) => (value: IPropsSelect['value']) => {
		changeFilter(field, value as ValueOf<IContractFilter>);
	};

	const search = (value: string) => changeFilter('search', value);

	const handleChangeDatePicker = (dates: [Date, Date]) => {
		const [startDate, endDate] = dates;
		if (startDate && endDate) {
			changeFilter('startDate', startDate);
			changeFilter('endDate', endDate);
		}
	};

	const openContract = (agreementId: string) => () => {
		history.push(`${Routes.DOCUMENT_PREVIEW.replace('/:id', '')}${agreementId}`);
	};

	const handleEdit = (agreementId: string) => () => {
		setLoading(true);
		return updateContractName({ agreementId, newAgreementName }).finally(() => setLoading(false));
	};

	const changeAgreementName = (value: string) => {
		setChangedName(value);
	};

	const copyAgreement = (agreementId: string) => async () => {
		const data = await dublicateContract(agreementId);
		if (data) {
			toast(<ToastDublicateAgreement data={data} />, { type: 'success', autoClose: 5000 });
			return true;
		}
	};

	const goToConfigure = (item: IContract) => () => {
		history.push(`${Routes.QUESTIONNAIRE}?label=${item.agreementName}&id=${item.agreementId}`);
	};

	const goToForm = (item: IContract) => () => {
		if (item?.agreementId) {
			history.push(`${Routes.CONTRACT_FORM.replace(':id', item?.agreementId)}`);
		}
	};

	const filteredList = items.filter((item) =>
		filter.status
			? filter.status?.includes(item.agreementStatus) || (item.vchasnoSync?.status && filter.status?.includes(item.vchasnoSync?.status))
			: item.agreementStatus,
	);

	const groupStatusesByLabel = groupBy(
		filters.statuses.map(({ status, type }) => ({
			label: type === 'VCHASNO' ? getStatusText(status) : t(`status.${status.toLowerCase()}`),
			value: status,
		})),
		'label',
	);

	return (
		<div className={classes.root} data-testid={TEST_IDS.CONTRACT_GENERATION_PAGE}>
			{(loading || loadingExport) && <Loader />}
			<div className={classes.body}>
				<NotificationAuthOtherUser />
				<div className={classes.header}>
					<div className={classes.title}>{t('title')}</div>
					<CreateDocument />
				</div>
				<div className={classes.containerSelect}>
					<div className={classes.container}>
						<Input
							fullWidth
							onChange={search}
							value={filter.search}
							placeholder={t('search')}
							rootClassName={classes.filters}
							controlClassName={classes.input}
							Icon={SearchIcon}
							positionIcon="right"
							delayOnChange={1000}
							disabled={onload}
						/>
					</div>
					<div className={classes.container}>
						<DatePicker
							onChange={handleChangeDatePicker}
							forceOpenDatepicker={false}
							datepickerProps={{
								twoMonth: true,
								endDate: filter.endDate,
								startDate: filter.startDate,
								maxDate: new Date(),
							}}
							rootClassName={classes.filters}
							disabled={onload}
						/>
					</div>
					<div className={classes.container}>
						<Select
							multiple
							onChange={handleChangeSelect('status')}
							value={filter.status}
							emptyLabel={t('statusTitle')}
							multipleTitle={t('multipleTitle.status')}
							rootClassName={classes.filters}
							testId={TEST_IDS.DOCUMENTS_FILTERS_STATUS}
							options={Object.keys(groupStatusesByLabel).map((key) => groupStatusesByLabel[key][0])}
							disabled={onload || !filters.statuses.length}
						/>
					</div>
					<div className={classes.container}>
						<Select
							withSearch
							onChange={handleChangeSelect('taxId')}
							value={filter.taxId}
							emptyLabel={t('document:allCode')}
							rootClassName={classes.filters}
							options={
								filters.partners.map((it) => ({
									value: it.taxId,
									label: `${it.taxId} — ${it.partnerName}`,
								})) || []
							}
							testId={TEST_IDS.DOCUMENTS_FILTERS_EDRPOU}
							disabled={onload || !filters.partners.length}
						/>
					</div>
					<div className={classes.container}>
						<Select
							withSearch
							multiple
							onChange={handleChangeSelect('group')}
							value={filter.group}
							emptyLabel={t('allTypesOfDocuments')}
							multipleTitle={t('multipleTitle.group')}
							rootClassName={classes.filters}
							options={
								Object.keys(filters.groups).map((key) => ({
									value: key,
									label: filters.groups[key],
								})) || []
							}
							testId={TEST_IDS.DOCUMENTS_FILTERS_GROUP}
							disabled={onload || !Object.keys(filters.groups).length}
						/>
					</div>
				</div>
				{onload && !wasLoaded ? (
					<Loader simple />
				) : (
					<table className={classes.documents} data-testid={TEST_IDS.DOCUMENTS_TABLE}>
						{onload && <Loader />}
						<tbody>
							{filteredList.map((item: IContract) => (
								<React.Fragment key={item.agreementId}>
									<tr className={classes.spacer}>
										<td colSpan={3}></td>
									</tr>
									<tr data-testid={TEST_IDS.DOCUMENTS_TABLE_BODY_ROW}>
										<td onClick={openContract(item.agreementId)}>
											<div className={classes.date}>
												{formatDate(new Date(item.createdAt))} {formatTime(new Date(item.createdAt))}
											</div>
										</td>
										<td title={item.agreementName} onClick={openContract(item.agreementId)}>
											<div className={classes.name}>{item.agreementName}</div>
										</td>
										<td onClick={openContract(item.agreementId)} className={classes.status}>
											{item.vchasnoSync ? (
												<div className={clsx(classes.statusText, getStatusStyle[item.vchasnoSync.status])}>
													<span>{getStatusText(item.vchasnoSync.status)}</span>
												</div>
											) : (
												<Status status={item.agreementStatus} />
											)}
										</td>
										<td>
											<div className={clsx(classes.actions, classes.flex)}>
												<div className={classes.table_actions}>
													{item.vchasnoSync && (
														<div className={classes.vchasno}>
															{item.vchasnoSync.url && item.vchasnoSync.status !== AgreementStatus.DELETED_FROM_VCHASNO_SYNC && (
																<Tooltip
																	outside
																	withClick
																	nowrap
																	placement="top-end"
																	text={
																		<div className={classes.vchasno_info}>
																			<h5>{t('vchasno.title')}</h5>
																			<div className={classes.vchasno_info_row}>
																				<span>{t('vchasno.date')}</span>
																				<div>
																					{formatDate(new Date(item.vchasnoSync.createdAt))} {formatTime(new Date(item.vchasnoSync.createdAt))}
																				</div>
																			</div>
																			<div className={classes.vchasno_info_row}>
																				<span>{t('vchasno.sender')}</span>
																				{item.vchasnoSync.senderEmail}
																			</div>
																			<div className={classes.vchasno_info_row}>
																				<div className={classes.vchasno_info_copy}>
																					<a href={item.vchasnoSync.url} target="_blank" rel="noreferrer">
																						<LinkIcon className={classes.copyIcon} />
																						<span>{t('vchasno.openLink')}</span>
																					</a>
																				</div>
																				<div className={classes.vchasno_info_copy}>
																					<CopyButton className={classes.copyIcon} text={item.vchasnoSync?.url}>
																						<span>{t('vchasno.copyLink')}</span>
																					</CopyButton>
																				</div>
																			</div>
																		</div>
																	}
																>
																	<InfoIcon color="#797A8D" />
																</Tooltip>
															)}
														</div>
													)}
												</div>
												<Tooltip
													outside
													withClick
													placement="left"
													rootClassName={classes.actions__more}
													className={classes.tooltipMore}
													testId={TEST_IDS.ACTIONS}
													text={
														<>
															{item.canConfigure && (
																<button className={clsx(classes.action, classes.flex, classes.alignItemsCenter)} onClick={goToConfigure(item)}>
																	<SettingIcon color="#797A8D" className={clsx(classes.actionIcon)} />
																	{t('goToConfigure')}
																</button>
															)}
															{[AgreementStatus.FILLED, AgreementStatus.CONFIGURED, AgreementStatus.PARTIALLY_FILLED].includes(
																item?.agreementStatus as AgreementStatus,
															) &&
																!item.vchasnoSync && (
																	<button className={clsx(classes.action, classes.flex, classes.alignItemsCenter)} onClick={goToForm(item)}>
																		<ContractIcon color="#797A8D" className={clsx(classes.actionIcon)} />
																		{t('goToEditForm')}
																	</button>
																)}
															{item?.agreementStatus === AgreementStatus.FILLED && getCountry() === CountryCodeEnum.UA && (
																<SendVchasno
																	setFields={changeFields}
																	contractId={item.agreementId}
																	fields={{ ...item, placeholderMap: {}, templateCase: '' }}
																>
																	<button className={clsx(classes.action, classes.flex, classes.alignItemsCenter)}>
																		<SendIcon className={clsx(classes.actionIcon)} />
																		{t('sendVchasno')}
																	</button>
																</SendVchasno>
															)}
															<Confirm
																title={t('actions.editName')}
																body={
																	<div className={classes.inputName}>
																		<Input fullWidth onChange={changeAgreementName} value={item.agreementName} label={t('agreementName')} />
																	</div>
																}
																confirm={{
																	onClick: handleEdit(item.agreementId),
																	text: t('confirm.editName'),
																}}
															>
																<button className={clsx(classes.action, classes.flex, classes.alignItemsCenter)}>
																	<EditIcon className={clsx(classes.actionIcon)} />
																	{t('actions.editName')}
																</button>
															</Confirm>
															<Confirm
																title={t('actions.dublicateAgreement', { agreementName: item.agreementName })}
																icon="copy"
																confirm={{
																	onClick: copyAgreement(item.agreementId),
																	text: t('actions.dublicateDocument'),
																}}
															>
																<button className={clsx(classes.action, classes.flex, classes.alignItemsCenter)}>
																	<DublicateFileIcon className={clsx(classes.actionIcon)} />
																	{t('actions.dublicateDocument')}
																</button>
															</Confirm>
															{item.agreementStatus !== AgreementStatus.DELETED_FROM_VCHASNO && (
																<>
																	<button
																		onClick={() => exportAgreement(item.agreementId, 'PDF', item.agreementName)}
																		className={clsx(classes.action, classes.flex, classes.alignItemsCenter)}
																	>
																		<ExportPDF className={clsx(classes.actionIcon)} />
																		{t('actions.exportPDF')}
																	</button>
																	<button
																		onClick={() => exportAgreement(item.agreementId, 'WORD', item.agreementName)}
																		className={clsx(classes.action, classes.flex, classes.alignItemsCenter)}
																	>
																		<ExportWORD className={clsx(classes.actionIcon)} />
																		{t('actions.exportWORD')}
																	</button>
																	<Confirm
																		confirm={{
																			onClick: handleDelete(item.agreementId),
																			text: t('confirm.deleteDocument.ok'),
																		}}
																		title={t('confirm.deleteDocument.title')}
																		body={t(item.vchasnoSync ? 'confirm.deleteDocument.bodyVchasno' : 'confirm.deleteDocument.body')}
																		icon="delete"
																	>
																		<button className={clsx(classes.action, classes.flex, classes.alignItemsCenter)}>
																			<DeleteIcon className={clsx(classes.actionIcon)} />
																			{t('actions.delete')}
																		</button>
																	</Confirm>
																</>
															)}
														</>
													}
												>
													<IconButton testId={TEST_IDS.USERS_PAGE_MORE_BUTTON}>
														<MoreIcon className={clsx(classes.actionIcon, classes.actionIcon_delete)} />
													</IconButton>
												</Tooltip>
											</div>
										</td>
									</tr>
								</React.Fragment>
							))}
						</tbody>
					</table>
				)}
				{!items.length && !onload && <EmptyResult>{t('emptyList')}</EmptyResult>}
				{canLoadMore && !!items.length && (
					<LoadMore onClick={loadMore} className={clsx(classes.flex, classes.justifyContentCenter, classes.loadMore)} />
				)}
			</div>
		</div>
	);
};

export default ContractGeneration;
