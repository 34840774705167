import { FinalStatus, PaymentMethods } from 'interfaces';
import { ILaaSOrder } from 'interfaces/laasOrder';
import { IOrder, OrderStatus } from 'interfaces/order';

export const FAKE_ORDER: IOrder = {
	id: 'AA11AAA1',
	code: 'AA11AAA1',
	activationTime: new Date().toDateString(),
	dispatchingTime: new Date().toDateString(),
	storeAddressId: 100000,
	storeName: 'Тестова Назва закладу',
	companyName: 'Тестова Назва контрагенту',
	amount: 356,
	isAction: false,
	partnerCommissionPercentage: 20.0,
	toBePaid: '05.05–08.05',
	finalStatus: FinalStatus.DELIVERED,
	cancelReason: "пом'яте пакування",
	partnerResolution: 'бесплатна доставка',
	chargedToPartner: 36.0,
	returnType: 'Self Refund',
	paymentMethod: PaymentMethods.CARD,
	receivedFunds: 400,
	deliveryFee: 40,
	deliveryPromotionAssumedByPartner: 0,
	orders: [],
	orderStatus: OrderStatus.PENDING,
	approvedAt: 'approvedAt',
	approvedBy: 'approvedBy',
	originAmount: 536,
	originChargedToPartner: 20,
	originPaymentMethod: PaymentMethods.CARD,
	promotionAssumedByPartner: 500,
	productDiscountAssumedByPartner: 0,
	deliveryDiscountAssumedByPartner: 0,
	originProductDiscountAssumedByPartner: 0,
	originDeliveryDiscountAssumedByPartner: 0,
	taxId: '111',
	expectedPaymentDate: null,
	lastPaymentDate: null,
	lastDiffInputDate: null,
	promotionDeliveryByPartner: 0,
	promoCalculationType: 'promoType',
	deliveryDiscountPaidByPartner: 0,
	startPeriodDate: null,
	endPeriodDate: null,
};

export const FAKE_LAAS_ORDER: ILaaSOrder = {
	orderId: 'AA11AAA1',
	orderCode: 'AA11AAA1',
	companyName: 'Тестова Назва контрагенту',
	partnerResolution: 'бесплатна доставка',
	chargedToPartner: 36.0,
	deliveryFee: 40,
	taxId: '111',
	cityCode: '',
	countryCode: '',
	cityTier: 0,
	customerId: 0,
	orderFinalStatus: FinalStatus.DELIVERED,
	orderCancelReason: '',
	orderCreatedLocalAt: '',
	kmTier: 0,
	trackingNumber: '',
	totalDeliveryFee: 0,
	serviceFee: 0,
	companyEmail: '',
	name: '',
	courierId: '',
	addressLine1: '',
	orderCourierArrivalToDeliveryLocalAt: '',
	pricingCalculation: '',
	pricingModel: '',
	cpoExpectedLocal: 0,
	rpoExpectedLocal: 0,
	cpoRealLocal: 0,
	rpoRealLocal: 0,
	fullFlightDistance: 0,
	distanceDrivenInMeters: 0,
	pickUpPoint: '',
	deliveryPoint: '',
};
