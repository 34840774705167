import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_ADDRESSES_FILTER, GET_COMPANY_NAME_FILTER, GET_STORE_NAME_FILTER } from 'api/invoices';
import { useInvoices } from 'hooks/useInvoices';
import { ICreditDebtFilter } from 'interfaces/filter';
import { IFilter } from 'interfaces/invoices';
import { TEST_IDS } from 'tests/config';
import { groupBy } from 'underscore';

import { Select } from 'components/FormControl';

import classes from './InvoiceFilters.module.scss';

const Filters = () => {
	const { t } = useTranslation(['accounts', 'orders']);
	const { filter, changeFilter } = useInvoices();
	const { loading: loadingCompanyNames, data: companyNames } = useQuery<ICreditDebtFilter>(GET_COMPANY_NAME_FILTER, {
		variables: {
			filterCase: 'COMPANY_NAME',
		},
	});
	const { loading: loadingStoreNames, data: storeNames } = useQuery<ICreditDebtFilter>(GET_STORE_NAME_FILTER, {
		variables: {
			filterCase: 'STORE_NAME',
		},
	});
	const { loading: loadingStoreAddress, data: storeAddress } = useQuery<ICreditDebtFilter>(GET_ADDRESSES_FILTER, {
		variables: {
			filterCase: 'ADDRESS',
		},
	});

	const handleChangeForm = (field: keyof IFilter) => (value: string) => {
		changeFilter(field, value);
		changeFilter('taxId', value);
	};

	const groupedStoreNames = useMemo(
		() => (storeNames?.creditDebtStoreFilter ? groupBy(storeNames.creditDebtStoreFilter, 'storeName') : {}),
		[storeNames?.creditDebtStoreFilter],
	);

	const handleChangeStoreName = (value: string) => {
		changeFilter('storeName', value);
		changeFilter('taxId', value ? groupedStoreNames[value].map((store) => store.taxId) : value);
	};

	return (
		<div className={classes.sectionFilters}>
			<Select
				onChange={handleChangeForm('companyName')}
				value={filter.companyName}
				emptyLabel={t('orders:filter.companyName.emptyLabel')}
				empty={!filter.companyName}
				rootClassName={classes.debtFilterItem}
				withSearch
				disabled={!companyNames?.creditDebtStoreFilter?.length || loadingCompanyNames || !!filter.storeName || !!filter.storeAddress}
				options={
					companyNames?.creditDebtStoreFilter?.map(({ taxId, companyName }, index) => ({
						value: taxId,
						label: companyName,
						additionalLabel: `${index}`,
					})) || []
				}
				testId={TEST_IDS.ACCOUNTS_FILTERS_DEBT_COMPANY_NAME}
			/>
			<Select
				onChange={handleChangeForm('storeAddress')}
				value={filter.storeAddress}
				emptyLabel={t('orders:filter.storeAddress.emptyLabel')}
				empty={!filter.storeAddress}
				rootClassName={classes.debtFilterItem}
				withSearch
				disabled={!storeAddress?.creditDebtStoreFilter?.length || loadingStoreAddress || !!filter.storeName || !!filter.companyName}
				options={
					storeAddress?.creditDebtStoreFilter?.map(({ taxId, address, storeAddressId }, index) => ({
						value: taxId,
						label: `${storeAddressId}, ${address}`,
						additionalLabel: `${index}`,
					})) || []
				}
				testId={TEST_IDS.ACCOUNTS_FILTERS_STORE_ADDRESS}
			/>
			<Select
				onChange={handleChangeStoreName}
				value={filter.storeName}
				emptyLabel={t('orders:filter.storeName.emptyLabel')}
				rootClassName={classes.debtFilterItem}
				empty={!filter.storeName}
				withSearch
				disabled={
					(!storeNames?.creditDebtStoreFilter?.length && !filter.storeName) || loadingStoreNames || !!filter.storeAddress || !!filter.companyName
				}
				options={
					Object.keys(groupedStoreNames)
						.map((storeName) => groupedStoreNames[storeName][0])
						.map(({ storeName }, index) => ({ value: storeName, label: storeName, additionalLabel: `${index}` })) || []
				}
				testId={TEST_IDS.ACCOUNTS_FILTERS_STORE_NAME}
			/>
		</div>
	);
};

export default Filters;
