import { IPagination } from 'interfaces';
import { IStoreAddress, IStoreAddressesFilter } from 'interfaces/storeAddress';

export const FETCH_STORE_ADRESSES_START = 'FETCH_STORE_ADRESSES_START';
export const FETCH_STORE_ADRESSES_SUCCESS = 'FETCH_STORE_ADRESSES_SUCCESS';
export const FETCH_STORE_ADRESSES_FAILURE = 'FETCH_STORE_ADRESSES_FAILURE';
export const CHANGE_STORE_ADRESSES_FILTER = 'CHANGE_STORE_ADRESSES_FILTER';
export const CLEAR_STORE_ADRESSES_FILTER = 'CLEAR_STORE_ADRESSES_FILTER';
export const SET_STORE_ADRESSES_PAGE = 'SET_STORE_ADRESSES_PAGE';
export const SET_STORE_ADRESSES_PAGE_SIZE = 'SET_STORE_ADRESSES_PAGE_SIZE';
export const SET_STORE_DATA = 'SET_STORE_DATA';

export interface IState {
	items: IStoreAddress[];
	filter: IStoreAddressesFilter;
	total: number;
	onload: boolean;
	wasLoaded: boolean;
	pagionation: IPagination;
}

export type IAction =
	| {
			type: typeof FETCH_STORE_ADRESSES_SUCCESS;
			payload: { items: IStoreAddress[]; total: number };
	  }
	| {
			type: typeof SET_STORE_ADRESSES_PAGE | typeof SET_STORE_ADRESSES_PAGE_SIZE;
			payload: number;
	  }
	| {
			type: typeof FETCH_STORE_ADRESSES_START | typeof FETCH_STORE_ADRESSES_FAILURE;
	  }
	| {
			type: typeof CHANGE_STORE_ADRESSES_FILTER;
			payload: { field: string; value: string | number };
	  }
	| {
			type: typeof SET_STORE_DATA;
			payload: IStoreAddress;
	  };
