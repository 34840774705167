import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetch } from 'api';
import { GLOVO_DOMAIN, GLOVO_ROLES, Routes } from 'const';
import clsx from 'helpers/clsx';
import { usePartnersFile } from 'hooks/usePartnersFile';
import { useUsers } from 'hooks/useUsers';
import { AccessData } from 'interfaces/partnersFile';
import { IUser } from 'interfaces/users';
import { columns } from 'pages/PartnersFile/columns';
import { ReactComponent as ArrowRightIcon } from 'static/images/arrow_right.svg';
import { ReactComponent as DeleteIcon } from 'static/images/deleteIcon.svg';
import { ReactComponent as PlusIcon } from 'static/images/plus.svg';
import { ReactComponent as SearchIcon } from 'static/images/search.svg';
import { isEqual } from 'underscore';

import { Input, Radio } from 'components/FormControl';
import Loader from 'components/Loader';
import MainButton from 'components/MainButton';
import Confirm from 'components/modals/Confirm';
import UserEmail from 'components/UserEmail';

import classes from './PartnersFilePermissions.module.scss';

const PartnersFilePermissions: React.FC = () => {
	const { t } = useTranslation('partners');
	const history = useHistory();
	const goBack = () => history.push(Routes.PARTNERS_FILE);
	const [activeTab, setActiveTab] = useState(false);
	const { filter: usersFilter, fetchUsers, changeFilter, onload, items, aborterRef, setAbortRef, resetFilter } = useUsers();
	const { fetchAccessData, accessData: accessDataState, loadingAccessData } = usePartnersFile();
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedEmail, setSelectedEmail] = useState<IUser['email']>('');
	const handleActive = (param: boolean) => () => setActiveTab(param);
	const [accessData, setAccessData] = useState<AccessData>(accessDataState);
	const [fields, setFields] = useState<AccessData['fields']>([]);
	const [findUser, setFindUser] = useState<string>('');

	const handleChangeAccess = (field: string) => () => {
		setFields(fields?.map((f) => (f.fieldName === field ? { ...f, adminAccess: !f.adminAccess } : f)));
	};

	const savePermissions = async () => {
		setLoading(true);
		try {
			const updatedFields = accessData?.fields
				.filter((oldField) => oldField.adminAccess !== fields.find((newField) => newField.fieldName === oldField.fieldName)?.adminAccess)
				.map((field) => ({ ...field, adminAccess: !field.adminAccess }));
			const { body }: { body: { message: string } } = await fetch('/partner/editFieldPermission', {
				auth: true,
				body: JSON.stringify(updatedFields),
				method: 'POST',
				headers: {
					'content-type': 'application/json',
				},
			});
			toast(t(body.message ? body.message : 'successAction'), { type: body.message ? 'error' : 'success' });
			if (!body.message) {
				return true;
			}
		} catch {
			toast(t('errors:somethingWentWrong'), { type: 'error' });
		} finally {
			setLoading(false);
		}
	};

	const deleteUser = (email: string) => async () => {
		setLoading(true);
		try {
			const { body }: { body: { message: string } } = await fetch(`/partner/removeEmail?email=${email}`, {
				auth: true,
				method: 'DELETE',
				headers: {
					'content-type': 'application/json',
				},
			});
			toast(t(body.message ? body.message : 'successAction'), { type: body.message ? 'error' : 'success' });
			if (!body.message) {
				const updatedUserList = accessData?.emails.filter((el: string) => el !== email);
				setAccessData({ ...accessData, emails: updatedUserList } as AccessData);
				return true;
			}
		} catch {
			toast(t('errors:somethingWentWrong'), { type: 'error' });
		} finally {
			setLoading(false);
		}
	};

	const addUser = async () => {
		setLoading(true);
		try {
			const { body }: { body: { message: string } } = await fetch('/partner/addEmail', {
				auth: true,
				body: JSON.stringify({ email: selectedEmail }),
				method: 'POST',
				headers: {
					'content-type': 'application/json',
				},
			});
			toast(t(body.message ? body.message : 'successAction'), { type: body.message ? 'error' : 'success' });
			if (!body.message) {
				const updatedUserList = [selectedEmail, ...accessData.emails];
				setAccessData({ ...accessData, emails: updatedUserList } as AccessData);
				resetFilter();
				setSelectedEmail('');
				return true;
			}
		} catch {
			toast(t('errors:somethingWentWrong'), { type: 'error' });
		} finally {
			setLoading(false);
		}
	};

	const handleCheckEmail = (email: IUser['email']) => () => setSelectedEmail(email);

	useEffect(fetchAccessData, []);

	useEffect(() => {
		if (usersFilter.storeAddressIdAndStoreNameAndEmail) {
			fetchUsers();
		}
	}, [usersFilter.storeAddressIdAndStoreNameAndEmail]);

	useEffect(() => {
		return () => resetFilter();
	}, []);

	useEffect(() => {
		if (accessDataState.fields.length) {
			setAccessData(accessDataState);
			setFields(accessDataState.fields);
		}
	}, [accessDataState]);

	const searchUsers = (value: string) => {
		setLoading(true);
		if (aborterRef && typeof setAbortRef === 'function') {
			aborterRef.abort();
			setAbortRef(new AbortController());
		}
		changeFilter('storeAddressIdAndStoreNameAndEmail', value);
		changeFilter('roles', GLOVO_ROLES);
		setLoading(false);
	};

	const searchExistUsers = (value: string) => setFindUser(value);

	const availableUsers = items.filter((u) => u.email.includes(GLOVO_DOMAIN) && !accessData?.emails.find((existUser) => existUser === u.email));

	return (
		<div className={classes.root}>
			{(loading || loadingAccessData) && <Loader />}
			<div className={classes.header}>
				<button className={classes.back_button} onClick={goBack}>
					<ArrowRightIcon className={classes.back_button_icon} /> {t('goBack')}
				</button>
				<h4>{t('manageTitle')}</h4>
			</div>
			<div className={classes.tabs}>
				<button onClick={handleActive(false)} className={clsx({ [classes.active]: !activeTab })}>
					{t('tabs.editAccess')}
				</button>
				<button onClick={handleActive(true)} className={clsx({ [classes.active]: activeTab })}>
					{t('tabs.users')}
				</button>
			</div>
			{activeTab ? (
				<div className={classes.users_wrap}>
					<div className={clsx(classes.actions, classes.top_actions, classes.flex)}>
						<Input
							delayOnChange={500}
							onChange={searchExistUsers}
							value={findUser}
							autoComplete="nope"
							placeholder={t('search')}
							Icon={SearchIcon}
							rootClassName={classes.search_user}
						/>
						<Confirm
							confirm={{
								onClick: addUser,
								text: t('confirm.save'),
								disabled: !selectedEmail,
							}}
							title={t('confirm.addUser.title')}
							body={
								<div className={classes.search}>
									<Input
										delayOnChange={500}
										onChange={searchUsers}
										value={usersFilter.storeAddressIdAndStoreNameAndEmail}
										autoComplete="nope"
										placeholder={t('search')}
										Icon={SearchIcon}
										rootClassName={classes.search_user}
									/>
									{usersFilter.storeAddressIdAndStoreNameAndEmail && onload ? (
										<Loader simple />
									) : (
										<ul className={classes.search_list}>
											{usersFilter.storeAddressIdAndStoreNameAndEmail &&
												availableUsers.map((user: IUser) => (
													<li key={user.id}>
														<Radio onChange={handleCheckEmail(user.email)} isChecked={selectedEmail === user.email}>
															{user.email}
														</Radio>
													</li>
												))}
										</ul>
									)}
								</div>
							}
						>
							<MainButton>
								<PlusIcon />
								{t('confirm.addUser')}
							</MainButton>
						</Confirm>
					</div>
					<div className={classes.users_table}>
						<table className={classes.users}>
							<thead>
								<tr>
									<th>{t('permissions.table.email')}</th>
									<th />
								</tr>
							</thead>
							<tbody>
								{!!accessData?.emails.length &&
									accessData?.emails
										.filter((email) => email.includes(findUser))
										.map((email) => (
											<React.Fragment key={email}>
												<tr className={classes.spacer}>
													<td colSpan={3}></td>
												</tr>
												<tr>
													<td title={email}>
														<UserEmail textClassName={clsx(classes.email, classes.ellipsis)}>{email}</UserEmail>
													</td>
													<td>
														<div className={clsx(classes.actions, classes.flex)}>
															<Confirm
																confirm={{
																	onClick: deleteUser(email),
																	text: t('confirm.delete'),
																}}
																title={t('confirm.title')}
																icon="delete"
															>
																<button className={classes.delete_button}>
																	<DeleteIcon color="#797A8D" />
																	{t('confirm.delete')}
																</button>
															</Confirm>
														</div>
													</td>
												</tr>
											</React.Fragment>
										))}
							</tbody>
						</table>
					</div>
				</div>
			) : (
				<div className={classes.table_wrap}>
					<table>
						<thead>
							<tr>
								<th>{t('permissions.tableTitle')}</th>
								<th>{t('permissions.onlyAdmin')}</th>
								<th>{t('permissions.allUsers')}</th>
							</tr>
						</thead>
						<tbody>
							{columns.map((column) => {
								if (column.disabled || !fields.find((f) => f.fieldName === column.field)) return null;
								const isChecked = fields.find((f) => f.fieldName === column.field)?.adminAccess;
								return (
									<tr key={column.field}>
										<td>{t(column.headerName)}</td>
										<td>
											<Radio isChecked={isChecked} onChange={handleChangeAccess(column.field)} />
										</td>
										<td>
											<Radio isChecked={!isChecked} onChange={handleChangeAccess(column.field)} />
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					<div className={classes.action}>
						<MainButton onClick={savePermissions} disabled={isEqual(fields, accessData?.fields)}>
							{t('permissions.save')}
						</MainButton>
					</div>
				</div>
			)}
		</div>
	);
};

export default PartnersFilePermissions;
