import { gql } from '@apollo/client';

export const GET_VCHASNO_DOCS = gql`
	query VchasnoDocs($page: Int, $pageSize: Int, $filter: VchasnoDocsFilter) {
		vchasnoDocs(page: $page, pageSize: $pageSize, filter: $filter) {
			vchasnoDocsItems {
				id
				title
				amount
				type
				category
				number
				edrpouOwners
				status
				pathToFile
				url
				direction
				extension
				date
				dateCreated
				source
				docId
				previewUrl
			}
			total
			totalPages
		}
	}
`;

export const GET_COUNT_VCHASNO_DOCS_UNSIGNED = gql`
	query VchasnoDocsUnsignedCount($dateFrom: Date) {
		vchasnoDocsUnsignedCount(dateFrom: $dateFrom)
	}
`;

export const GET_VCHASNO_DOCS_LAST_SYNC = gql`
	query VchasnoDocsLastSync {
		vchasnoDocsLastSync {
			date
		}
	}
`;

export const GET_VCHASNO_DOCS_STATUSES = gql`
	query VchasnoDocsStatuses($filter: VchasnoDocsFilter!) {
		vchasnoDocsStatuses(filter: $filter) {
			value
			title
		}
	}
`;

export const GET_VCHASNO_DOCS_CATEGORIES = gql`
	query VchasnoDocsCategories($filter: VchasnoDocsFilter!) {
		vchasnoDocsCategories(filter: $filter) {
			value
			title
		}
	}
`;

export const GET_VCHASNO_DOCS_COMPANIES = gql`
	query VchasnoDocsCompanies($filter: VchasnoDocsFilter!) {
		vchasnoDocsCompanies(filter: $filter) {
			ipn
			companyName
		}
	}
`;

export const GET_VCHASNO_DOCS_NUMBER = gql`
	query VchasnoNumbers($filter: VchasnoDocsFilter!) {
		vchasnoNumbers(filter: $filter) {
			number
		}
	}
`;

export const GET_VCHASNO_DOCS_STORES = gql`
	query VchasnoStoreAddress($filter: VchasnoDocsFilter!) {
		vchasnoStoreAddress(filter: $filter) {
			storeAddressId
			address
		}
	}
`;

export const GET_VCHASNO_DOCS_STATUSES_BY_ID = gql`
	query VchasnoDocsStatusesByIds($filter: VchasnoDocsStatusInput) {
		vchasnoDocsStatusesByIds(filter: $filter) {
			docId
			status
		}
	}
`;
