import { IPagination } from 'interfaces';
import { IUser, IUserFilter } from 'interfaces/users';

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const CHANGE_USERS_FILTER = 'CHANGE_USERS_FILTER';
export const CLEAR_USERS_FILTER = 'CLEAR_USERS_FILTER';
export const SET_USERS_PAGE = 'SET_USERS_PAGE';
export const SET_USERS_PAGE_SIZE = 'SET_USERS_PAGE_SIZE';
export const FETCH_CURRENT_USER_START = 'FETCH_CURRENT_USER_START';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_FAILURE = 'FETCH_CURRENT_USER_FAILURE';
export const DELETE_USER_BY_ID = 'DELETE_USER_BY_ID';
export const UPDATE_USER_BY_ID = 'UPDATE_USER_BY_ID';
export const RESET_USERS_FILTER = 'RESET_USERS_FILTER';

export interface IState {
	items: IUser[];
	filter: IUserFilter;
	total: number;
	onload: boolean;
	wasLoaded: boolean;
	pagionation: IPagination;
	current: {
		data?: IUser;
		loading: boolean;
	};
}

export type IAction =
	| {
			type: typeof FETCH_USERS_SUCCESS;
			payload: { users: IUser[]; total: number };
	  }
	| {
			type: typeof SET_USERS_PAGE | typeof SET_USERS_PAGE_SIZE;
			payload: number;
	  }
	| {
			type:
				| typeof FETCH_USERS_START
				| typeof FETCH_USERS_FAILURE
				| typeof FETCH_CURRENT_USER_START
				| typeof FETCH_CURRENT_USER_FAILURE
				| typeof RESET_USERS_FILTER;
	  }
	| {
			type: typeof CHANGE_USERS_FILTER;
			payload: { field: string; value: string | number };
	  }
	| {
			type: typeof FETCH_CURRENT_USER_SUCCESS | typeof UPDATE_USER_BY_ID;
			payload: IUser;
	  }
	| { type: typeof DELETE_USER_BY_ID; payload: string };
