import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGetStartedContext } from 'contexts/GetStarted';
import { getCoords, getScrollTop } from 'helpers';
import clsx from 'helpers/clsx';
import { useInterval } from 'hooks/useInterval';
import GetStartedImage from 'static/images/get-started.svg';
import { ReactComponent as InfoIcon } from 'static/images/info.svg';
import { ReactComponent as Arr } from 'static/images/keyboard_arrow_down.svg';
import { ReactComponent as Triangle } from 'static/images/triangle.svg';
import { TEST_IDS } from 'tests/config';

import GetStartedCommon from './Common';

import classes from './GetStarted.module.scss';

const GetStartedTemplate: React.FC = () => {
	const { currentStep, nextStep, prevStep, count, currentStepNumber, isLastStep, onClose } = useGetStartedContext();
	const [modal, setModal] = useState<React.ReactElement>();
	const [spotlights, setSpotlights] = useState<React.ReactElement | null>();
	const [refDiff, setRefDiff] = useState<HTMLElement | null>();
	const refBody = useRef<HTMLDivElement | null>(null);
	const { t } = useTranslation(['getStarted', 'common']);
	const runWatch = currentStep?.selectorDiff && !refDiff;
	const delay = runWatch ? 10 : null;
	useInterval(() => {
		const el: HTMLElement | null = currentStep?.selectorDiff ? currentStep.element.querySelector(currentStep.selectorDiff) : null;
		setRefDiff(el);
	}, delay);

	useEffect(() => {
		setRefDiff(null);
	}, [currentStep]);

	const isStartScreen = !currentStep;

	const NextButton: React.FC = ({ children }) => (
		<button className={classes.buttonNext} onClick={nextStep} data-testid={TEST_IDS.GET_STARTED_NEXT_BUTTON}>
			{children} <Arr />
		</button>
	);

	const PrevButton: React.FC = () => (
		<button className={classes.buttonNext} onClick={prevStep} data-testid={TEST_IDS.GET_STARTED_PREV_BUTTON}>
			<Arr style={{ rotate: '180deg' }} />
		</button>
	);

	const SkipButton: React.FC = ({ children }) => (
		<button className={classes.buttonSkip} onClick={onClose}>
			{children}
		</button>
	);

	const getStarted = (
		<div className={classes.startScreen} data-testid={TEST_IDS.GET_STARTED_GREET_SCREEN}>
			<img src={GetStartedImage} alt="" />
			<div className={clsx(classes.title, classes.alignCenter)}>
				<Trans t={t} i18nKey="title" />
			</div>
			<div className={classes.buttons}>
				<NextButton>{t('nextButtonStartText')}</NextButton>
				<SkipButton>{t('skipButtonText')}</SkipButton>
			</div>
		</div>
	);

	const renderStepBody = () => {
		return (
			<>
				<Triangle className={classes.triangle} />
				<div className={classes.body}>
					{currentStep && (
						<GetStartedCommon entity={currentStep.id} testId={`get-started-${currentStep.id.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()}`} />
					)}
					{currentStep?.sort && currentStep?.sort <= 6 && (
						<div className={clsx(classes.diff, classes.text)}>
							<InfoIcon color="#797A8D" />
							{t('diffInBannersDescription')}
						</div>
					)}
					<div className={classes.buttons}>
						{!isLastStep && <SkipButton>{t('closeButtonText')}</SkipButton>}
						<div className={classes.buttons_right}>
							{currentStepNumber >= 1 && <PrevButton />}
							<div className={classes.count}>
								{currentStepNumber + 1} {t('common:from')} {count}
							</div>
							<NextButton>{isLastStep ? t('nextButtonLastText') : t('nextButtonText')}</NextButton>
						</div>
					</div>
				</div>
			</>
		);
	};

	const Modal = (
		<div
			className={clsx(
				classes.modal,
				{ [classes[`modalType_${currentStep?.id}`]]: currentStep },
				{
					[classes[`modalPosition_${currentStep?.position}`]]: !isStartScreen,
				},
			)}
			ref={refBody}
		>
			{isStartScreen ? getStarted : renderStepBody()}
		</div>
	);

	const getModal = () => {
		if (currentStep?.selectorDiff) {
			if (!refDiff) return;
			const { top, left } = getCoords(refDiff);
			return (
				<div
					className={classes.modalRoot}
					style={{
						top,
						left,
						width: refDiff.clientWidth,
						height: refDiff.clientHeight,
						borderRadius: currentStep?.borderRadiusDiff,
					}}
				>
					{Modal}
				</div>
			);
		}

		if (currentStep?.element) {
			const { left, top } = getCoords(currentStep.element);
			const width = currentStep.element.clientWidth;
			const height = currentStep.element.clientHeight;
			const diffScroll = currentStep?.sticky ? getScrollTop() : 0;
			return (
				<div
					className={clsx(classes.modalRoot, { [classes.sticky]: currentStep?.sticky })}
					style={{
						width,
						height,
						top: top - diffScroll || 400,
						left,
						borderRadius: currentStep.borderRadius,
					}}
				>
					{Modal}
				</div>
			);
		}

		return Modal;
	};

	const renderSpotlight = () => {
		if (!currentStep?.element) return null;
		const { left, top } = getCoords(currentStep.element);
		const width = currentStep.element.clientWidth;
		const height = currentStep.element.clientHeight;
		const diffScroll = currentStep?.sticky ? getScrollTop() : 0;
		return (
			<div
				className={clsx(classes.spotlight, { [classes.sticky]: currentStep?.sticky })}
				style={{
					width,
					height,
					top: top - diffScroll,
					left,
					borderRadius: currentStep.borderRadius,
				}}
			/>
		);
	};

	const renderSpotlightAdditionally = () =>
		!isStartScreen &&
		currentStep?.additionally &&
		currentStep?.additionally.map((it, index) => {
			const { top, left } = getCoords(it.element);
			return (
				<div
					className={classes.spotlight}
					style={{
						top,
						left,
						width: it.element.clientWidth,
						height: it.element.clientHeight,
						borderRadius: it.borderRadius,
					}}
					key={index}
				/>
			);
		});

	const renderSpotlightDiffElement = () => {
		if (currentStep?.selectorDiff) {
			if (!refDiff) return null;
			const { top, left } = getCoords(refDiff);
			return (
				<div
					className={classes.spotlight}
					style={{
						top,
						left,
						width: refDiff.clientWidth,
						height: refDiff.clientHeight,
						borderRadius: currentStep?.borderRadiusDiff,
					}}
				/>
			);
		}
		return null;
	};

	const getSpotlights = () => {
		return (
			<>
				{renderSpotlight()}
				{renderSpotlightDiffElement()}
				{renderSpotlightAdditionally()}
			</>
		);
	};

	const renderBody = () => {
		setModal(getModal());
		setSpotlights(getSpotlights());
	};

	useEffect(() => {
		renderBody();
		window.addEventListener('resize', renderBody);
		window.addEventListener('scroll', renderBody);
		return () => {
			window.removeEventListener('resize', renderBody);
			window.removeEventListener('scroll', renderBody);
		};
	}, [currentStep, refDiff]);

	return (
		<div className={clsx(classes.root, { [classes.start]: isStartScreen })} data-testid={TEST_IDS.GET_STARTED_MODAL}>
			<div className={classes.overlay}>{spotlights}</div>
			<div className={classes.container}>{modal}</div>
		</div>
	);
};

export default GetStartedTemplate;
