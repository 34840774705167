import { useTranslation } from 'react-i18next';
import { Routes } from 'const';

import classes from './ToastDublicateAgreement.module.scss';

export const ToastDublicateAgreement = ({ data }: { data: { id: string; name: string } }) => {
	const { t } = useTranslation('contractGeneration');
	return (
		<div>
			{t('successDublicateAgreement')}
			<p>
				{t('successDublicateAgreementLink')}{' '}
				<a className={classes.link} href={Routes.DOCUMENT_PREVIEW.replace(':id', data.id)}>
					{data.name}
				</a>
			</p>
		</div>
	);
};
