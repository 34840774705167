export interface IField {
	id: string;
	title: string;
	render: boolean;
}

export enum Field {
	Date = 'date',
	NameDocument = 'nameDocument',
	Type = 'type',
	Sum = 'sum',
	Partner = 'partner',
	DocumentNo = 'documentNo',
	CodeCompany = 'codeCompany',
	Status = 'status',
	StatusPay = 'statusPay',
	LinkToVchasno = 'linkToVchasno',
}
