import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IFilter } from 'interfaces/document';
import { useSelector } from 'store';
import {
	changeDocumentsFilter,
	clearDocumentsFilter,
	fetchDocuments as fetchDocumentsAction,
	setDocumentsPage,
	setDocumentsPageSize,
} from 'store/documents/actions';

import { IDocument } from 'components/DocumentsListItem/types';

import { useUser } from './useUser';

import statusClasses from '../components/DocumentsListItem/DocumentListItem.module.scss';

export interface IUseDocuments {
	items: IDocument[];
	filter: IFilter;
	total: number;
	onload: boolean;
	wasLoaded: boolean;
	loadingTotals: boolean;
	pagination: {
		page: number;
		pageSize: number;
	};
	fetchDocuments(filterArg?: { contractNumber: string }): void;
	clearFilter(): void;
	setPage(page: number): void;
	setPageSize(pageSize: number): void;
	changeFilter(field: keyof IFilter, value: IFilter[typeof field]): void;
	getStatusText(status: string): string;
	getStatusStyle: { [key: string]: string };
	aborterRef: AbortController;
}

export const useDocuments = (): IUseDocuments => {
	const { items, filter, pagination, total, onload, wasLoaded, loadingTotals } = useSelector((state) => state.documents);
	const dispatch = useDispatch();
	const { isServiceUser } = useUser();
	const { t } = useTranslation('document');
	const [aborterRef, setAbortRef] = useState(new AbortController());

	const fetchDocuments = (filterArg: { contractNumber: string }) => {
		return dispatch(fetchDocumentsAction(filterArg || filter, pagination, aborterRef));
	};

	const aboartRequest = () => {
		if (aborterRef && typeof setAbortRef === 'function') {
			aborterRef.abort();
			setAbortRef(new AbortController());
		}
	};

	const clearFilter = () => {
		aboartRequest();
		dispatch(clearDocumentsFilter());
	};

	const setPage = (page: number) => {
		dispatch(setDocumentsPage(page));
	};

	const setPageSize = (page: number) => {
		dispatch(setDocumentsPageSize(page));
	};

	const changeFilter = (field: keyof IFilter, value: IFilter[typeof field]) => {
		aboartRequest();
		dispatch(changeDocumentsFilter(field, value));
	};

	const getStatusText = (code: string) => {
		if (code) return isServiceUser ? t(`statuses.${code}`) : t(`statusesPartner.${code}`);
		return '';
	};

	const getStatusStyle = {
		'7000': statusClasses.statusBlockUpload,
		'7001': isServiceUser ? statusClasses.statusBlockWait : statusClasses.statusBlockWaitUserSign,
		'7002': isServiceUser ? statusClasses.statusBlockWaitUserSign : statusClasses.statusBlockWait,
		'7003': isServiceUser ? statusClasses.statusBlockWait : statusClasses.statusBlockWaitUserSign,
		'7004': isServiceUser ? statusClasses.statusBlockWaitUserSign : statusClasses.statusBlockWait,
		'7006': statusClasses.statusBlockDocumentDeflected,
		'7007': statusClasses.statusBlockWaitUserSign,
		'7008': statusClasses.statusBlockAllsign,
		'7010': statusClasses.statusBlockSended,
		'8001': statusClasses.statusBlockDocumentDeflected,
	};

	return {
		fetchDocuments,
		items,
		total,
		onload,
		wasLoaded,
		loadingTotals,
		clearFilter,
		setPage,
		setPageSize,
		changeFilter,
		pagination,
		filter,
		getStatusText,
		getStatusStyle,
		aborterRef,
	};
};
