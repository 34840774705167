import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'helpers/clsx';
import { ReactComponent as PlusIcon } from 'static/images/plus.svg';

import MainButton from 'components/MainButton';

import Template from './template';
import { IProps } from './types';

import classes from './CreateEditCustomer.module.scss';

const CreateEditCustomer: React.FC<IProps> = ({ children, beforeClick, ...props }) => {
	const [open, setOpen] = useState<boolean>(false);
	const { t } = useTranslation(['storeAddresses']);

	const onClose = () => {
		setOpen(false);
	};

	const onOpen = () => {
		setOpen(true);
	};

	const handleClick = () => {
		beforeClick?.();
		onOpen();
	};

	const childrenClone = React.Children.map(children, (child) => {
		return (
			React.isValidElement(child) &&
			React.cloneElement(child as React.ReactElement, {
				onClick: handleClick,
			})
		);
	});

	return (
		<>
			{childrenClone || (
				<MainButton className={clsx(classes.flex, classes.alignItemsCenter)} onClick={handleClick}>
					<PlusIcon className={classes.addIcon} /> {t('create/edit.add')}
				</MainButton>
			)}
			{open && <Template {...props} onClose={onClose} />}
		</>
	);
};

export default CreateEditCustomer;
