import { format } from 'date-fns';
import { IDebt, IFilter, IInvoices, IPayment } from 'interfaces/invoices';

export const middlewareInvoices = (invoices: IInvoices[]) => {
	return invoices.map((inv) => ({
		...inv,
		totalAmount: Number(inv.totalAmount),
		paidAmount: Number(inv.paidAmount),
		residualAmount: Number(inv.residualAmount),
		children: middlewarePayments(inv.children),
	}));
};

export const middlewarePayments = (payments: IPayment[]) => {
	return payments.map((payment) => ({
		...payment,
		totalAmount: Number(payment.totalAmount),
	}));
};

export const middlewareDebt = (debts: IDebt[]) => {
	const sortedDebts = [...debts]
		.sort((a: IDebt, b: IDebt) => (a.edrpou > b.edrpou ? 1 : a.edrpou < b.edrpou ? -1 : 0))
		.sort((a: IDebt, b: IDebt) => (a.contractNumber > b.contractNumber ? 1 : a.contractNumber < b.contractNumber ? -1 : 0));
	const groupedDebts = sortedDebts.reduce((acc: IDebt[], debt: IDebt) => {
		const prevEl: IDebt = acc[acc.length - 1];
		const equalCondition = acc.length && prevEl.edrpou === debt.edrpou && prevEl.contractNumber === debt.contractNumber;
		if (equalCondition) return acc;
		const childElems = sortedDebts.filter(
			(it: IDebt) => it.edrpou === debt.edrpou && it.contractNumber === debt.contractNumber && it.documentDate && it.documentName,
		);
		const result = getCalculatedSum(childElems);
		return [...acc, { ...debt, children: childElems, ...result }];
	}, []);
	return groupedDebts;
};

export const normalizeFilter = (filter: IFilter, execute: keyof IFilter) => {
	return {
		...filter,
		startDate: format(filter.startDate, 'yyyy-MM-dd'),
		endDate: format(filter.endDate, 'yyyy-MM-dd'),
		edrpou: filter.edrpou ? filter.edrpou.split(' ') : filter.taxId,
		companyName: undefined,
		storeAddress: undefined,
		storeName: undefined,
		taxId: undefined,
		[execute]: undefined,
	};
};

export const normalizeSingleDate = (date: Date) => {
	return format(date, 'yyyy-MM-dd');
};

export const normalizeInvoiceInput = ({ date, taxId }: { date: Date; taxId?: string[] }) => {
	return {
		date: format(date, 'yyyy-MM-dd'),
		edrpou: taxId,
	};
};

export const getCalculatedSum = (debtGroup: IDebt[]) => {
	const keys: ('expiredDebtAmount' | 'unexpiredDebtAmount' | 'totalDebtAmount')[] = ['expiredDebtAmount', 'unexpiredDebtAmount', 'totalDebtAmount'];
	return keys.reduce((obj, key) => ({ ...obj, [key]: debtGroup.reduce((acc: number, debt: IDebt) => acc + debt[key], 0) }), {});
};
