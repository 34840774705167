import React from 'react';
import clsx from 'helpers/clsx';

import { IPropsRadio } from './types';

import classes from './FormControl.module.scss';

const Radio: React.FC<IPropsRadio> = ({ rootClassName, textClassName, isChecked, onChange, children }) => {
	return (
		<div className={clsx(classes.radio, rootClassName)} onClick={onChange}>
			<div
				className={clsx(classes.radioIcon, {
					[classes.radioIcon_active]: isChecked,
				})}
			/>
			<div className={clsx(classes.radioText, textClassName)}>{children}</div>
		</div>
	);
};

export default Radio;
