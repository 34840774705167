import { gql } from '@apollo/client';

export const GET_POWER_OUTAGES = gql`
	query StoreAddressesBot($pageNumber: Int, $pageSize: Int, $filter: StoreAddressBotFilter) {
		storeAddressesBot(pageNumber: $pageNumber, pageSize: $pageSize, filter: $filter) {
			storeAddressBots {
				storeAddressId
				storeName
				address
				hash
			}
			total
			totalPages
		}
	}
`;
