import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'helpers';
import clsx from 'helpers/clsx';
import { getCurrency } from 'helpers/jwt';
import { ReactComponent as Icon } from 'static/images/list.svg';

import Popover from 'components/Popover';

import { IProps } from './types';

import classes from './ProductList.module.scss';

const ProductList: React.FC<IProps> = ({ children, rootClassName, items, total }) => {
	const { t } = useTranslation('orders');

	const CurrencyIcon = getCurrency();

	const renderPrice = (price: number) => (
		<div className={classes.price}>
			{CurrencyIcon && <CurrencyIcon />}
			{formatPrice(price, 2)}
		</div>
	);

	const bodyPopover = (
		<>
			<div className={classes.dropdownRow}>
				<div className={classes.dropdownTitle}>{t('productList.title')}</div>
				<div className={classes.products}>
					{items.map((item) => (
						<div className={classes.productItem} key={item}>
							<div className={classes.productItemMain}>
								<div className={classes.productItemName}>{item}</div>
							</div>
						</div>
					))}
				</div>
			</div>
			<div className={clsx(classes.dropdownRow, classes.total)}>
				<div className={classes.dropdownTitle}>{t('productList.total')}:</div>
				{renderPrice(total)}
			</div>
		</>
	);

	return (
		<div className={clsx(classes.root, rootClassName)}>
			<Popover body={bodyPopover} placement="bottom" rootClassName={classes.popoverRoot} popoverClassName={classes.dropdownModal} withOverlay>
				<button className={classes.button}>
					<Icon className={classes.icon} />
					{children}
				</button>
			</Popover>
		</div>
	);
};

export default ProductList;
