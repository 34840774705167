import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import clsx from 'helpers/clsx';
import { useDocuments } from 'hooks/useDocuments';
import { ReactComponent as LinkIcon } from 'static/images/target_link.svg';

import EmptyResult from 'components/EmptyResult';
import Loader from 'components/Loader';
import LoadMore from 'components/LoadMore';
import Modal from 'components/modals/Modal';
import { LightTooltip } from 'components/StyledLightTooltip';
import { StyledTableCell } from 'components/StyledTableCell';

import { IPropsTemplate } from './types';

import classes from './AllDocuments.module.scss';

const AllDocuments: React.FC<IPropsTemplate> = ({ onClose, contractNumber }) => {
	const { t } = useTranslation(['document', 'partners']);
	const { items, fetchDocuments, onload, wasLoaded, getStatusText, setPage, getStatusStyle, total, pagination, loadingTotals } = useDocuments();
	const [currentContractNumber, setCurrentContract] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (contractNumber) {
			setLoading(true);
			setPage(0);
			setCurrentContract(contractNumber);
		}
	}, [contractNumber]);

	useEffect(() => {
		if (contractNumber === currentContractNumber) {
			fetchDocuments({ contractNumber });
		}
	}, [pagination.page, currentContractNumber]);

	useEffect(() => {
		if (loading && !loadingTotals && contractNumber === currentContractNumber) {
			setLoading(loadingTotals);
		}
	}, [loadingTotals, currentContractNumber, contractNumber, loading]);

	const loadMore = () => {
		setPage(pagination.page + 1);
	};

	const start = pagination.page * pagination.pageSize + 1;
	const end = start + pagination.pageSize - 1;
	const canLoadMore = end < total;

	return (
		<Modal
			open
			modalClassName={classes.modal}
			title={t('document') + ` ${contractNumber}`}
			onClose={onClose}
			rootClassName={classes.container}
			titleClassName={classes.title}
			closeButtonClassName={classes.closeButton}
			bodyClassName={classes.body}
		>
			{(onload && wasLoaded && !loadingTotals) || (onload && !wasLoaded) || loading ? (
				<Loader simple />
			) : items.length > 0 ? (
				<TableContainer
					component={Paper}
					sx={{
						borderRadius: '20px',
						maxHeight: '80vh',
					}}
				>
					<Table stickyHeader size="small" sx={{ tableLayout: 'fixed' }}>
						<TableHead>
							<TableRow>
								<StyledTableCell width={180} size="small">
									{t('fields.nameDocument')}
								</StyledTableCell>
								<StyledTableCell width={120}>{t('fields.type')}</StyledTableCell>
								<StyledTableCell width={180}>{t('fields.contractLink')}</StyledTableCell>
								<StyledTableCell width={160}>{t('fields.status')}</StyledTableCell>
								<StyledTableCell width={90}>{t('fields.date')}</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{items.map((doc) => (
								<TableRow key={doc.id}>
									<StyledTableCell width={180} size="small">
										{doc.title.length > 20 ? (
											<LightTooltip title={doc.title} placement="right" arrow>
												<div>{doc.title.slice(0, 20)}...</div>
											</LightTooltip>
										) : (
											doc.title
										)}
									</StyledTableCell>
									<StyledTableCell width={120}>
										{doc.category.length > 13 ? (
											<LightTooltip title={doc.category} placement="right" arrow>
												<div>{doc.category.slice(0, 13)}...</div>
											</LightTooltip>
										) : (
											doc.category
										)}
									</StyledTableCell>
									<StyledTableCell width={180}>
										{doc.url && (
											<div className={classes.link_wrapper}>
												<LightTooltip title={doc.url} placement="right" arrow>
													<div>{doc.url.slice(0, 20)}...</div>
												</LightTooltip>
												<a href={doc.url} target="_blank" rel="noreferrer">
													<LinkIcon color="#797A8D" />
												</a>
											</div>
										)}
									</StyledTableCell>
									<StyledTableCell width={160}>
										<div className={clsx(classes.statusText, getStatusStyle[doc.status as keyof typeof getStatusStyle])}>
											{getStatusText(doc.status)}
										</div>
									</StyledTableCell>
									<StyledTableCell width={90}>{doc.date}</StyledTableCell>
								</TableRow>
							))}
							<TableRow>
								<StyledTableCell colSpan={5}>
									{!!items?.length && canLoadMore && !(onload && wasLoaded && loadingTotals) && (
										<LoadMore onClick={loadMore} className={classes.loadMore} />
									)}
									{onload && wasLoaded && loadingTotals && <Loader simple />}
								</StyledTableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<EmptyResult>{t('partners:emptyList')}</EmptyResult>
			)}
		</Modal>
	);
};

export default AllDocuments;
