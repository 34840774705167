import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'helpers/clsx';
import { useLaaSOrders } from 'hooks/useLaaSOrders';
import { useUser } from 'hooks/useUser';
import { Direction, OrderSorting } from 'interfaces/order';
import { ReactComponent as Arr } from 'static/images/keyboard_arrow_down.svg';
import { TEST_IDS } from 'tests/config';

import { IFild } from './types';

import classes from './OrdersContainer.module.scss';

const OrdersTableHead: React.FC = () => {
	const { changeSorting, items, filter } = useLaaSOrders();
	const { t } = useTranslation('orders');
	const { isCustomer } = useUser();

	const fields: IFild[] = [
		{
			id: 'orderCreatedLocalAt',
			title: t('fields.date'),
			render: true,
			className: classes.dateCell,
		},
		{
			id: 'orderCourierArrivalToDeliveryLocalAt',
			title: t('fields.orderCourierArrivalToDeliveryLocalAt'),
			render: true,
		},
		{
			id: 'cityCode',
			title: t('fields.cityCode'),
			render: true,
		},
		{
			id: 'orderId',
			title: t('fields.orderId'),
			render: true,
			className: classes.pointCodeCell,
		},
		{
			id: 'customerId',
			title: t('fields.customerId'),
			render: isCustomer,
			className: classes.pointCodeCell,
		},
		{
			id: 'orderCode',
			title: t('fields.code'),
			render: !isCustomer,
			className: classes.idCell,
		},
		{
			id: 'trackingNumber',
			title: t('fields.trackingNumber'),
			render: !isCustomer,
			className: classes.idCell,
		},
		{
			id: 'name',
			title: t('fields.name'),
			render: true,
		},
		{
			id: 'companyName',
			title: t('fields.companyName'),
			render: true,
		},
		{
			id: 'taxId',
			title: t('fields.taxId'),
			render: true,
			className: classes.taxId,
		},
		{
			id: 'pickUpPoint',
			title: t('fields.pickUpPoint'),
			render: true,
		},
		{
			id: 'deliveryPoint',
			title: t('fields.deliveryPoint'),
			render: true,
		},
		{
			id: 'kmTier',
			title: t('fields.kmTier'),
			render: isCustomer,
		},
		{
			id: 'rpoRealLocal',
			title: t('fields.rpoRealLocal'),
			render: true,
		},
		{
			id: 'fullFlightDistance',
			title: t('fields.fullFlightDistance'),
			render: !isCustomer,
		},
		{
			id: 'distanceDrivenInMeters',
			title: t('fields.distanceDrivenInMeters'),
			render: !isCustomer,
		},
		{
			id: 'finalStatus',
			title: t('fields.finalStatus'),
			render: true,
		},
		{
			id: 'cancelReason',
			title: t('fields.cancelReason'),
			render: true,
		},
		{
			id: 'partnerResolution',
			title: t('fields.partnerResolution'),
			render: true,
		},
		{
			id: 'chargedToPartner',
			title: t('fields.chargedToPartner'),
			render: true,
			className: classes.alignRight,
			sorting: OrderSorting.CHARGED_TO_PARTNER,
		},
	];

	const handleChangeSorting = (sortBy: OrderSorting) => () => changeSorting(sortBy);

	return (
		<thead data-testid={TEST_IDS.ORDERS_TABLE_HEAD}>
			<tr className={classes.sticky}>
				{fields.map((it) => {
					if (!it.render) return null;
					const activeSort = filter.sorting?.sortBy && filter.sorting.sortBy === it.sorting;
					const canSort = it.sorting && items.length;
					const reverseSort = activeSort && filter.sorting?.direction === Direction.DESC;
					return (
						<td
							key={it.id}
							className={clsx(it.className, {
								[classes.canSort]: canSort,
								[classes.activeSort]: activeSort,
								[classes.activeSort_reverse]: activeSort && filter.sorting?.direction === Direction.DESC,
							})}
							onClick={it.sorting && items.length ? handleChangeSorting(it.sorting) : undefined}
							data-testid={clsx({
								[TEST_IDS.ORDERS_TABLE_HEAD_SORT_CELL]: canSort,
								[TEST_IDS.ORDERS_TABLE_HEAD_ACTIVE_SORT_CELL]: activeSort,
								[TEST_IDS.ORDERS_TABLE_HEAD_ACTIVE_SORT_REVERSE_CELL]: reverseSort,
							})}
						>
							<div data-testid={it?.id}>
								{it.title.split('\n').map((h) => (
									<div key={h}>{h}</div>
								))}
								{activeSort && <Arr />}
							</div>
						</td>
					);
				})}
			</tr>
		</thead>
	);
};

export default OrdersTableHead;
