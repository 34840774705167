import React from 'react';
import { useQuery } from 'hooks/useQuery';
import SystemLayout from 'layouts/system';
import ServiceNotification from 'pages/ServiceNotification';

const Maintenance: React.FC = ({ children }) => {
	const query = useQuery();
	if (process.env.REACT_APP_SHOW_MAINTENANCE === 'true' && query.get('hideMaintenance') !== 'true') {
		return (
			<SystemLayout>
				<ServiceNotification />
			</SystemLayout>
		);
	}
	return <>{children}</>;
};

export default Maintenance;
