import { gql } from '@apollo/client';

export default gql`
	type ProductOrder {
		name: String
		count: Int
		amount: Float
		additionally: String
	}

	type StoreAddressDto {
		id: ID
		address: String
	}

	type OrderDtoPage {
		orderDtos: [OrderDto]
		total: Int
		totalPages: Int
	}

	type DateRange {
		min: String
		max: String
	}

	type Totals {
		chargedToPartnerTotal: Float
		commissionTotal: Float
		orderTotal: Float
		paidCashTotal: Float
		productDiscountAssumedByPartnerTotal: Float
		productDiscountAssumedByPartnerTotalWithVat: Float
		productDiscountAssumedByPartnerTotalVat: Float
		deliveryDiscountAssumedByPartnerTotal: Float
		deliveryDiscountAssumedByPartnerTotalWithVat: Float
		deliveryDiscountAssumedByPartnerTotalVat: Float
		deliveryFeeTotal: Float
		deliveryDiscountTotal: Float
		partnerPaymentTotal: Float
		commissionTotalVat: Float
		commissionTotalWithVat: Float
		additionalCommissionWithoutVat: Float
		productDiscount: Float
		disputeAmount: Float
	}

	type OrderApprovalDto {
		orderId: Long!
		approvedAt: String!
		approvedBy: Int!
		reason: String!
		amendmentId: Int!
	}

	type Dispute {
		orderId: Long
		storeAddressId: Long
		storeId: Long
		storeName: String
		type: String
		reason: String
		amount: BigDecimal
		currency: String
		orderActivationLocalDate: Date
		orderCountryCode: String
		orderCityCode: String
		orderCode: String
		disputeCreationDate: Date
		partnerCommissionPercentage: BigDecimal
	}

	type OrderDto {
		id: ID!
		activationTime: String
		dispatchingTime: String
		pickUpTime: String
		storeAddressId: Int
		cityCode: String
		countryCode: String
		storeName: String
		details: String
		companyName: String
		taxId: String
		orders: [String]
		code: String
		priceMinor: Float
		estimatedPrice: Float
		purchase: Float
		amount: Float
		promotionAssumedByPartner: Float
		partnerCommissionPercentage: Float
		cancellationStrategy: String
		finalStatus: String
		cancelReason: String
		partnerResolution: String
		chargedToPartner: Float
		paymentMethod: String
		paymentMethod2: String
		orderImportId: String
		createdAt: String
		createdBy: String
		orderStatus: OrderStatus
		changeReasonCode: Int
		otherChangeReason: String
		lastAmendmentId: String
		origStoreName: String
		origCompanyName: String
		origTaxId: String
		origPartnerCommissionPercentage: Float
		address: String
		hasComments: Boolean
		originAmount: Float
		originChargedToPartner: Float
		originPaymentMethod: String
		productDiscountAssumedByPartner: Float
		deliveryDiscountAssumedByPartner: Float
		deliveryFee: Float
		isMarketplaceOrder: Boolean
		partnerPayment: Float
		deliveryPromotionAssumedByPartner: Float
		receivedFunds: Float
		originDeliveryFee: Float
		originReceivedFunds: Float
		originFinalStatus: String
		expectedPaymentDate: Date
		lastPaymentDate: Date
		lastDiffInputDate: Date
		startPeriodDate: Date
		endPeriodDate: Date
		disputes: [Dispute]
	}
`;
