import { clientGraphql } from 'api';
import { GET_VCHASNO_DOCS } from 'api/vchasnoDocs';
import { normalizeFilter } from 'helpers/document';
import { IFilter } from 'interfaces/document';
import { Dispatch } from 'redux';

import { IDocument } from 'components/DocumentsListItem/types';

import {
	CHANGE_DOCUMENTS_FILTER,
	CLEAR_DOCUMENTS_FILTER,
	FETCH_DOCUMENTS_FAILURE,
	FETCH_DOCUMENTS_START,
	FETCH_DOCUMENTS_SUCCESS,
	SET_DOCUMENTS_PAGE,
	SET_DOCUMENTS_PAGE_SIZE,
} from './types';

export const fetchDocuments =
	(
		filter: { contractNumber: string; startDate?: Date } | IFilter,
		{ page, pageSize }: { page: number; pageSize: number },
		aborterRef: AbortController,
	) =>
	(dispatch: Dispatch): Promise<IDocument | undefined> => {
		dispatch({ type: FETCH_DOCUMENTS_START });
		return clientGraphql
			.query({
				query: GET_VCHASNO_DOCS,
				variables: { page, pageSize, filter: filter.startDate ? normalizeFilter(filter as IFilter) : filter },
				context: {
					fetchOptions: {
						signal: aborterRef.signal,
					},
				},
			})
			.then((res) => {
				dispatch({
					payload: {
						documents: res.data.vchasnoDocs.vchasnoDocsItems,
						total: res.data.vchasnoDocs.total,
					},
					type: FETCH_DOCUMENTS_SUCCESS,
				});
				return res.data;
			})
			.catch(() => {
				dispatch({ type: FETCH_DOCUMENTS_FAILURE });
				return undefined;
			});
	};

export const setDocumentsPage = (payload: number) => ({
	payload,
	type: SET_DOCUMENTS_PAGE,
});

export const setDocumentsPageSize = (payload: number) => ({
	payload,
	type: SET_DOCUMENTS_PAGE_SIZE,
});

export const changeDocumentsFilter = (field: keyof IFilter, value: IFilter[typeof field]) => ({
	payload: { field, value },
	type: CHANGE_DOCUMENTS_FILTER,
});

export const clearDocumentsFilter = () => ({
	payload: null,
	type: CLEAR_DOCUMENTS_FILTER,
});
