export interface ITemplate {
	id: string;
	title: string;
}

export interface IQuestionDTO {
	template: string;
	question?: IQuestionBody;
	agreementId: string;
	finish: boolean;
	addZeroBookmark?: boolean;
	createdAt?: string;
}

export interface IAnswer {
	key: string;
	value: string;
	selected: boolean;
	lastAnswer: boolean;
	explain?: string;
}

export interface IQuestionBody {
	type: string;
	questionNumber: number;
	headerDto: {
		header: string;
		explain?: string;
	};
	questionBody: IAnswer[];
}

export interface VchasnoSync {
	createdAt: string;
	firstSignBy: string;
	senderEmail: string;
	status: string;
	url: string;
	docId: string;
}

export interface IContract {
	agreementId: string;
	agreementName: string;
	agreementStatus: string;
	createdAt: string;
	createdBy: string;
	vchasnoSync?: VchasnoSync;
	canConfigure: boolean;
}

export interface IContractFilter {
	search?: string;
	startDate?: Date;
	endDate?: Date;
	taxId?: string;
	status?: string[];
	group?: string[];
}

export interface IAgreementInput {
	defaultValue: boolean;
	type: string;
	width: string;
	label: string;
	priority: number;
	subLabel: string;
	value: string;
	varName: string;
	configuration?: {
		tableNumber?: number;
		rowNumber?: number;
		priority: number;
		subtype: string;
		tinGroup: string;
		tinType: string;
		min?: string;
		max?: string;
		pattern?: RegExp;
	};
}

export interface IAgreement {
	agreementId: string;
	agreementName: string;
	agreementStatus: string;
	configurationDto?: {
		contractType: string;
		debtStatus: string;
		department: string;
		hasOutstandingPeriods: string;
		hasTIN: string;
		isVatPayer: string;
		partnerType: string;
		representative: string;
		signatoryGlovo: string;
		templateCase: string;
	};
	createdAt: string;
	createdBy: string;
	placeholderMap: {
		[x: string]: IAgreementInput;
	};
	templateCase: string;
	vchasnoSync?: VchasnoSync;
	canConfigure?: boolean;
}

export enum AgreementStatus {
	FILLED = 'FILLED',
	CONFIGURED = 'CONFIGURED',
	PARTIALLY_FILLED = 'PARTIALLY_FILLED',
	CANCELLED = 'CANCELLED',
	DELETED_FROM_VCHASNO = 'DELETED_FROM_VCHASNO',
	DELETED_FROM_VCHASNO_SYNC = '8001',
}

export interface IAgreementCompany {
	taxId: string;
	partnerName: string;
}

export interface Filters {
	partners: IAgreementCompany[];
	statuses: { status: string; type: 'VCHASNO' | 'AGREEMENT' }[];
	groups: { [key: string]: string };
}

export interface IOpenDataBot {
	[key: string]: string;
	status: string;
}

export interface WorkTime {
	open: string;
	close: string;
}

export interface WorkHoursDay {
	before: WorkTime;
	after?: WorkTime;
}

export interface WorkHours {
	[key: string]: WorkHoursDay;
}

export interface WorkTimeError {
	open?: boolean;
	close?: boolean;
}

export interface WorkHoursDayError {
	before: WorkTimeError;
	after?: WorkTimeError;
}

export interface WorkHoursErrors {
	[key: string]: WorkHoursDayError;
}

export interface IVchasnoForm {
	agreementId: string;
	edrpouOwner: string;
	recipients: {
		email?: string;
		edrpou: string;
	}[];
	documentDate: string;
	firstSignBy?: string;
	documentNumber: string;
}

export interface IVchasnoResponse {
	syncStatus: string;
	createdAt: string;
	reason?: string;
	url: string;
	key?: string;
}

export interface VchasnoStatusesById {
	docId: string;
	status: string;
}

export type IPStores = { [key: number]: IAgreementInput[] } | [];
