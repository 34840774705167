import React from 'react';
import clsx from 'helpers/clsx';

import { IProps } from './types';

import classes from './Nowrap.module.scss';

const Nowrap: React.FC<IProps> = ({ className, children }) => {
	return <div className={clsx(classes.root, className)}>{children}</div>;
};

export default Nowrap;
