import { FC } from 'react';
import { ReactComponent as CloseIcon } from 'static/images/close.svg';
import { TEST_IDS } from 'tests/config';

import { IListItemProps } from './types';

import classes from './CreateEditUser.module.scss';

const ListItem: FC<IListItemProps> = ({ storeAddressId, storeName, address, onSelect, onRemove }) => {
	return (
		<div onMouseDown={onSelect} className={classes.option} data-testid={TEST_IDS.LIST_ITEM_OPTION}>
			<div className={classes.option_headline}>
				<div>{storeAddressId}</div>
				<div className={classes.option_storeName}>{storeName}</div>
				<div onClick={onRemove} className={classes.option_close} data-testid={TEST_IDS.REMOVE_LIST_ITEM}>
					<CloseIcon />
				</div>
			</div>
			<div className={classes.option_address}>{address}</div>
		</div>
	);
};
export default ListItem;
