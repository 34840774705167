import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_VCHASNO_DOCS_LAST_SYNC } from 'api/vchasnoDocs';
import { format } from 'date-fns';
import clsx from 'helpers/clsx';
import { ReactComponent as Synchronization } from 'static/images/synchronization.svg';

import classes from './VchasnoSync.module.scss';

export const VchasnoSync = ({ className }: { className?: string }) => {
	const { t } = useTranslation('document');
	const { data: dataLastSync } = useQuery<{ vchasnoDocsLastSync: { date: string } }>(GET_VCHASNO_DOCS_LAST_SYNC);

	return (
		<div className={clsx(classes.synchro, className)}>
			<Synchronization />
			<div className={classes.synchro_text}>{t('synchronization')}</div>
			<div className={classes.synchro_date}>
				{dataLastSync?.vchasnoDocsLastSync.date && format(new Date(dataLastSync?.vchasnoDocsLastSync.date), 'dd.MM.yyyy HH:mm')}
			</div>
		</div>
	);
};
