import { fetch } from 'api';

import { useUser } from './useUser';

interface IUseFeedback {
	sendMessage(email: string): Promise<unknown>;
}

export const useFeedback = (): IUseFeedback => {
	const { user } = useUser();

	const sendMessage = (message: string) => {
		return fetch(
			'/slack-sender/feedbackFintool/',
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					message: message.trim(),
					email: user?.email,
				}),
				feedback: true,
			},
			false,
		);
	};

	return {
		sendMessage,
	};
};
