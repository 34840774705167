import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Template from './template';
import { IProps } from './types';

import classes from './Commission.module.scss';

const Commission: React.FC<IProps> = (props) => {
	const [open, setOpen] = useState<boolean>(false);
	const { t } = useTranslation(['partners']);

	const onClose = () => {
		setOpen(false);
	};

	const onOpen = () => {
		setOpen(true);
	};

	return (
		<>
			{props.currentCommissionList.length ? (
				<button className={classes.showAllButton} onClick={onOpen}>
					{t('showAll')}
				</button>
			) : (
				<>
					{props.hasAccess && (
						<button className={classes.showAllButton} onClick={onOpen}>
							{t('addCommission')}
						</button>
					)}
				</>
			)}
			{open && <Template {...props} onClose={onClose} />}
		</>
	);
};

export default Commission;
