import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { LazyQueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import {
	GET_COUNT_VCHASNO_DOCS_UNSIGNED,
	GET_VCHASNO_DOCS_CATEGORIES,
	GET_VCHASNO_DOCS_COMPANIES,
	GET_VCHASNO_DOCS_NUMBER,
	GET_VCHASNO_DOCS_STATUSES,
	GET_VCHASNO_DOCS_STORES,
} from 'api/vchasnoDocs';
import { Routes } from 'const';
import DocumentsHead from 'containers/Documents';
import NotificationAuthOtherUser from 'containers/NotificationAuthOtherUser';
import { format } from 'date-fns';
import { getMonthRange } from 'helpers';
import clsx from 'helpers/clsx';
import { executeFilterParam, normalizeFilter } from 'helpers/document';
import { useDocuments } from 'hooks/useDocuments';
import { useQuery as useQueryString } from 'hooks/useQuery';
import { useUser } from 'hooks/useUser';
import { StatusUnsigned, ValueOf } from 'interfaces';
import { IFilter } from 'interfaces/document';
import { ReactComponent as EyeIco } from 'static/images/eye-stroke.svg';
import { ReactComponent as Fielder } from 'static/images/fielder.svg';
import { ReactComponent as WarnIco } from 'static/images/warn.svg';
import { TEST_IDS } from 'tests/config';

import DocumentListItem from 'components/DocumentsListItem';
import { DatePicker, Select } from 'components/FormControl';
import { IPropsSelect } from 'components/FormControl/types';
import Loader from 'components/Loader';
import LoadMore from 'components/LoadMore';
import { VchasnoSync } from 'components/VchasnoSync';

import { DocsCategories, DocsStatuses, IDocsCompanies, IDocsContract, IDocsStores, OptionSelect } from './types';

import classes from './Documents.module.scss';

const Documents: React.FC = () => {
	const { fetchDocuments, filter, changeFilter, clearFilter, pagination, setPage, items, onload, wasLoaded, total, getStatusText, aborterRef } =
		useDocuments();
	const { t } = useTranslation(['document', 'errors']);
	const { isServiceUser } = useUser();

	useEffect(fetchDocuments, [filter, pagination]);

	const [fetchStatuses, { data: dataStatuses, loading: loadingStatuses }] = useLazyQuery<DocsStatuses>(GET_VCHASNO_DOCS_STATUSES);
	const [fetchCategories, { data: dataCategories, loading: loadingCategories }] = useLazyQuery<DocsCategories>(GET_VCHASNO_DOCS_CATEGORIES);
	const [fetchCompanies, { data: dataCompanies, loading: loadingCompanies }] = useLazyQuery<IDocsCompanies>(GET_VCHASNO_DOCS_COMPANIES);
	const [fetchStores, { data: dataStores, loading: loadingStores }] = useLazyQuery<IDocsStores>(GET_VCHASNO_DOCS_STORES);
	const [fetchContractNumbers, { data: dataContractNum, loading: loadingContractNum }] = useLazyQuery<IDocsContract>(GET_VCHASNO_DOCS_NUMBER);
	const { data: dataUnsigned } = useQuery<{ vchasnoDocsUnsignedCount: number }>(GET_COUNT_VCHASNO_DOCS_UNSIGNED, {
		variables: (() => {
			const [startDate] = getMonthRange(12);
			return { dateFrom: format(startDate, 'yyyy-MM-dd') };
		})(),
	});

	const params: LazyQueryHookOptions = {
		variables: { filter: normalizeFilter(filter) },
		fetchPolicy: 'network-only',
		context: {
			fetchOptions: {
				signal: aborterRef.signal,
			},
		},
	};

	const fetchExecute = () => {
		fetchCompanies(params);
		fetchStores(params);
		fetchContractNumbers(params);
		fetchCategories(executeFilterParam(params, 'categories'));
		fetchStatuses(executeFilterParam(params, 'statuses'));
	};

	useEffect(() => {
		fetchExecute();
	}, [filter]);

	const history = useHistory();
	const query = useQueryString();
	const showUnsignedDocuments = query.get('showUnsigned');

	useEffect(() => {
		if (showUnsignedDocuments) {
			handleAllNoSignatureDoc();
			history.push(Routes.DOCUMENTS);
		}
	}, [showUnsignedDocuments]);

	const handleChangeDatePicker = (dates: [Date, Date]) => {
		const [startDate, endDate] = dates;
		if (startDate && endDate) {
			changeFilter('startDate', startDate);
			changeFilter('endDate', endDate);
		}
	};

	const handleChangeSelect = (field: keyof IFilter) => (value: IPropsSelect['value']) => {
		changeFilter(field, value as ValueOf<IFilter>);
	};

	const handleAllNoSignatureDoc = () => {
		const [startDate, endDate] = getMonthRange(12);
		clearFilter();
		changeFilter('startDate', startDate);
		changeFilter('endDate', endDate);
		changeFilter('statuses', [isServiceUser ? StatusUnsigned.GLOVO_UNSIGNED : StatusUnsigned.PARTNER_UNSIGNED]);
	};

	const loadMore = () => {
		setPage(pagination.page + 1);
	};

	const start = pagination.page * pagination.pageSize + 1;
	const end = start + pagination.pageSize - 1;
	const canLoadMore = end < total;

	const getCategoryText = (code: string) => {
		if (code) return t(`category.${code}`);
		return '';
	};

	const getItemsWithSelect = () => {
		return (filter.statuses?.length || filter.categories?.length || filter.edrpouOwner) && items?.length === 0;
	};

	return (
		<div className={clsx(classes.root, { [classes.padding]: !!items?.length && !canLoadMore })} data-testid={TEST_IDS.DOCUMENTS_PAGE}>
			<NotificationAuthOtherUser />
			<div className={classes.header}>
				<div className={classes.syncContainer}>
					<div className={classes.title}>{t('document')}</div>
					<VchasnoSync className={classes.synchroBackground} />
				</div>
				{!!dataUnsigned?.vchasnoDocsUnsignedCount && (
					<div className={classes.signature}>
						<span>
							<WarnIco />
							{t('doc', { count: dataUnsigned.vchasnoDocsUnsignedCount })}
						</span>
						<span onClick={handleAllNoSignatureDoc}>
							<EyeIco />
							{t('unsignedCheck')}
						</span>
					</div>
				)}
			</div>

			<div className={classes.containerSelect}>
				<div className={classes.container}>
					<DatePicker
						onChange={handleChangeDatePicker}
						forceOpenDatepicker={false}
						datepickerProps={{
							twoMonth: true,
							endDate: filter.endDate,
							startDate: filter.startDate,
							maxDate: new Date(),
						}}
						rootClassName={classes.filterItem}
					/>
				</div>

				<div className={classes.container}>
					<Select
						multiple
						onCloseClear
						onChange={handleChangeSelect('categories')}
						value={filter.categories || []}
						emptyLabel={t('categoryTitle')}
						multipleTitle={t('multipleTitle.category')}
						rootClassName={classes.filterCategories}
						disabled={(!dataCategories?.vchasnoDocsCategories?.length && !filter.categories?.length) || loadingCategories}
						testId={TEST_IDS.DOCUMENTS_FILTERS_CATEGORIES}
						options={
							dataCategories?.vchasnoDocsCategories.map((it: OptionSelect) => ({
								value: String(it.value),
								label: getCategoryText(it.value as string),
							})) || []
						}
					/>
				</div>

				<div className={classes.container}>
					<Select
						multiple
						onCloseClear
						onChange={handleChangeSelect('statuses')}
						value={filter.statuses || []}
						emptyLabel={t('statusTitle')}
						multipleTitle={t('multipleTitle.status')}
						rootClassName={classes.filterItem}
						disabled={(!dataStatuses?.vchasnoDocsStatuses?.length && !filter.statuses?.length) || loadingStatuses}
						testId={TEST_IDS.DOCUMENTS_FILTERS_STATUS}
						options={
							dataStatuses?.vchasnoDocsStatuses?.map((it: OptionSelect) => ({
								value: String(it.value),
								label: getStatusText(it.value as string),
							})) || []
						}
						renderOption={(option) => (
							<div className={classes.optionContainer}>
								<div className={classes.optionValue}>{option.label}</div>
							</div>
						)}
					/>
				</div>

				<div className={classes.container}>
					<Select
						onChange={handleChangeSelect('edrpouOwner')}
						value={filter.edrpouOwner}
						emptyLabel={t('allCode')}
						rootClassName={classes.filterItem}
						disabled={(!dataCompanies?.vchasnoDocsCompanies?.length && !filter.edrpouOwner) || loadingCompanies}
						options={
							dataCompanies?.vchasnoDocsCompanies.map((it) => ({
								value: it.ipn,
								label: `${it.ipn} — ${it.companyName}`,
							})) || []
						}
						testId={TEST_IDS.DOCUMENTS_FILTERS_EDRPOU}
						withSearch
					/>
				</div>
			</div>
			<div className={clsx(classes.containerSelect)}>
				<div className={classes.container}>
					<Select
						onChange={handleChangeSelect('storeAddress')}
						value={filter.storeAddress}
						emptyLabel={t('allAddresses')}
						rootClassName={classes.filterItem}
						disabled={(!dataStores?.vchasnoStoreAddress?.length && !filter.storeAddress) || loadingStores}
						options={dataStores?.vchasnoStoreAddress.map((it) => ({ value: it.storeAddressId, label: `${it.storeAddressId} — ${it.address}` })) || []}
						withSearch
					/>
				</div>
				<div className={classes.container}>
					<Select
						onChange={handleChangeSelect('contractNumber')}
						value={filter.contractNumber}
						emptyLabel={t('allContracts')}
						rootClassName={classes.filterItem}
						disabled={(!dataContractNum?.vchasnoNumbers?.length && !filter?.contractNumber) || loadingContractNum}
						options={dataContractNum?.vchasnoNumbers?.map((it) => ({ value: it.number, label: it.number })) || []}
						withSearch
					/>
				</div>
			</div>
			{onload && wasLoaded && <Loader />}

			{onload && !wasLoaded ? (
				<Loader simple />
			) : (
				<div className={classes.tableContainer}>
					<>
						<table data-testid={TEST_IDS.DOCUMENTS_TABLE}>
							<DocumentsHead />
							<tbody>{items?.map((it) => <DocumentListItem item={it} key={`${it?.pathToFile?.split('/').at(-1)}${it.id}`} />)}</tbody>
						</table>
						{!items?.length && (
							<div className={classes.noDataContainer}>
								<Fielder />
								{getItemsWithSelect() ? (
									<>
										<div className={classes.titleNoData}>{t('noDocumentsWithSelect')}</div>
										<div className={classes.checkDocument}>{t('clearFilter')}</div>
									</>
								) : (
									<>
										<div className={classes.titleNoData}>{t('noDocuments')}</div>
										<div className={classes.checkDocument}>{t('needCheckDocument')}</div>
										<div className={classes.linkVchasno}>
											<a href="http://vchasno.ua/app" target="_blank" rel="noreferrer">
												{t('linkVchasno')}
											</a>
										</div>
									</>
								)}
							</div>
						)}
						{!!items?.length && canLoadMore && <LoadMore onClick={loadMore} className={classes.loadMore} />}
					</>
				</div>
			)}
		</div>
	);
};

export default Documents;
