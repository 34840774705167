import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import langDetector from 'services/LangDetector';

const ns: string[] = [
	'accountingTypes',
	'chat',
	'common',
	'createUser',
	'errors',
	'exports',
	'getStarted',
	'form',
	'login',
	'menu',
	'meta',
	'orders',
	'resetPassword',
	'forgotPassword',
	'roles',
	'storeAddresses',
	'translation',
	'users',
	'validation',
	'feedback',
	'authOtherUser',
	'payoutSchedules',
	'contractGeneration',
	'partners',
	'disputeReason',
];

const languageDetector = new LanguageDetector();
languageDetector.addDetector(langDetector);

i18n
	.use(initReactI18next)
	.use(languageDetector)
	.use(Backend)
	.init({
		fallbackLng: 'uk',
		defaultNS: 'common',
		ns,
		detection: {
			order: ['langDetector'],
		},
		interpolation: {
			escapeValue: false,
		},
		backend: {
			projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
			version: process.env.REACT_APP_LOCIZE_VERSION,
		},
	});

export default i18n;
