import { getMonthRange } from 'helpers';
import { ValueOf } from 'interfaces';
import { IFilter } from 'interfaces/document';

import { IAction } from './types';
import {
	CHANGE_DOCUMENTS_FILTER,
	CLEAR_DOCUMENTS_FILTER,
	FETCH_DOCUMENTS_FAILURE,
	FETCH_DOCUMENTS_START,
	FETCH_DOCUMENTS_SUCCESS,
	IState,
	SET_DOCUMENTS_PAGE,
	SET_DOCUMENTS_PAGE_SIZE,
} from './types';

const initialState: IState = {
	items: [],
	total: 0,
	onload: true,
	wasLoaded: false,
	loadingTotals: false,
	filter: ((): IState['filter'] => {
		const [startDate, endDate] = getMonthRange(3);
		return {
			startDate,
			endDate,
		};
	})(),
	pagination: {
		page: 0,
		pageSize: 25,
	},
};

const reducer = (state = initialState, action: IAction): IState => {
	switch (action.type) {
		case FETCH_DOCUMENTS_START:
			return {
				...state,
				onload: true,
			};
		case FETCH_DOCUMENTS_SUCCESS:
			return {
				...state,
				items: !state.pagination.page ? action.payload.documents : [...state.items, ...action.payload.documents],
				total: action.payload.total,
				onload: false,
				wasLoaded: true,
				loadingTotals: false,
			};
		case FETCH_DOCUMENTS_FAILURE:
			return {
				...state,
				items: [],
				total: 0,
				onload: false,
				wasLoaded: true,
			};
		case SET_DOCUMENTS_PAGE:
			return {
				...state,
				loadingTotals: true,
				pagination: {
					...state.pagination,
					page: action.payload,
				},
			};
		case SET_DOCUMENTS_PAGE_SIZE:
			return {
				...state,
				pagination: {
					...state.pagination,
					pageSize: action.payload,
				},
			};
		case CHANGE_DOCUMENTS_FILTER: {
			const getFilterValue = (value: ValueOf<IFilter>) => {
				if (typeof value === 'boolean') return value;
				return value || undefined;
			};
			return {
				...state,
				filter: {
					...state.filter,
					[action.payload.field]: getFilterValue(action.payload.value),
				},
				pagination: {
					page: 0,
					pageSize: 25,
				},
			};
		}
		case CLEAR_DOCUMENTS_FILTER:
			return {
				...state,
				filter: {
					...initialState.filter,
				},
			};
		default:
			return state;
	}
};

export default reducer;
