import React from 'react';
import ReactDOM from 'react-dom';
import { useGetStartedContext } from 'contexts/GetStarted';

import Template from './template';

const GetStarted: React.FC = () => {
	const { open } = useGetStartedContext();
	if (!open) {
		return null;
	}

	return ReactDOM.createPortal(<Template />, document.body);
};

export default GetStarted;
