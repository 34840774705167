import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetch } from 'api';
import { saveAs } from 'file-saver';
import { getCountry } from 'helpers/jwt';
import { normalizeFilter } from 'helpers/order';
import { normalizeFilter as normalizePartnerFilter } from 'helpers/partnersFile';
import { CountryCodeEnum, IFetchOptions } from 'interfaces';

import { useCustomers } from './useCustomers';
import { useLaaSOrders } from './useLaaSOrders';
import { useOrders } from './useOrders';
import { usePartnersFile } from './usePartnersFile';
import { useStoreAddresses } from './useStoreAddresses';
import useToast from './useToast';

export type TypesExport = 'excel' | 'summary' | 'orders';

export interface IUseExport {
	onload: boolean;
	isError: boolean;
	exportSummary(): void;
	exportUAOrders(): void;
	exportExcel(): void;
	exportStoreAddresses(): void;
	exportPromoDelivery(): void;
	exportPromoProducts(): void;
	exportNavision(): void;
	exportCustomers(): void;
	exportPartnerFile(printPartner?: boolean): void;
	exportOrderReport(dateRange: { month: string; year: string }): void;
	exportSummaryDH(): void;
	exportLaaSOrders(isPartner?: boolean): void;
	exportTapOnPhone(): void;
	exportLaaSSummary(): void;
	exportGMOReport(): void;
	exportManualAdjustments(): void;
	exportDisputes(): void;
	exportDisputesSummary(): void;
	exportAgreement(id: string, type: 'WORD' | 'PDF', fileName: string): void;
	openModal: boolean;
	setOpenModal: Dispatch<SetStateAction<boolean>>;
}

export const getFilenameFromDisposition = (disposition: string | null): string | undefined => {
	if (!disposition) return;
	const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
	const matches = filenameRegex.exec(disposition);
	if (matches != null && matches[1]) {
		return matches[1].replace(/['"]/g, '');
	}
};

export const useExport = (): IUseExport => {
	const { filter: filterOrders } = useOrders();
	const { filter: filterLaaSOrders } = useLaaSOrders();
	const { filter: filterPartner, filtration: filtrationPartner } = usePartnersFile();
	const { filter: filterStoreAddresses } = useStoreAddresses();
	const { filter: filterCustomers } = useCustomers();
	const { toast } = useToast();
	const [isError, setIsError] = useState<boolean>(false);
	const [onload, setOnload] = useState<boolean>(false);
	const { t } = useTranslation('errors');
	const [openModal, setOpenModal] = useState<boolean>(false);
	const country = getCountry();

	const exportReport = (url: string, body: unknown | null = null, options: IFetchOptions = {}, withCountryCode = true, fileName?: string) => {
		setOnload(true);
		setIsError(false);
		fetch<Blob>(
			url,
			{
				method: 'post',
				headers: {
					'Content-Type': 'application/json',
					responseType: 'blob',
				},
				body: body ? JSON.stringify(body) : undefined,
				...options,
			},
			withCountryCode,
		)
			.then((res) => {
				if (res.body) {
					const filename = getFilenameFromDisposition(res.response.headers.get('content-disposition'));
					const text = res.body;
					saveAs(text, fileName || filename);
				}
				if (res.response.status === 202) {
					setOpenModal(true);
				}
			})
			.catch(() => {
				toast(t('fileDownload'), { type: 'error' });
				setIsError(true);
			})
			.finally(() => {
				setOnload(false);
			});
	};

	const exportOrdersReport = (url: string) => {
		setOnload(true);
		setIsError(false);
		fetch(url)
			.catch(() => {
				setIsError(true);
			})
			.finally(() => {
				setOnload(false);
			});
	};

	const exportLaaSSummary = () => exportReport('/reports/summary_on_demand.xlsx', normalizeFilter(filterLaaSOrders));
	const exportLaaSOrders = (partner?: boolean) =>
		exportReport(`/reports/laas_orders.xlsx${partner ? `?isPartner=true` : ''}`, normalizeFilter(filterLaaSOrders));
	const exportSummary = () => exportReport('/reports/summary.xlsx', normalizeFilter(filterOrders));
	const exportPartnerFile = (print?: boolean) =>
		exportReport(`/reports/partners.xlsx${print ? `?printPartner=true` : ''}`, normalizePartnerFilter(filterPartner, filtrationPartner));
	const exportUAOrders = () => exportReport('/reports/ua_orders.xlsx', normalizeFilter(filterOrders));
	const exportExcel = () => exportReport('/reports/orders_amendments.xlsx', normalizeFilter(filterOrders, country === CountryCodeEnum.KZ));
	const exportStoreAddresses = () => exportReport('/reports/store_addresses.xlsx', filterStoreAddresses);
	const exportCustomers = () => exportReport('/reports/customers.xlsx', filterCustomers);
	const exportPromoDelivery = () => exportReport('/reports/promo_delivery.xlsx', normalizeFilter(filterOrders));
	const exportPromoProducts = () => exportReport('/reports/promo_products.xlsx', normalizeFilter(filterOrders));
	const exportNavision = () => exportReport('/reports/navision.xlsx', normalizeFilter(filterOrders));
	const exportOrderReport = ({ month, year }: { month: string; year: string }) =>
		exportOrdersReport(`/order-file-report.xlsx?year=${year}&month=${month}`);
	const exportSummaryDH = () => exportReport('/reports/delivery_hero.xlsx', normalizeFilter(filterOrders));
	const exportTapOnPhone = () => exportReport('/reports/taptophone/transactions.xlsx', normalizeFilter(filterOrders));
	const exportGMOReport = () => exportReport('/reports/gmo_report.xlsx', normalizeFilter(filterOrders));
	const exportAgreement = (id: string, type: 'WORD' | 'PDF', fileName: string) =>
		exportReport(
			`/document/id/${id}?type=${type}`,
			null,
			{
				method: 'get',
				fintoolContracts: true,
				headers: {
					'Content-Type': 'application/pdf',
					responseType: 'blob',
				},
			},
			false,
			fileName,
		);
	const exportManualAdjustments = () => exportReport('/reports/manual_adjustment.xlsx', normalizeFilter(filterOrders));

	const exportDisputes = () => exportReport('/reports/disputes.xlsx', normalizeFilter(filterOrders));
	const exportDisputesSummary = () => exportReport('/reports/disputes_summary.xlsx', normalizeFilter(filterOrders));

	return {
		onload,
		isError,
		exportCustomers,
		exportSummary,
		exportUAOrders,
		exportExcel,
		exportStoreAddresses,
		exportPromoDelivery,
		exportPromoProducts,
		exportNavision,
		exportOrderReport,
		exportSummaryDH,
		exportPartnerFile,
		exportAgreement,
		exportLaaSOrders,
		exportTapOnPhone,
		exportLaaSSummary,
		exportGMOReport,
		openModal,
		setOpenModal,
		exportManualAdjustments,
		exportDisputes,
		exportDisputesSummary,
	};
};
