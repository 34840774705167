import { MouseEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatDate, formatPrice } from 'helpers';
import clsx from 'helpers/clsx';
import { ReactComponent as InvoiceIco } from 'static/images/account.svg';
import { ReactComponent as ArrIcon } from 'static/images/keyboard_arrow_down.svg';
import { ReactComponent as PinIcon } from 'static/images/pin.svg';
import { TEST_IDS } from 'tests/config';

import CopyButton from 'components/CopyButton';
import Tooltip from 'components/Tooltip';

import AdditionalPayment from './AdditionalPayment';
import { IProps, STATUS } from './types';

import classes from 'pages/Accounts/Accounts.module.scss';

const InvoiceRow = ({ item }: IProps) => {
	const [isCollapse, setIsCollapse] = useState(false);
	const { t } = useTranslation('accounts');
	const [visibleTip, setVisibleTip] = useState(false);

	const getSideForTooltip = (e: MouseEvent) => {
		if (window.innerHeight - e.clientY > 150) {
			return setVisibleTip(true);
		}
		setVisibleTip(false);
	};

	const handleCollapse = () => {
		setIsCollapse(!isCollapse);
	};

	return (
		<>
			<tr className={clsx(classes.paymentRow)} data-testid={TEST_IDS.ACCOUNTS_TABLE_BODY_INVOICE_ROW}>
				<td className={clsx(classes.light, classes.iconCell)}>
					<InvoiceIco />
				</td>
				<td className={clsx(classes.light, classes.typeCell)}>
					<div className={classes.typeInner}>
						<div className={clsx(classes.typeText, classes.alignCenter)}>{t('invoice')}</div>
					</div>
					{!!item.children.length && (
						<div className={classes.pinPaymentCell}>
							<div onClick={handleCollapse} className={classes.pinPaymentBtn}>
								<PinIcon />
								<ArrIcon />
							</div>
						</div>
					)}
				</td>
				<td className={clsx(classes.light, classes.dateCell)}>
					<div className={clsx(classes.flex, classes.lightTextSmall)}>{formatDate(new Date(item.date))}</div>
				</td>
				<td className={clsx(classes.light, classes.docNumCell, classes.alignRight)}>
					<div className={clsx(classes.lightTextSmall, classes.flex, classes.right)}>{item.number}</div>
				</td>
				<td className={clsx(classes.light, classes.agentNumCell)}>
					<div className={clsx(classes.partner)}>{item.companyName}</div>
					<div className={clsx(classes.agreeText)}>{item.edrpou}</div>
				</td>
				<td className={clsx(classes.light, classes.offerCell, classes.alignRight)}>
					<div className={clsx(classes.lightText, classes.flex, classes.right)}>{item.contractNumber}</div>
				</td>
				<td className={clsx(classes.light, classes.numCell)}>
					<div className={clsx(classes.mdText, classes.flex, classes.right, { [classes.fulfilled]: item.totalAmount })}>
						{formatPrice(item.totalAmount, 2, ',', '0,00')}
					</div>
				</td>
				<td className={clsx(classes.light, classes.numCell)}>
					<div className={clsx(classes.mdText, classes.flex, classes.right, { [classes.fulfilled]: item.paidAmount })}>
						{formatPrice(item.paidAmount, 2, ',', '0,00')}
					</div>
				</td>
				<td className={clsx(classes.light, classes.numCell)}>
					<div className={clsx(classes.mdText, classes.flex, classes.right, { [classes.fulfilled]: item.residualAmount })}>
						{formatPrice(item.residualAmount, 2, ',', '0,00')}
					</div>
				</td>
				<td className={clsx(classes.light, classes.paymentDateCell)}>
					<div className={clsx(classes.lightText, classes.flex)}>{!(item.status === STATUS.NOT_PAID) && formatDate(new Date(item.paidDate))}</div>
				</td>
				<td className={clsx(classes.light, classes.statusCell)}>
					<div
						className={clsx(
							classes.badge,
							{ [classes.badge_partially]: item.status === STATUS.PARTIALLY_PAID },
							{ [classes.badge_unpaid]: item.status === STATUS.NOT_PAID },
							classes.badge_paid,
							classes.alignCenter,
						)}
					>
						{item.status === STATUS.PARTIALLY_PAID ? t('partiallyPaid') : item.status === STATUS.PAID ? t('paid') : t('unPaid')}
					</div>
				</td>
				<td onMouseEnter={getSideForTooltip} className={clsx(classes.light, classes.AppointmentCell)}>
					<Tooltip
						className={clsx({ [classes.appointmentTolltip]: visibleTip })}
						text={<Trans t={t} i18nKey={item.purpose} />}
						placement={visibleTip ? 'bottom-end' : 'left'}
						nowrap
					>
						<div className={clsx(classes.flex, classes.appointmentInner)}>
							<CopyButton className={classes.copyIcon} text={item.purpose} />
							<span className={classes.appointmentText}>{item.purpose}</span>
						</div>
					</Tooltip>
				</td>
			</tr>
			{isCollapse && <>{item?.children?.map((childItem) => <AdditionalPayment item={childItem} key={childItem.id} />)}</>}
		</>
	);
};

export default InvoiceRow;
