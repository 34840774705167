import { clientGraphql } from 'api';
import { GET_ORDERS, GET_TOTALS } from 'api/orders';
import { middlewareOrder, normalizeFilter } from 'helpers/order';
import { IFilter, IOrder } from 'interfaces/order';
import { Dispatch } from 'redux';

import {
	CHANGE_ORDERS_FILTER,
	CLEAR_ALL_ORDERS,
	CLEAR_ORDERS_FILTER,
	FETCH_ORDERS_FAILURE,
	FETCH_ORDERS_START,
	FETCH_ORDERS_SUCCESS,
	FETCH_ORDERS_TOTALS_FAILURE,
	FETCH_ORDERS_TOTALS_START,
	FETCH_ORDERS_TOTALS_SUCCESS,
	SET_ORDERS_PAGE,
	SET_ORDERS_PAGE_SIZE,
	UPDATE_ORDER,
} from './types';

export const fetchOrders =
	(filter: IFilter, { page, pageSize }: { page: number; pageSize: number }) =>
	(dispatch: Dispatch): Promise<IOrder[] | undefined> => {
		dispatch({ type: FETCH_ORDERS_START });
		return clientGraphql
			.query({
				query: GET_ORDERS,
				variables: {
					page,
					pageSize,
					filter: { ...normalizeFilter(filter), approvedAfterPeriodTab: filter.approvedAfterPeriodTab, disputesTab: filter.disputesTab },
				},
				fetchPolicy: filter.approvedAfterPeriodTab || filter.disputesTab ? 'no-cache' : 'network-only',
			})
			.then((res) => {
				dispatch({
					payload: {
						orders: middlewareOrder(res.data.orders.orderDtos),
						total: res.data.orders.total,
					},
					type: FETCH_ORDERS_SUCCESS,
				});
				return res.data.orders.orderDtos;
			})
			.catch(() => {
				dispatch({ type: FETCH_ORDERS_FAILURE });
				return undefined;
			});
	};

export const fetchTotals =
	(filter: IFilter, aborterRef: AbortController) =>
	(dispatch: Dispatch): Promise<IOrder[] | undefined> => {
		dispatch({ type: FETCH_ORDERS_TOTALS_START });
		return clientGraphql
			.query({
				query: GET_TOTALS,
				variables: { filter: normalizeFilter(filter) },
				fetchPolicy: 'network-only',
				context: {
					fetchOptions: {
						signal: aborterRef.signal,
					},
				},
			})
			.then((res) => {
				dispatch({
					payload: res.data.totals,
					type: FETCH_ORDERS_TOTALS_SUCCESS,
				});
				return res.data.totals;
			})
			.catch(() => {
				dispatch({ type: FETCH_ORDERS_TOTALS_FAILURE });
				return undefined;
			});
	};

export const setOrdersPage = (payload: number) => ({
	payload,
	type: SET_ORDERS_PAGE,
});

export const setOrdersPageSize = (payload: number) => ({
	payload,
	type: SET_ORDERS_PAGE_SIZE,
});

export const changeOrdersFilter = (field: keyof IFilter, value: IFilter[typeof field]) => ({
	payload: { field, value },
	type: CHANGE_ORDERS_FILTER,
});

export const clearAllOrders = () => ({
	payload: null,
	type: CLEAR_ALL_ORDERS,
});

export const clearOrdersFilter = (dates?: [Date, Date]) => ({
	payload: dates,
	type: CLEAR_ORDERS_FILTER,
});

export const updateOrder = (payload: IOrder) => ({
	payload,
	type: UPDATE_ORDER,
});
