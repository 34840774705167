import { clientGraphql } from 'api';
import { GET_DEBT_COMPANIES, GET_DEBT_GLOVO, GET_INVOICES, GET_PAYMENTS } from 'api/invoices';
import { middlewareDebt, middlewareInvoices, middlewarePayments, normalizeFilter, normalizeInvoiceInput } from 'helpers/invoices';
import { IFilter, IInvoices } from 'interfaces/invoices';
import { Dispatch } from 'redux';

import {
	CHANGE_INVOICES_FILTER,
	FETCH_DEBT_COMPANIES_FAILURE,
	FETCH_DEBT_COMPANIES_START,
	FETCH_DEBT_COMPANIES_SUCCESS,
	FETCH_DEBT_GLOVO_FAILURE,
	FETCH_DEBT_GLOVO_START,
	FETCH_DEBT_GLOVO_SUCCESS,
	FETCH_INVOICES_FAILURE,
	FETCH_INVOICES_START,
	FETCH_INVOICES_SUCCESS,
	FETCH_PAYMENTS_FAILURE,
	FETCH_PAYMENTS_START,
	FETCH_PAYMENTS_SUCCESS,
	SET_INVOICES_PAGE_SIZE,
	SET_PAYMENTS_PAGE_SIZE,
} from './types';

export const fetchInvoices =
	(filter: IFilter, { page, pageSize }: { page: number; pageSize: number }) =>
	(dispatch: Dispatch): Promise<IInvoices[] | undefined> => {
		dispatch({ type: FETCH_INVOICES_START });
		return clientGraphql
			.query({
				query: GET_INVOICES,
				variables: { page, pageSize, filter: normalizeFilter(filter, 'status') },
			})
			.then((res) => {
				dispatch({
					payload: {
						invoices: middlewareInvoices(res.data.invoices.invoiceDtos),
						total: res.data.invoices.total,
					},
					type: FETCH_INVOICES_SUCCESS,
				});
				return res.data.invoices.invoiceDtos;
			})
			.catch(() => {
				dispatch({ type: FETCH_INVOICES_FAILURE });
				return undefined;
			});
	};

export const fetchGlovoDebt =
	({ date, taxId }: { date: Date; taxId?: string[] }) =>
	(dispatch: Dispatch): Promise<IInvoices[] | undefined> => {
		dispatch({ type: FETCH_DEBT_GLOVO_START });
		return clientGraphql
			.query({
				query: GET_DEBT_GLOVO,
				variables: normalizeInvoiceInput({ date, taxId }),
			})
			.then((res) => {
				dispatch({
					payload: {
						debts: middlewareDebt(res.data.debtGlovo),
					},
					type: FETCH_DEBT_GLOVO_SUCCESS,
				});
				return res.data.debtGlovo;
			})
			.catch(() => {
				dispatch({ type: FETCH_DEBT_GLOVO_FAILURE });
				return undefined;
			});
	};

export const fetchCompaniesDebt =
	({ date, taxId }: { date: Date; taxId?: string[] }) =>
	(dispatch: Dispatch): Promise<IInvoices[] | undefined> => {
		dispatch({ type: FETCH_DEBT_COMPANIES_START });
		return clientGraphql
			.query({
				query: GET_DEBT_COMPANIES,
				variables: normalizeInvoiceInput({ date, taxId }),
			})
			.then((res) => {
				dispatch({
					payload: {
						debts: middlewareDebt(res.data.debtCompanies),
					},
					type: FETCH_DEBT_COMPANIES_SUCCESS,
				});
				return res.data.debtCompanies;
			})
			.catch(() => {
				dispatch({ type: FETCH_DEBT_COMPANIES_FAILURE });
				return undefined;
			});
	};

export const changeInvoicesFilter = (field: keyof IFilter, value: IFilter[typeof field]) => ({
	payload: { field, value },
	type: CHANGE_INVOICES_FILTER,
});

export const setInvoicesPageSize = (payload: number) => ({
	payload,
	type: SET_INVOICES_PAGE_SIZE,
});

export const fetchPayments =
	(filter: IFilter, { page, pageSize }: { page: number; pageSize: number }) =>
	(dispatch: Dispatch): Promise<IInvoices[] | undefined> => {
		dispatch({ type: FETCH_PAYMENTS_START });
		return clientGraphql
			.query({
				query: GET_PAYMENTS,
				variables: { page, pageSize, filter: normalizeFilter(filter, 'statuses') },
			})
			.then((res) => {
				dispatch({
					payload: {
						payments: middlewarePayments(res.data.payments.paymentDtos),
						total: res.data.payments.total,
					},
					type: FETCH_PAYMENTS_SUCCESS,
				});
				return res.data.payments.paymentsDtos;
			})
			.catch(() => {
				dispatch({ type: FETCH_PAYMENTS_FAILURE });
				return undefined;
			});
	};

export const setPaymentsPageSize = (payload: number) => ({
	payload,
	type: SET_PAYMENTS_PAGE_SIZE,
});
