import { Trans, useTranslation } from 'react-i18next';
import { formatDate, formatPrice } from 'helpers';
import clsx from 'helpers/clsx';
import { ReactComponent as PinIcon } from 'static/images/pin.svg';
import { ReactComponent as UnpinIcon } from 'static/images/unpin.svg';
import { TEST_IDS } from 'tests/config';

import CopyButton from 'components/CopyButton';
import Tooltip from 'components/Tooltip';

import { IProps } from './types';

import classes from 'pages/Accounts/Accounts.module.scss';

const PaymentRow = ({ item }: IProps) => {
	const { t } = useTranslation('accounts');

	const unpinPayment = () => (
		<>
			<td className={classes.iconCell}>
				<UnpinIcon />
			</td>
			<td className={clsx(classes.light, classes.typeCell)}>
				<div className={classes.typeInner}>
					<Tooltip text={<Trans t={t} i18nKey="tooltips.unpinPayment" />} nowrap>
						<div className={clsx(classes.typeText)}>
							{t('payment')}
							<div className={classes.lightTextSmall}>{t('unpinPayment')}</div>
						</div>
					</Tooltip>
				</div>
			</td>
		</>
	);

	return (
		<>
			<tr className={clsx(classes.paymentRow, { [classes.offPayment]: !item.invoiceNumber })} data-testid={TEST_IDS.ACCOUNTS_TABLE_BODY_PAYMENT_ROW}>
				{!item.invoiceNumber ? (
					unpinPayment()
				) : (
					<>
						<td className={clsx(classes.light, classes.iconCell)}>
							<PinIcon />
						</td>
						<td className={clsx(classes.light, classes.typeCell)}>
							<div className={classes.typeInner}>
								<div className={classes.typeText}>{t('payment')}</div>
							</div>
						</td>
					</>
				)}
				<td className={clsx(classes.light, classes.docNumCell, classes.alignRight)}>
					<div className={clsx(classes.lightTextSmall, classes.flex, classes.right)}>{item.number}</div>
				</td>
				<td className={clsx(classes.light, classes.agentNumCell)}>
					<div className={clsx(classes.partner)}>{item.companyName}</div>
					<div className={clsx(classes.agreeText)}>{item.edrpou}</div>
				</td>
				<td className={clsx(classes.light, classes.offerCell, classes.alignRight)}>
					<div className={clsx(classes.lightText, classes.flex, classes.right)}>{item.contractNumber}</div>
				</td>
				<td className={clsx(classes.light, classes.numCell)}>
					<div className={clsx(classes.iconWrap, classes.flex, classes.right, { [classes.fulfilled]: item.totalAmount })}>
						{formatPrice(item.totalAmount, 2, ',', '0,00')}
					</div>
				</td>
				<td className={clsx(classes.light, classes.paymentDateCell)}>
					<div className={clsx(classes.lightText, classes.flex, classes.center)}>{formatDate(new Date(item.date))}</div>
				</td>
				<td className={clsx(classes.light, classes.AppointmentCell)}>
					<div className={clsx(classes.flex, classes.appointmentInner)}>
						<CopyButton className={classes.copyIcon} text={item.purpose} />
						<span className={classes.appointmentText}>{item.purpose}</span>
					</div>
				</td>
			</tr>
		</>
	);
};

export default PaymentRow;
