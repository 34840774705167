import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'helpers/clsx';
import { ReactComponent as PlusIcon } from 'static/images/plus.svg';

import MainButton from 'components/MainButton';

import Template from './template';
import { IProps } from './types';

import classes from './CreateEditUser.module.scss';

const CreateEditUser: React.FC<IProps> = ({ children, ...props }) => {
	const [open, setOpen] = useState<boolean>(false);
	const { t } = useTranslation(['createUser', 'roles', 'validation', 'errors']);

	const onClose = () => setOpen(false);
	const onOpen = () => setOpen(true);

	const childrenClone = React.Children.map(children, (child) => {
		return (
			React.isValidElement(child) &&
			React.cloneElement(child as React.ReactElement, {
				onClick: onOpen,
			})
		);
	});

	return (
		<>
			{childrenClone || (
				<MainButton className={clsx(classes.flex, classes.alignItemsCenter)} onClick={onOpen}>
					<PlusIcon className={classes.addUserIcon} /> {t('add')}
				</MainButton>
			)}
			{open && <Template {...props} onClose={onClose} />}
		</>
	);
};

export default CreateEditUser;
