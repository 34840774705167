import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'helpers/clsx';
import { ReactComponent as CheckIcon } from 'static/images/check.svg';
import { ReactComponent as CopyIcon } from 'static/images/copy.svg';
import { ReactComponent as EditIcon } from 'static/images/editFile.svg';
import { ReactComponent as ResetPasswordIcon } from 'static/images/reset-password.svg';
import { ReactComponent as TrashBasketIcon } from 'static/images/trash-basket.svg';
import { ReactComponent as WarningIcon } from 'static/images/warningIcon.svg';

import Modal from 'components/modals/Modal';

import { IProps } from './types';

import classes from './Confirm.module.scss';

const Confirm: React.FC<IProps> = ({ children, confirm, title, body, titleSuccess, bodySuccess, icon, onCloseFunc }) => {
	const [open, setOpen] = useState<boolean>(false);
	const [success, setSuccess] = useState<boolean>(false);
	const { t } = useTranslation('form');

	const onClose = () => {
		setOpen(false);
		setSuccess(false);
		if (onCloseFunc) {
			onCloseFunc();
		}
	};

	const onOpen = () => {
		setOpen(true);
	};

	const childrenClone = React.Children.map(children, (child) => {
		return (
			React.isValidElement(child) &&
			React.cloneElement(child as React.ReactElement, {
				onClick: (e: React.MouseEvent<HTMLElement>) => {
					onOpen();
					if (child.props.onClick) {
						child.props.onClick(e);
					}
				},
			})
		);
	});

	const handleConfirm = () => {
		confirm.onClick().then((res) => {
			if (!res) return false;
			if (!titleSuccess) return onClose();
			setSuccess(true);
		});
	};

	const renderBody = () => {
		if (success && bodySuccess) return <div className={classes.body}>{bodySuccess}</div>;
		if (success || !body) return null;
		return <div className={classes.body}>{body}</div>;
	};

	const getConfirmButtonConfig = () => {
		if (success)
			return {
				text: t('ok'),
				onClick: onClose,
			};
		return {
			text: confirm.text,
			onClick: handleConfirm,
			disabled: confirm.disabled,
		};
	};

	const renderIcon = () => {
		if (success)
			return (
				<div className={classes.icon}>
					<CheckIcon />
				</div>
			);
		if (icon === 'delete')
			return (
				<div className={classes.icon}>
					<TrashBasketIcon />
				</div>
			);
		if (icon === 'resetPassword')
			return (
				<div className={classes.icon}>
					<ResetPasswordIcon />
				</div>
			);
		if (icon === 'edit')
			return (
				<div className={clsx(classes.icon, classes.icon_edit)}>
					<EditIcon />
				</div>
			);
		if (icon === 'warning')
			return (
				<div className={clsx(classes.icon, classes.icon_warning)}>
					<WarningIcon />
				</div>
			);
		if (icon === 'copy')
			return (
				<div className={clsx(classes.icon, classes.icon_copy)}>
					<CopyIcon />
				</div>
			);
		return null;
	};

	return (
		<>
			{childrenClone}
			<Modal
				modalClassName={clsx(classes.modal, {
					[classes.modal_centered]: !body,
				})}
				alignTitle="center"
				withoutShadowTitle
				open={open}
				onClose={onClose}
				buttons={{
					cancel: success ? undefined : {},
					confirm: getConfirmButtonConfig(),
				}}
			>
				{renderIcon()}
				<div className={classes.title}>{success ? titleSuccess : title}</div>
				{renderBody()}
			</Modal>
		</>
	);
};

export default Confirm;
