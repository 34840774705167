import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import { GET_STORE_ADDRESSES_DATA } from 'api/storeAddresses';
import { IDebtWithChildren, IFilter, IInvoicesModify, IPagination, IPaymentModify } from 'interfaces/invoices';
import { IStoreAddress } from 'interfaces/storeAddress';
import { useSelector } from 'store';
import {
	changeInvoicesFilter,
	fetchCompaniesDebt as fetchCompaniesDebtAction,
	fetchGlovoDebt as fetchGlovoDebtAction,
	fetchInvoices as fetchInvoicesAction,
	fetchPayments as fetchPaymentsAction,
	setInvoicesPageSize,
	setPaymentsPageSize,
} from 'store/accounts/actions';

export interface IUseInvoices {
	invoices: IInvoicesModify[];
	payments: IPaymentModify[];
	invoicesPagionation: IPagination;
	paymentsPagionation: IPagination;
	invoicesTotal: number;
	paymentsTotal: number;
	glovoDebts: IDebtWithChildren[];
	companiesDebts: IDebtWithChildren[];
	filter: IFilter;
	onload: boolean;
	wasLoaded: boolean;
	debtsLoad: boolean;
	fetchInvoices(): void;
	fetchPayments(): void;
	fetchGlovoDebt({ date, taxId }: { date: Date; taxId?: string[] }): void;
	fetchCompaniesDebt({ date, taxId }: { date: Date; taxId?: string[] }): void;
	fetchStoreAddresses(storeAddressIds: number[]): Promise<{ storeAddressData: IStoreAddress[] }>;
	changeFilter(field: keyof IFilter, value: IFilter[typeof field]): void;
	setPageSize(pageSize: number, activeTab: boolean): void;
}

export const useInvoices = (): IUseInvoices => {
	const {
		invoices: { items: invoices, pagionation: invoicesPagionation, total: invoicesTotal },
		payments: { items: payments, pagionation: paymentsPagionation, total: paymentsTotal },
		glovoDebts,
		companiesDebts,
		filter,
		onload,
		wasLoaded,
		debtsLoad,
	} = useSelector((state) => state.accounts);

	const [fetchAddressQuery] = useLazyQuery(GET_STORE_ADDRESSES_DATA);
	const { t } = useTranslation(['errors']);
	const dispatch = useDispatch();

	const fetchInvoices = () => {
		return dispatch(fetchInvoicesAction(filter, invoicesPagionation));
	};

	const fetchPayments = () => {
		return dispatch(fetchPaymentsAction(filter, paymentsPagionation));
	};

	const fetchGlovoDebt = ({ date, taxId }: { date: Date; taxId?: string[] }) => {
		return dispatch(fetchGlovoDebtAction({ date, taxId }));
	};

	const fetchCompaniesDebt = ({ date, taxId }: { date: Date; taxId?: string[] }) => {
		return dispatch(fetchCompaniesDebtAction({ date, taxId }));
	};

	const fetchStoreAddresses = (storeAddressIds: number[]): Promise<{ storeAddressData: IStoreAddress[] }> => {
		const params: LazyQueryHookOptions = {
			variables: { storeAddressIds },
			fetchPolicy: 'network-only',
		};
		return new Promise((resolve, reject) => {
			fetchAddressQuery(params)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(() => {
					toast(t('errors:somethingWentWrong'), { type: 'error' });
					reject(false);
				});
		});
	};

	const changeFilter = (field: keyof IFilter, value: IFilter[typeof field]) => {
		dispatch(changeInvoicesFilter(field, value));
	};

	const setPageSize = (pageSize: number, activeTab: boolean) => {
		if (activeTab) return dispatch(setInvoicesPageSize(pageSize));
		dispatch(setPaymentsPageSize(pageSize));
	};

	return {
		invoices,
		payments,
		glovoDebts,
		companiesDebts,
		filter,
		invoicesPagionation,
		paymentsPagionation,
		invoicesTotal,
		paymentsTotal,
		onload,
		debtsLoad,
		wasLoaded,
		fetchInvoices,
		fetchPayments,
		changeFilter,
		fetchGlovoDebt,
		fetchCompaniesDebt,
		fetchStoreAddresses,
		setPageSize,
	};
};
