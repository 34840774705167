import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FetchResult, QueryOptions, useMutation } from '@apollo/client';
import { CREATE_STORE_ADRESS, DELETE_STORE_ADRESS, EDIT_STORE_ADRESS } from 'api/storeAddresses';
import { normilizeInput } from 'helpers/storeAddresses';
import { IPagination } from 'interfaces';
import { IStoreAddress, IStoreAddressesFilter, IStoreAddressForm } from 'interfaces/storeAddress';
import { useSelector } from 'store';
import {
	changeStoreAddressesFilter,
	fetchStoreAddresses as fetchStoreAddressesAction,
	setStoreAddressesPage,
	setStoreAddressesPageSize,
	setStoreData,
} from 'store/storeAddresses/actions';

export interface IUseStoreAddresses {
	items: IStoreAddress[];
	filter: IStoreAddressesFilter;
	total: number;
	onload: boolean;
	wasLoaded: boolean;
	pagionation: IPagination;
	fetchStoreAddresses(): void;
	changeFilter(field: keyof IStoreAddressesFilter, value: IStoreAddressesFilter[typeof field]): void;
	setPageSize(pageSize: number): void;
	setPage(page: number): void;
	createStoreAddress(input: IStoreAddressForm): Promise<FetchResult<IStoreAddressForm> | null>;
	editStoreAddress(input: IStoreAddressForm, refetchQueries?: QueryOptions[]): Promise<boolean>;
	deleteStoreAddress(storeAddressId?: IStoreAddress['storeAddressId'], date?: string, refetchQueries?: QueryOptions[]): Promise<boolean>;
	setAbortRef(AbortController: AbortController): void;
	aborterRef: AbortController;
}

export const useStoreAddresses = (): IUseStoreAddresses => {
	const { items, filter, pagionation, total, onload, wasLoaded } = useSelector((state) => state.storeAddresses);
	const [createStoreAddressMutation] = useMutation(CREATE_STORE_ADRESS);
	const [editStoreAddressMutation] = useMutation(EDIT_STORE_ADRESS);
	const [deleteStoreAddressMutation] = useMutation(DELETE_STORE_ADRESS);
	const dispatch = useDispatch();
	const [aborterRef, setAbortRef] = useState(new AbortController());

	const fetchStoreAddresses = useCallback(() => {
		return dispatch(fetchStoreAddressesAction(filter, pagionation, aborterRef));
	}, [dispatch, filter, pagionation]);

	const changeFilter = (field: keyof IStoreAddressesFilter, value: IStoreAddressesFilter[typeof field]) => {
		if (field === 'status') {
			const values = filter.status.includes(value) ? filter.status.filter((status) => status !== value) : [...filter.status, value];
			return dispatch(changeStoreAddressesFilter(field, values));
		}
		dispatch(changeStoreAddressesFilter(field, value));
	};

	const setPageSize = (pageSize: number) => {
		dispatch(setStoreAddressesPageSize(pageSize));
	};

	const setPage = (page: number) => {
		dispatch(setStoreAddressesPage(page));
	};

	const createStoreAddress = (input: IStoreAddressForm): Promise<FetchResult<IStoreAddressForm> | null> => {
		return createStoreAddressMutation({
			variables: { input: normilizeInput(input) },
		});
	};

	const editStoreAddress = (input: IStoreAddressForm, refetchQueries?: QueryOptions[]): Promise<boolean> => {
		return editStoreAddressMutation({
			variables: { input: normilizeInput(input, true) },
			refetchQueries,
			awaitRefetchQueries: true,
		}).then(({ data }) => {
			if (data?.editStoreAddress) {
				dispatch(setStoreData(data.editStoreAddress));
			}
			return true;
		});
	};

	const deleteStoreAddress = (storeAddressId?: IStoreAddress['storeAddressId'], date?: string, refetchQueries?: QueryOptions[]): Promise<boolean> => {
		return deleteStoreAddressMutation({
			variables: { storeAddressId, date },
			refetchQueries,
		}).then(({ data }) => {
			if (data?.deleteStoreAddress) {
				dispatch(setStoreData(data.deleteStoreAddress));
			}
			return true;
		});
	};

	return {
		items,
		filter,
		pagionation,
		total,
		onload,
		wasLoaded,
		fetchStoreAddresses,
		changeFilter,
		setPageSize,
		setPage,
		createStoreAddress,
		editStoreAddress,
		deleteStoreAddress,
		setAbortRef,
		aborterRef,
	};
};
