import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { fetch as fetchApi, prefixApiUrl } from 'api';
import { viewUrlGoogle, viewUrlOffice } from 'const';
import DocumentStores from 'containers/DocumentsStores';
import { formatDate } from 'helpers';
import clsx from 'helpers/clsx';
import { getCountry } from 'helpers/jwt';
import { useClickAwayListener } from 'hooks/useClickAwayListener';
import { useDocuments } from 'hooks/useDocuments';
import useToast from 'hooks/useToast';
import { useUser } from 'hooks/useUser';
import { ReactComponent as ArrowDownIcon } from 'static/images/arrow_down.svg';
import { ReactComponent as ShowIcon } from 'static/images/eye-stroke.svg';
import { ReactComponent as InputDocumentIcon } from 'static/images/inputDocument.svg';
import { ReactComponent as LinkIcon } from 'static/images/link.svg';
import { ReactComponent as LinkVchasnoIcon } from 'static/images/linkVchasno.svg';
import { ReactComponent as OutputDocumentIcon } from 'static/images/outputDocument.svg';
import { TEST_IDS } from 'tests/config';

import CopyButton from 'components/CopyButton';
import IconButton from 'components/IconButton';
import Loader from 'components/Loader';
import Modal from 'components/modals/Modal';
import Nowrap from 'components/Nowrap';
import Tooltip from 'components/Tooltip';

import { FileTypes, IDocument, ImageTypes, SourceDocType, StatusDocType } from './types';

import classes from './DocumentListItem.module.scss';
export interface IProps {
	item: IDocument;
}

const DocumentListItem: React.FC<IProps> = ({ item }) => {
	const [showItem, setShowItem] = useState<boolean>(false);
	const [openDrawer, setOpenDrawer] = useState<boolean>(false);
	const [loadPreview, setLoadPreview] = useState<boolean>(false);
	const { toast } = useToast();
	const ref = useRef(null);
	const { isServiceUser } = useUser();
	const { t } = useTranslation('document');
	const { getStatusText, getStatusStyle } = useDocuments();

	const handleDrawerOpen = () => {
		setOpenDrawer(true);
		getPreview();
	};
	const handleDrawerClose = () => setOpenDrawer(false);

	const googleUrl = `https://drive.google.com/file/d/${item.docId}/view`;
	const isVchasnoDoc = item.source !== SourceDocType.GOOGLE_DRIVE;
	const imageType = Object.values(ImageTypes).filter((type) => type === item.extension);

	const getPreview = () => {
		setLoadPreview(true);
		fetchApi(`/documents/generate_link?documentId=${item.docId}`)
			.then((uid) => {
				const url = `${prefixApiUrl || window.location.origin}/${getCountry()?.toLocaleLowerCase() || ''}/documents/download/${uid.body}/${item.source}`;
				if (!isVchasnoDoc) return fetchDriveDoc(url);
				setLoadPreview(false);
				const viewerLink = item.extension === FileTypes.doc || item.extension === FileTypes.docx ? viewUrlOffice : viewUrlGoogle;
				const iFrame: HTMLElement | null = document.getElementById('previewFrame');
				if (iFrame) iFrame.setAttribute('src', item.extension === FileTypes.pdf || imageType.length ? url : `${viewerLink}${url}`);
			})
			.catch(() => {
				setLoadPreview(false);
				toast(t('errors:somethingWentWrong'), { type: 'error' });
			});
	};

	const fetchDriveDoc = (url: string) => {
		fetch(url)
			.then((response) => response.arrayBuffer())
			.then((buffer) => {
				setLoadPreview(false);
				const blob = new Blob([buffer], { type: 'application/pdf' });
				const blobLink = URL.createObjectURL(blob);
				const iFrame: HTMLElement | null = document.getElementById('previewFrame');
				if (iFrame) iFrame.setAttribute('src', blobLink);
			})
			.catch(() => {
				setLoadPreview(false);
				toast(t('errors:somethingWentWrong'), { type: 'error' });
			});
	};

	const getDocTypeStatus = () => {
		if (!isServiceUser) {
			if (StatusDocType.Input === item.direction) {
				return (
					<>
						<Tooltip text={<Trans t={t} i18nKey={t('docType.outcoming')} />} nowrap placement="right">
							<span>
								<Nowrap>
									<span>
										<OutputDocumentIcon />
									</span>
								</Nowrap>
							</span>
						</Tooltip>
					</>
				);
			}

			if (StatusDocType.Output === item.direction) {
				return (
					<>
						<Tooltip text={<Trans t={t} i18nKey={t('docType.incoming')} />} nowrap placement="right">
							<span>
								<Nowrap>
									<span>
										<InputDocumentIcon />
									</span>
								</Nowrap>
							</span>
						</Tooltip>
					</>
				);
			}
		}
		if (StatusDocType.Input === item.direction) {
			return (
				<>
					<Tooltip text={<Trans t={t} i18nKey={t('docType.incoming')} />} nowrap placement="right">
						<span>
							<Nowrap>
								<span>
									<InputDocumentIcon />
								</span>
							</Nowrap>
						</span>
					</Tooltip>
				</>
			);
		}

		if (StatusDocType.Output === item.direction) {
			return (
				<>
					<Tooltip text={<Trans t={t} i18nKey={t('docType.outcoming')} />} nowrap placement="right">
						<span>
							<Nowrap>
								<span>
									<OutputDocumentIcon />
								</span>
							</Nowrap>
						</span>
					</Tooltip>
				</>
			);
		}
	};

	const getListItem = () => {
		setShowItem(!showItem);
	};

	const blockItem = () => {
		setShowItem(false);
	};

	const handleCopyText = (text: string | string[]) => {
		if (Array.isArray(text)) return text.join(' ');
		return text;
	};
	useClickAwayListener(ref, blockItem);

	const getEdrpouOwners = (valueArr: string[]) => {
		return (
			<div className={classes.blockEdrpou}>
				{valueArr.length > 1 && (
					<>
						<button className={clsx(classes.edrpouOwnerButton)} onClick={getListItem}>
							+{valueArr.slice(1).length} <ArrowDownIcon />
						</button>
						{showItem && (
							<div className={classes.blockEdrpouItem}>
								{valueArr.slice(1).map((it) => (
									<div key={it}>{it}</div>
								))}
							</div>
						)}
					</>
				)}
				<span>{valueArr[0]}</span>
			</div>
		);
	};

	const drawerTitle = (
		<div className={classes.titleContainer}>
			<div className={classes.titleWithLink}>
				<span>{item.title}</span>
				<IconButton size="average" className={classes.adminLink} component="a" target="_blank" href={isVchasnoDoc ? item.url : googleUrl}>
					<LinkIcon />
				</IconButton>
			</div>
			<div className={clsx(classes.statusText, getStatusStyle[item.status as keyof typeof getStatusStyle])}>
				<span>{getStatusText(item.status)}</span>
			</div>
		</div>
	);

	return (
		<>
			<Modal
				title={drawerTitle}
				modalClassName={classes.drawerInner}
				titleClassName={classes.drawerTitle}
				bodyClassName={classes.drawerBody}
				customPosition={{ top: -20, left: 60 }}
				closeButtonClassName={classes.closeIcon}
				open={openDrawer}
				onClose={handleDrawerClose}
			>
				<div className={classes.wrapperFrame}>
					{loadPreview ? (
						<div className={classes.innerContent}>
							<Loader simple />
						</div>
					) : (
						<div className={classes.innerContent}>
							{imageType.length ? (
								<img className={classes.previewImage} id="previewFrame" alt="preview" />
							) : (
								<iframe className={classes.previewFrame} data-testid={TEST_IDS.DOCUMENT_FRAME} title="preview" id="previewFrame"></iframe>
							)}
							<DocumentStores taxIds={item.edrpouOwners} />
						</div>
					)}
				</div>
			</Modal>
			<tr ref={ref} data-testid={TEST_IDS.DOCUMENTS_TABLE_BODY_ROW} onClick={handleDrawerOpen}>
				<td className={classes.date}>
					<div>
						{item.direction && getDocTypeStatus()}
						<span className={classes.dateTitle}>
							{item.source === SourceDocType.GOOGLE_DRIVE ? formatDate(new Date(item?.date)) : formatDate(new Date(item?.dateCreated))}
						</span>
					</div>
				</td>

				<td className={classes.nameDocument}>
					<Tooltip text={<Trans t={t} i18nKey={item.title} />} nowrap placement="right">
						<span>
							<Nowrap>
								<span>{item.title}</span>
							</Nowrap>
						</span>
					</Tooltip>
				</td>

				<td className={classes.type}>
					<Nowrap>
						<span>{item.category}</span>
					</Nowrap>
				</td>

				<td className={classes.numberDocument}>
					<Nowrap>
						<span>{item.number}</span>
					</Nowrap>
				</td>

				<td className={classes.edrpouOwner}>
					<div className={classes.edrpouOwner_inner}>
						{getEdrpouOwners(item.edrpouOwners)}
						<CopyButton text={handleCopyText(item.edrpouOwners)} />
					</div>
				</td>

				{/* <td className={classes.partner}>
					<div className={classes.partner_inner}>
						{item.edrpouOwners}
						<div onClick={handleCopyText(item.edrpouOwners)} className={classes.partner_ico}>
							<CopyIcon />
						</div>
					</div>
				</td> */}

				<td className={classes.status}>
					<div className={clsx(classes.statusText, getStatusStyle[item.status as keyof typeof getStatusStyle])}>
						<Nowrap>
							<span>{getStatusText(item.status)}</span>
						</Nowrap>
					</div>
				</td>

				<td className={classes.linkTo}>
					<div className={classes.linkTo_inner}>
						<Tooltip text={t('preview')} nowrap placement="left">
							<div className={classes.linkTo_show}>
								<div className={classes.link} onClick={handleDrawerOpen}>
									<Nowrap>
										<span>
											<ShowIcon />
										</span>
									</Nowrap>
								</div>
							</div>
						</Tooltip>
						<Tooltip text={t('openVchasno')} nowrap placement="top-end">
							<div className={classes.linkTo_elem}>
								<a href={isVchasnoDoc ? item.url : googleUrl} className={classes.link} target="_blank" rel="noreferrer">
									<Nowrap>
										<span>
											<LinkVchasnoIcon width={15} height={15} />
										</span>
									</Nowrap>
								</a>
							</div>
						</Tooltip>
					</div>
				</td>
			</tr>
		</>
	);
};

export default DocumentListItem;
