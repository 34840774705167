import { clientGraphql } from 'api';
import { GET_CUSTOMERS } from 'api/customers';
import { IPagination } from 'interfaces';
import { ICustomer, ICustomerFilter } from 'interfaces/customer';
import { Dispatch } from 'redux';

import {
	CHANGE_CUSTOMER_DATA,
	CHANGE_CUSTOMERS_FILTER,
	FETCH_CUSTOMERS_FAILURE,
	FETCH_CUSTOMERS_START,
	FETCH_CUSTOMERS_SUCCESS,
	SET_CUSTOMERS_PAGE,
	SET_CUSTOMERS_PAGE_SIZE,
} from './types';

export const fetchCustomers =
	(filter: ICustomerFilter, { page, pageSize }: IPagination, aborterRef: AbortController) =>
	(dispatch: Dispatch): void => {
		dispatch({ type: FETCH_CUSTOMERS_START });
		clientGraphql
			.query({
				query: GET_CUSTOMERS,
				variables: { page, pageSize, search: filter.search },
				fetchPolicy: 'network-only',
				context: {
					fetchOptions: {
						signal: aborterRef.signal,
					},
				},
			})
			.then(({ data }: { data: { customers: { data: ICustomer; total: number } } }) => {
				dispatch({
					payload: {
						items: data.customers.data,
						total: data.customers.total,
					},
					type: FETCH_CUSTOMERS_SUCCESS,
				});
			})
			.catch(() => {
				dispatch({ type: FETCH_CUSTOMERS_FAILURE });
			});
	};

export const setCustomersPage = (payload: number) => ({
	payload,
	type: SET_CUSTOMERS_PAGE,
});

export const setCustomersPageSize = (payload: number) => ({
	payload,
	type: SET_CUSTOMERS_PAGE_SIZE,
});

export const changeCustomersPageFilter = (field: keyof ICustomerFilter, value: ICustomerFilter[typeof field]) => ({
	payload: { field, value },
	type: CHANGE_CUSTOMERS_FILTER,
});

export const setCustomerData = (updatedCustomer: ICustomer) => ({
	payload: updatedCustomer,
	type: CHANGE_CUSTOMER_DATA,
});
