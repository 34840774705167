import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { clientGraphql } from 'api';
import { formatDate, formatPrice, getDisputesAmount, getUniqueDisputes } from 'helpers';
import clsx from 'helpers/clsx';
import { getCountry } from 'helpers/jwt';
import { useOrders } from 'hooks/useOrders';
import { useUser } from 'hooks/useUser';
import { CountryCodeEnum, FinalStatus } from 'interfaces';
import { IOrder, OrderStatus } from 'interfaces/order';
import { ReactComponent as ApprovedIcon } from 'static/images/approved.svg';
import { ReactComponent as AutoIcon } from 'static/images/autoapprove.svg';
import { ReactComponent as CanceledIcon } from 'static/images/canceled.svg';
import { ReactComponent as EditIcon } from 'static/images/edit.svg';
import { ReactComponent as PaymentMethod } from 'static/images/paymentMethodAfterPeriod.svg';
import { ReactComponent as PendingIcon } from 'static/images/pending.svg';
import { TEST_IDS } from 'tests/config';

import CopyButton from 'components/CopyButton';
import Nowrap from 'components/Nowrap';
import Tooltip from 'components/Tooltip';

// import { ReactComponent as PercentIcon } from 'static/images/percent.svg';
import classes from './OrdersContainer.module.scss';
const Modal = React.lazy(() => import('./Modal'));

interface IProps {
	item: IOrder;
}

const Order: React.FC<IProps> = ({ item }) => {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const { hasMarketplace } = useUser();
	const { filter } = useOrders();
	const { t } = useTranslation(['orders', 'common', 'disputeReason']);

	const handleOpenModal = () => setOpenModal(true);

	const country = getCountry();

	const handleCloseModal = async () => {
		setOpenModal(false);
		await clientGraphql.clearStore();
	};

	const getIconState = () => {
		switch (item.orderStatus) {
			case OrderStatus.APPROVED:
				return <ApprovedIcon className={clsx(classes.icon, classes.icon_approved)} />;
			case OrderStatus.REJECTED:
				return <CanceledIcon className={clsx(classes.icon, classes.icon_canceled)} />;
			case OrderStatus.AMENDING:
				return <EditIcon className={clsx(classes.icon, classes.icon_edit)} />;
			case OrderStatus.PENDING:
				return <PendingIcon className={clsx(classes.icon, classes.icon_pending)} />;
			case OrderStatus.AUTO_APPROVAL:
				return <AutoIcon className={clsx(classes.icon, classes.icon_approved)} />;
		}
	};

	const getToBePaid = () => {
		if (!item.expectedPaymentDate || !item.lastPaymentDate) return null;
		const expectedPaymentDate = new Date(item.expectedPaymentDate);
		const lastPaymentDate = new Date(item.lastPaymentDate);
		return `${formatDate(expectedPaymentDate, '.', false)} - ${formatDate(lastPaymentDate, '.', false)}`;
	};

	const showDisputesAndWaitingTime = country === CountryCodeEnum.KZ;

	return (
		<>
			<tr
				className={clsx({ [classes.isClosed]: item.orderStatus === OrderStatus.APPROVED })}
				onClick={handleOpenModal}
				data-testid={TEST_IDS.ORDERS_TABLE_BODY_ROW}
			>
				<td className={clsx(classes.light, classes.idCell)}>
					<div className={classes.codeInner}>
						<div className={clsx(classes.iconWrap, classes.flex, classes.alignCenter)}>
							{getIconState()}
							{item.code}
						</div>
						<CopyButton className={classes.codeIco} text={item.code} />
					</div>
				</td>
				{filter.approvedAfterPeriodTab && (
					<td className={clsx(classes.dateCell)}>
						{item.approvedAfterPeriodDto && (
							<Tooltip text={<Trans t={t} i18nKey="tooltips.approvedAt" />} nowrap>
								<span className={clsx(classes.flex, classes.alignCenter)}>
									<Nowrap>{formatDate(new Date(item.approvedAfterPeriodDto.approvedAt))}</Nowrap>
								</span>
							</Tooltip>
						)}
					</td>
				)}
				<td className={clsx(classes.dateCell)}>
					<Tooltip text={<Trans t={t} i18nKey="tooltips.date" />} nowrap>
						<span className={clsx(classes.flex, classes.alignCenter)}>
							<Nowrap>{formatDate(new Date(item.dispatchingTime))}</Nowrap>
						</span>
					</Tooltip>
				</td>
				{filter.disputesTab && (
					<td className={clsx(classes.dateCell)}>
						{item.disputes && (
							<Tooltip
								nowrap
								text={getUniqueDisputes(item.disputes, 'disputeCreationDate').map(
									(d, i, arr) => `${formatDate(new Date(d))}${arr.length > 1 && i !== 0 ? ', ' : ''}`,
								)}
							>
								<span className={clsx(classes.flex, classes.alignCenter)}>
									<Nowrap>
										{getUniqueDisputes(item.disputes, 'disputeCreationDate').map(
											(d, i, arr) => `${formatDate(new Date(d))}${arr.length > 1 && i !== 0 ? ', ' : ''}`,
										)}
									</Nowrap>
								</span>
							</Tooltip>
						)}
					</td>
				)}
				<td className={clsx(classes.light, classes.pointCodeCell)}>
					<Tooltip text={<Trans t={t} i18nKey="tooltips.storeAddressId" />} nowrap>
						<span>
							<Nowrap>{item.storeAddressId}</Nowrap>
						</span>
					</Tooltip>
				</td>
				<td className={clsx(classes.big, classes.bigLength)} title={item.storeName}>
					<Tooltip text={t('fields.storeName')} nowrap>
						<span>
							<Nowrap>{item.storeName}</Nowrap>
						</span>
					</Tooltip>
				</td>
				<td className={classes.companyName} title={item.companyName}>
					<Tooltip text={<Trans t={t} i18nKey="tooltips.companyName" />} nowrap>
						<span>
							<span className={clsx(classes.taxId, classes.big)}>
								<Nowrap>{item.taxId}</Nowrap>
							</span>
							&nbsp;
							<Nowrap>{item.companyName}</Nowrap>
						</span>
					</Tooltip>
				</td>
				<td className={clsx(classes.big, classes.alignRight, { [classes.light]: !item.amount })}>
					<Tooltip text={<Trans t={t} i18nKey="tooltips.amount" />} nowrap>
						<span>
							<Nowrap>{formatPrice(item.amount, 2)}</Nowrap>
						</span>
					</Tooltip>
					{!!item.approvedAfterPeriodDto?.amount && filter.approvedAfterPeriodTab && (
						<span>
							<Nowrap className={clsx(classes.diff, classes.success, { [classes.error]: item.approvedAfterPeriodDto?.amount < 0 })}>
								{formatPrice(item.approvedAfterPeriodDto?.amount, 2, ',', '---', true)}
							</Nowrap>
						</span>
					)}
				</td>
				<td className={clsx(classes.big, classes.alignRight, { [classes.light]: !item.productDiscountAssumedByPartner })}>
					{item.productDiscountAssumedByPartner ? (
						<Tooltip text={<Trans t={t} i18nKey="tooltips.productDiscountAssumedByPartner" />} nowrap>
							<span>
								<Nowrap>{formatPrice(item.productDiscountAssumedByPartner, 2)}</Nowrap>
							</span>
						</Tooltip>
					) : (
						<span>
							<Nowrap>{formatPrice(item.productDiscountAssumedByPartner, 2)}</Nowrap>
						</span>
					)}
					{!!item.approvedAfterPeriodDto?.productDiscountAssumedByPartner && filter.approvedAfterPeriodTab && (
						<span>
							<Nowrap
								className={clsx(classes.diff, classes.success, {
									[classes.error]: item.approvedAfterPeriodDto?.productDiscountAssumedByPartner < 0,
								})}
							>
								{formatPrice(item.approvedAfterPeriodDto?.productDiscountAssumedByPartner, 2, ',', '---', true)}
							</Nowrap>
						</span>
					)}
				</td>
				<td className={clsx(classes.big, classes.alignRight, { [classes.light]: !item.deliveryDiscountAssumedByPartner })}>
					{item.deliveryDiscountAssumedByPartner ? (
						<Tooltip text={<Trans t={t} i18nKey="tooltips.deliveryDiscountAssumedByPartner" />} nowrap>
							<span>
								<Nowrap>{formatPrice(item.deliveryDiscountAssumedByPartner, 2)}</Nowrap>
							</span>
						</Tooltip>
					) : (
						<span>
							<Nowrap>{formatPrice(item.deliveryDiscountAssumedByPartner, 2)}</Nowrap>
						</span>
					)}
					{!!item.approvedAfterPeriodDto?.deliveryDiscountAssumedByPartner && filter.approvedAfterPeriodTab && (
						<span>
							<Nowrap
								className={clsx(classes.diff, classes.success, {
									[classes.error]: item.approvedAfterPeriodDto?.deliveryDiscountAssumedByPartner < 0,
								})}
							>
								{formatPrice(item.approvedAfterPeriodDto?.deliveryDiscountAssumedByPartner, 2, ',', '---', true)}
							</Nowrap>
						</span>
					)}
				</td>
				{/* <Tooltip text={item.isAction && <Trans t={t} i18nKey="tooltips.isAction" />} nowrap>
					<td className={classes.isActionCell}>{item.isAction && <div className={classes.isAction}><PercentIcon /></div>}</td>
				</Tooltip> */}
				<td className={clsx(classes.big, classes.alignRight, { [classes.light]: !item.promotionAssumedByPartner })}>
					{item.promotionAssumedByPartner ? (
						<Tooltip text={<Trans t={t} i18nKey="tooltips.promotionAssumedByPartner" />} nowrap>
							<span>
								<Nowrap>{formatPrice(item.promotionAssumedByPartner, 2)}</Nowrap>
							</span>
						</Tooltip>
					) : (
						<span>
							<Nowrap>{formatPrice(item.promotionAssumedByPartner, 2)}</Nowrap>
						</span>
					)}
					{!!item.approvedAfterPeriodDto?.promotionAssumedByPartner && filter.approvedAfterPeriodTab && (
						<Nowrap
							className={clsx(classes.diff, classes.success, {
								[classes.error]: item.approvedAfterPeriodDto?.promotionAssumedByPartner < 0,
							})}
						>
							{formatPrice(item.approvedAfterPeriodDto?.promotionAssumedByPartner, 2, ',', '---', true)}
						</Nowrap>
					)}
				</td>
				<td className={clsx(classes.promoDelivery, classes.alignRight, { [classes.light]: !item.deliveryDiscountPaidByPartner })}>
					{item.deliveryDiscountPaidByPartner ? (
						<Tooltip text={<Trans t={t} i18nKey="tooltips.promotionDeliveryByPartner" />} nowrap>
							<span>
								<Nowrap>{formatPrice(item.deliveryDiscountPaidByPartner, 2)}</Nowrap>
							</span>
						</Tooltip>
					) : (
						<span>
							<Nowrap>{formatPrice(item.deliveryDiscountPaidByPartner, 2)}</Nowrap>
						</span>
					)}
					{!!item.approvedAfterPeriodDto?.deliveryDiscountPaidByPartner && filter.approvedAfterPeriodTab && (
						<Nowrap
							className={clsx(classes.diff, classes.success, {
								[classes.error]: item.approvedAfterPeriodDto?.deliveryDiscountPaidByPartner < 0,
							})}
						>
							{formatPrice(item.approvedAfterPeriodDto?.deliveryDiscountPaidByPartner, 2, ',', '---', true)}
						</Nowrap>
					)}
				</td>
				<td className={clsx(classes.success, classes.big, classes.alignRight, classes.percentCell)}>
					<Tooltip text={<Trans t={t} i18nKey="tooltips.partnerCommissionPercentage" />} nowrap>
						<span>
							<Nowrap>{item.partnerCommissionPercentage}%</Nowrap>
						</span>
					</Tooltip>
				</td>
				{hasMarketplace && (
					<>
						<td className={clsx(classes.big, classes.alignRight, { [classes.light]: !item.receivedFunds })}>
							{item.receivedFunds ? (
								<Tooltip text={<Trans t={t} i18nKey="tooltips.receivedFunds" />} nowrap>
									<span>
										<Nowrap>{formatPrice(item.receivedFunds, 2)}</Nowrap>
									</span>
								</Tooltip>
							) : (
								<span>
									<Nowrap>{formatPrice(item.receivedFunds, 2)}</Nowrap>
								</span>
							)}
							{!!item.approvedAfterPeriodDto?.receivedFunds && filter.approvedAfterPeriodTab && (
								<span>
									<Nowrap
										className={clsx(classes.diff, classes.success, {
											[classes.error]: item.approvedAfterPeriodDto?.receivedFunds < 0,
										})}
									>
										{formatPrice(item.approvedAfterPeriodDto?.receivedFunds, 2, ',', '---', true)}
									</Nowrap>
								</span>
							)}
						</td>
						<td className={clsx(classes.big, classes.alignRight, { [classes.light]: !item.deliveryFee })}>
							{item.deliveryFee ? (
								<Tooltip text={t('fields.deliveryFee')} nowrap>
									<span>
										<Nowrap>{formatPrice(item.deliveryFee, 2)}</Nowrap>
									</span>
								</Tooltip>
							) : (
								<span>
									<Nowrap>{formatPrice(item.deliveryFee, 2)}</Nowrap>
								</span>
							)}
							{!!item.approvedAfterPeriodDto?.deliveryFee && filter.approvedAfterPeriodTab && (
								<span>
									<Nowrap className={clsx(classes.diff, classes.success, { [classes.error]: item.approvedAfterPeriodDto?.deliveryFee < 0 })}>
										{formatPrice(item.approvedAfterPeriodDto?.deliveryFee, 2, ',', '---', true)}
									</Nowrap>
								</span>
							)}
						</td>
						<td className={clsx(classes.big, { [classes.light]: !item.deliveryPromotionAssumedByPartner })}>
							<Nowrap>{formatPrice(item.deliveryPromotionAssumedByPartner || 0, 2)}</Nowrap>
							{!!item.approvedAfterPeriodDto?.deliveryPromotionAssumedByPartner && filter.approvedAfterPeriodTab && (
								<Nowrap
									className={clsx(classes.diff, classes.success, {
										[classes.error]: item.approvedAfterPeriodDto?.deliveryPromotionAssumedByPartner < 0,
									})}
								>
									{formatPrice(item.approvedAfterPeriodDto?.deliveryPromotionAssumedByPartner, 2, ',', '---', true)}
								</Nowrap>
							)}
						</td>
						<td className={clsx(classes.big, { [classes.light]: !item.serviceFee })}>
							<Nowrap>{formatPrice(item.serviceFee || 0, 2)}</Nowrap>
						</td>
					</>
				)}
				<td className={clsx(classes.big, { [classes.light]: !item.partnerPayment })}>
					<Nowrap>{formatPrice(item.partnerPayment, 2)}</Nowrap>
					{!!item.approvedAfterPeriodDto?.partnerPayment && filter.approvedAfterPeriodTab && (
						<Nowrap className={clsx(classes.diff, classes.success, { [classes.error]: item.approvedAfterPeriodDto?.partnerPayment < 0 })}>
							{formatPrice(item.approvedAfterPeriodDto?.partnerPayment, 2, ',', '---', true)}
						</Nowrap>
					)}
				</td>
				<td>
					<Tooltip text={<Trans t={t} i18nKey="tooltips.toBePaid" />} nowrap>
						<span>
							<Nowrap>{getToBePaid()}</Nowrap>
						</span>
					</Tooltip>
				</td>
				<td className={clsx(classes.light, { [classes.error]: item.finalStatus === FinalStatus.CANCELED })}>
					<Tooltip text={<Trans t={t} i18nKey="tooltips.finalStatus" />} nowrap>
						<span>
							<Nowrap>{item.finalStatus}</Nowrap>
						</span>
					</Tooltip>
				</td>
				<td className={clsx(classes.mediumLength)} title={item.cancelReason}>
					<Tooltip text={<Trans t={t} i18nKey="tooltips.cancelReason" />} nowrap>
						<span>
							<Nowrap>{item.cancelReason}</Nowrap>
						</span>
					</Tooltip>
				</td>
				<td title={item.partnerResolution}>
					<Nowrap>{item.partnerResolution}</Nowrap>
				</td>
				<td className={clsx(classes.alignRight, classes.big)}>
					<Tooltip text={item.chargedToPartner > 0 && <Trans t={t} i18nKey="tooltips.chargedToPartner" />} placement="left" nowrap>
						<span>
							<Nowrap>{formatPrice(item.chargedToPartner, 2)}</Nowrap>
						</span>
					</Tooltip>
					{!!item.approvedAfterPeriodDto?.chargedToPartner && filter.approvedAfterPeriodTab && (
						<span>
							<Nowrap className={clsx(classes.diff, classes.success, { [classes.error]: item.approvedAfterPeriodDto?.chargedToPartner < 0 })}>
								{formatPrice(item.approvedAfterPeriodDto?.chargedToPartner, 2, ',', '---', true)}
							</Nowrap>
						</span>
					)}
				</td>
				{/* <td className={classes.light}>
					<Tooltip text={<Trans t={t} i18nKey="tooltips.returnType" />} placement="left" nowrap>
						<span><Nowrap>{item.returnType}</Nowrap></span>
					</Tooltip>
				</td> */}
				{showDisputesAndWaitingTime && (
					<>
						<td className={clsx(classes.big, { [classes.light]: !item.waitingTime })}>
							<Nowrap>{formatPrice(item.waitingTime, 2)}</Nowrap>
						</td>
						<td className={clsx(classes.big, { [classes.light]: !item.disputes?.length })}>
							<Nowrap>{formatPrice(item.disputes && item.disputes?.length > 0 ? getDisputesAmount(item.disputes) : 0, 2)}</Nowrap>
						</td>
						<td>
							{item.disputes && getDisputesAmount(item.disputes) !== 0 && (
								<Tooltip
									text={
										<div>
											{item.disputes &&
												getUniqueDisputes(item.disputes, 'type').map((r, i, arr) => (
													<span key={r}>
														<Trans t={t} i18nKey={`disputeReason:${r}`} />
														{i + 1 !== arr.length && ', '}
													</span>
												))}
										</div>
									}
									placement="left"
									nowrap
								>
									<span>
										<Nowrap>
											{item.disputes &&
												getUniqueDisputes(item.disputes, 'type').map((r, i, arr) => (
													<span key={r}>
														<Trans t={t} i18nKey={`disputeReason:${r}`} />
														{i + 1 !== arr.length && ', '}
													</span>
												))}
										</Nowrap>
									</span>
								</Tooltip>
							)}
						</td>
					</>
				)}
				<td>
					<div style={{ display: 'flex' }}>
						<Tooltip text={<Trans t={t} i18nKey="tooltips.paymentMethod" />} placement="left" nowrap>
							<span>
								<Nowrap>{item.paymentMethod}</Nowrap>
							</span>
						</Tooltip>
						{item.approvedAfterPeriodDto && !item.approvedAfterPeriodDto?.isOriginalPaymentMethod && filter.approvedAfterPeriodTab && (
							<Tooltip text={<Trans t={t} i18nKey="tooltips.changedPaymentMethod" />} placement="bottom-end" nowrap>
								<span>
									<Nowrap>
										<PaymentMethod style={{ marginLeft: 5 }} />
									</Nowrap>
								</span>
							</Tooltip>
						)}
					</div>
				</td>
			</tr>
			{openModal && <Modal open onClose={handleCloseModal} item={item} />}
		</>
	);
};

export default Order;
