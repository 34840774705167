import React from 'react';
import Orders from 'containers/LaaSOrders';
import NotificationAuthOtherUser from 'containers/NotificationAuthOtherUser';
import { useExport } from 'hooks/useExport';
import { useLaaSOrders } from 'hooks/useLaaSOrders';
import { TEST_IDS } from 'tests/config';

import Loader from 'components/Loader';

import OrdersTotals from './Totals';

import classes from './Orders.module.scss';

const OrdersPage: React.FC = () => {
	const { onload: onloadOrders, wasLoaded } = useLaaSOrders();
	const { onload: loadingExport } = useExport();

	const showLoader = () => {
		return (onloadOrders && wasLoaded) || loadingExport;
	};

	return (
		<div className={classes.root} data-testid={TEST_IDS.ORDERS_PAGE}>
			<NotificationAuthOtherUser />
			{showLoader() && <Loader />}
			<OrdersTotals />
			<Orders />
		</div>
	);
};

export default OrdersPage;
