export interface IDocument {
	id: string | number;
	docId: string;
	date: string;
	direction: string;
	dateCreated: string | Date;
	title: string;
	amount: number;
	type: string;
	category: string;
	extension: string;
	number: number | string;
	edrpouOwners: string[];
	status: string;
	source: SourceDocType;
	pathToFile: string;
	url: string;
	storeAddressIds: number[];
}

export enum Status {
	DocumentDeflected = '7006',
}

export enum StatusDocType {
	Input = 'INCOMING',
	Output = 'OUTCOMING',
}

export enum SourceDocType {
	OUTCOMING_VCHASNO = 'OUTCOMING_VCHASNO',
	INCOMING_VCHASNO = 'INCOMING_VCHASNO',
	GOOGLE_DRIVE = 'GOOGLE_DRIVE',
}

export enum ImageTypes {
	jpg = '.jpg',
	jpeg = '.jpeg',
	png = '.png',
}

export enum FileTypes {
	doc = '.doc',
	docx = '.docx',
	pdf = '.pdf',
}
