import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'helpers/clsx';
import { ReactComponent as PlusIcon } from 'static/images/plus.svg';

import MainButton from 'components/MainButton';

import Template from './template';
import { IProps } from './types';

import classes from './CreateContract.module.scss';

const CreateContract: React.FC<IProps> = ({ children, ...props }) => {
	const [open, setOpen] = useState<boolean>(false);
	const { t } = useTranslation(['partners']);

	const onClose = () => {
		setOpen(false);
		if (props.onClose) props.onClose();
	};

	const onOpen = () => {
		setOpen(true);
	};

	const childrenClone = React.Children.map(children, (child) => {
		return (
			React.isValidElement(child) &&
			React.cloneElement(child as React.ReactElement, {
				onClick: (e: React.MouseEvent<HTMLElement>) => {
					onOpen();
					if (child.props.onClick) {
						child.props.onClick(e);
					}
				},
			})
		);
	});

	return (
		<>
			{childrenClone || (
				<MainButton className={clsx(classes.flex, classes.alignItemsCenter)} onClick={onOpen}>
					<PlusIcon className={classes.addIcon} /> {t('createContract')}
				</MainButton>
			)}
			{open && <Template {...props} onClose={onClose} />}
		</>
	);
};

export default CreateContract;
