import { useTranslation } from 'react-i18next';
import clsx from 'helpers/clsx';

import { Select } from 'components/FormControl';

import { IProps } from './types';

import classes from './UserStoreAddresses.module.scss';

const UserCustomerData = ({ data, isLastItem }: IProps) => {
	const { t } = useTranslation('users');

	if (!data.length) return null;

	const handleChangeOnlyView = () => undefined;

	return (
		<Select
			onlyView
			rootClassName={clsx(classes.addressSelect, { [classes.isLast]: isLastItem })}
			controlClassName={classes.control}
			emptyLabel={`${t('users:availableCustomers')} (${data.length})`}
			onChange={handleChangeOnlyView}
			options={data?.map(({ customerId: value, name: label, address: additionalLabel }) => ({ value, label, additionalLabel }))}
			renderOption={({ value, label, additionalLabel }) => (
				<div className={classes.selectOption}>
					<div className={classes.selectOption_headline}>
						<div>{value}</div>
						<div>{label}</div>
					</div>
					<div className={classes.selectOption_address}>{additionalLabel}</div>
				</div>
			)}
		/>
	);
};

export default UserCustomerData;
