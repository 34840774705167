import { TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux';
import { AnyAction, applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkDispatch } from 'redux-thunk';

import accounts from './accounts/reducer';
import contractGeneration from './contracts/reducer';
import customers from './customers/reducer';
import documents from './documents/reducer';
import laasOrders from './laasOrders/reducer';
import orders from './orders/reducer';
import partnersFile from './partnersFile/reducer';
import storeAddresses from './storeAddresses/reducer';
import users from './users/reducer';

const rootReducer = combineReducers({
	orders,
	storeAddresses,
	users,
	documents,
	accounts,
	partnersFile,
	contractGeneration,
	laasOrders,
	customers,
});

export type AppState = ReturnType<typeof rootReducer>;

export const useSelector: TypedUseSelectorHook<AppState> = useReduxSelector;
export type AppDispatch = ThunkDispatch<typeof rootReducer, unknown, AnyAction>;

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
