import React from 'react';
import clsx from 'helpers/clsx';

import { IPropsTextarea } from './types';

import 'react-datepicker/dist/react-datepicker.css';
import classes from './FormControl.module.scss';

const Input: React.ForwardRefRenderFunction<HTMLDivElement, IPropsTextarea> = (
	{ rootClassName, controlClassName, onChange, value, disabled = false, placeholder, label, fullWidth, error },
	refRoot,
) => {
	return (
		<div
			ref={refRoot}
			className={clsx(classes.root, classes.input, rootClassName, {
				[classes.fullWidth]: fullWidth,
			})}
		>
			{label && <div className={classes.label}>{label}</div>}
			<div className={classes.inner}>
				<textarea
					className={clsx(classes.control, controlClassName, {
						[classes.error]: error,
					})}
					onChange={onChange}
					value={value}
					disabled={disabled}
					placeholder={placeholder}
				/>
			</div>
			{error && <div className={classes.errorText}>{error}</div>}
		</div>
	);
};

export default React.forwardRef(Input);
