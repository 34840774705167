import React, { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import clsx from 'helpers/clsx';
import { useClickAwayListener } from 'hooks/useClickAwayListener';

import { IProps } from './types';

import classes from './Tooltip.module.scss';

const Tooltip: React.FC<IProps> = ({
	children,
	text,
	placement = 'right',
	nowrap = false,
	withClick = false,
	outside = false,
	warning = false,
	className,
	rootClassName,
	forceOpen,
	testId,
}) => {
	const [open, setOpen] = useState<boolean>(!!forceOpen);
	const ref = useRef(null);

	useEffect(() => setOpen(!!forceOpen), [forceOpen]);

	const toggleOpen = () => {
		setOpen((state) => !state);
	};

	const handleClose = () => {
		setOpen(false);
	};

	useClickAwayListener(ref, handleClose);

	const tooltip = text && (
		<div
			data-testid={testId}
			className={clsx(classes.tooltip, className, {
				[classes.tooltip_warning]: warning,
				[classes[`tooltip_${placement}`]]: placement,
				[classes.nowrap]: nowrap,
				[classes.tooltip_open]: open,
			})}
		>
			{text}
		</div>
	);

	const childrenClone = React.Children.map(children, (child) => {
		return (
			React.isValidElement(child) &&
			React.cloneElement(child as React.ReactElement, {
				className: clsx(child.props.className, {
					[classes.root]: !outside,
					[classes.root_withHover]: !withClick,
				}),
				children: outside ? (
					child.props.children
				) : (
					<>
						{child.props.children}
						{tooltip}
					</>
				),
				onClick: withClick ? toggleOpen : undefined,
			})
		);
	});

	const content = outside ? (
		<div
			className={clsx(classes.root, rootClassName, {
				[classes.root_withHover]: !withClick,
			})}
			ref={ref}
		>
			{childrenClone}
			{tooltip}
		</div>
	) : (
		<>{childrenClone}</>
	);

	return content;
};

export default Tooltip;
