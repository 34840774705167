import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UAShortWeekDays, weekDays } from 'const';
import { parseWorkingHours } from 'helpers/contractGeneration';
import { TEST_IDS } from 'tests/config';

import { LightTooltip } from 'components/StyledLightTooltip';

import Template from './template';
import { IProps } from './types';

import classes from './WorkingHours.module.scss';

const WorkingHours: React.FC<IProps> = ({ children, ...props }) => {
	const [open, setOpen] = useState<boolean>(false);
	const { t } = useTranslation('contractGeneration');

	const onClose = () => {
		setOpen(false);
		if (props.onClose) props.onClose();
	};

	const onOpen = () => {
		setOpen(true);
	};

	const childrenClone = React.Children.map(children, (child) => {
		return (
			React.isValidElement(child) &&
			React.cloneElement(child as React.ReactElement, {
				onClick: onOpen,
			})
		);
	});

	const workingHours = parseWorkingHours(props.input.value, props.translation);

	const withShortString = weekDays.map((key) => {
		const shortDayName = UAShortWeekDays[key as keyof typeof UAShortWeekDays];
		if (workingHours[key]) {
			const openBefore = workingHours[key].before.open;
			const closeBefore = workingHours[key].before.close;
			const openAfter = workingHours[key]?.after?.open;
			const closeAfter = workingHours[key]?.after?.close;
			return `${shortDayName} ${openBefore}${closeBefore ? ` - ${closeBefore}` : ''}; ${openAfter ? openAfter : ''}${closeAfter ? ` - ${closeAfter}` : ''}`;
		}
		return `${shortDayName} ${t('workingHours.dayOff')}`;
	});

	return (
		<>
			{childrenClone ||
				(props.input.defaultValue ? (
					<button type="button" onClick={onOpen} data-testid={TEST_IDS.WORKING_HOURS_ADD} className={classes.addButton}>
						{t('contractGeneration:workingHours.add')}
					</button>
				) : (
					<LightTooltip
						arrow
						placement="left"
						color="#493F58"
						title={
							<div className={classes.workingHoursTooltip_body}>
								{withShortString.map((day, i) => (
									<div key={i}>
										<span>{day.slice(0, 2)}</span>
										{day.replace(day.slice(0, 2), '')}
									</div>
								))}
							</div>
						}
					>
						<button type="button" data-testid={TEST_IDS.WORKING_HOURS_ADD} onClick={onOpen} className={classes.addButton}>
							{withShortString.slice(0, 2).toString().slice(0, 16)}...
						</button>
					</LightTooltip>
				))}
			{open && <Template {...props} workingHoursString={props.input.value} onClose={onClose} />}
		</>
	);
};

export default WorkingHours;
