import { gql } from '@apollo/client';

export default gql`
	type Query {
		orders(page: Int, pageSize: Int, filter: OrderFilter): OrderDtoPage
		order(id: ID!): OrderDto
		dateRange: DateRange
		datePickerSchedule(filter: AccountingTypeFilter!): DatePickerScheduleDto
		companyNames(filter: OrderFilter!): [CompanyDto]
		storeNames(filter: OrderFilter!): [String]
		totals(filter: OrderFilter!): Totals
		storeAddress(filter: OrderFilter!): [StoreAddressDto]
		getCurrentUser: UserDto
		getUsers(pageNumber: Int!, pageSize: Int!, filter: UserFilter): UserDtoPage!
		storeAddresses(page: Int, pageSize: Int, filter: StoreAddressFilter): LastStoreAddressValuesDtoPage
		storeAddressHistory(storeAddressId: Int): [StoreAddressFullDto]
		getComments(orderId: ID!): [PartnerCommentResponseDTO!]
		getAmendmentsHistory(orderId: ID!): [OrderPartnerAmendmentDto]
		orderApproval(orderId: ID!): OrderApprovalShortDto
		payoutSchedules(filter: PayoutScheduleFilter): PayoutScheduleDtoPage
		payoutScheduleNotifications: [PayoutScheduleNotification]
		vchasnoDocs(page: Int, pageSize: Int, filter: VchasnoDocsFilter): VchasnoDocsPage
		vchasnoDocsLastSync: VchasnoDocsLastSync
		vchasnoDocsStatuses: [VchasnoDocsFilterItem]
		vchasnoDocsCategories: [VchasnoDocsFilterItem]
		vchasnoDocsCompanies: [VchasnoDocFilterIpnItem]
		vchasnoNumbers(filter: VchasnoDocsFilter): [VchasnoDocNumberFilterItem]
		vchasnoStoreAddress(filter: VchasnoDocsFilter): [VchasnoDocStoreAddressFilterItem]
		vchasnoDocsUnsignedCount(dateFrom: Date): Int
		vchasnoDocsEdrpouOwners: [VchasnoDocsFilterItem]
		storeAddressData(storeAddressIds: [Int]): [StoreAddressesDataDto]
		storeAddressByTaxId(taxIds: [String]): [StoreAddressEdrpouDto]
		invoices(page: Int, pageSize: Int, filter: InvoicePageFilter): InvoicePage
		payments(page: Int, pageSize: Int, filter: PaymentPageFilter): PaymentPage
		debtGlovo(date: Date!): [DebtDto]
		debtCompanies(date: Date!): [DebtDto]
		contractNumbers: [FilterItemDto]
		companyInfo: [CompanyWithInvoiceFilterDto]
		getDeptForUser: OverdueDebtForUserDto
		storeAddressesBot(pageNumber: Int, pageSize: Int, filter: StoreAddressBotFilter): StoreAddressBotPage
		getBulkUploadHistory: [BulkUploadFileDto]
	}
`;
